import React, { Component } from 'react';
import { Container, Grid, Typography, } from '@material-ui/core';
import Security from '../../../../assests/images/who-we-are/Group 1652.png';
import watchimg from '../../../../assests/images/who-we-are/Group 1654.png';
import members from '../../../../assests/images/who-we-are/Group 1657.png';
import umbrella from '../../../../assests/images/who-we-are/Group 1658.png';
import Trust from '../../../../assests/images/who-we-are/Group 1662.png';
import knowldge from '../../../../assests/images/who-we-are/Group 1661.png';
import loyalty from '../../../../assests/images/who-we-are/Group 1660.png';
import competative from '../../../../assests/images/who-we-are/Group 1659.png';



class WhoWeAreGreatReasonsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                <div className="space-5">
                    <Container>
                        <div className="space-top-20 space-bottom-40"> 
                            <Typography variant="h5" className="color-CEA05F text-center1 text-uppercase"><b>8 great reasons to have a watch from WatchOps</b></Typography>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <div className="space-">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} sm={2} md={2}>
                                            <div className="text-center1"> 
                                                <img src={Security} alt="security"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} sm={10} md={10}>
                                            <div>
                                                <div>
                                                    <Typography variant="h6" className="font-weight-500 color-383838">Authorised Retailer</Typography>
                                                </div>
                                                <div>
                                                    <p className="color-383838">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} sm={2} md={2}>
                                            <div className="text-center1">
                                                <img src={watchimg} alt="security"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} sm={10} md={10}>
                                            <div>
                                                <div>
                                                    <Typography variant="h6" className="font-weight-500 color-383838">Largest Selection</Typography>
                                                </div>
                                                <div>
                                                    <p className="color-383838">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} sm={2} md={2}>
                                            <div className="text-center1">
                                                <img src={members} alt="security"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} sm={10} md={10}>
                                            <div>
                                                <div>
                                                    <Typography variant="h6" className="font-weight-500 color-383838">Dedicated After-Sales Staff</Typography>
                                                </div>
                                                <div>
                                                    <p className="color-383838">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} sm={2} md={2}>
                                            <div className="text-center1">
                                                <img src={umbrella} alt="security"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} sm={10} md={10}>
                                            <div>
                                                <div>
                                                    <Typography variant="h6" className="font-weight-500 color-383838">You Are Protected Against Malpractices</Typography>
                                                </div>
                                                <div>
                                                    <p className="color-383838">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                            <div className="space-">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} sm={2} md={2}>
                                            <div className="text-center1"> 
                                                <img src={Trust} alt="security"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} sm={10} md={10}>
                                            <div>
                                                <div>
                                                    <Typography variant="h6" className="font-weight-500 color-383838">Trust</Typography>
                                                </div>
                                                <div>
                                                    <p className="color-383838">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} sm={2} md={2}>
                                            <div className="text-center1">
                                                <img src={knowldge} alt="security"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} sm={10} md={10}>
                                            <div>
                                                <div>
                                                    <Typography variant="h6" className="font-weight-500 color-383838">Knowledgeable Staff and Great Boutiques</Typography>
                                                </div>
                                                <div>
                                                    <p className="color-383838">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} sm={2} md={2}>
                                            <div className="text-center1">
                                                <img src={loyalty} alt="security"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} sm={10} md={10}>
                                            <div>
                                                <div>
                                                    <Typography variant="h6" className="font-weight-500 color-383838">Loyalty Programme</Typography>
                                                </div>
                                                <div>
                                                    <p className="color-383838">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-">
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} sm={2} md={2}>
                                            <div className="text-center1">
                                                <img src={competative} alt="security"/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} sm={10} md={10}>
                                            <div>
                                                <div>
                                                    <Typography variant="h6" className="font-weight-500 color-383838">Competitive Pricing</Typography>
                                                </div>
                                                <div>
                                                    <p className="color-383838">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="bg-image-who-we-are">

                </div>
            </div>
         );
    }
}
 
export default WhoWeAreGreatReasonsComponent;