import React, { Component } from "react";
import { connect } from 'react-redux';
import Slider from "react-slick";
import { Container, Typography, Button } from '@material-ui/core';
import img from '../../../../assests/images/bgimage.png';
import '../style/homeComponent.css';
import { useHistory } from "react-router-dom";
import { Route } from 'react-router-dom';
// import SignSubscribeComponent from './signinSubscribeComponent';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background:"#9F7C50", borderRadius: "50px",color:"white", position:'absolute', right:"0px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#9F7C50", borderRadius: "50px",color:"#fff", position:'absolute', left:"0px" }}
      onClick={onClick}
    />
  );
}

class HomeBannerSlider extends Component {
  constructor(props) {
    super(props);
  
    this.state = {

    }
    this.handleChange = this.handleChange.bind(this);
  }

 
  handleChange(){
    
    const { history } = this.props; 
      if(this.props.currentUser && this.props.currentUser.userId){
        history.push("/search")
      }else{
      history.push("/login")
      }
 }
 
  render() {
     const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />
    };
    return (
      <div>
      <div className="height-25 overflow-hidden">
        <Container maxWidth="xl" style={{padding:'0px',overflow:'hidden'}}>
          <Slider {...settings}>
            <div className="width-100">
              <img src={img} alt="" className="img-resonsive"/>
              <Container>
              <div className="overflow-hidden">
                <Container className="position-text-banner">
                    <Typography variant="p" className="color-white">WatchOps COLLECTION</Typography><br/>
                    <Typography variant="h4" className="alignment-2 color-white space-top-10 font-weight-700">OUR COLLECTION IS SECOND TO NONE</Typography><br/>
                    <Typography variant="p" className="alignment-2 color-white">This is the best in class effective wathes from the luxuary brand watch.</Typography><br/>
                    <Typography variant="p">Take a peek look in to it</Typography><br/><br/>
                  
                    <Button variant="outlined" className="button-slider" onClick={this.handleChange}  >
                   
                      View the collection
                    </Button>
                  
                    {/* <Button variant="outlined" className="button-slider1">
                      Download brochure
                    </Button> */}
                </Container>
              </div>
              </Container>
            </div>
            <div className="width-100">
              <img src={img} alt="" className="img-resonsive"/>
              <Container>
              <div>
                <Container className="position-text-banner">
                    <Typography variant="small" className="color-white">WatchOps COLLECTION</Typography><br/>
                    <Typography variant="h4" className="alignment-2 color-white space-top-10">OUR COLLECTION IS SECOND TO NONE</Typography><br/>
                    <Typography variant="small" className="alignment-2 color-white">This is the best in class effective wathes from the luxuary brand watch.</Typography><br/>
                    <Typography variant="small">Take a peek look in to it</Typography><br/><br/>
                    <Button variant="outlined" className="button-slider" onClick={this.handleChange} >
                      View the collection
                    </Button>
                    {/* <Button variant="outlined" className="button-slider1">
                      Download brochure
                    </Button> */}
                </Container>
              </div>
              </Container>
            </div>
            <div className="width-100">
              <img src={img} alt="" className="img-resonsive"/>
              <Container>
              <div>
                <Container className="position-text-banner">
                    <Typography variant="small" className="color-white">WatchOps COLLECTION</Typography><br/>
                    <Typography variant="h4" className="alignment-2 color-white space-top-10 font-weight-700">OUR COLLECTION IS SECOND TO NONE</Typography><br/>
                    <Typography variant="small" className="alignment-2 color-white">This is the best in class effective wathes from the luxuary brand watch.</Typography><br/>
                    <Typography variant="small">Take a peek look in to it</Typography><br/><br/>
                    <Button variant="outlined" className="button-slider" onClick={this.handleChange}>
                      View the collection
                    </Button>
                    {/* <Button variant="outlined" className="button-slider1">
                      Download brochure
                    </Button> */}
                </Container>
              </div>
              </Container>
            </div>
            <div className="width-100">
              <img src={img} alt="" className="img-resonsive"/>
              <Container>
              <div>
                <Container className="position-text-banner">
                    <Typography variant="small" className="color-white">WatchOps COLLECTION</Typography><br/>
                    <Typography variant="h4" className="alignment-2 color-white space-top-10 font-weight-700">OUR COLLECTION IS SECOND TO NONE</Typography><br/>
                    <Typography variant="small" className="alignment-2 color-white">This is the best in class effective wathes from the luxuary brand watch.</Typography><br/>
                    <Typography variant="small">Take a peek look in to it</Typography><br/><br/>
                    <Button variant="outlined" className="button-slider">
                      View the collection
                    </Button>
                    {/* <Button variant="outlined" className="button-slider1">
                      Download brochure
                    </Button> */}
                </Container>
              </div>
              </Container>
            </div>
          </Slider>
          </Container>
          {/* <div className="position-text-banner1">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Card className="padding">
                  <CardMedia
                    className="media"
                    image={img1}
                  />
                  <CardContent align="center padding-0">
                    <Typography variant="h6" align="center" className="text-wrap">
                      Freedom to change your mind
                    </Typography>
                    <Typography variant="p" align="center">
                      Resales are difficult. Before you commit to something, take it for a test drive.
                    </Typography>
                  </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Card className="padding">
                    <CardMedia
                      className="media"
                      image={img2}
                    />
                    <CardContent align="center">
                      <Typography variant="h6" align="center" className="text-wrap">
                        Freedom to experience variety
                      </Typography>
                      <Typography variant="p" align="center">
                        Your sense of style is constantly evolving. Your watches should be too.
                        <br/>
                        <br/>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Card className="padding">
                    <CardMedia
                      className="media"
                      image={img3}
                    />
                    <CardContent align="center">
                      <Typography variant="h6" align="center" className="text-wrap">
                        Freedom to try before you buy
                      </Typography>
                      <Typography variant="p" align="center">
                        You’re in love but have no idea if it’s going to work in your life. Now you can find out.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
          </div> */}
      </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    currentUser: auth.currentUser,
  }
}
export default connect(mapStateToProps, null)(HomeBannerSlider);