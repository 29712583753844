import React from "react";
import {
  Grid,
  Container,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  InputBase,
  InputAdornment,
  TableContainer,
  Modal,
  Box,
  Input,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { StyledEngineProvider } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { toast } from "react-toastify";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import InviteUsers from "@mui/icons-material/MarkAsUnreadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import ResendInvitation from "@mui/icons-material/SendAndArchive";
import ChangeRole from "@mui/icons-material/SendAndArchive";
import EditIcon from "@mui/icons-material/Edit";
import InviteUserModal from "./Modal/InviteUserModal";
import ChangeRoleModal from "./Modal/changeRoleModal";
import ResendInvitationModal from "./Modal/resendInvitationModal";
import RemoveUser from "./Modal/removeUser";
import EditUserModal from "./Modal/editUserModal";
import UpdateSubscriptionStatusModal from "./Modal/UpdateSubscriptionStatusModal";
import {
  getUserTrackingList,
  getUserTrackingDownload,
} from "./actions/administration";
import { useEffect } from "react";
import { connect } from "react-redux";
import { capitalize } from "lodash";
import {
  formatDate,
  getComponentActionList,
  isActionAllowed,
} from "../../../common/components/Helper/Utils";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-multi-date-picker";
import ConfirmationModal from "./Modal/confirmationModal";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  sortIcon: {
    fontSize: "1rem",
    verticalAlign: "middle",
    cursor: "pointer",
  },
}));

// const columns = [
//   { id: "name", label: "Name", minWidth: 100 },
//   { id: "email", label: "Email", minWidth: 100 },
//   {
//     id: "role",
//     label: "Role",
//     minWidth: 100,
//   },
//   {
//     id: "status",
//     label: "Status",
//     minWidth: 100,
//   },
//   {
//     id: "activity",
//     label: "Last Activity Time",
//     minWidth: 100,
//   },
//   {
//     id: "action",
//     label: "Action",
//     minWidth: 100,
//   },
// ];

// const columns = [
//   { id: "name", label: "Name", minWidth: 100 },
//   { id: "email", label: "Email", minWidth: 70 },
//   {
//     id: "businessname",
//     label: "Business Name",
//     minWidth: 100,
//   },
//   {
//     id: "createdon",
//     label: "Created On",
//     minWidth: 100,
//   },
//   {
//     id: "plandetails",
//     label: "Plan Name",
//     minWidth: 100,
//   },
//   {
//     id: "planexpirydate",
//     label: "Plan Expiry Date",
//     minWidth: 100,
//   },
//   // {
//   //   id: "businessaddress",
//   //   label: "Business Address",
//   //   minWidth: 100,
//   // },
//   // {
//   //   id: "phone",
//   //   label: "Phone Number",
//   //   minWidth: 100,
//   // },
//   {
//     id: "userlast",
//     label: "Last Logged In",
//     minWidth: 100,
//   },
//   {
//     id: "inventorycount",
//     label: "Total Inventory Count",
//     minWidth: 100,
//   },
//   {
//     id: "lastinventorycreate",
//     label: "Last Inventory Added Time",
//     minWidth: 100,
//   },
//   {
//     id: "isdeleted",
//     label: "Status",
//     minWidth: 100,
//   },
//   {
//     id: "action",
//     label: "Action",
//     minWidth: 100,
//   },
// ];

const columns = [
  { id: "name", label: "Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 70 },
  {
    id: "businessname",
    label: "Business Name",
    minWidth: 100,
  },
  { id: "phone", label: "Phone", minWidth: 100 },
  {
    id: "createdon",
    label: "Created On",
    minWidth: 100,
  },
  {
    id: "plandetails",
    label: "Plan",
    minWidth: 130,
  },
  ,
  {
    id: "dealerInventorySubscription",
    label: "Dealer Inventory Subscription",
    minWidth: 100,
  },
  // {
  //   id: "planexpirydate",
  //   label: "Plan Expiry Date",
  //   minWidth: 100,
  // },
  // {
  //   id: "businessaddress",
  //   label: "Business Address",
  //   minWidth: 100,
  // },
  // {
  //   id: "phone",
  //   label: "Phone Number",
  //   minWidth: 100,
  // },
  {
    id: "userlast",
    label: "Last Logged In",
    minWidth: 100,
  },
  {
    id: "inventorycount",
    label: "Total Inventory",
    minWidth: 100,
  },
  {
    id: "lastinventorycreate",
    label: "Last Inventory Added On",
    minWidth: 100,
  },
  {
    id: "isdeleted",
    label: "Status",
    minWidth: 100,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const UserTracking = (props) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [editopen, setEditOpen] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [saveModalEdit, setSaveModalEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorE2, setAnchorE2] = useState("");
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const [updateSubscriptionModal, setUpdateSubscriptionModal] = useState(false);
  const [resendInvitationModal, setResendInvitationModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [confirmUserModal, setconfirmUserModal] = useState(false);
  const [activatepayload, setactivatepayload] = useState("");
  const [data, setData] = useState([]);
  const [role, setRole] = useState("1");
  const [status, setStatus] = useState("ALL");
  const [selectedUser, setSelectedUser] = useState(null);
  const [accessData, setAccessData] = useState([]);
  const [userData, setUserData] = React.useState(
    window.sessionStorage.getItem("userDetails")
      ? JSON.parse(window.sessionStorage.getItem("userDetails"))
      : null
  );
  const [TrackingDateValue, setTrackingDateValue] = useState([]);
  const [selectedTrackingDate, setSelectedTrackingDate] = useState([]);

  useEffect(() => {
    const accessData1 = getComponentActionList("administration");
    setAccessData(accessData1);
  }, []);

  useEffect(() => {
    setPage(0);
    const userDetails = window.sessionStorage.getItem("userDetails")
      ? JSON.parse(window.sessionStorage.getItem("userDetails"))
      : null;
    const databoardObject = {
      accountid: userDetails?.accountId,
      name: searchKeyword ? searchKeyword.trim() : "",
    };
    if (role !== "1") {
      databoardObject["role"] = role;
    }
    if (status !== "ALL") {
      databoardObject["status"] = status;
    }
    const userObbject = props.getUserTrackingList();
  }, [role, status]);

  useEffect(() => {
    setData(props?.userTrackingDetails?.userTrackingList);
  }, [props?.userTrackingDetails?.userTrackingList]);

  useEffect(() => {
    if (TrackingDateValue && TrackingDateValue.length === 2) {
      setSelectedTrackingDate(TrackingDateValue);
      fetchUsersGrid(TrackingDateValue);
    }
    if (
      TrackingDateValue?.length === 0 &&
      selectedTrackingDate &&
      selectedTrackingDate.length === 2
    ) {
      setSelectedTrackingDate([]);
      fetchUsersGrid("clear");
    }
  }, [TrackingDateValue]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filterUserRole = (e) => {
    setRole(e.target.value);
  };

  const filterUserStatus = (e) => {
    setStatus(e.target.value);
  };

  const openAction = Boolean(anchorE2);
  const handleClick = (event, row) => {
    setAnchorE2(event.currentTarget);
    setSelectedUser(row);
    destrutureData(row);
  };

  const handleCloseAction = () => {
    setAnchorE2("");
    setSaveModal(false);
    setSaveModalEdit(false);
    setOpen(false);
    setEditOpen(false);
    setChangeRoleModal(false);
    setResendInvitationModal(false);
    setRemoveUserModal(false);
    setconfirmUserModal(false);
  };

  const handleModalOpen = () => {
    setOpen(!open);
    setInviteUserModal(true);
  };

  const handleModalEditOpen = (row) => {
    setEditOpen(!editopen);
    setEditUserModal(true);
    setAnchorE2("");
  };

  const handleModalEditClose = () => {
    setEditOpen(false);
    setSaveModalEdit(false);
  };

  const handleModalClose = () => {
    setOpen(false);
    setSaveModal(false);
  };

  const saveModalOpen = (fetch = null) => {
    setSaveModal(true);
    setconfirmUserModal(false);
    setAnchorE2("");
    if (fetch) {
      fetchUsersGrid();
    }
  };

  const saveModalEditOpen = (fetch = null) => {
    setSaveModalEdit(true);
    setEditOpen(false);
    if (fetch) {
      fetchUsersGrid();
    }
  };

  const handleChangeRole = (row) => {
    handleCloseAction();
    setChangeRoleModal(!changeRoleModal);
  };

  const handleUpdateSubscriptionStatus = (ref) => {
    handleCloseAction();
    setUpdateSubscriptionModal(!updateSubscriptionModal);
    if (ref === 'updateSuccess') {
      fetchUsersGrid();
    }
  };

  const destrutureData = (row) => {
    setactivatepayload(row);
  };

  const handleConfirmUser = (row) => {
    setconfirmUserModal(!confirmUserModal);
  };

  const getStatusColor = (value) => {
    switch (value) {
      case "Active":
        return "#1b5e20";
      case "Invitation Sent":
        return "#1565c0";
      default:
        return "#f57c00";
    }
  };

  const getSubscriptionName = {
    "access_only": "Access Only",
    "flagship_suppliers": "Flag Ship Suppliers",
    "paid_subscribers": "Paid Subscribers",
    "free_trial": "Free Trial",
    "early_adopters": "Early Adopters",
    "no_subscription": "No Subscription"
  }

  const handleClearButton = () => {
    setSearchKeyword("");
    fetchUsersGrid("clear");
  };

  const handleEnterEvent = (event) => {
    if (event.key == "Enter") {
      fetchUsersGrid();
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const fetchUsersGrid = (from = null) => {
    const userDetails = window.sessionStorage.getItem("userDetails")
      ? JSON.parse(window.sessionStorage.getItem("userDetails"))
      : null;
    // const dashboardData = {
    //   accountid: userDetails?.accountId,
    //   search: from === "clear" ? "" : searchKeyword.trim(),
    // };
    const dashboardData = {
      search: from === "clear" ? "" : searchKeyword.trim(),
    };
    if (role !== "1") {
      dashboardData["role"] = role;
    }
    if (status !== "ALL") {
      dashboardData["status"] = status;
    }
    if (Array.isArray(from) && from?.length > 0) {
      dashboardData["userCreatedInterval"] = {
        from: from[0].format(),
        to: from[1].format(),
      };
    }
    setPage(0);
    props.getUserTrackingList(dashboardData);
  };

  const endAdornment = () => {
    const values = searchKeyword;
    if (values) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={handleClearButton}
            onMouseDown={handleMouseDown}
            color="primary"
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={fetchUsersGrid}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={fetchUsersGrid}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    }
  };

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <TextField
        id="outlined-basic"
        label="Created On"
        variant="outlined"
        onFocus={onFocus}
        classes={{}}
        style={{ width: "100%" }}
        placeholder="from YYYY/MM/DD to YYYY/MM/DD"
        value={value}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarMonthIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  const downloadInvoiceTotal = (type) => {
    const userDetails = window.sessionStorage.getItem("userDetails")
      ? JSON.parse(window.sessionStorage.getItem("userDetails"))
      : null;

    const userId = userDetails?.accountId;
    let data;
    if (selectedTrackingDate && selectedTrackingDate.length > 0) {
      data = {
        userCreatedInterval: {
          from: selectedTrackingDate[0].format(),
          to: selectedTrackingDate[1].format(),
        },
      };
    } else {
      data = {};
    }
    //setDownloadInvoice(true)
    getUserTrackingDownload(data).then((res) => {
      //setDownloadInvoice(false)
      if (res && res.status === 0) {
        toast(res.message || "Something went wrong", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  };

  return (
    <div className="space-bottom-30 someextrahight">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">User Activity Tracking</Typography>
          </Grid>
          <Grid item xs={12} md={6}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Button
              className="text-capitalize primary-bg color-white"
              onClick={() => handleUpdateSubscriptionStatus()}
              style={{ float: "right", width: "20rem", marginRight: "20px" }}
            >
              Update Subscription Status
            </Button>
            <Button
              className="text-capitalize primary-bg color-white"
              onClick={() => downloadInvoiceTotal()}
              style={{ float: "right", width: "15rem" }}
            >
              Download Tracking
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              range
              render={<CustomInput />}
              value={TrackingDateValue}
              containerStyle={{ display: "block" }}
              onChange={(dateObjects) => {
                setTrackingDateValue(dateObjects);
              }}
              rangeHover
              dateSeparator=" to "
              plugins={[<DatePanel />]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className="form-width-99">
              <div style={{ marginLeft: "5px" }}>
                <Paper
                  // component="form"
                  sx={{ p: "2px 0px" }}
                  style={{
                    height: "55px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <InputBase
                    fullWidth
                    // sx={{ ml: 3, flex: 1 }}
                    style={{ flex: 1, marginLeft: "20px" }}
                    inputProps={{
                      "aria-label": "Search by Name / Email",
                    }}
                    placeholder="Search by Business Name / Email"
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                    onKeyPress={handleEnterEvent}
                    value={searchKeyword}
                    endAdornment={endAdornment()}
                  />
                </Paper>
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <>
                          {column.id === "action" ? (
                            <>
                              {accessData.length > 0 && (
                                <StyledTableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {column.label}
                                </StyledTableCell>
                              )}
                            </>
                          ) : (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {column.label}
                            </StyledTableCell>
                          )}
                        </>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && data.length ? (
                      <>
                        {data
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const roleName = row["rolename"];
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];

                                  return (
                                    <>
                                      {column.id !== "action" ? (
                                        <>
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                          >
                                            {column.id === "name" && (
                                              <>
                                                <Typography style={{ minWidth: '150px' }}>
                                                  {`${row.firstname || ""} ${row.lastname || ""
                                                    }`}{" "}
                                                </Typography>
                                              </>
                                            )}
                                            {column.id === "role" && (
                                              <>
                                                <Typography>
                                                  {capitalize(
                                                    `${row.rolename}`
                                                  )}{" "}
                                                </Typography>
                                              </>
                                            )}
                                            {(column.id === "email") && (
                                              <>
                                                <Typography style={{ minWidth: '200px' }}>
                                                  {capitalize(
                                                    `${row[column.id]}`
                                                  )}{" "}
                                                </Typography>
                                              </>
                                            )}
                                            {(column.id === "businessname") && (
                                              <>
                                                <Typography>
                                                  {capitalize(
                                                    `${row[column.id]}`
                                                  )}{" "}
                                                </Typography>
                                              </>
                                            )}
                                            {(column.id === "phone") && (
                                              <>
                                                <Typography>
                                                  {capitalize(
                                                    `${row[column.id] ?? "-"}`
                                                  )}{" "}
                                                </Typography>
                                              </>
                                            )}
                                            {column.id === "inventorycount" && (
                                              <>
                                                <Typography>
                                                  {`${row[column.id]}`}{" "}
                                                </Typography>
                                              </>
                                            )}

                                            {column.id === "activity" && (
                                              <>
                                                <Typography>
                                                  {/* {`${row.modifiedon
                                                    ? moment(
                                                      row.modifiedon
                                                    ).format("MM/DD/YYYY")
                                                    : ""
                                                    }`}{" "} */}
                                                  {row.modifiedon
                                                    ? formatDate(row.modifiedon, "MM/DD/YYYY")
                                                    : ""
                                                  }
                                                </Typography>
                                              </>
                                            )}
                                            {column.id === "userlast" && (
                                              <>
                                                <Typography style={{ whiteSpace: "nowrap" }}>
                                                  {/* {`${row.userlastlogin
                                                    ? moment(
                                                      row.userlastlogin
                                                    ).format("MM/DD/YYYY")
                                                    : ""
                                                    }`}{" "} */}
                                                  {row.userlastlogin
                                                    ? formatDate(row.userlastlogin, "MM/DD/YYYY")
                                                    : ""
                                                  }
                                                </Typography>
                                              </>
                                            )}
                                            {column.id ===
                                              "lastinventorycreate" && (
                                                <>
                                                  <Typography style={{ whiteSpace: "nowrap" }}>
                                                    {/* {`${row.lastinventorycreated
                                                      ? moment(
                                                        row.lastinventorycreated
                                                      ).format("MM/DD/YYYY")
                                                      : ""
                                                      }`}{" "} */}
                                                    {row.lastinventorycreated
                                                      ? formatDate(row.lastinventorycreated, "MM/DD/YYYY")
                                                      : ""
                                                    }
                                                  </Typography>
                                                </>
                                              )}
                                            {column.id === "createdon" && (
                                              <>
                                                <Typography style={{ whiteSpace: "nowrap" }}>
                                                  {/* {`${row.usercreatedon
                                                    ? moment(
                                                      row.usercreatedon
                                                    ).format("MM/DD/YYYY")
                                                    : ""
                                                    }`}{" "} */}
                                                  {row.usercreatedon
                                                    ? formatDate(row.usercreatedon, "MM/DD/YYYY")
                                                    : ""
                                                  }
                                                </Typography>
                                              </>
                                            )}
                                            {column.id === "plandetails" && (
                                              <>
                                                <Typography style={{ whiteSpace: "nowrap" }} title={`Expired On: ${row.planexpirydate
                                                  // ? moment(
                                                  //   row.planexpirydate
                                                  // ).format("MM/DD/YYYY")
                                                  ? formatDate(row.planexpirydate, "MM/DD/YYYY")
                                                  : ""
                                                  }`}>
                                                  {`${row.plandetails &&
                                                    row.plandetails !== ""
                                                    ? capitalize(
                                                      row.plandetails
                                                    )
                                                    : "-"
                                                    }`}{" "}
                                                </Typography>
                                              </>
                                            )}
                                            {column.id === "dealerInventorySubscription" && (
                                              <>
                                                <Typography style={{ whiteSpace: "nowrap" }} title={`Expired On: ${row.dealerinventory_expirydate
                                                  // ? moment(
                                                  //   row.dealerinventory_expirydate
                                                  // ).format("MM/DD/YYYY")
                                                  ? formatDate(row.dealerinventory_expirydate, "MM/DD/YYYY")
                                                  : ""
                                                  }`}>
                                                  {`${row.dealerinventory_plan &&
                                                    row.dealerinventory_plan !== ""
                                                    ? getSubscriptionName[row.dealerinventory_plan]
                                                    : "-"
                                                    }`}{" "}
                                                </Typography>
                                              </>
                                            )}
                                            {column.id === "planexpirydate" && (
                                              <>
                                                <Typography>
                                                  {/* {`${row.planexpirydate
                                                    ? moment(
                                                      row.planexpirydate
                                                    ).format("MM/DD/YYYY")
                                                    : ""
                                                    }`}{" "} */}
                                                  {row.planexpirydate
                                                    ? formatDate(row.planexpirydate, "MM/DD/YYYY")
                                                    : ""
                                                  }
                                                </Typography>
                                              </>
                                            )}
                                            {column.id === "isdeleted" && (
                                              <>
                                                <p
                                                  style={{
                                                    color: row.isdeleted
                                                      ? "red"
                                                      : "green",
                                                    fontWeight: 600,
                                                    fontSize: 12,
                                                    whiteSpace: "nowrap"
                                                  }}
                                                >
                                                  {row.isdeleted
                                                    ? "In Active"
                                                    : "Active"}{" "}
                                                </p>
                                              </>
                                            )}
                                            {column.id ===
                                              "businessaddress" && (
                                                <>
                                                  <Typography>
                                                    {`${row.addressline1 || ""} ${row.addressline2 || ""
                                                      } ${row.city || ""} ${row.statename || ""
                                                      } ${row.country || ""}`}{" "}
                                                  </Typography>
                                                </>
                                              )}
                                          </TableCell>
                                        </>
                                      ) : (
                                        <>
                                          {accessData.length > 0 && (
                                            <TableCell
                                              key={column.id}
                                              align={column.align}
                                            >
                                              <StyledEngineProvider>
                                                <MoreVertIcon
                                                  aria-haspopup="true"
                                                  aria-expanded={
                                                    openAction
                                                      ? "true"
                                                      : undefined
                                                  }
                                                  style={{
                                                    color: "#1976d2",
                                                    textDecoration: "none",
                                                    textTransform: "none",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={(e) => {
                                                    handleClick(e, row);
                                                  }}
                                                ></MoreVertIcon>

                                                <StyledMenu
                                                  id="demo-customized-menu"
                                                  MenuListProps={{
                                                    "aria-labelledby":
                                                      "demo-customized-button",
                                                  }}
                                                  anchorEl={anchorE2}
                                                  open={openAction}
                                                  onClose={() =>
                                                    handleCloseAction()
                                                  }
                                                >
                                                  <MenuItem
                                                    key={row.id + "Edit"}
                                                    onClick={() => {
                                                      handleConfirmUser(row);
                                                    }}
                                                  >
                                                    {activatepayload?.isdeleted ? (
                                                      <EditIcon />
                                                    ) : (
                                                      <DeleteIcon />
                                                    )}
                                                    {activatepayload?.isdeleted
                                                      ? "Activate User"
                                                      : "Deactivate User"}
                                                  </MenuItem>
                                                </StyledMenu>
                                              </StyledEngineProvider>
                                            </TableCell>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </>
                    ) : (
                      <TableCell colspan={12} style={{ textAlign: "center" }}>
                        <Typography variant="h6" className="color-BCBCBC">
                          No Records Found
                        </Typography>
                      </TableCell>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length || 0}
                rowsPerPage={rowsPerPage}
                SelectProps={{
                  native: true,
                }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

            {inviteUserModal && (
              <InviteUserModal
                open={open}
                handleModalClose={() => handleModalClose()}
                saveModal={saveModal}
                saveModalOpen={saveModalOpen}
              />
            )}
            {editUserModal && (
              <EditUserModal
                editopen={editopen}
                handleModalEditClose={() => handleModalEditClose()}
                saveModalEdit={saveModalEdit}
                saveModalEditOpen={saveModalEditOpen}
                userData={selectedUser}
              />
            )}
            {changeRoleModal && (
              <ChangeRoleModal
                open={changeRoleModal}
                handleClose={() => handleCloseAction()}
                SaveModal={saveModal}
                saveModalOpen={saveModalOpen}
                userData={selectedUser}
              />
            )}

            {resendInvitationModal && (
              <ResendInvitationModal
                open={resendInvitationModal}
                handleClose={() => handleCloseAction()}
                SaveModal={saveModal}
                saveModalOpen={saveModalOpen}
                userData={selectedUser}
              />
            )}
            {removeUserModal && (
              <RemoveUser
                open={removeUserModal}
                handleClose={() => handleCloseAction()}
                SaveModal={saveModal}
                saveModalOpen={saveModalOpen}
                userData={selectedUser}
              />
            )}
            {confirmUserModal && (
              <ConfirmationModal
                open={confirmUserModal}
                handleClose={() => handleCloseAction()}
                SaveModal={saveModal}
                saveModalOpen={saveModalOpen}
                userData={activatepayload}
              />
            )}
            {updateSubscriptionModal && (
              <UpdateSubscriptionStatusModal
                open={updateSubscriptionModal}
                handleClose={handleUpdateSubscriptionStatus}
                SaveModal={saveModal}
                saveModalOpen={saveModalOpen}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div >
  );
};
const mapStateToProps = (state) => ({
  userTrackingDetails: state.Admin,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUserTrackingList: (data) => dispatch(getUserTrackingList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTracking);
