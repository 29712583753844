import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import DashboardIcon from '../../../../assests/images/dashboard/Group 1919.svg';
import InventoryComponent from './inventoryComponent';
import DashboardComponent from './dashboardComponent';
import BulkUploadComponent from './bulkUploadComponent';
import ProfileComponent from './profileComponent';
import CustomersComponent from './customerComponent';
import InvoiceComponent from './invoiceComponent';

import Box from '@material-ui/core/Box';
import '../style/style.css';



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const componentWillReceiveProps=(newProps)=>{
  if (newProps.currentUser && !this.state.currentUser) {
    this.setState({
      ...this.state,
      currentUser: newProps.currentUser,
    });
    this.props.getuser(newProps.currentUser.userId);
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    // height: 650,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab:{

  }
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState( 0);
  
  useEffect(()=>{    
    if (props.location.pathname === "/myinventory" && localStorage.getItem("tabInvoiceDashboard") != "yes" ) {
        setValue(0);
    }

    // if(localStorage.getItem("tabInvoiceDashboard") == "yes"){
    //   console.log(props?.location?.state?.tab ,"tab");
    //   setValue(3)
    //   // props.location.state = undefined
    //   localStorage.setItem("tabInvoiceDashboard", "no");}
  },[props.location]);

  

  const newpropsforprofile = props
  const handleChange = (event, newValue) => {
    console.log(newValue,"abc");
     setValue(newValue);
  };
 


  return (
    
    <div className={classes.root}>
        <Container maxWidth="xl">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={2}>
            <Tabs
            
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
               <Tab image={DashboardIcon} style={{textTransform:'none'}}  label="&nbsp;&nbsp;My Inventory" {...a11yProps(0)}  className="color-383838"  />
                <Tab image={DashboardIcon} style={{textTransform:'none'}}  label="&nbsp;&nbsp;Dashboard" {...a11yProps(0)}  className="color-383838" />
               {/* <Tab image={DashboardIcon} style={{textTransform:'none'}}  label="&nbsp;&nbsp;My Profile" {...a11yProps(2)}  className="color-383838" /> */}
               {/* <Tab image={DashboardIcon} style={{textTransform:'none'}} label="&nbsp;&nbsp;Bulk Upload" {...a11yProps(1)}  className="color-383838" /> */}
               {/* <Tab image={DashboardIcon} style={{textTransform:'none'}} label="&nbsp;&nbsp;Invoices" {...a11yProps(4)} className="color-383838" /> */}
                {/* <Tab image={DashboardIcon} style={{textTransform:'none'}} label="&nbsp;&nbsp;Customers" {...a11yProps(5)} className={classes.tabs} className="color-383838" /> */}

            </Tabs>
            </Grid>
            <Grid item xs={12} sm={9} md={10}>
            <TabPanel value={value} index={0}>
            <InventoryComponent {...props}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <DashboardComponent/>
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <div className="maxminunheight">
                <ProfileComponent {...newpropsforprofile}/>
                </div>
            </TabPanel> */}
{/*            
            <TabPanel value={value} index={1}>
                <BulkUploadComponent/>
            </TabPanel> */}
            {/* <TabPanel value={value} index={3}>
                <InvoiceComponent {...props}/>
            </TabPanel> */}
            {/* <TabPanel value={value} index={5}>
                <CustomersComponent/>
            </TabPanel> */}
            
            </Grid>
        </Grid>
        </Container>
    </div>
  );
}
