import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import watch from "../../../../assests/images/Bitmap@2x.png";
import ContentWrapper from "../../../../common/components/contentWrapper";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { SpinnerRoundFilled } from "spinners-react";
import searchApi from "../../dashboard/actions/dashboardaapi";
import LimitReachedComponent from '../../subscription/LimitReachedComponent';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import alt from "../../../../assests/images/img-soon.png";
import plusSign from "../../../../assests/images/Group 1771.png";
import "../style/browsewatchesstyle.css";
import Pagination from "@material-ui/lab/Pagination";
import constants from "./../../../../utils/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment } from "@material-ui/core";
import {
  fetchBrowseWatches,
  brandList,
  caseSize,
  caseMaterial,
  brandMaterial,
  dialColor,
  filterBySearch,
  ModelList,
  fetchSearchBrowseWatches,
  filterBySearchWatch,
} from "../actions/browseWatchesActions";
import { searchProducts } from "./../../dealer/actions/dealerAction";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import scrollToComponent from "react-scroll-to-component";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import HomeIcon from "@material-ui/icons/Home";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import GrainIcon from "@material-ui/icons/Grain";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import Icon from "@material-ui/core/Icon";
import $ from "jquery";
import ologo from "../../../../assests/images/ologo.png";
import AccessRestricted from "../../common/components/accessRestricted";
import UpgradePlan from "../../common/components/upgradePlan";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";

class BrowseWatchesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        code: "",
        name: "",
        unit: "",
        rate: "",
      },
      hasMore: true,
      userData: null,
      searchString: "",
      nonsearchstring: false,
      searchStringdetails: "",
      cursor: 0,
      modledata: '',
      producId: '',
      searchItems: [],
      errormsgfinal: "",
      items: Array.from({ length: this.props.browseWatchesInfo.length }),
      CaseSize: "",
      BrandName: "",
      noItemFound: null,
      defaultdata: [],
      CaseMaterial: "",
      BandMaterial: "",
      ModelName: "",
      pagenumber: 1,
      showRelatedWatches: false,
      ModelName123: '',
      newpage: 1,
      pagecontent: 12,
      pagenumber1: 1,
      final: [],
      defaultbrandname: '',
      pagecontent1: 12,
      favrtwatches: [],
      similarwatches: [],
      DialColor: "",
      displaySearchData: false,
      removedbrndname: "",
      selectedProduct: { productId: "" },
      Image: {},
      brand_id: '',
      loaded: false,
      browserwatchesdis: false,
      browsewatchnormal: true,
      notfounddisplay: false,
      modelBrand: '',
      limitReachedModalOpen: false,
      autocomplete: {

        currentInput: "",
        loading: false,
        data: [],
      },
      selected: { product: null },
      searchSubString: "",
      relatedWatchesCount: true,
    };

    this.handleChangeOfBrandByBrandName = this.handleChangeOfBrandByBrandName.bind(
      this
    );
    this.handleChangeOfCaseSizeBySize = this.handleChangeOfCaseSizeBySize.bind(
      this
    );
    this.handleChangeOfCaseMaterialByMaterial = this.handleChangeOfCaseMaterialByMaterial.bind(
      this
    );
    this.handleChangeOfBandMaterialByMaterial = this.handleChangeOfBandMaterialByMaterial.bind(
      this
    );
    // this.handleChangeOfMdelByModelName = this.handleChangeOfMdelByModelName.bind(
    //   this
    // );
    // this.handleChangeOfDialColorInfoByDialColor = this.handleChangeOfDialColorInfoByDialColor.bind(
    //   this
    // );
    this.handleChangeOfMdelByModelName1 = this.handleChangeOfMdelByModelName1.bind(this)
    this.paginationProductList = this.paginationProductList.bind(this);
    this.onImagesShow = this.onImagesShow.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.browseWatches = this.browseWatches.bind(this);

    this.autocomplete = this.autocomplete.bind(this);
    this.hanldeKeyup = this.hanldeKeyup.bind(this);
    this.hanldeKeydown = this.hanldeKeydown.bind(this);
    this.handleListKeydown = this.handleListKeydown.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getAutocomplete = _.debounce(this.getAutocomplete, 500);
    this.removebrandname = this.removebrandname.bind(this)
    this.removeCaseSize = this.removeCaseSize.bind(this)
    this.removeCaseMaterial = this.removeCaseMaterial.bind(this)
    this.removeBrandMaterial = this.removeBrandMaterial.bind(this)
    this.removeDailColor = this.removeDailColor.bind(this)
    this.removeModelName = this.removeModelName.bind(this)
    this.fetchMoreData1 = this.fetchMoreData1.bind(this)
    this.fetchMoreData = this.fetchMoreData.bind(this)
    this.selectItem = this.selectItem.bind(this)
    this.getSearchData = this.getSearchData.bind(this)
    this.closeLimitReachedModal = this.closeLimitReachedModal.bind(this);
    // this.getAllproductsdetails = this.getAllproductsdetails.bind(this)
  }

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.searchSting) {
      const watchComponent = this.props.location.state.watchComponent;
      this.setState({ ...watchComponent });
    }
    if (this.props.match.params.productId != null) {
      this.getSearchData()
    }
    //  else{
    let PId = null;
    try {
      PId = this.props.match.params.productId;
      if (PId) {
        this.state.selectedProduct.productId = PId;
        this.setState({
          searchStringdetails: PId,
          ...this.state,
        });
        // this.props.fetchSearchBrowseWatches(PId);
      } else {
        // this.props.fetchBrowseWatches();
        this.setState({ loaded: true })
      }
    } catch (error) {
      console.log(error);
    }
    this.props.brandList();
    this.props.caseSize();
    this.props.caseMaterial();
    this.props.brandMaterial();
    this.props.dialColor();
    this.props.ModelList();
    // this.fetchMoreData1()
    this.getAllproductsdetails();
    const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    this.setState({ userData: userDetails });
    //  }
  }
  getSearchData = () => {
    this.setState({ loaded: false });
    console.log(this.state.notfounddisplay, "status");
    if (this.props && this.props.match.params.productId.length > 0) {
      setTimeout(() => {
        this.setState(
          {
            browserwatchesdis: true,
            browsewatchnormal: false,
            relatedWatchesCount: true,
            searchStringdetails: this.props.match.params.productId,
            loaded: false,
          },
          function () {
            console.log(this.state.dealersOverallTotal);
          }
        );
      }, 10);
    } else {
      setTimeout(() => {
        this.setState(
          {
            browserwatchesdis: false,
            loaded: false,
          },
          function () {
            console.log(this.state.dealersOverallTotal);
          }
        );
      }, 10);
    }
    console.log(this.state.browserwatchesdis);
    this.setState({
      modledata: "",
      brand_id: "",
      CaseSize: "",
      CaseMaterial: "",
      band_material: "",
      case_size: "",
      case_material: "",
      BandMaterial: "",
      model_name: "",
      DialColor: "",
      ModelName: "",

      nonsearchstring: false,
      displaySearchData: true,
      browserwatchesdis: true,
      browsewatchnormal: false,
      loaded: false,
      pagenumber: 1,
    });
    let data = {
      brand_id: "",
      band_material: "",
      case_size: "",
      case_material: "",
      page_no: "",
      limit: "100",
    };
    this.props.brandList();
    this.props.caseSize();
    this.props.caseMaterial();
    this.props.brandMaterial();
    this.props.dialColor();
    this.props.ModelList();
    const newPageNumber =
      this.state.pagenumber >= 1 ? 1 : this.state.pagenumber;
    console.log(
      data,
      this.state.searchString,
      this.state.producId,
      newPageNumber,
      this.state.pagecontent,
      this.state
    );
    searchApi
      .productSeach(
        data,
        this.props.match.params.productId,
        newPageNumber,
        this.state.pagecontent
      )
      .then((res) => {
        if (res && res.data && res.data.success === 0 && res.data.data && res.data.data.isLimitExited) {
          this.setState({
            loaded: true,
            limitReachedModalOpen: true
          })
        } else {
          console.log(res.data.data, "ress-----", res);
          this.setState({ loaded: false });
          if (res.data.success === 1) {
            if (res.data.data.length > 0) {
              this.setState({
                final: res.data.data,
                notfounddisplay: true,
                nonsearchstring: false,
                pagenumber: this.state.pagenumber + 1,
                pagecontent: this.state.pagecontent,
                loaded: true,
              });
              console.log(this.state.notfounddisplay, "status");
              console.log(this.state.final, "finalfinal");
            }
          } else if (res.data.success === 0) {
            console.log("logout");
            this.setState({ loaded: false });
          } else {
            console.log("logout1");
            this.setState({ loaded: false });
          }
        }
      });
  };

  getAllproductsdetails() {
    this.setState({ loaded: false });
    searchApi
      .getallproducts(this.state.pagenumber1, this.state.pagecontent1)
      .then((res) => {
        console.log("res", res.data.data, res.data);
        if (res.data.success === 1) {
          this.setState({
            defaultdata: [...this.state.defaultdata, ...res.data.data],
            loaded: true,
            pagenumber1: this.state.pagenumber1 + 1,
            pagecontent1: this.state.pagecontent1,
          });
        } else if (res.data.success === 0) {
          console.log("logout1");
          this.setState({ loaded: true });
          window.location.href = "/login";
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
        console.log(this.state.defaultdata, "defaultdata");
      });
  }

  fetchMoreData = () => {
    let scrollTop = document.documentElement.scrollHeight;
    console.log(scrollTop, "scrolltop");
    if (scrollTop >= 800) {
      console.log(this.state.searchString);
      let data = {
        user_id: "",
        brand_id: this.state.brand_id,
        brand_name: this.state.modledata,
        band_material: this.state.BandMaterial,
        case_size: this.state.CaseSize,
        dail_color: this.state.DialColor,
        case_material: this.state.CaseMaterial,
        page_no: "",
        limit: "100",
      };
      searchApi
        .productSeach(
          data,
          this.state.modelBrand
            ? this.state.modelBrand
            : this.state.searchStringdetails,
          this.state.pagenumber,
          this.state.pagecontent
        )
        .then((res) => {
          console.log("res", res.data.data);

          if (res.data.success === 1) {
            const finalData = [...this.state.final, ...res.data.data];
            const showRelatedWatches = finalData.filter(
              (row) => row.likedistance >= 1
            );
            this.setState({
              final: [...this.state.final, ...res.data.data],
              loaded: true,
              pagenumber: this.state.pagenumber + 1,
              pagecontent: this.state.pagecontent,
              noItemFound: null,
              showRelatedWatches: showRelatedWatches.length > 0 ? true : false,
            });
          } else {
            this.setState({
              errorMessage: res.message,
            });
          }
          console.log(this.state.final, "dtt");
          if (res.data.data.length == 0) {
            this.setState({
              errormsgfinal: "",
              noItemFound: "ITEMS NOT FOUND",
            });
          }
        });
    }
  };


  // onscorall(){
  // let scrollTop =  document.documentElement.scrollHeight
  // console.log(scrollTop)
  // if(scrollTop >=1800) {
  //     // this.fetchMoreData()
  //   }
  // }
  fetchMoreData1() {
    searchApi
      .getallproducts(this.state.pagenumber1, this.state.pagecontent1)
      .then((res) => {
        console.log("res", res.data.data);
        if (res.data.success === 1) {
          this.setState({
            defaultdata: [...this.state.defaultdata, ...res.data.data],
            loaded: true,
            pagenumber1: this.state.pagenumber1 + 1,
            pagecontent1: this.state.pagecontent1,
          });
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
        console.log(this.state.defaultdata, "defaultdata");
      });
  }
  componentWillReceiveProps(newProps) {
    this.state.autocomplete.data = newProps.autocompleteProducts.list;
    this.setState({
      ...this.state,
    });
  }

  handleChangeOfBrandByBrandName = (event) => {
    this.setState({
      loaded: false,
    });
    const favrtwatches = [];
    const similarwatches = [];
    console.log(event.target.value);
    let brand = event.target.value;
    let brandId = brand.brandid;

    let brandName = "";
    if (brand.brandname == "ALL") {
      brandName = "";
    } else {
      brandName = brand.brandname;
      brandId = brand.brandid;
    }
    this.setState({
      brand_id: brandId,
      BrandName: brand.brandname,
      modledata: brand.brandname,
      pagenumber: 2

    });
    let data = {
      brand_id: brandId,
      brand_name: brand.brandname,
      band_material: this.state.CaseMaterial,
      case_size: this.state.CaseSize,
      case_material: this.state.case_material,
      model_name: this.state.modelName,
      dail_color: this.state.DialColor,
      page_no: "1",
      limit: "100",
    };
    // this.setState({pagenumber:this.state.pagenumber+1,
    //   pagecontent:this.state.pagecontent+12})
    console.log(
      data,
      "data",
      brandId,
      this.state.brandId,
      this.state.pagenumber,
      this.state.pagecontent
    );
    let pnumber =
      brandId != "" ? this.state.pagenumber - 1 : this.state.pagenumber;
    let pagenumber =
      brandId != "" ? this.state.pagecontent : this.state.pagecontent;
    console.log(data, "data", brandId, this.state.brandId, pnumber, pagenumber);
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("watchres", res.data.data);

        console.log(this.state.final, "bro");
        if (res.data.success === 1) {
          if (res.data.data.length == 0) {
            this.setState({
              pagenumber: this.state.pagenumber - 1,
              pagecontent: this.state.pagecontent,
              loaded: true,
              final: res.data.data,
              relatedWatchesCount: false,
              notfounddisplay: true,
            });
          } else {
            this.setState({
              final: res.data.data,
              relatedWatchesCount: true,
              loaded: true,
            });
          }
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
      });
    console.log(this.state.favrtwatches, this.state.similarwatches, "result--");
  };

  removebrandname() {
    var element = document.getElementsByClassName("brandClass")[0];
    element.classList.remove("MuiInputLabel-shrink");
    //  var classname = document.getElementsByClassName("")
    $(".brandLangend .PrivateNotchedOutline-legendLabelled-87 > span").css(
      "display",
      "none"
    );
    this.props.brandList();
    console.log("remove");
    this.setState({
      loaded: false,
      modledata: "",
      defaultbrandname: "All",
      removedbrndname: "",
      brand_id: "",
      band_material: this.state.CaseMaterial,
      case_size: this.state.CaseSize,
      case_material: this.state.case_material,
      page_no: "1",
      limit: "100",
      pagenumber: 2,
    });

    let data = {
      brand_id: "",
      band_material: this.state.CaseMaterial,
      case_size: this.state.CaseSize,
      case_material: this.state.case_material,
      page_no: "1",
      model_name: this.state.modelName,
      dail_color: this.state.DialColor,

      limit: "100",
    };
    console.log(data, this.state.brand_id, this.state.brandId);
    let pnumber =
      this.state.brand_id != ""
        ? this.state.pagenumber - 1
        : this.state.pagenumber;
    let pagenumber =
      this.state.brand_id != ""
        ? this.state.pagecontent
        : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("watchres", res.data.data);

        if (res.data.success === 1) {
          this.setState({
            final: res.data.data,
            loaded: true,
            noItemFound: null
          });
          console.log(this.state.browseWatchesInfo);
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
      });
  }
  removeCaseSize() {
    var element = document.getElementsByClassName("caseclass")[0];
    element.classList.remove("MuiInputLabel-shrink");
    $(".caselagend .PrivateNotchedOutline-legendLabelled-87 > span").css(
      "display",
      "none"
    );

    this.props.caseSize();
    this.setState({
      CaseSize: "",
      loaded: false,
      pagenumber: 2,
    });
    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: this.state.CaseMaterial,
      case_size: "",
      case_material: this.state.case_material,
      page_no: "1",
      model_name: this.state.modelName,
      dail_color: this.state.DialColor,
      limit: "100",
    };
    console.log(data);
    let pnumber =
      this.state.CaseSize != ""
        ? this.state.pagenumber - 1
        : this.state.pagenumber;
    let pagenumber =
      this.state.CaseSize != ""
        ? this.state.pagecontent
        : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("watchres", res.data.data);
        if (res.data.success === 1) {
          this.setState({
            final: res.data.data,
            loaded: true,
            noItemFound: null
          });
          console.log(this.state.browseWatchesInfo);
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
      });
  }
  removeCaseMaterial() {
    var element = document.getElementsByClassName("casematerialClass")[0];
    element.classList.remove("MuiInputLabel-shrink");
    $(".materialLagend .PrivateNotchedOutline-legendLabelled-87 > span").css(
      "display",
      "none"
    );
    this.props.caseMaterial();
    console.log("data");
    this.setState({
      CaseMaterial: "",
      loaded: false,
      pagenumber: 2,
    });
    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: "",
      case_size: this.state.CaseSize,
      case_material: "",
      page_no: "1",
      model_name: this.state.modelName,
      dail_color: this.state.DialColor,
      limit: "100",
    };
    console.log(
      data,
      "data",
      this.state.case_material,
      this.state.caseMaterial,
      this.state.brandId,
      this.state.pagenumber,
      this.state.pagecontent
    );
    let pnumber =
      this.state.caseMaterial != ""
        ? this.state.pagenumber - 1
        : this.state.pagenumber;
    let pagenumber =
      this.state.caseMaterial != ""
        ? this.state.pagecontent
        : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("res", res.data.data);
        if (res.data.success === 1) {
          this.setState({
            final: res.data.data,
            loaded: true,
            noItemFound: null
          });
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
        console.log(this.state.final, "dtt");
      });
  }
  removeBrandMaterial() {
    var element = document.getElementsByClassName("removebrandmaterial")[0];
    element.classList.remove("MuiInputLabel-shrink");
    $(
      ".brandmaterialLagend .PrivateNotchedOutline-legendLabelled-87 > span"
    ).css("display", "none");

    this.props.brandMaterial();
    this.setState({
      BandMaterial: "",
      loaded: false,
      pagenumber: 2,
    });
    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: "",
      case_size: this.state.CaseSize,
      case_material: this.state.CaseMaterial,
      page_no: constants.PAGINATION_START,
      model_name: this.state.modelName,
      dail_color: this.state.DialColor,
      limit: "100",
    };
    console.log(
      data,
      "data",
      this.state.BandMaterial,
      this.state.brandId,
      this.state.pagenumber,
      this.state.pagecontent
    );
    let pnumber =
      this.state.BandMaterial != ""
        ? this.state.pagenumber - 1
        : this.state.pagenumber;
    let pagenumber =
      this.state.BandMaterial != ""
        ? this.state.pagecontent
        : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("res", res.data.data);
        if (res.data.success === 1) {
          this.setState({
            final: res.data.data,
            loaded: true,
            noItemFound: null
          });
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
        console.log(this.state.final, "dtt");
      });
  }
  removeDailColor() {
    // var element = document.getElementsByClassName("dialcolorClass")[0];
    // element.classList.remove("MuiInputLabel-shrink");
    this.props.dialColor();
    // $(".dialcolorLagend .PrivateNotchedOutline-legendLabelled-87 > span").css(
    //   "display",
    //   "none"
    // );
    this.setState({
      DialColor: "",
      pagenumber: 2,
      loaded: false,
    });
    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: this.state.BandMaterial,
      case_size: this.state.CaseSize,
      case_material: this.state.CaseMaterial,
      dail_color: "",
      page_no: constants.PAGINATION_START,
      model_name: this.state.modelName,
      limit: "100",
    };
    let pnumber =
      this.state.DialColor != ""
        ? this.state.pagenumber - 1
        : this.state.pagenumber;
    let pagenumber =
      this.state.DialColor != ""
        ? this.state.pagecontent
        : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("res", res.data.data);
        if (res.data.success === 1) {
          this.setState({
            final: res.data.data,
            loaded: true,
            noItemFound: null
          });
        } else {
          this.setState({
            errorMessage: res.message,
            loaded: true,
          });
        }
        console.log(this.state.final, "dtt");
      });
  }
  removeModelName() {
    var element = document.getElementsByClassName("modalnumberClass")[0];
    element.classList.remove("MuiInputLabel-shrink");
    $(".madelnamelagend .PrivateNotchedOutline-legendLabelled-87 > span").css(
      "display",
      "none"
    );
    this.props.ModelList();
    this.setState({
      ModelName: "",
      pagenumber: 2,
      loaded: false,
    });
    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: this.state.BandMaterial,
      case_size: this.state.CaseSize,
      case_material: this.state.CaseMaterial,
      model_name: "",
      page_no: constants.PAGINATION_START,
      dail_color: this.state.DialColor,
      model_name: this.state.modelName,

      limit: "100",
    };
    let pnumber =
      this.state.BandMaterial != ""
        ? this.state.pagenumber - 1
        : this.state.pagenumber;
    let pagenumber =
      this.state.BandMaterial != ""
        ? this.state.pagecontent
        : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("res", res.data.data);
        if (res.data.success === 1) {
          this.setState({
            final: res.data.data,
            loaded: true,
            noItemFound: null
          });
        } else {
          this.setState({
            errorMessage: res.message,
            loaded: true,
          });
        }
        console.log(this.state.browseWatchesInfo, "dtt");
      });
  }
  handleChangeOfCaseSizeBySize = (event) => {
    const favrtwatches = [];
    const similarwatches = [];
    let caseSizedetails = event.target.value;
    console.log(caseSizedetails);
    let caseSize = "";
    if (caseSizedetails.casesize == "ALL") {
      caseSize = "";
    } else {
      caseSize = caseSizedetails.casesize;
    }
    this.setState({
      CaseSize: caseSizedetails.casesize,
      loaded: false,
    });
    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: this.state.BandMaterial,
      case_size: caseSize,
      case_material: this.state.CaseMaterial,
      page_no: constants.PAGINATION_START,
      model_name: this.state.modelName,
      dail_color: this.state.DialColor,
      limit: "100",
    };
    // this.setState({
    //   pagenumber: this.state.pagenumber + 1,
    //   pagecontent: this.state.pagecontent + 12
    // })
    console.log(
      data,
      "data",
      caseSize,
      this.state.brandId,
      this.state.pagenumber,
      this.state.pagecontent
    );
    let pnumber =
      caseSize != "" ? this.state.pagenumber - 1 : this.state.pagenumber;
    let pagenumber =
      caseSize != "" ? this.state.pagecontent : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("res", res.data.data);
        if (res.data.success === 1) {
          if (res.data.data.length == 0) {
            this.setState({
              pagenumber: this.state.pagenumber - 1,
              pagecontent: this.state.pagecontent,
              loaded: true,
              final: res.data.data,
              notfounddisplay: true,
              noItemFound: "ITEM NOT FOUND",
              relatedWatchesCount: false,
            });
          } else {
            this.setState({
              final: res.data.data,
              loaded: true,
              noItemFound: null,
              relatedWatchesCount: true,
            });
          }
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
      });
  };
  closeLimitReachedModal = () => {
    this.setState({ limitReachedModalOpen: false })
  }
  handleChangeOfCaseMaterialByMaterial = (event) => {
    const favrtwatches = [];
    const similarwatches = [];
    let casematerialdetails = event.target.value;
    console.log(casematerialdetails);
    let caseMaterial = "";
    if (casematerialdetails.casematerial == "ALL") {
      caseMaterial = "";
    } else {
      caseMaterial = casematerialdetails.casematerial;
    }
    this.setState({
      CaseMaterial: caseMaterial,
      loaded: false,
    });
    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: this.state.BandMaterial,
      case_size: this.state.CaseSize,
      case_material: caseMaterial,
      page_no: constants.PAGINATION_START,
      model_name: this.state.modelName,
      dail_color: this.state.DialColor,
      limit: "100",
    };
    console.log(
      data,
      "data",
      caseMaterial,
      this.state.CaseMaterial,
      this.state.brandId,
      this.state.pagenumber,
      this.state.pagecontent
    );
    let pnumber =
      caseMaterial != "" ? this.state.pagenumber - 1 : this.state.pagenumber;
    let pagenumber =
      caseMaterial != "" ? this.state.pagecontent : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("res", res.data.data);
        this.setState({
          final: res.data.data,
        });
        if (res.data.success === 1) {
          if (res.data.data.length == 0) {
            this.setState({
              pagenumber: this.state.pagenumber - 1,
              pagecontent: this.state.pagecontent,
              loaded: true,
              final: res.data.data,
              notfounddisplay: true,
              relatedWatchesCount: false,
              noItemFound: "ITEM NOT FOUND",
            });
          } else {
            this.setState({
              final: res.data.data,
              loaded: true,
              relatedWatchesCount: true,
              noItemFound: null,

            });
          }
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
      });
  };

  handleChangeOfBandMaterialByMaterial = (event) => {
    const favrtwatches = [];
    const similarwatches = [];
    let brandMaterialdetails = event.target.value;
    let bandMaterial = "";
    if (brandMaterialdetails.bandmaterial == "ALL") {
      bandMaterial = "";
    } else {
      bandMaterial = brandMaterialdetails.bandmaterial;
    }
    this.setState({
      BandMaterial: bandMaterial,
      loaded: false,
    });
    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: bandMaterial,
      case_size: this.state.CaseSize,
      case_material: this.state.CaseMaterial,
      page_no: constants.PAGINATION_START,
      model_name: this.state.modelName,
      dail_color: this.state.DialColor,
      limit: "100",
    };
    console.log(
      data,
      "data",
      bandMaterial,
      this.state.brandId,
      this.state.pagenumber,
      this.state.pagecontent
    );
    let pnumber =
      bandMaterial != "" ? this.state.pagenumber - 1 : this.state.pagenumber;
    let pagenumber =
      bandMaterial != "" ? this.state.pagecontent : this.state.pagecontent;
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        pagenumber
      )
      .then((res) => {
        console.log("res", res.data.data);
        this.setState({
          final: res.data.data,
        });
        if (res.data.success === 1) {
          if (res.data.data.length == 0) {
            this.setState({
              pagenumber: this.state.pagenumber - 1,
              pagecontent: this.state.pagecontent,
              final: res.data.data,
              loaded: true,
              notfounddisplay: true,
              relatedWatchesCount: false,
              noItemFound: "ITEM NOT FOUND",
            });
          } else {
            this.setState({
              final: res.data.data,
              loaded: true,
              relatedWatchesCount: true,
              noItemFound: null,
            });
          }
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
      });
  };

  handleChangeOfMdelByModelName1 = (event) => {
    const favrtwatches = [];
    const similarwatches = [];
    let modeldetails = event.target.value;

    let modelName = "";
    if (modeldetails.model == "ALL") {
      modelName = "";
    } else {
      modelName = modeldetails.model;
    }

    this.setState({
      ModelName: modelName,
      loaded: false,
    });

    let data = JSON.stringify({
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: this.state.BandMaterial,
      case_size: this.state.CaseSize,
      case_material: this.state.CaseMaterial,
      model_name: modelName,
      dail_color: this.state.DialColor,

      page_no: constants.PAGINATION_START,
      limit: "100",
    });
    this.setState({
      pagenumber: this.state.pagenumber + 1,
      pagecontent: this.state.pagecontent,
    });
    searchApi
      .productSeach(
        data,
        this.state.modelBrand
          ? this.state.modelBrand
          : this.state.searchStringdetails,
        this.state.newpage,
        this.state.pagecontent
      )
      .then((res) => {
        console.log("res", res.data.data);
        this.setState({
          final: res.data.data,
        });
        if (res.data.success === 1) {
          if (res.data.data.length == 0) {
            this.setState({
              pagenumber: this.state.pagenumber - 1,
              pagecontent: this.state.pagecontent,
              final: res.data.data,
              loaded: true,
              noItemFound: "ITEM NOT FOUND",
              notfounddisplay: true,
            });
          } else {
            this.setState({
              final: res.data.data,
              loaded: true,
              noItemFound: null
            });
          }
          this.setState({
            final: res.data.data,
          });
        } else {
          this.setState({
            errorMessage: res.message,
          });
        }
      });
  };

  // handleChangeOfDialColorInfoByDialColor = (event) => {
  //   let dialColordetails = event.target.value;
  //   this.setState({
  //     DialColor: dialColordetails.dialcolor,
  //     loaded: false,
  //   });
  //   let data = {
  //     brand_id: this.state.brand_id,
  //     brand_name: this.state.modledata,
  //     band_material: this.state.BandMaterial,
  //     case_size: this.state.CaseSize,
  //     case_material: this.state.CaseMaterial,
  //     page_no: constants.PAGINATION_START,
  //     dail_color: dialColordetails.dialcolor,
  //     model_name: this.state.ModelName,
  //     limit: "100",
  //   };
  //   console.log(data);
  //   this.setState({
  //     pagenumber: this.state.pagenumber + 1,
  //     pagecontent: this.state.pagecontent,
  //     DialColor: dialColordetails.dialcolor,
  //   });
  //   searchApi
  //     .productSeach(
  //       data,
  //       this.state.modelBrand
  //         ? this.state.modelBrand
  //         : this.state.searchStringdetails,
  //       this.state.newpage,
  //       this.state.pagecontent
  //     )
  //     .then((res) => {
  //       const favrtwatches = [];
  //       const similarwatches = [];
  //       console.log("res", res.data.data);
  //       this.setState({
  //         final: res.data.data,
  //       });
  //       if (res.data.success === 1) {
  //         if (res.data.data.length == 0) {
  //           this.setState({
  //             pagenumber: this.state.pagenumber - 1,
  //             pagecontent: this.state.pagecontent,
  //             loaded: true,
  //             final: res.data.data,
  //             notfounddisplay: true,
  //             noItemFound: "ITEM NOT FOUND",
  //           });
  //         } else {
  //           this.setState({
  //             final: res.data.data,
  //             loaded: true,
  //             noItemFound: null,
  //           });
  //         }
  //       } else {
  //         this.setState({
  //           errorMessage: res.message,
  //         });
  //       }
  //     });
  // };
  paginationProductList = (event, page) => {
    let productIdPagination = null;
    productIdPagination = this.props.match.params.productId;
    let data = JSON.stringify({
      user_id: "",
      brand_id: this.state.brand_id,
      brand_name: this.state.modledata,
      band_material: this.state.BandMaterial,
      case_size: this.state.CaseSize,
      case_material: this.state.CaseMaterial,
      page_no: page,
      limit: "100",
    });
  };
  onImagesShow = (row) => { };

  browseWatches() {
    if (this.state.nonsearchstring === true) {
      setTimeout(() => {
        this.setState({ nonsearchstring: false });
      }, 500);
    }
    this.setState({
      modledata: "",
      brand_id: "",
      CaseSize: "",
      CaseMaterial: "",
      band_material: "",
      case_size: "",
      case_material: "",
      BandMaterial: "",
      model_name: "",
      DialColor: "",
      ModelName: "",
      nonsearchstring: false,
      displaySearchData: true,
      browserwatchesdis: true,
      browsewatchnormal: false,
      relatedWatchesCount: true,
      loaded: false,
      pagenumber: 1,
    });
    let data = {
      brand_id: "",
      band_material: "",
      case_size: "",
      case_material: "",
      page_no: "",
      limit: "100",
    };

    this.props.brandList();
    this.props.caseSize();
    this.props.caseMaterial();
    this.props.brandMaterial();
    this.props.dialColor();
    this.props.ModelList();
    const newPageNumber =
      this.state.pagenumber >= 1 ? 1 : this.state.pagenumber;
    console.log(
      data,
      this.state.searchString,
      this.state.producId,
      newPageNumber,
      this.state.pagecontent
    );
    searchApi
      .productSeach(
        data,
        this.state.searchStringdetails,
        newPageNumber,
        this.state.pagecontent
      )
      .then((res) => {
        if (res && res.data && res.data.success === 0 && res.data.data && res.data.data.isLimitExited) {
          this.setState({
            loaded: true,
            limitReachedModalOpen: true
          })
        } else {
          console.log(res.data.data, "res-----");
          var element1 = document.getElementsByClassName("brandClass")[0];
          element1?.classList.remove("MuiInputLabel-shrink");
          var element2 = document.getElementsByClassName("caseclass")[0];
          element2?.classList.remove("MuiInputLabel-shrink");
          var element3 = document.getElementsByClassName("casematerialClass")[0];
          element3?.classList.remove("MuiInputLabel-shrink");
          var element4 = document.getElementsByClassName(
            "removebrandmaterial"
          )[0];
          element4?.classList.remove("MuiInputLabel-shrink");
          var element5 = document.getElementsByClassName("dialcolorClass")[0];
          element5?.classList.remove("MuiInputLabel-shrink");
          var element6 = document.getElementsByClassName("modalnumberClass")[0];
          element6?.classList.remove("MuiInputLabel-shrink");
          const finalData = [...res?.data?.data];
          const showRelatedWatches = finalData.filter(
            (row) => row.likedistance >= 1
          );

          this.setState({
            final: res.data.data,
            loaded: true,
            nonsearchstring: false,
            relatedWatchesCount: true,
            pagenumber: this.state.pagenumber + 1,
            pagecontent: this.state.pagecontent,
            showRelatedWatches: showRelatedWatches.length > 0 ? true : false,
          });
          scrollToComponent(this.Violet, {
            offset: 0,
            align: "top",
            duration: 500,
          });
        }
      });
    console.log("slectedata", this.state.selected, this.state.searchString);
  }
  onProductChange = _.debounce((e, v, r) => {
    console.log(e);
    if (v) {
      switch (e) {
        case "product_selected":
          this.setState({
            ...this.state,
            selected: { product: v },
          });
          // this.props.history.push('/browsewatches/' + v.model)
          break;
        case "input_changed":
          this.state.autocomplete.currentInput = v;

          this.state.autocomplete.loading = true;
          this.setState({
            ...this.state,
          });
          if (v.length >= 3 && r == "input") {
            this.props.searchProducts({ searchText: v }, (error, res) => { });
          }
          break;
      }
    } else {
      console.log("dddd");
    }
  }, 600);

  autocomplete(evt) {

    console.log(this.state.searchString);
    let text = evt.target.value;
    var string = text.replace(/[&\/\\#,+!@^&()$~%.'":;*?<>{}]/g, "");
    console.log("string", string);
    this.setState({ searchStringdetails: text, searchSubString: string });
    this.setState({ loading: true });
    // this.getAutocomplete(string);
  }

  getAutocomplete = (evt) => {
    console.log(this.state.searchString.length);
    this.props.searchProducts({ searchText: evt }, (error, res) => {
      this.setState({ searchItems: this.state.autocomplete.data });
      this.setState({ loading: false });
      if (this.state.autocomplete.data.length > 0) {
        // console.log("true1  true",this.state.autocomplete.data.length > 0)
        this.setState({
          nonsearchstring: true,
        });
      } else {
        this.setState({
          nonsearchstring: false,
        });
        // console.log("false1  false",this.state.autocomplete.data.length > 0)
      }
    });

    console.log(this.state.autocomplete.data);
  };
  hanldeKeyup(evt) { }

  hanldeKeydown(evt) {
    console.log(this.state.searchString);
  }

  selectItem(id) {
    this.setState({
      modledata: "",
      brand_id: "",
      CaseSize: "",
      CaseMaterial: "",
      band_material: "",
      case_size: "",
      case_material: "",
      BandMaterial: "",
      model_name: "",
      DialColor: "",
      ModelName: "",
      pagenumber: 1,
      nonsearchstring: false,
      displaySearchData: false,
      loaded: false,
      producId: id,
    });
    const { searchItems } = this.state;
    let selectedItem = searchItems.find((item) => item.model === id);
    this.setState({ searchString: selectedItem.brandname });
    this.setState({
      searchStringdetails: selectedItem.searchattr,
      browserwatchesdis: true,
      browsewatchnormal: false,
    });
    this.setState({ autocomplete: { data: [] } });
    console.log("selectedItem", selectedItem);
    let data = {};
    console.log(
      id,
      this.state.producId,
      this.state.pagenumber,
      this.state.pagecontent
    );
    const prodString = selectedItem.model;
    this.setState({
      modelBrand: prodString,
    });
    this.props.brandList();
    this.props.caseSize();
    this.props.caseMaterial();
    this.props.brandMaterial();
    this.props.dialColor();
    this.props.ModelList();
    const newPageNumber =
      this.state.pagenumber >= 1 ? 1 : this.state.pagenumber;
    searchApi
      .productSeach(data, prodString, newPageNumber, this.state.pagecontent)
      .then((res) => {
        if (res && res.data && res.data.success === 0 && res.data.data && res.data.data.isLimitExited) {
          this.setState({
            loaded: true,
            limitReachedModalOpen: true
          })
        } else {
          console.log(res.data.data, "res-----");

          var element1 = document.getElementsByClassName("brandClass")[0];
          element1?.classList.remove("MuiInputLabel-shrink");
          var element2 = document.getElementsByClassName("caseclass")[0];
          element2?.classList.remove("MuiInputLabel-shrink");
          var element3 = document.getElementsByClassName("casematerialClass")[0];
          element3?.classList.remove("MuiInputLabel-shrink");
          var element4 = document.getElementsByClassName(
            "removebrandmaterial"
          )[0];
          element4?.classList.remove("MuiInputLabel-shrink");
          var element5 = document.getElementsByClassName("dialcolorClass")[0];
          element5?.classList.remove("MuiInputLabel-shrink");
          var element6 = document.getElementsByClassName("modalnumberClass")[0];
          element6?.classList.remove("MuiInputLabel-shrink");
          this.setState({
            final: res.data.data,
            loaded: true,
            pagenumber: this.state.pagenumber + 1,
            pagecontent: this.state.pagecontent,
          });
          scrollToComponent(this.Violet, {
            offset: 0,
            align: "top",
            duration: 500,
          });
        }
      });
    // setTimeout(() => {
    //   this.setState({ loaded: true })

    // }, 500);
  }
  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13 && event.target.value) {
      //13 is the enter keycode
      this.browseWatches();
    }
  }

  handleListKeydown(evt) {
    console.log(evt);
    console.log(this.state.searchString);
  }
  handleClick(event) {
    event.preventDefault();
  }
  handleChange(evt) {
    this.setState({ item: { [evt.target.name]: evt.target.value } });
  }

  getContentView() {
    const favrtwatches = [];
    const similarwatches = [];
    const brandName = localStorage.getItem("brand");
    const CaseSize = localStorage.getItem("caseSize");
    const browseWatchesInfo =
      this.state.browseWatchesInfo && this.state.browseWatchesInfo.length > 0
        ? this.state.browseWatchesInfo
        : this.props.browseWatchesInfo;
    for (let i = 0; i < browseWatchesInfo.length; i++) {
      if (
        browseWatchesInfo[i].likedistance < 1
      ) {
        favrtwatches.push(browseWatchesInfo[i]);
      } else {
        similarwatches.push(browseWatchesInfo[i]);
      }
    }
    const finalsimlarResult = this.state.final
      ? this.state.final
      : similarwatches;
    const finalfavrtwatchesResult = this.state.final
      ? this.state.final
      : favrtwatches;
    const brandInfo = this.props.brandInfo.data;
    const caseSizeInfo = this.props.caseSizeInfo.data;
    const CaseMaterialByMaterial = this.props.caseMaterialInfo.data;
    const brandMaterialInfo = this.props.brandMaterialInfo.data;
    // const dialColorInfo = this.props.dialColorInfo.data;
    const ModelListInfo = this.props.ModelListInfo.data;
    let productId = null;
    productId = this.props.match.params.productId;

    const { searchItems, cursor, item, handleChange } = this.state;
    const { code, name, unit, rate } = item;
    return (
      <div style={{ overflow: "hidden" }}>
        <ScrollUpButton
          EasingType="easeInOutBounce"
          ShowAtPosition={1000}
          AnimationDuration={500}
          style={{
            backgroundColor: '#CEA05F',
            borderRadius: '50%',
            padding: "10px",
            border: "none",
            fill: "#fff",
            bottom: "75px"
          }}
        />
        <Paper>
          <div>
            <div>
              <div className="text-center1">
                <img src={ologo} alt="impresari_logo" />
              </div>
              <div>
                <Container maxWidth="xs">
                  <div className="">
                    <Grid container spacing={2}>
                      <Grid item xs={7} sm={8} md={8}>
                        <div style={{ margin: "0 auto" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Choose a Watch"
                              onChange={this.autocomplete}
                              onKeyPress={this.enterPressed.bind(this)}
                              // onKeyDown={this.hanldeKeydown}
                              value={this.state.searchStringdetails}
                              className="custom-input form-control searchoption"
                              autocomplete="off"
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={5} sm={4} md={4}>
                        <Button
                          variant="contained"
                          type="submit"
                          className="button-slider2 buttonslider3"
                          onClick={this.browseWatches}
                          disabled={this.state.searchStringdetails <= 0}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <div></div>
                </Container>
              </div>
            </div>
          </div>

          {/* </Loader> */}

          <div
            className="violet"
            ref={(section) => {
              this.Violet = section;
            }}
          ></div>
          <div className="sppbg"></div>
          <Container>
            {this.state.loaded === false ? (
              <div class="d-spinner">
                <SpinnerRoundFilled
                  size={51}
                  thickness={125}
                  speed={80}
                  color="rgba(57, 107, 172, 1)"
                  className="spp"
                  enabled={this.state.loaded == false}
                />
              </div>
            ) : (
              <div>
                <br />

                <div className="filter">
                  {this.state.browserwatchesdis ? (
                    <Grid container spacing={2} style={{ display: "block" }}>
                      <Grid item xs={12} sm={6} style={{ margin: "0 auto" }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={3}>
                            <div style={{ margin: "0 auto", width: "140px" }}>
                              <span
                                onClick={this.removebrandname}
                                style={{ float: "right" }}
                              >
                                <Icon
                                  className={
                                    this.state.modledata
                                      ? "fa fa-times-circle"
                                      : ""
                                  }
                                />
                              </span>
                              <FormControl
                                variant="outlined"
                                className="formControl"
                              >
                                <InputLabel
                                  id="demo-simple-select-outlined-label"
                                  className="font-14 font-weight-600 brandClass"
                                >
                                  Brand
                                </InputLabel>
                                <Select
                                  className="brandLangend"
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  onChange={this.handleChangeOfBrandByBrandName}
                                  label="Brand"
                                >
                                  <MenuItem value="">All</MenuItem>
                                  {brandInfo &&
                                    brandInfo.map((row) => (
                                      <MenuItem value={row}>
                                        {row.brandname}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <Typography
                                  variant="h6"
                                  className="color-383838"
                                  style={{ fontSize: "15px" }}
                                >
                                  {this.state.modledata
                                    ? this.state.modledata
                                    : this.state.removedbrndname}{" "}
                                </Typography>
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <div style={{ margin: "0 auto", width: "135px" }}>
                              <span
                                onClick={this.removeCaseSize}
                                style={{ float: "right" }}
                              >
                                <Icon
                                  className={
                                    this.state.CaseSize
                                      ? "fa fa-times-circle"
                                      : ""
                                  }
                                />
                              </span>

                              <FormControl
                                variant="outlined"
                                className="formControl"
                              >
                                <InputLabel
                                  id="demo-simple-select-outlined-label "
                                  className="font-14 font-weight-600 caseclass"
                                >
                                  Case Size
                                </InputLabel>
                                <Select
                                  className="caselagend"
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  onChange={this.handleChangeOfCaseSizeBySize}
                                  label="Case Size"
                                >
                                  <MenuItem value="">All</MenuItem>
                                  {caseSizeInfo &&
                                    caseSizeInfo.map((row) => (
                                      <MenuItem value={row}>
                                        {row.casesize}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <Typography
                                  variant="h6"
                                  className="color-383838"
                                  style={{ fontSize: "15px" }}
                                >
                                  {this.state.CaseSize}
                                </Typography>
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <div style={{ margin: "0 auto", width: "135px" }}>
                              <span
                                onClick={this.removeCaseMaterial}
                                style={{ float: "right" }}
                              >
                                <Icon
                                  className={
                                    this.state.CaseMaterial
                                      ? "fa fa-times-circle"
                                      : ""
                                  }
                                />
                              </span>
                              <FormControl
                                variant="outlined"
                                className="formControl"
                              >
                                <InputLabel
                                  id="demo-simple-select-outlined-label"
                                  className="font-14 font-weight-600 casematerialClass"
                                >
                                  Case Material
                                </InputLabel>
                                <Select
                                  className="materialLagend"
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  onChange={
                                    this.handleChangeOfCaseMaterialByMaterial
                                  }
                                  label="Case Material"
                                >
                                  <MenuItem value="">All</MenuItem>
                                  {CaseMaterialByMaterial &&
                                    CaseMaterialByMaterial.map((row) => (
                                      <MenuItem value={row}>
                                        {row.casematerial}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <Typography
                                  variant="h6"
                                  className="color-383838"
                                  style={{ fontSize: "15px" }}
                                >
                                  {this.state.CaseMaterial}
                                </Typography>
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <div style={{ margin: "0 auto", width: "135px" }}>
                              <span
                                onClick={this.removeBrandMaterial}
                                style={{ float: "right" }}
                              >
                                <Icon
                                  className={
                                    this.state.BandMaterial
                                      ? "fa fa-times-circle"
                                      : ""
                                  }
                                />
                              </span>

                              <FormControl
                                variant="outlined"
                                className="formControl"
                              >
                                <InputLabel
                                  id="demo-simple-select-outlined-label"
                                  className="font-14 font-weight-600 removebrandmaterial"
                                >
                                  Band Material
                                </InputLabel>
                                <Select
                                  className="brandmaterialLagend"
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  onChange={
                                    this.handleChangeOfBandMaterialByMaterial
                                  }
                                  label="Band Material"
                                >
                                  <MenuItem value="">All</MenuItem>
                                  {brandMaterialInfo &&
                                    brandMaterialInfo.map((row) => (
                                      <MenuItem value={row}>
                                        {row.bandmaterial}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <Typography
                                  variant="h6"
                                  className="color-383838"
                                  style={{ fontSize: "15px" }}
                                >
                                  {this.state.BandMaterial}
                                </Typography>
                              </FormControl>
                            </div>
                          </Grid>
                          {/* <Grid item xs={12} sm={6} md={2}>
                            <div style={{ margin: "0 auto", width: "135px" }}>
                              <span
                                onClick={this.removeDailColor}
                                style={{ float: "right" }}
                              >
                                <Icon
                                  className={
                                    this.state.DialColor
                                      ? "fa fa-times-circle"
                                      : ""
                                  }
                                />
                              </span>

                              <FormControl
                                variant="outlined"
                                className="formControl"
                              >
                                <InputLabel
                                  id="demo-simple-select-outlined-label"
                                  className="font-14 font-weight-600 removebrandmaterial"
                                >
                                  Dial Color
                                </InputLabel>
                                <Select
                                  className="brandmaterialLagend"
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  
                                  onChange={
                                    this.handleChangeOfDialColorInfoByDialColor
                                  }
                                  label="Dial Color"
                                >
                                  <MenuItem value="">All</MenuItem>
                                  {dialColorInfo &&
                                    dialColorInfo.map((row) => (
                                      <MenuItem style={{whiteSpace:'normal',width:'300px'}} value={row}>
                                        {row.dialcolor}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <Typography
                                  variant="h6"
                                  className="color-383838"
                                  style={{ fontSize: "15px" }}
                                >
                                  {this.state.DialColor}
                                </Typography>
                              </FormControl>
                            </div>
                          </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid item xs={12} sm={12}>
                          <div className="sentense">
                            <a href="/add-to-inventory-new-watch-details">
                              <h3>Still Can't Find Your Watch?</h3>
                            </a>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </div>
                {/* ) : (
             ""
           )} */}
                {this.state.browserwatchesdis ? (
                  <div>
                    <div>
                      <InfiniteScroll
                        dataLength={finalsimlarResult.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.noItemFound ? false : true}
                        loader={
                          <div style={{ margin: "25px auto", width: "100px" }}>
                            <Loader
                              type="Puff"
                              color="#9F7C50"
                              height={50}
                              width={50}
                            />
                            <div style={{ marginLeft: "-10px" }}>
                              <h4 className="color-p">Loading....</h4>
                            </div>
                          </div>
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                          </p>
                        }
                      // loader={<h4>{this.state.errormsgfinal}</h4>}
                      >
                        {this.state.final.length == 0 ? (
                          ""
                        ) : (
                          <div className="space-1 ">
                            <Typography
                              variant="h6"
                              component="p"
                              className="primary-text uppercase"
                              align="center"
                            >
                              Favourite Watches
                            </Typography>
                          </div>
                        )}
                        {this.state.final.length == 0 ? (
                          <Typography
                            variant="h6"
                            component="p"
                            className="primary-text uppercase"
                            align="center"
                          >
                            {this.state.notfounddisplay == true
                              ? "items not found"
                              : ""}
                          </Typography>
                        ) : (
                          <Container>
                            <Grid container spacing={4}>
                              {this.state.final &&
                                this.state.final.map((row) =>
                                  row.likedistance < 1 ? (
                                    this.state.relatedWatchesCount = false,
                                    <Grid xs={12} sm={6} md={3}>
                                      <Link>
                                        <Card className="padding1 alignment-1">
                                          <CardMedia
                                            onClick={() => {
                                              // window.open(
                                              //   "/product-listing/" +
                                              //     row.productid
                                              // );


                                              // localStorage.setItem(this.state.searchStringdetails,JSON.stringify(this.state.final ))
                                              this.props.history.push('/product-listing/' + row.productid + '?q=' + this.state.searchStringdetails, { watchComponent: { ...this.state } })
                                            }}
                                            component="img"
                                            alt={alt}
                                            height="170"
                                            onLoad={this.onImagesShow(
                                              row?.imagepaths?.images[0]
                                            )}
                                            src={
                                              row &&
                                                row.imagepaths &&
                                                row?.imagepaths?.images[0]
                                                ? row?.imagepaths?.images[0].split(
                                                  ","
                                                )[0]
                                                : alt
                                            }
                                            title={row.productname}
                                          />
                                          <br />
                                          <div
                                            style={{
                                              border: "0.5px solid #9F7C50",
                                              margin: "0 auto",
                                              width: "85%",
                                            }}
                                          ></div>
                                          <CardContent
                                            onClick={() => {
                                              // window.open(
                                              //   "/product-listing/" +
                                              //     row.productid
                                              // );
                                              // localStorage.setItem(this.state.searchStringdetails,JSON.stringify(this.state.final ))

                                              this.props.history.push('/product-listing/' + row.productid + '?q=' + this.state.searchStringdetails, { watchComponent: { ...this.state } })
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              className="textSecondary color-292C2F"
                                              component="p"
                                              align="center"
                                            >
                                              <b>
                                                {row && row.model
                                                  ? row.model
                                                  : ""}
                                              </b>
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              className="textSecondary color-292C2F"
                                              component="p"
                                              align="center"
                                            >
                                              <b>
                                                {row && row.brandname
                                                  ? row.brandname
                                                  : ""}
                                              </b>

                                              {/* <b>
                                      {row && row.productid && row.productid
                                          ? row.productid
                                          : ""}
                                        {row && row.proattr && row.prodattr.bandmaterial
                                          ? row.prodattr.bandmaterial
                                          : ""}
                                      </b> */}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              className="textSecondary color-292C2F"
                                              component="p"
                                              align="center"
                                            >
                                              <b>
                                                {row && row.modelname
                                                  ? row.modelname
                                                  : ""}
                                              </b>
                                            </Typography>
                                            <div className="space-half"></div>
                                            <Typography
                                              variant="body2"
                                              className="textSecondary color-292C2F"
                                              component="p"
                                              align="center"
                                            >
                                              <b>
                                                {row &&
                                                  row.proattr &&
                                                  row.prodattr.material
                                                  ? row.prodattr.movement
                                                  : ""}
                                              </b>

                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              className="textSecondary color-292C2F"
                                              component="p"
                                              align="center"
                                            >
                                              <b>
                                                {row && row.price
                                                  ? "$" + row.price
                                                  : ""}
                                              </b>
                                            </Typography>
                                          </CardContent>
                                        </Card>
                                      </Link>
                                    </Grid>

                                  ) : (

                                    ""
                                  )
                                )}
                            </Grid>
                          </Container>
                        )}

                        {/* ?} */}
                        <br />

                        {
                          this.state.relatedWatchesCount == true ?
                            <div className="space-1 ">
                              <Typography
                                variant="h6"
                                component="p"
                                className="primary-text "
                                align="center"
                              >
                                No exact match found
                              </Typography>
                            </div>
                            : ""

                        }
                        {/* {this.state.final.length == 0 ? "" : <div className="space-1">
                <Typography
                  variant="h6"
                  component="p"
                  className="primary-text uppercase"
                  align="center"
                >
                  RELATED WATCHES
            </Typography>
              </div>} */}
                        {this.state.showRelatedWatches && (
                          <div className="space-1">
                            <Typography
                              variant="h6"
                              component="p"
                              className="primary-text uppercase"
                              align="center"
                            >
                              RELATED WATCHES
                            </Typography>
                          </div>
                        )}
                        <br />

                        <div>
                          <div className="padding1">
                            {
                              <div>
                                <Container>
                                  {/* <InfiniteScroll
                          dataLength={finalsimlarResult.length}
                          next={this.fetchMoreData}
                          hasMore={true}
                          loader={
                            <div style={{margin: '25px auto', width: '100px'}}> 
                              <Loader type="Puff" color="#9F7C50" height={50} width={50} />
                              <div style={{marginLeft: '-10px'}}>
                                <h4 className="color-p">Loading....</h4>
                              </div>
                            </div>                 
                          }
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                          // loader={<h4>{this.state.errormsgfinal}</h4>}
                        > */}

                                  <Grid container spacing={4}>
                                    {this.state.final &&
                                      this.state.final.map((row) =>
                                        row.likedistance >= 1 ? (
                                          <Grid xs={12} sm={6} md={3}>
                                            <Link>
                                              <Card
                                                onClick={() => {
                                                  //   window.open(
                                                  //     "/product-listing/" +
                                                  //       row.productid
                                                  //   );
                                                  // localStorage.setItem(this.state.searchStringdetails,JSON.stringify(this.state.final ))

                                                  this.props.history.push('/product-listing/' + row.productid + '?q=' + this.state.searchStringdetails, { watchComponent: { ...this.state } })
                                                }}
                                                className="padding1 alignment-1"
                                              >
                                                <CardMedia
                                                  component="img"
                                                  alt={alt}
                                                  height="170"
                                                  onLoad={this.onImagesShow(
                                                    row?.imagepaths?.images[0]
                                                  )}
                                                  src={
                                                    row?.imagepaths?.images[0] ? (
                                                      row?.imagepaths?.images[0].split(
                                                        ","
                                                      )[0]
                                                    ) : (
                                                      <img
                                                        src={watch}
                                                        alt="watch"
                                                      />
                                                    )
                                                  }
                                                  title={row.productname}
                                                />
                                                <br />
                                                <div
                                                  style={{
                                                    border:
                                                      "0.5px solid #9F7C50",
                                                    margin: "0 auto",
                                                    width: "85%",
                                                  }}
                                                ></div>
                                                <CardContent>
                                                  <Typography
                                                    variant="body2"
                                                    className="textSecondary color-292C2F"
                                                    component="p"
                                                    align="center"
                                                  >
                                                    <b>
                                                      {row && row.model
                                                        ? row.model
                                                        : ""}
                                                    </b>
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    className="textSecondary color-292C2F"
                                                    component="p"
                                                    align="center"
                                                  >
                                                    {/* <b>
                                              {row && row.reference ? row.reference : ""}
                                            </b> */}
                                                    <b>
                                                      {row && row.brandname
                                                        ? row.brandname
                                                        : ""}
                                                    </b>
                                                    {/* {row && row.productid && row.productid
                                        ? row.productid
                                        : ""} */}
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    className="textSecondary color-292C2F"
                                                    component="p"
                                                    align="center"
                                                  >
                                                    <b>
                                                      {row && row.modelname
                                                        ? row.modelname
                                                        : ""}
                                                    </b>
                                                  </Typography>
                                                  <div className="space-half"></div>
                                                  <Typography
                                                    variant="body2"
                                                    className="textSecondary color-292C2F"
                                                    component="p"
                                                    align="center"
                                                  >
                                                    <b>
                                                      {row &&
                                                        row.proattr &&
                                                        row.prodattr.material
                                                        ? row.prodattr.movement
                                                        : ""}
                                                    </b>
                                                  </Typography>
                                                  <Typography
                                                    variant="body2"
                                                    className="textSecondary color-292C2F"
                                                    component="p"
                                                    align="center"
                                                  >
                                                    <b>
                                                      {row && row.price
                                                        ? "$" + row.price
                                                        : ""}
                                                    </b>
                                                  </Typography>
                                                </CardContent>
                                              </Card>
                                            </Link>
                                          </Grid>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </Grid>

                                  {/* </InfiniteScroll> */}
                                </Container>
                              </div>
                            }
                          </div>
                        </div>
                      </InfiniteScroll>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* ------------------------------------------------------------------------------------------------------------------- */}

                {this.state.browsewatchnormal ? (
                  <div>
                    <Container></Container>
                    <br />
                    <div className="space-2">
                      {/* <Typography

                  variant="h6"
                  component="p"
                  className="primary-text uppercase"
                  align="center"
                >
                  WATCHES LIST
            </Typography> */}
                    </div>
                    <br />

                    <div>
                      <div className="padding1">
                        {
                          // <div>
                          //   <Container>
                          //     <InfiniteScroll
                          //       dataLength={this.state.defaultdata.length}
                          //       next={this.fetchMoreData1}
                          //       hasMore={this.state.hasMore}
                          //       loader={<h4></h4>}
                          //     >
                          //       <Grid container spacing={4}>
                          //         {this.state.defaultdata &&
                          //           this.state.defaultdata.map((row) => (
                          //             <Grid xs={12} sm={6} md={3}>
                          //               <Link>
                          //                 <Card
                          //                   onClick={() => {
                          //                     window.open(
                          //                       "/product-listing/" + row.productid
                          //                     );
                          //                   }}
                          //                   className="padding1 alignment-1"
                          //                 >
                          //                   <CardMedia
                          //                     component="img"
                          //                     alt={alt}
                          //                     height="170"
                          //                     onLoad={this.onImagesShow(
                          //                       row.imagepaths.images[0]
                          //                     )}
                          //                     src={
                          //                       row &&
                          //                         row.imagepaths &&
                          //                         row.imagepaths.images[0]
                          //                         ? row.imagepaths.images[0].split(",")[0]
                          //                         : <img src={watch} alt="watch" />
                          //                     }
                          //                     title="Contemplative Reptile"
                          //                   />
                          //                   <br />
                          //                   <div
                          //                     style={{
                          //                       border: "0.5px solid #9F7C50",
                          //                       margin: "0 auto",
                          //                       width: "85%",
                          //                     }}
                          //                   ></div>
                          //                   <CardContent>
                          //                     <Typography
                          //                       variant="body2"
                          //                       className="textSecondary color-292C2F"
                          //                       component="p"
                          //                       align="center"
                          //                     >
                          //                       <b>
                          //                         {row && row.prodattr.reference ? row.prodattr.reference : ""}
                          //                       </b>
                          //                     </Typography>
                          //                     <Typography
                          //                       variant="body2"
                          //                       className="textSecondary color-292C2F"
                          //                       component="p"
                          //                       align="center"
                          //                     >
                          //                       <b>
                          //                         {row && row.brandname
                          //                           ? row.brandname
                          //                           : ""}
                          //                       </b>
                          //                     </Typography>
                          //                     <div className="space-half"></div>
                          //                     <Typography
                          //                       variant="body2"
                          //                       className="textSecondary color-292C2F"
                          //                       component="p"
                          //                       align="center"
                          //                     >
                          //                       {/* <b>
                          //                         {row &&
                          //                           row.proattr &&
                          //                           row.prodattr.material
                          //                           ? row.prodattr.movement
                          //                           : ""}
                          //                       </b> */}
                          //                     </Typography>
                          //                     <Typography
                          //                       variant="body2"
                          //                       className="textSecondary color-292C2F"
                          //                       component="p"
                          //                       align="center"
                          //                     >
                          //                       {/* <b>
                          //                         {row && row.prodattr.reference ? row.prodattr.reference : ""}
                          //                       </b> */}
                          //                     </Typography>
                          //                   </CardContent>
                          //                 </Card>
                          //               </Link>
                          //             </Grid>
                          //           ))}
                          //         </Grid>
                          //       </InfiniteScroll>
                          //     </Container>
                          //   </div>
                        }
                      </div>
                    </div>

                    <div>{/* <hr /> */}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {this.state.limitReachedModalOpen && (
              <LimitReachedComponent isOpen={this.state.limitReachedModalOpen} onClose={this.closeLimitReachedModal} keyword="Product search" />
            )}
          </Container>
          <div className="space-2"></div>
        </Paper>
      </div>
    );
  }
  render() {
    localStorage.setItem("menuTitle", "SEARCH");
    return (
      <ContentWrapper contentView={this.state.userData && (!this.state.userData?.planDetails || this.state.userData?.planDetails === "Noplan") ?
        <div style={{ marginTop: "25px" }}> <UpgradePlan /> </div> : this.getContentView()}>
      </ContentWrapper>
    )
  }
}

function mapStateToProps({ browseWatchesInfo, dealer }) {
  return {
    browseWatchesInfo: browseWatchesInfo.browseWatchesInfo.data,
    brandInfo: browseWatchesInfo.brandInfo,
    caseSizeInfo: browseWatchesInfo.caseSizeInfo,
    caseMaterialInfo: browseWatchesInfo.caseMaterialInfo,
    brandMaterialInfo: browseWatchesInfo.brandMaterialInfo,
    dialColorInfo: browseWatchesInfo.dialColorInfo,
    ModelListInfo: browseWatchesInfo.ModelListInfo,
    autocompleteProducts: dealer.autoCompleteProducts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBrowseWatches,
      fetchSearchBrowseWatches,
      brandList,
      caseSize,
      caseMaterial,
      brandMaterial,
      dialColor,
      ModelList,
      filterBySearch,
      filterBySearchWatch,
      searchProducts,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowseWatchesComponent);
