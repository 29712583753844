const initialState = {
    individualBrowseWatchesInfo: {
        data: [],
    },
    inventoryInfo:{
         data:[],
    },
    brandIdInfo: {
        data: [],
    },
    SubscriptionPlanInfo: {
        data: []
    },
    brandInfo: {
        data: []
    },
    caseMaterialInfo: {
        data: []
    },
    brandMaterialList: [],
    packagesListInfo: [],
    countryList: [],
    selectedPackage: null,
    businessDetails: null,
    billingDetails: null,
    paymentDetails: null,
    activePackagesListInfo: [],
    statesListInfo: {
        data: [],
    },
    autoCompleteProducts: { loading: false, list: [], error: null },
    invoiceDashboardList: { loading: false, list: [], error: null },
    paymentResponseInfo: []

};
export default (state = initialState, action) => {
    let currentState = state;
    switch (action.type) {
        case 'FETCH_INDIVIDUAL_BROWSE_WATCH_DATA_SUCCESS':
            currentState = {
                ...state,
                individualBrowseWatchesInfo: action.payload
            };
            break;
        case 'GET_BRAND_ID':
            currentState = {
                ...state,
                brandIdInfo: action.payload
            };
            break;
        case 'FETCH_SUBSCRIPTION_PLAN_SUCCESS':
            currentState = {
                ...state,
                SubscriptionPlanInfo: action.payload
            };
        case 'FETCH_BROWSE_WATCHES_BRAND_DATA_SUCCESS':
            currentState = {
                ...state,
                brandInfo: action.payload
            };
            break;
            case 'FETCH_BROWSE_WATCHES_DEALER_DATA_SUCCESS':
                currentState = {
                    ...state,
                    dealerInfo: action.payload
                };
                break;
            case 'FETCH_INVENTORY_DETAIL':
                currentState = {
                    ...state,
                    inventoryInfo: action.payload
                };
                break;
        case 'FETCH_BROWSE_WATCHES_CASE_MATERIAL_DATA_SUCCESS':
            currentState = {
                ...state,
                caseMaterialInfo: action.payload
            };
            break;
        case 'FETCH_BROWSE_WATCHES_BAND_MATERIAL_DATA_SUCCESS':
            currentState = {
                ...state,
                brandMaterialList: action.payload
            };
            break;
        case 'FETCH_COUNTRYLIST_DATA_SUCCESS':
            currentState = {
                ...state,
                countryList: action.payload
            };
            break;
        case 'FETCH_STATESLIST_DATA_SUCCESS':
            currentState = {
                ...state,
                statesListInfo: action.payload
            };
            break;
        case 'FETCH_PACKAGES_DATA_SUCCESS':
            currentState = {
                ...state,
                packagesListInfo: action.payload
            };
            break;
        case 'FETCH_ACTIVE_PACKAGES_DATA_SUCCESS':
            currentState = {
                ...state,
                activePackagesListInfo: action.payload
            };
            break;
        case 'PACKAGES_SELECTED_SUCCESS':
            currentState = {
                ...state,
                selectedPackage: action.payload
            };
            break;
        case 'UPDATE_BUSINESS_DETAILS':
            currentState = {
                ...state,
                businessDetails: action.payload
            };
            break;
        case 'UPDATE_BILLING_DETAILS':
            currentState = {
                ...state,
                billingDetails: action.payload
            };
            break;
        case 'UPDATE_PAYMENT_DETAILS':
            currentState = {
                ...state,
                paymentDetails: action.payload
            };
            break;

        case 'AUTOCOMPLETE_PRODUCTS_REQUESTED':
            currentState = {
                ...state,
                autoCompleteProducts: { ...state.autoCompleteProducts, loading: true, error: null }
            };
            break;
        case 'AUTOCOMPLETE_PRODUCTS_SUCCESS':
            currentState = {
                ...state,
                autoCompleteProducts: { loading: false, list: action.payload, error: null }
            };
            break;
        case 'AUTOCOMPLETE_PRODUCTS_FAILED':
            currentState = {
                ...state,
                autoCompleteProducts: { loading: false, list: [], error: action.payload }
            };
            break;
        case 'PAYMENT_RESPONSE_DATA':
            currentState = {
                ...state,
                paymentResponseInfo: action.payload
            };
            break;
            case 'INVOICE_DASHBORDS_LIST':
                currentState = {
                    ...state,
                    invoiceDashboardList: { ...state.invoiceDashboardList, loading: true, error: null }
                };
                break;
            case 'INVOICE_DASHBORDS_LIST_SUCCESS':
                currentState = {
                    ...state,
                    invoiceDashboardList: { loading: false, list: action.payload, error: null }
                };
                break;
            case 'INVOICE_DASHBORDS_LIST_FAILED':
                currentState = {
                    ...state,
                    invoiceDashboardList: { loading: false, list: [], error: action.payload }
                };
                break;

        default:
    }
    return currentState;
}
