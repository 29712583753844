import { Stepper, Step, StepLabel, Typography, Container } from '@material-ui/core'
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PricingComponent from '../pricingComponent'
import RegisterDetails from '../registerDetails';
import DealerChooseSubscriptionsComponent from '../dealerChooseSubscriptionsComponent';
import DealerSubscriptionSuccessfullDealerPayment from '../dealerSubscriptionSuccessfull'
import DealerSubscriptionSuccessfull from '../dealerSubscriptionSuccessfull';
import DealerSubscriptionThankYou from '../dealerSubscriptionThankYou';
import { toast } from "react-toastify";
import DealerPayment from '../dealerPayment';
import {
    updateBusinessDetails, updateBillingDetails,
    saveBillingDetails, updatePaymentDetails
} from '../../actions/dealerAction';
import ContentWrapper from '../../../../../common/components/contentWrapper';
import { useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getuser } from '../../../dashboard/actions/dashboardAction';
import RegisterSignUp from './RegisterComponent';
import ChooseSubscription from './chooseSubscription';
import DealerMakepayment from './dealerMakepayment';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      paddingTop: '30px'
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));
function NewStepperComponent(props) {
    const classes = useStyles();
    const history= useHistory();
    const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"))
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps,setsteps] = React.useState([])
    
    React.useEffect(()=>{
        
        if(userDetails?.userId){
            const selectpackage = localStorage.getItem('selectPack') ? JSON.parse(localStorage.getItem('selectPack')) : null
            const stepsfield = [' Choose Plan','Make Payment/Checkout', 'Confirmation Details']
            setsteps(stepsfield)
            if(selectpackage){
                setActiveStep(1)
            }
       }
       else{
        const stepsfield = ['Register Account', ' Choose Plan','Make Payment/Checkout', 'Confirmation Details']
           setsteps(stepsfield)
    
       }

    },[])
    
    const addBusinessDetails = (value) => {
        if (value) {
            updateBusinessDetails(value, (error, res) => {
                if (error) {
                    console.log(error, "dattta");
                } else {
                    setActiveStep(1);
                }
            });
        } else {
            setActiveStep(1);
        }

    };
    const addBillingDetails = (value) => {
        if (value) {
            props.saveBillingDetails(value)
            setActiveStep(1);
        } else {
            setActiveStep(1);
        }

    };
    const addPaymentDetails = (value) => {

        if (value) {
            updateBillingDetails(value, (error, res) => {
                const checkoutdata = res.data
                localStorage.setItem("checkoutdetails", JSON.stringify(checkoutdata))
                const paymentmode = localStorage.getItem('paymentType');
                if (error) {
                } else {
                    if (res.success == 1) {
                        let attributes = {
                            "card_number": value.card_number,
                            "exp_month": value.exp_month,
                            "exp_year": value.exp_year,
                            "cvc": value.cvc
                        }
                        let data = {
                            "user_id": value.user_id,
                            "membertier_id": value.membertier_id,
                            "order_id": res.data.order_id,
                            "gateway": value.gateway,
                            "listing_addon": false,
                            "card": attributes,
                            mode: paymentmode && paymentmode.toLowerCase() || 'monthly'
                        };
                        updatePaymentDetails(data, (error, res) => {
                            if (error) {
                                toast(res && res.message, {
                                    autoClose: 5000,
                                    type: toast.TYPE.ERROR,
                                    position: toast.POSITION.BOTTOM_CENTER,
                                });
                            } else {
                                if (res && res.success === 1) {
                                    toast(res.message, {
                                        autoClose: 5000,
                                        type: toast.TYPE.SUCCESS,
                                        position: toast.POSITION.BOTTOM_CENTER,
                                    });
                                    setActiveStep(5);
                                    localStorage.removeItem("savedbildetails")
                                    localStorage.removeItem("singlepkg")
                                    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null
                                    if(userDetails && userDetails?.userId){
                                        props.getuser(userDetails?.userId)
                                    }
                                }
                                else if (res && res.success === 0) {
                                    toast(res && res.message, {
                                        autoClose: 5000,
                                        type: toast.TYPE.ERROR,
                                        position: toast.POSITION.BOTTOM_CENTER,
                                    });
                                }
                                else {
                                    toast("Somthing went wrong", {
                                        autoClose: 5000,
                                        type: toast.TYPE.ERROR,
                                        position: toast.POSITION.BOTTOM_CENTER,
                                    });
                                }
                            }
                        })

                    }
                    else {
                        toast("Somthing went wrong", {
                            autoClose: 5000,
                            type: toast.TYPE.ERROR,
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                    }
                }
            });

        } else {
            setActiveStep(2);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        var signlepkgdata = JSON.parse(localStorage.getItem('selectPack'))
        console.log('signlepkgdata',signlepkgdata)
        if(signlepkgdata){
            setActiveStep((prev) => prev + 2)
        }
        else{
            setActiveStep((prev) => prev + 1)
        }
        
        // if(userDetails?.userId){
        //     setActiveStep((prev) => prev + 1)
        // }
        // else{
        //     history.push("/login");
        // }
        
    }
    const setStep = (value) => {
        setActiveStep(value);
    };
    const confirmtDetails = (value) => {
        //setActiveStep(0);
    };
    function getStepContent(stepIndex) {
        console.log(props.currentUser, "dsds")

        if (userDetails && userDetails?.userId) {
            switch (stepIndex) {
                case 0:
                    return <ChooseSubscription
                        {...props}
                        subsflow={true}
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={addBusinessDetails}
                    />;
                case 1:
                    return <DealerMakepayment
                        {...props}
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={addBillingDetails}
                        selectedPackage={props.selectedPackage}
                    />;
                case 2:
                    return <DealerPayment
                        {...props}
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={addPaymentDetails}
                        selectedPackage={props.selectedPackage}
                    />;
                case 3:
                    return <DealerSubscriptionSuccessfull
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={confirmtDetails}
                    />;
                case 5:
                    return <DealerSubscriptionThankYou
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={confirmtDetails}
                    />;

                default:
                    return <DealerSubscriptionThankYou />;
            }
        }
        else {
            switch (stepIndex) {
                // case 0:
                //   return <SubscriptionComponent
                //     history={props.history}
                //     handleNext={handleNext}
                //     setStep={setStep}
                //   />;
                case 0:
                    return <RegisterSignUp handleNext={handleNext} />;;
                case 1:
                    return <ChooseSubscription
                        {...props}
                        subsflow={true}
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={addBusinessDetails}
                    />;
                case 2:
                    return <DealerMakepayment
                        {...props}
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={addBillingDetails}
                        selectedPackage={props.selectedPackage}
                    />;
                case 3:
                    return <DealerPayment
                        {...props}
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={addPaymentDetails}
                        selectedPackage={props.selectedPackage}
                    />;
                case 4:
                    return <DealerSubscriptionSuccessfull
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={confirmtDetails}
                    />;
                case 5:
                    return <DealerSubscriptionThankYou
                        handleNext={handleNext}
                        setStep={setStep}
                        submit={confirmtDetails}
                    />;

                default:
                    return <DealerSubscriptionThankYou />;
            }
        }
    }

    function headerForm(){
        return(
<div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                <div className="float-right">
                </div>
            </div>


        </div>
        )
    }
    return (
        <ContentWrapper contentView={headerForm()}/>
    )
}
function mapStateToProps({ dealer, auth }) {
    return {
      selectedPackage: dealer.selectedPackage,
      currentUser: auth.currentUser,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      saveBillingDetails,
      updatePaymentDetails,
      getuser
    },
      dispatch)
  }
  export default connect(mapStateToProps,mapDispatchToProps)(NewStepperComponent)