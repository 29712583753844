import React, { Component } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';


import '../style/who-we-are.css';

class WhoWeAreBannerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                <div className="bg-who-we-are-page">
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <div className="space-7 text-center1">
                                    <Typography variant="h5" className="color-fff text-uppercase">About WatchOps</Typography>
                                    <Typography variant="h3" className="color-fff text-uppercase">who we are</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}></Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="space-top-30">
                    <Container>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <Typography variant="h5" className="text-center1 font-weight-600 color-383838">People who love luxury watches inspire us as much as precious timepieces. <br></br> luxury watches have our undivided attention.</Typography>
                                </div>
                                <div className="text-center1 space-top-20">
                                <Typography variant="h6" className="color-383838">WatchOps has built the largest and most complete searchable watch model database in the world,<br></br> sure to become an irreplaceable tool in every dealer’s toolbox.</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
         );
    }
}
 
export default WhoWeAreBannerComponent;