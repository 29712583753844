import React, { Component } from 'react';
import { Container, Grid, Typography, Paper, Link, Icon } from '@material-ui/core';

import '../style/dealer.css';


class DealerBannerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                <div className="banner-dealer-background-image space-5 margin-space">
                 <Container maxWidth="md">
                        <Grid container spacing={1}>
                            <div className="margin-space">
                                <Typography variant="h6" component="h6" className="color-white text-center1">ONLY OFFICIAL DEALER ARE ALLOWED TO SELL<br/> AND MAINTAIN A WATCH</Typography>
                                <Paper className="space-3">
                                    <ul className="banner-dealer-content">
                                        <li><Icon className="fa fa-check color-p font-weight-400 font-size-16" />&nbsp;&nbsp;Sell your watches on the leading marketplace for Luxury watches</li>
                                        <li><Icon className="fa fa-check color-p font-weight-400 font-size-16" />&nbsp;&nbsp;Affluent buyers from <b>over 100 countries</b></li>
                                        <li><Icon className="fa fa-check color-p font-weight-400 font-size-16" />&nbsp;&nbsp;Safe and <b>transparent payment processing</b> - also available intenationally</li>
                                        <li><Icon className="fa fa-check color-p font-weight-400 font-size-16" />&nbsp;&nbsp;No risk, <b>monthly cancellation possible</b></li>
                                        <li>
                                            <Link className="banner-dealer-button-color">START YOUR 30-DAY FREE TRAIL NOW!</Link>
                                        </li>
                                        <li>
                                            <ul>
                                                <li>Staring at 100$ a month</li>
                                                <li className="color-p">Subscriptions price overview</li>
                                                <li className="color-p">For private sellers</li>
                                            </ul>
                                        </li>
                                        <li><div className="space-2"></div></li>
                                    </ul>
                                </Paper>
                            </div>
                        </Grid>
                    </Container>
                </div>
            </div>
         );
    }
}
 
export default DealerBannerComponent;