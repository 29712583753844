import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";
import { Container, Grid, Typography, Button, Paper } from "@material-ui/core";
import rolex3 from "../../../../assests/images/rolex3.png";
import chrono from "../../../../assests/chrono24.png";
import ebay from "../../../../assests/ebay.png";
import listingImg from "../../../../assests/listprice.png";
import defultImg from "../../../../assests/images/Bitmap@2x.png";
import pricetag from "../../../../assests/images/Group 1863.png";
import plusSign from "../../../../assests/images/Group 1771.png";
import NewContentWrapper from "../../../../common/components/newContentWrapper";
import Card from "@material-ui/core/Card";
import searchinventry from "../../dashboard/actions/dashboardaapi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import queryString from "query-string";

import CardContent from "@material-ui/core/CardContent";
import ProductListingDetailsCompoent from "./productListingDetailsComponent";
import { Link } from "react-router-dom";
import scrollToComponent from "react-scroll-to-component";
import { SpinnerRoundFilled } from "spinners-react";
import { individualBrowseWatch } from "../actions/dealerAction";
var Loader = require("react-loader");

class ProductingListingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventryPricelist: [],
      loaded: false,
      ebayAmout: "",
      croono21Ammount: "",
      retailAmount: "",
      pricemoney: "",
      reatilAmouts: "",
    };
    this.getpriceLsting = this.getpriceLsting.bind(this);
    this.sroclltop = this.sroclltop.bind(this);
    this.getproductdetails = this.getproductdetails.bind(this);
  }

  componentDidMount() {
    if (this.props.individualBrowseWatchesInfo.success === 0) {
      console.log("login");
      window.location.href = "/login";
    }

    console.log(
      this.props.individualBrowseWatchesInfo.data,
      "props",
      this.state.reatilAmout
    );
    let productId = this.props.match.params.productId;
    this.props.individualBrowseWatch(productId);
    localStorage.setItem("menuTitle", "BROWSE WATCHES");
    this.getpriceLsting(productId);
    this.getproductdetails(productId);
    this.sroclltop();
  }
  sroclltop() {
    scrollToComponent(this.Violet, { offset: 0, align: "top", duration: 1500 });
  }

  getproductdetails(productId) {
    //
    setTimeout(() => {
      searchinventry.getAllProductsdetails(productId).then((res) => {
        console.log("amoutt", res.data.data);
        this.setState({
          reatilAmouts: res.data.data && res.data.data.price,
          loaded: true,
        });
      });
      console.log(this.state.reatilAmouts, "amoutt");
    }, 10);
  }
  getpriceLsting(productId) {
    //
    // console.log(this.props.individualBrowseWatchesInfo)
    // let reatilAmout =''
    // if (this.props.individualBrowseWatchesInfo.success == 0) {
    //     reatilAmout = this.props.individualBrowseWatchesInfo.price;
    //     this.setState({reatilAmouts :this.props.individualBrowseWatchesInfo.price})
    // } else {
    //     reatilAmout = this.props.individualBrowseWatchesInfo.data.price;
    //     this.setState({reatilAmouts :this.props.individualBrowseWatchesInfo.data.price})

    // }
    searchinventry.getinventrypriceList(productId).then((res) => {
      console.log("inventrypricelist", res.data.data);
      this.setState({
        inventryPricelist: res.data.data,
      });
      console.log(productId, "id", this.state.pricemoney);
      if (
        this.state.inventryPricelist &&
        this.state.inventryPricelist.length > 0
      ) {
        for (let i = 0; i < this.state.inventryPricelist.length; i++) {
          if (this.state.inventryPricelist[i].watch_source == "Chrono24") {
            this.setState({
              croono21Ammount: this.state.inventryPricelist[i].avg,
            });
          } else if (this.state.inventryPricelist[i].watch_source == "Retail") {
            this.setState({
              retailAmount: this.state.inventryPricelist[i].avg,
            });
          } else if (this.state.inventryPricelist[i].watch_source == "EBay") {
            this.setState({ ebayAmout: this.state.inventryPricelist[i].avg });
          }
        }
        this.setState({
          retailAmount: this.state.retailAmount
            ? this.state.retailAmount
            : this.state.reatilAmouts,
        });
        console.log(this.state.retailAmount, "amout");
      }
    });
  }
  newContentView() {
    let individualBrowseWatchesInfo = "";
    let productId = "";
    let individualBrowseWatchesDetails = "";
    if (this.props.individualBrowseWatchesInfo.success == 0) {
      individualBrowseWatchesInfo = this.props.individualBrowseWatchesInfo;
    } else {
      individualBrowseWatchesInfo = this.props.individualBrowseWatchesInfo.data;
      productId = individualBrowseWatchesInfo.productid;
      individualBrowseWatchesDetails =
        individualBrowseWatchesInfo && individualBrowseWatchesInfo.prodattr
          ? individualBrowseWatchesInfo.prodattr
          : "";
    }
    console.log(individualBrowseWatchesInfo);

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    if (individualBrowseWatchesInfo.success === 0) {
      return (
        <div
          style={{
            marginLeft: "400px",
            marginTop: "50px",
            textAlign: "center",
          }}
        >
          <Card style={{ width: "600px", textAlign: "center" }}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                <div> {individualBrowseWatchesInfo.message}</div>
              </Typography>
            </CardContent>
          </Card>
        </div>
      );
    } else {
      return (
        <div>
          <Paper>
            <Container>
              <Grid container xs={12} sm={12}>
                <h1 style={{ display: "flex", alignItems: "center" }}>
                  <ArrowBackIcon
                    style={{
                      color: "action",
                      paddingRight: "15px",
                      fontSize: 40,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // let history = useHistory();
                      let queries = queryString.parse(
                        this.props.location.search
                      );
                      this.props.history.replace("/search", {
                        searchSting: queries.q,
                        watchComponent: this.props.location.state.watchComponent
                      });
                    }}
                  >
                    {" "}
                  </ArrowBackIcon>
                  Product Details
                </h1>
              </Grid>
            </Container>
            <Container
              container
              class="violet"
              ref={(section) => {
                this.Violet = section;
              }}
            >
              {/* <div>
                        <ul style={{listStyleType: 'none', display: 'flex'}}>
                            <li><a href="/home">Home</a></li>&nbsp;&nbsp;&nbsp;
                            <li><a href="/browsewatches">Browse watches</a></li>

                        </ul>
                    </div> */}
            </Container>
            {this.state.loaded == false ? (
              <div class="d-spinner">
                <SpinnerRoundFilled
                  size={51}
                  thickness={125}
                  speed={80}
                  color="rgba(57, 107, 172, 1)"
                  className="spp"
                  enabled={this.state.loaded == false}
                />
              </div>
            ) : (
              <div className="space-4 ">
                <Container>
                  <Grid container sacing={2}>
                    <Grid item xs={12} sm={6} md={5}>
                      <div className="space-3">
                        <Slider {...settings} style={{ textAlign: "center" }}>
                          <div class="img-box">
                            <img
                              src={
                                individualBrowseWatchesInfo?.imagepaths?.images
                                  ? individualBrowseWatchesInfo?.imagepaths
                                      ?.images[0]
                                  : defultImg
                              }
                              alt="client-logo-1"
                              className="img-width-2 img-responsive1"
                              style={{
                                display: "initial",
                                width: "auto",
                                maxHeight: "350px",
                              }}
                            />
                          </div>
                          {/* <div >
                                        <img src={individualBrowseWatchesInfo && individualBrowseWatchesInfo.imagepaths && individualBrowseWatchesInfo.imagepaths.images} alt="client-logo-1" className="img-width-2 img-responsive1"  />
                                    </div> */}
                        </Slider>
                        {/* <div className="text-center1 space-top-20">
                                    <Link href="/" style={{ color: '#292C2F' }}>Click to open expanded view</Link>
                                </div> */}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7}>
                      <div className="space-3">
                        <div>
                          <Typography variant="h5">
                            {individualBrowseWatchesInfo
                              ? (individualBrowseWatchesInfo.brandname
                                  ? individualBrowseWatchesInfo.brandname
                                  : "") +
                                (individualBrowseWatchesInfo.prodattr &&
                                individualBrowseWatchesInfo.prodattr.reference
                                  ? " - " +
                                    individualBrowseWatchesInfo.prodattr
                                      .reference
                                  : "") +
                                (individualBrowseWatchesInfo.prodattr &&
                                individualBrowseWatchesInfo.prodattr.model
                                  ? " - " +
                                    individualBrowseWatchesInfo.prodattr.model
                                  : "")
                              : "-"}
                          </Typography>
                        </div>
                        {/* <Loader loaded={this.state.loaded}>   */}

                        <div className="space-top-30 space-bottom-30">
                          <Grid container spacing={4}>
                            {/* {this.state.inventryPricelist &&
                                                this.state.inventryPricelist.slice(0,1).map ((price) => ( */}
                            <React.Fragment>
                              {/* <Grid item xs={12} sm={12} md={3}>
                                <img
                                  src={ebay}
                                  alt=""
                                  width="133px"
                                  style={{ padding: "20px 0px" }}
                                />
                                {this.state.ebayAmout ? (
                                  <div
                                    style={{ marginLeft: "25px" }}
                                    className="eby"
                                  >
                                    <Typography variant="small">
                                      $
                                      {this.state.ebayAmout &&
                                      this.state.ebayAmout.slice(0, 8)
                                        ? this.state.ebayAmout &&
                                          this.state.ebayAmout.slice(0, 8)
                                        : " ---"}
                                    </Typography>
                                  </div>
                                ) : (
                                  <div>
                                    <Typography variant="small">
                                      {"---"}
                                    </Typography>
                                  </div>
                                )}
                              </Grid>

                              <Grid item xs={12} sm={12} md={3}>
                                <img
                                  src={chrono}
                                  alt=""
                                  width="133px"
                                  style={{ padding: "20px 0px" }}
                                  onClick={this.addChoonoListing}
                                />

                                {this.state.croono21Ammount ? (
                                  <Typography variant="small">
                                    $
                                    {this.state.croono21Ammount.slice(0, 8)
                                      ? this.state.croono21Ammount.slice(0, 8)
                                      : "---"}
                                  </Typography>
                                ) : (
                                  <div>
                                    <Typography variant="small">
                                      {"---"}
                                    </Typography>
                                  </div>
                                )}
                              </Grid> */}
                              <Grid item xs={12} sm={12} md={3}>
                                <img
                                  src={listingImg}
                                  alt=""
                                  width="133px"
                                  style={{ padding: "20px 0px" }}
                                />
                                {this.state.retailAmount ? (
                                  <Typography variant="small">
                                    $
                                    {this.state.retailAmount &&
                                    this.state.retailAmount.slice(0, 8)
                                      ? this.state.retailAmount &&
                                        this.state.retailAmount.slice(0, 8)
                                      : "  List Price Not Available "}
                                  </Typography>
                                ) : (
                                  <Typography variant="small">
                                    {" "}
                                    {"List Price Not Available"}
                                  </Typography>
                                )}
                              </Grid>
                              {/* <Grid item xs={12} sm={3} md={3}>
                                                            <img src={listingImg} alt="" width="133px" style={{ padding: "20px 0px" }} />
                                                         { this.state && this.state.retailAmount.length ==0 ?  <Typography variant="small"> ${individualBrowseWatchesInfo.price}</Typography> :  <Typography variant="small">${(this.state.retailAmount.slice(0, 8)) ? (this.state.retailAmount.slice(0, 8)) : ""}</Typography>} 

                                                        </Grid> */}
                            </React.Fragment>
                            {/* ))} */}
                          </Grid>
                        </div>
                        {/* </Loader> */}
                        {/* <div>
                          <Typography variant="h5">Description</Typography>
                        </div>
                        <div>
                          <p className="text-align-justify color-848484">
                            {individualBrowseWatchesInfo &&
                            individualBrowseWatchesInfo.productdesc
                              ? individualBrowseWatchesInfo &&
                                individualBrowseWatchesInfo.productdesc
                              : "---"}
                          </p>
                        </div> */}
                        <Button
                          type="submit"
                          className="primary-bg color-white text-center1 form-width-70"
                          onClick={() => {
                            this.props.history.push("/dealer/" + productId);
                          }}
                          style={{ margin: "20px 0px" }}
                        >
                          ADD TO INVENTORY
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <div>
                    <div className="space-bottom-10">
                      <Typography variant="h5" className="color-292C2F">
                        <b>Product Specifications</b>
                      </Typography>
                    </div>
                    <div className="space-bottom-15">
                      <Typography variant="small" className="color-292C2F">
                        <b>Watch Information</b>
                      </Typography>
                    </div>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Brand Name
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesInfo.brandname
                                      ? individualBrowseWatchesInfo.brandname
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Model Number
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.reference
                                      ? individualBrowseWatchesDetails.reference
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Model Name
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.model
                                      ? individualBrowseWatchesDetails.model
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Case Material
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.casematerial
                                      ? individualBrowseWatchesDetails.casematerial
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Case Size
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.casesize
                                      ? individualBrowseWatchesDetails.casesize
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Dial Color
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.dialcolor
                                      ? individualBrowseWatchesDetails.dialcolor
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0 ">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Band Material
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.bandmaterial
                                      ? individualBrowseWatchesDetails.bandmaterial
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Case Back
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.caseback
                                      ? individualBrowseWatchesDetails.caseback
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Case Shape
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.shape
                                      ? individualBrowseWatchesDetails.shape
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Power Reserve
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.powerreserve
                                      ? individualBrowseWatchesDetails.powerreserve
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        {individualBrowseWatchesDetails.bandtype ? (
                          <div className="space-bottom-10 space-top-10 border-0">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Band Type
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {individualBrowseWatchesDetails.bandtype}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          ""
                        )}
                        {individualBrowseWatchesDetails.bezel ? (
                          <div className="space-bottom-10 space-top-10 border-0">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Bezel
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {individualBrowseWatchesDetails.bezel}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          ""
                        )}
                        {individualBrowseWatchesDetails.glass ? (
                          <div className="space-bottom-10 space-top-10 border-0">
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="color-00000061"
                                  >
                                    Glass
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <div>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {individualBrowseWatchesDetails.glass}
                                    </b>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ marginTop: "-10px" }}
                    >
                      <div className="space-m-1">
                        {/* <Typography variant="small"><b>Caliber</b></Typography> */}
                      </div>
                      {individualBrowseWatchesDetails.movement ? (
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Movement
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.movement}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        ""
                      )}
                      {individualBrowseWatchesDetails.caliber ? (
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Caliber
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.caliber}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        ""
                      )}
                      {individualBrowseWatchesDetails.luminous ? (
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Luminous
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.luminous}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="space-bottom-15 space-top-10">
                        {/* <Typography variant="small"><b>Case</b></Typography> */}
                      </div>
                      {individualBrowseWatchesDetails.casediameter ? (
                        <div className="space-bottom-10 space-top-10 border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Case diameter
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {individualBrowseWatchesDetails.casediameter
                                      ? individualBrowseWatchesDetails.casediameter
                                      : "---"}
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        ""
                      )}
                      {individualBrowseWatchesDetails.waterresistant ? (
                        <div className=" border-0">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Water resistance
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {
                                      individualBrowseWatchesDetails.waterresistant
                                    }
                                  </b>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </div>
            )}
          </Paper>
        </div>
      );
    }
  }
  render() {
    localStorage.setItem("menuTitle", "BROWSE WATCHES");
    return <NewContentWrapper contentView={this.newContentView()} />;
  }
}

function mapStateToProps({ individualBrowseWatchesInfo }) {
  return {
    individualBrowseWatchesInfo:
      individualBrowseWatchesInfo.individualBrowseWatchesInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      individualBrowseWatch,
    },
    dispatch
  );
}

// navigator.geolocation.getCurrentPosition(showMap);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductingListingComponent);
