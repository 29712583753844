import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Paper, Typography, Grid, Button, InputLabel, Input, FormControl, TextField, FormHelperText, Link } from '@material-ui/core';
import validator from 'validator';
import '../../../../common/style/main.css';
import { recoverPassword } from "./../../reducer/actions";
import { toast } from "react-toastify";
import ReactivateAccountModal from '../../../../common/components/ReactivateAccountModal';
import { formatDate } from '../../../../common/components/Helper/Utils';

class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: 'none',
      showing: true,
      status: 0,
      countryCode: '',
      emailId: '',
      phoneNumber: '',
      emailFlag: 'email',
      phoneNumberFlag: '',
      emailIdErrorText: '',
      phoneErrorText: '',
      countryCodeError: '',
      phoneemailErrorText: '',
      isValid: false,
      open: false,
      userId: '',
      email: '',
      component: 'FORGOT',
      deletedDate: ''
    }
    this.onEmailIdChange = this.onEmailIdChange.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.onChangeOfCountryCode = this.onChangeOfCountryCode.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onEmailIdChange(event) {
    let emailIdVal = validator.isEmail(event.target.value);
    if (!emailIdVal) {
      this.setState({
        emailIdErrorText: "Enter Valid Email Id",
        isValid: false
      });
    } else {
      this.setState({
        emailId: event.target.value,
        emailIdErrorText: "",
        isValid: true
      });
    }
  }
  onChangeOfCountryCode(event) {
    let countryCodeVal = event.target.value.length;
    console.log(countryCodeVal);
    if (countryCodeVal > 4) {
      this.setState({
        countryCodeError: "Enter minimum 3 characters for country code",
        isValid: false
      })
    }
    else {
      this.setState({
        countryCodeError: "",
        countryCode: event.target.value,
        isValid: true
      })
    }
  }
  onPhoneNumberChange(event) {
    const regex = "^\\d{10}$";
    let PhoneNumberVal = event.target.value;
    if (event.target.value >= 10) {
      let phonemuber = PhoneNumberVal.match(regex);
      if (!phonemuber) {
        this.setState({
          ...this.state,
          phoneErrorText: "Enter correct Phone Number",
          phoneNumber: event.target.value,
          isValid: false
        })
      }
      else {
        this.setState({
          ...this.state,
          phoneErrorText: "",
          phoneNumber: event.target.value,
          isValid: true
        })
      }
    }
    else {
      this.setState({
        ...this.state,
        phoneErrorText: "",
        phoneNumber: event.target.value,
        isValid: true
      })
    }
  }

  validateInput() {
    let emailIdErrorText = '';
    let phoneErrorText = '';
    let countryCodeError = '';
    let isValid = true;
    if (this.state.emailFlag === 'email') {
      if (this.state.emailId.length === 0) {
        emailIdErrorText = 'Enter Correct Email Id';
        isValid = false;
      }
    }
    if (this.state.phoneNumberFlag === 'phone') {
      if (this.state.countryCode.length === 0 || this.state.countryCode.length <= 1) {
        console.log("countrycode", this.state.countryCode.length);
        countryCodeError = 'Enter Country Code';
        isValid = false;
      }
      if (this.state.phoneNumber.length === 0 || this.state.phoneNumber.length <= 9) {
        phoneErrorText = 'Enter phone number ';
        isValid = false;
      }
    }
    this.setState({
      ...this.state,
      emailIdErrorText,
      phoneErrorText,
      countryCodeError
    });
    return isValid;
  }

  submitForm(event) {
    if (this.validateInput()) {
      let data = {};
      if (this.state.emailFlag == 'email') {
        data = {
          'to': this.state.emailId,
          'url': 'email-otp/send'
        };
      } else {
        data = {
          'phone': this.state.phoneNumber,
          'country_code': this.state.countryCode,
          'url': 'otp/send'
        };
      }
      const { history } = this.props;
      this.props.recoverPassword(data, (error, res) => {
        if (error) {
          toast("Somthing went wrong", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });

        } else {
          if (res.success == 1) {
            toast("Otp is sent", {
              autoClose: 5000,
              type: toast.TYPE.SUCCESS,
              position: toast.POSITION.BOTTOM_CENTER,
            });
            history.push("/verify-otp");
          }

          else if (res.success === 0 && res.deletedAccount && res.deletedAccount.keepThisAccount) {
            let date = res.deletedAccount.deletedDate;
            console.log(date, "date is created")
            // let currentDate=moment(date, "YYYY-MM-DD");
            let currentDate = formatDate(date, "YYYY-MM-DD");
            currentDate.add(1, 'month')
            this.setState({
              open: true,
              userId: res.deletedAccount.userId,
              email: res.deletedAccount.email,
              deletedDate: currentDate.format("YYYY-MM-DD")
            })
            console.log(currentDate, "Delete Date")
            //   toast("Your Account can be activated.Please Login again", {
            //     autoClose: 5000,
            //     type: toast.TYPE.SUCCESS,
            //     position: toast.POSITION.BOTTOM_CENTER,
            // });
          }
          else {
            toast(res.message, {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        }
      })
    }
  }
  emailShow = () => {
    this.setState({ showing: true });
    this.state.phoneNumber = '';
    this.state.countryCode = '';
    this.state.phoneNumberFlag = '';
    this.setState({
      emailFlag: 'email',
    })
  }
  mobileShow = () => {
    this.setState({ showing: false });
    this.state.emailId = '';
    this.state.emailFlag = '';
    this.setState({
      phoneNumberFlag: 'phone',
    })
  }

  handleClose() {
    this.setState({
      open: false
    })
  }


  getContentView() {
    const { status } = this.state;

    return (
      <div>
        <Container maxWidth="sm" className="space-3">
          <Paper className="border-radius">
            <div className="text-center">
              <Typography variant="h5" gutterBottom align="center" className="text-white">
                Recover Password
              </Typography>
            </div>
            <div className="text-center1">
              <Typography variant="small" className="text-center1">
                Password Recovery via
                {/* <br/>you will receive an email with instructions. if you are <br/>having problems recovering your password <Link href="/" className="notVendorLink">contact</Link> */}
              </Typography>
            </div>
            <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='recovery_password' >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ textAlign: 'center' }}>
                    <label >
                      <input type="radio" name="tab" defaultChecked value="email" align="center" onClick={this.emailShow} /><b>EMAIL</b>
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <label>
                      <input type="radio" name="tab" value="mobile" onClick={this.mobileShow} /><b>MOBILE NUMBER</b>
                    </label>
                  </div>

                  {this.state.showing
                    ? <FormControl className="form-width-100 ">
                      <InputLabel htmlFor="component-simple" className="color-BCBCBC">EMAIL ID</InputLabel>
                      <Input id="email"
                        display="block"
                        onChange={this.onEmailIdChange}
                        defaultValue={this.state.emailId}
                      />
                      <FormHelperText className="error-o">{this.state.emailIdErrorText}</FormHelperText>
                    </FormControl>
                    :
                    <Grid container spacing={2}>
                      <Grid item={12} sm={4} md={4} xs={12}>
                        <FormControl className="registerSpace form-width-100">
                          <InputLabel id="demo-simple-select-outlined-label" className="color-BCBCBC">COUNTRY CODE</InputLabel>
                          <Input id="phone"
                            display="none"
                            onChange={this.onChangeOfCountryCode}
                          />

                          <FormHelperText className="error-o">{this.state.countryCodeError}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item={12} sm={8} md={8} xs={12}>
                        <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10"  >
                          <InputLabel htmlFor="component-simple" className="color-BCBCBC">PHONE NUMBER</InputLabel>
                          <Input id="phone"
                            display="none"
                            onChange={this.onPhoneNumberChange}
                            inputProps={{ maxLength: 10 }}
                            defaultValue={this.state.phoneNumber}
                          />
                          <FormHelperText className="error-o" >{this.state.phoneErrorText}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>

                  }

                  {/* <Typography variant="h6" align="center" className="color-383838 space-m-1 ">
                    OR
                           </Typography>
                  */}

                  {/* <FormHelperText className="error-o">{this.state.phoneemailErrorText}</FormHelperText> */}

                </Grid>
                <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} >Send</Button>
              </Grid>
            </form>
            <div className="space-1"></div>
          </Paper>
        </Container>
        <ReactivateAccountModal open={this.state.open} handleClose={this.handleClose} deletedDate={this.state.deletedDate} data={{ ...this.state }} />

      </div>
    );
  }
  render() {
    return (<ContentWrapper
      contentView={this.getContentView()}
    />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    recoverPassword,
  },
    dispatch)
}
export default connect(null, mapDispatchToProps)(ForgotPasswordComponent);





