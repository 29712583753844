import axios from "axios";
import { toast } from "react-toastify";
import constants from './../../../utils/constants'
import { getToken } from './../../../utils/Authentication';

export function login(apiInput, successCallBack, errorCallBack) {
    const url = constants.BASE_URL + 'users/login';
    const headers = {
        'Content-Type':'application/json'
    };
    axios
        .post(url, apiInput, { headers })
        .then((response) => {
            successCallBack(response.data);
        })
        .catch((error) => {
            errorCallBack(error);
        });
}
export function sociallogin(apiInput, successCallBack, errorCallBack) {
    const url = constants.BASE_URL + 'users';
    const headers = {};
    axios
        .post(url, apiInput, { headers })
        .then((response) => {
            successCallBack(response.data);
        })
        .catch((error) => {
            errorCallBack(error);
        });
}

export function addUserData(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    var config = {
        method: 'post',
        url: constants.BASE_URL + 'users/v1',
        headers: headers,
        data: data
    };
    return (function(dispatch) {
        axios(config)
            .then((response) => {
                dispatch({
                    type: 'USER_REGISTERED_SUCCESS',
                    payload: {...response.data, ...data }
                });
                // toast(response.data.message, {
                //     autoClose: 5000,
                //     type: toast.TYPE.SUCCESS,
                //     position: toast.POSITION.BOTTOM_CENTER,
                // });
                // document.getElementById("subscribe_signup_form").reset();
                return callback(null, response.data);


            }).catch((error) => {
                return callback(error, null);
            })
    });
};

export function validateUserDetails(data, callback) {
    const headers = {
        // 'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    var config = {
        method: 'post',
        url: constants.BASE_URL + 'users/validation',
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            console.log("validate user response")
            console.log(response)
            return callback(null, response.data);
        }).catch((error) => {
            return callback(error, null);
        })
   
};

export function ReactivateUserAccount(userId, successCallBack, errorCallBack) {
    console.log("hi hello")
    const headers = {
        
        'Content-Type': 'application/json'
    };
    
    var config = {
        method: 'put',
        url: constants.BASE_URL + 'users/reacivateaccount/' + userId,
        headers: headers,
       
    };
     console.log(config,"Hello Config")
        axios(config)
            .then((response) => {
                console.log("Successcallback response in from reactivate account", response)
                successCallBack(response.data);
            })
            .catch((error) => {
                console.log("Error response in catch from reactivate account", error)
                errorCallBack(error)
            })
};


export function updateUserData(data, callback) {

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    var config = {
        method: 'patch',
        url: constants.BASE_URL + 'users/business' ,
        headers: headers,
        data: data
    };
    axios(config)
        .then((response) => {
            console.log(response.data.message,"token") 
            toast(response.data.message, {
                autoClose: 5000,
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER,
            });
            window.location.href='/search';
        // this.props.setStep(2);
            // document.getElementById("subscribe_user_update_form").reset();
            // return callback(null, response.data);

        })
        // .catch((error) => {
        //     return callback(error, null);
        // })
};

export function countriesList() {
    return (function(dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `countries/`, { headers })
            .then((response) => {
                dispatch({
                    type: 'FETCH_COUNTRYLIST_DATA_SUCCESS',
                    payload: response.data
                });
            }).catch((error) => {})
    });
}

export function statesList(id) {
    return (function(dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `/countries/` + id + `/states/`, { headers })
            .then((response) => {
                dispatch({
                    type: 'FETCH_STATESLIST_DATA_SUCCESS',
                    payload: response.data
                });
            }).catch((error) => {})
    });
}

export function _action(action) {
    return (function(dispatch) {
        dispatch(action);
    });
}

export function recoverPassword(data, callback) {
    ;
    console.log(data,"data.c")
    let _data = {};
    let url = 'email-otp/send';
    if (data.to) {
        _data = {
            'to': data.to,
        };
    } else {
        url = 'otp/send';
        _data = {
            'phone': data.phone,
            'country_code': data.country_code,
        };

    }
    return (function(dispatch) {
        const headers = {
            'Content-Type': 'application/json',
        };
        axios
            .post(constants.BASE_URL + url, _data, { headers })
            .then((response) => {
                dispatch({
                    type: 'FETCH_RECOVERPASSWORD_DATA_SUCCESS',
                    payload: {...response.data, ..._data }
                });
                callback(null, response.data);
            }).catch((error) => {
                callback(error, null);
            })
    });
}

export function verifyOtp(data, callback) {
    let _data = {};
    let url = 'otp/verify';
    if (!data.to) {
        _data = {
            'otp': data.otp,
            'otp_id': data.otp_id,
        };
    } else {
        url ="email-otp/verify"
        _data = {
            'otp': data.otp,
            'to': data.to,
        };

    }
    return (function(dispatch) {
        const headers = {
            'Content-Type': 'application/json',
        };
        axios
            .post(constants.BASE_URL + url, _data, { headers })
            .then((response) => {
                dispatch({
                    type: 'FETCH_VERIFYOTP_DATA_SUCCESS',
                    payload: {...response.data, ..._data }
                });
                callback(null, response.data);
            }).catch((error) => {
                callback(error, null);
            })
    });
}
export function forgotPasswordChange(data, callback) {
    return (function(dispatch) {
        const headers = {
            'Content-Type': 'application/json',
        };
        axios
            .patch(constants.BASE_URL + `users/update-password`, data, { headers })
            .then((response) => {
                dispatch({
                    type: 'FETCH_FORGOT_PASSWORD_DATA_SUCCESS',
                    payload: {...response.data, ...data }
                });
                callback(null, response.data);
            }).catch((error) => {
                callback(error, null);
            })
    });
}

export function setUserDeatils(userDetails) {
    console.log(userDetails);
    return (function(dispatch) {
        dispatch({
            type: 'GET_USER_DETAILS_DATA_SUCCESS',
            payload: userDetails
        });

    });
};

export function getInvitationExpiryStatus(data,callback) {
    console.log('getInvitationExpiryStatus',data);
     const headers = {
        'Content-Type': 'application/json',
    };
    return axios
        .get(constants.BASE_URL + `accounts/expirevalidation/${data.email}`, { headers })
        .then((response) => {
            callback(null, response.data);
        }).catch((error) => {
            callback(error, null);
        })
};
export function updatePassword(data,callback) {
    console.log('updatePassword',data);
     const headers = {
        'Content-Type': 'application/json',
    };
    return axios
    .patch(constants.BASE_URL + `accounts/update-password`, data, { headers })
    .then((response) => {
        callback(null, response.data);
    }).catch((error) => {
        callback(error, null);
    })
};