import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Paper, Typography, Grid, InputLabel, Input, Button, FormControl, TextField, FormHelperText, Link } from '@material-ui/core';
import validator from 'validator';
import '../../../../common/style/main.css';
import { toast } from "react-toastify";
import { verifyOtp, recoverPassword } from "./../../reducer/actions";

class OtpComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: '',
      onOtphange: '',
      showing: false,
      timerotp: '',
      otpErrorText: '',
      isValid: false,
      twoMinutes: 60*2,
    }
    this.onOtphange = this.onOtphange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.onResendOtp = this.onResendOtp.bind(this);
    this.onChangeMode = this.onChangeMode.bind(this);
    this.startTimer();
  }


  onOtphange(event) {
    let otpVal = validator.isNumeric(event.target.value);
    if (!otpVal) {
      this.setState({
        ...this.state,
        otpErrorText: "Enter Otp",
        isValid: false
      });
    } else {
      this.setState({
        ...this.state,
        otp: event.target.value,
        otpErrorText: "",
        isValid: true
      });
    }
  }
  startTimer() {
    let timer = this.state.twoMinutes;
    let duration = '';
    let minutes = '';
    let seconds = '';
    let _timeRef = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      if (timer > 0) {
        this.setState({
          ...this.state,
          twoMinutes: minutes + ":" + seconds
        })
      } else {
        clearInterval(_timeRef);
        this.setState({ showing: true });
      }
      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);

  }

  onChangeMode() {
    const { history } = this.props;
    history.push("/forgot-password");
  }



  validateInput() {
   
    let otpErrorText = '';
    let isValid = true;
    if (this.state.otp.length === 0) {
      otpErrorText = 'Enter Otp';
      isValid = false;
    }

    this.setState({
      ...this.state,
      otpErrorText
    });
    return isValid;
  }

  submitForm(event) {
    console.log(this.props.recoveryPasswordInfo)
    if (this.validateInput()) {
      let data = {};
      if (this.props.recoveryPasswordInfo && this.props.recoveryPasswordInfo.phone ) {
        data = {
          'otp': this.state.otp,
          'otp_id': this.props.recoveryPasswordInfo && this.props.recoveryPasswordInfo.otp_id,
          'url': 'otp/verify'
        };

      } else {
      
        
        data = {
          'otp': this.state.otp,
          'to': this.props.recoveryPasswordInfo && this.props.recoveryPasswordInfo.to,
          'url': 'email-otp/verify'
        };
      }
      console.log(data)
      const { history } = this.props;
      this.props.verifyOtp(data, (error, res) => {
        if (error) {
          toast("Invalid OTP", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });

        } else {
          if (res.success == 1 || res.message == 'Token is valid.') {

            toast("Otp is verified", {
              autoClose: 5000,
              type: toast.TYPE.SUCCESS,
              position: toast.POSITION.BOTTOM_CENTER,
            });
            history.push("/reset-password");
          } else {
            toast('Invalid OTP', {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        }
      })

    }
  }



  onResendOtp() {
    console.log(this.props.recoveryPasswordInfo,"otpp")
    let data = {};
    if (this.props.recoveryPasswordInfo && this.props.recoveryPasswordInfo.phone) {
      data = {
        'phone': this.props.recoveryPasswordInfo && this.props.recoveryPasswordInfo.phoneNumber,
        'country_code': this.props.recoveryPasswordInfo && this.props.recoveryPasswordInfo.countryCode,
        'url': 'otp/send'
      };

    } else {
      data = {
        'to': this.props.recoveryPasswordInfo && this.props.recoveryPasswordInfo.to,
        'url': 'email-otp/send'
      };
    }
    this.props.recoverPassword(data, (error, res) => {
      if (error) {
        toast("Somthing went wrong", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });

      } else {
        if (res.success == 1) {
          console.log(this.state.twoMinutes);
          document.getElementById("otp_form").reset();
          this.setState({ showing: false });
          toast("Otp is sent", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          toast(res.message, {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }
    })
  }



  getContentView() {

    return (
      <div>
        <Container maxWidth="sm" className="space-3">
          <Paper className="border-radius">
            <div className="text-center">
              <Typography variant="h5" gutterBottom align="center" className="text-white">
                OTP Verify Code
              </Typography>
            </div>
            <div className="text-center1">
              <Typography variant="small" className="text-center1">
                Please Check your Emails for a message with your code. Your Code is 6 digits long.
              </Typography>
            </div>
            <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='otp_form' >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>

                  <FormControl className="form-width-100 ">
                    <InputLabel htmlFor="component-simple" className="color-BCBCBC">OTP</InputLabel>
                    <Input id="component-simple"
                      onChange={this.onOtphange}
                      defaultValue={this.state.emailId}
                    />
                    <FormHelperText className="error-o">{this.state.otpErrorText}</FormHelperText>
                  </FormControl>

                </Grid>
                <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} >Verify</Button>
              </Grid>
            </form>

            {this.state.showing
              ?
              <div className="text-center1">
                <a href="" onClick={this.onResendOtp} disableElevation style={{float:'right', color:'#CEA05F',paddingRight:'50px'}}><b>Resend OTP </b></a>
              </div>
              : <div className="text-center1">Didn't receive the code? Request New Code in {this.state.twoMinutes}
              </div>
            }
              <br/>
            <div className="text-center1">
              <a href="" onClick={this.onChangeMode} style={{color:'#CEA05F'}}><b>Change Recovery Mode</b></a>
            </div>
            {/* <div className="text-center1">
                    <Link onChange={this.onResendOtp}>Resend Otp</Link>
                </div> */}
            <div className="space-1"></div>
          </Paper>
        </Container>
      </div>
    );
  }
  render() {

    return (<ContentWrapper
      contentView={this.getContentView()}
    />
    )
  }
}

function mapStateToProps({ auth }) {
  return {
    recoveryPasswordInfo: auth.recoveryPasswordInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    verifyOtp,
    recoverPassword,
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpComponent);




