import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Modal,Grid} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@material-ui/core';
import { ReactivateUserAccount } from "../../components/Auth/reducer/actions"; // correct the path
import { toast } from "react-toastify";

export default function ReactivateAccountModal({  open, handleClose,data}) {

  const {email, userId, deletedDate, component} = data;


  // const accountActivationLastDate = 'June 12, 2023'
 
  // const navigateToLogin =() =>{
  //   if(component === 'LOGIN' && component === 'SINGUP'){
  //     handleClose();
  //     window.location.href='/login';
  //   }
  // }
 const reactivateAccount = () => {

    ReactivateUserAccount(
      userId,
      (res) => {
        handleClose();
        if (res.success === 1) {
          toast("Your account has been activates successfully. Please login", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });

        }else {  
          toast("Something went wrong", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      },
      (error) => {
        toast("Something went wrong", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    );

  
   }

  return (
    <div>
    
      <Modal
        open={open}
         onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 250,
                  bgcolor: 'background.paper',
                  border: '0px solid #000',
                  borderRadius:3,}}>        
          <Grid sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
            <IconButton onClick={handleClose}><ClearIcon /></IconButton>
          </Grid>
          
          <Box sx={{
                  pt:0,pl:4,pr:4,pb:4}}>
            
    
             <Typography id="modal-modal-title" variant="h6"  sx={{align:'center'}}>
               Want to keep using this account?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 ,align:'justify'}}>
            You requested to delete your account {email}. If you want to keep it, you have untill  <b>{deletedDate}</b> to let us know. Otherwise, all your information will be deleted.
          </Typography>
          <Grid sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',mt:'5%'}}>
            <Grid >
            <Button variant="contained"  className="SubmitColor" onClick={reactivateAccount}>Keep Account</Button>

            </Grid>
            <Grid>
            <Button variant="text" sx={{color:'black'}} onClick={handleClose} >Cancel</Button>

            </Grid>
          </Grid>
        </Box>
        </Box>
      </Modal>
    </div>
  );
}

