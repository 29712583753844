import axios from 'axios';

import constants from './../../../../utils/constants';

export function countriesList() {
    return (function (dispatch) {
        const headers = {
            'Content-Type':'application/x-www-form-urlencoded'
        };
        axios
        .get(constants.BASE_URL + `countries`, {headers})
        .then((response) => {
            dispatch({
                type: 'FETCH_COUNTRY_LIST_DATA_SUCCESS',
                payload: response.data
            });
        }).catch((error) => {
        })
    });
  };

  