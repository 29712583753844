
import React, { useEffect, useState } from 'react'
import { Table, TableBody, makeStyles, Container, TableHead, TableCell, TableContainer, CircularProgress, FormGroup, Checkbox, Dialog, DialogTitle, DialogContent, FormControlLabel, DialogActions, TableRow, Paper, Grid, Button, RadioGroup } from '@material-ui/core'
import tick from '../../../../assests/images/tick-circle.svg'
import InfoIcon from '../../../../assests/images/info.svg'
import CloseIcon from '../../../../assests/images/close.svg'
import getUserdetailsforsubscrption from '../../dashboard/actions/dashboardaapi';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Typography } from '@mui/material';
import {
    countriesList, packagesList, setPackage, activePackageList
} from '../actions/dealerAction';
import { connect, useDispatch } from 'react-redux'

const useStyles = makeStyles({
    button: {
        height: '40px',
        border: '1px solid #FF3535',
        color: '#FF3535',
        padding: '10px',
        background: '#ffff',
        borderRadius: '8px',
        fontSize: '12px',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#FF3535',
        },
    },
    paperwidth: {
        width: '400px'
    }

})
// const rows = [
//     { Value: 'Search', start: tick, starterpackage: tick, basic: tick, premium: tick },
//     { Value: 'Image Sharing', start: CloseIcon, starterpackage: tick, basic: tick, premium: tick },
//     { Value: "Inventory", start: CloseIcon, starterpackage: tick, basic: tick, premium: tick },
//     { Value: 'Invoicing/Payments', start: CloseIcon, starterpackage: CloseIcon, basic: tick, premium: tick },
//     { Value: 'Unlimited Image Cloud', start: CloseIcon, starterpackage: CloseIcon, basic: tick, premium: tick },
//     { Value: 'No of Users', start: 1, starterpackage: 3, basic: 3, premium: 10 },
// ];


function LoaderDialog(props) {
    const { open, setOpen } = props

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {

    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Payment processing please wait...."}
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ margin: '20px' }} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
const PricingComponent = ({ handleNext, setPackage }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    // const [isloader, setloader] = useState(false)
    const [packagesList, setPackagesList] = useState(null);
    const [toggleValue, setToggleValue] = useState('MONTHLY')
    const [activePacKage, setActivePackage] = useState(null);
    const [activePacKagesDetails, setActivePackageDetails] = useState(null)
    const [rows, setRows] = useState([]);
    localStorage.setItem('paymentType','MONTHLY');
   const  getactivepackages = ()=>{
        getUserdetailsforsubscrption.getActivepackage()
        .then((res) => {
            if(res?.data.success ===1){
                console.log(res.data.data)
                setActivePackageDetails(res.data?.data)
                setActivePackage(res?.data?.data?.membertierid)
            }
          })
        }
    useEffect(() => {

        let searchFeature = { Value: 'Search', };
        let ImageSharing = { Value: 'Image Sharing' };
        let inventory = { Value: "Inventory" };
        let invoice = { Value: 'Invoicing/Payments' };
        let imageCloud = { Value: 'Unlimited Image Cloud' };
        let users = { Value: 'No of Users' };
        const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;
        if(userDetails && userDetails?.userId){
            getactivepackages();
        }
        // { Value: 'Unlimited Image Cloud', start: CloseIcon, starterpackage: CloseIcon, basic: tick, premium: tick },
        // { Value: 'No of Users', start: 1, starterpackage: 3, basic: 3, premium: 10 },
        getUserdetailsforsubscrption.getAllpackgesList()
            .then((res) => {
                console.log(res?.data?.data, "packages list")
                setPackagesList(res?.data?.data)
                const processData = res?.data?.data

                processData.map((singlePack) => {
                    singlePack?.packagedetails?.packagedetails.map((featuredetails) => {
                        console.log("singlePack.membertiername", singlePack.membertiername)
                        if (featuredetails.feature === "Search") {
                            searchFeature[singlePack.shortdesc] = featuredetails?.isIncluded ? tick : CloseIcon
                        }
                        else if (featuredetails.feature === "Image Sharing") {
                            ImageSharing[singlePack.shortdesc] = featuredetails?.isIncluded ? tick : CloseIcon
                        }
                        else if (featuredetails.feature === "Inventory") {
                            inventory[singlePack.shortdesc] = featuredetails?.isIncluded ? tick : CloseIcon
                        }
                        else if (featuredetails.feature === "Invoicing/Payments") {
                            invoice[singlePack.shortdesc] = featuredetails?.isIncluded ? tick : CloseIcon
                        }
                        else if (featuredetails.feature === "Unlimited Image Cloud") {
                            imageCloud[singlePack.shortdesc] = featuredetails?.isIncluded ? tick : CloseIcon
                        }
                        else if (featuredetails.feature === "No Of Users") {
                            users[singlePack.shortdesc] = featuredetails?.isIncluded ? featuredetails?.limit : 0
                        }
                    })
                })
                const row = []
                row.push(searchFeature);
                row.push(ImageSharing);
                row.push(inventory);
                row.push(invoice);
                row.push(imageCloud);
                row.push(users);
                console.log(row, "row")
                setRows(row);
            })
    }, [])

    return (
        <React.Fragment>
            <Container>
                <ToggleButtonGroup
                    value={toggleValue}
                    color="error"
                    exclusive
                    onChange={(e) => { localStorage.setItem('paymentType',e.target.value); setToggleValue(e.target.value) }}
                    aria-label="Platform"
                    sx={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        marginLeft:"40% "
                    }}
                >
                    <ToggleButton value="MONTHLY" sx={{ borderRadius: "20px 0px 0px 20px", width: "200px" }}>MONTHLY</ToggleButton>
                    <ToggleButton value="ANNUAL" sx={{ borderRadius: " 0px 20px 20px 0px", width: "200px" }}>ANNUAL</ToggleButton>
                </ToggleButtonGroup>
                <TableContainer style={{ paddingBottom: '25px' }}>
                    <Table sx={{ minWidth: 400 }} aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell style={{ border: '1px solid black', textAlign: 'center', padding: 0, width: '20%', fontWeight: 'bold' }}>Features</TableCell>
                                {packagesList && packagesList.map((pack) => {
                                    return (
                                        <TableCell style={{ border: '1px solid black', textAlign: 'center', padding: 0 }}>
                                            <Grid style={{ marginBottom: '10px', marginTop: "10px", fontWeight: "bold", fontSize: "16px" }}>{pack?.membertiername}</Grid>

                                            <Grid container style={{ display: 'flex', marginTop: '20px', marginBottom: "20px", background: '#ffff', justifyContent: "space-evenly" }}>
                                                {toggleValue === "MONTHLY" ? (
                                                    <Grid item style={{
                                                        // flex: '50%', borderTop: '1px solid black',
                                                        // borderRight: '1px solid black',
                                                        textAlign: 'center',
                                                        fontSize: '16px'
                                                    }}>
                                                        <div style={{ fontWeight: 'bold', color: '#FF3535' }}>{pack?.currency && pack?.currency === "USD" ? "$" : pack?.currency} {pack?.pricepermonth}/Month</div><div></div>
                                                    </Grid>
                                                ) : (
                                                    <Grid item style={{
                                                        // flex: '50%', borderTop: '1px solid black',
                                                        textAlign: 'center',
                                                        fontSize: '16px'
                                                    }}>
                                                        <div style={{ fontWeight: 'bold', color: '#FF3535' }}>{pack?.currency && pack?.currency === "USD" ? "$" : pack?.currency} {pack?.priceperyear}/Year</div><div></div>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <div style={{ marginBottom: "10px" }}>
                                            {  ( activePacKage === pack.membertierid && ( (toggleValue === "MONTHLY" && pack?.pricepermonth === activePacKagesDetails?.sub_total) ||  (toggleValue !== "MONTHLY" && pack?.priceperyear === activePacKagesDetails?.sub_total)  )) ? 
                                            (
                                                    <Button style={{  color: '#fff',
                                                    backgroundColor: '#FF3535'}} 
                                                >
                                                          Active
                                                </Button>
                                                )  :  (
                                                    <Button className={classes.button} 
                                                    onClick={() => {
                                                     dispatch({
                                                        type: "PACKAGES_SELECTED_SUCCESS",
                                                        payload: pack,
                                                      });
                                                    // setPackage(pack);        
                                                    localStorage.setItem('singlepkg', JSON.stringify(pack));
                                                    handleNext(pack)}}
                                                >
                                                        Subscribe Now
                                                </Button>
                                                ) }
                                            </div>

                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.Value}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ border: '1px solid black' }}>
                                        {row.Value} <span style={{ float: 'right' }}><img src={InfoIcon} style={{ width: '15px', textAlign: 'center' }}></img></span>
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{ border: '1px solid black', textAlign: 'center' }}>
                                        {typeof row.Basicpackage != 'number' ? <img src={row.Basicpackage} style={{ width: '25px' }}></img> : <>{row.Basicpackage}</>}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', textAlign: 'center' }}>
                                        {typeof row.Starterpackage != 'number' ? <img src={row.Starterpackage} style={{ width: '25px' }}></img> : <>{row.Starterpackage}</>}
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{ border: '1px solid black', textAlign: 'center' }}>
                                        {typeof row.Premiumpackage != 'number' ? <img src={row.Premiumpackage} style={{ width: '25px' }}></img> : <>{row.Premiumpackage}</>}
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid black', textAlign: 'center' }}>
                                        {typeof row.SuperPremiumpackage != 'number' ? <img src={row.SuperPremiumpackage} style={{ width: '25px' }}></img> : <>{row.SuperPremiumpackage}</>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

            {/* <LoaderDialog open={isloader} /> */}

        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    invoiceDetails: state.InvoiceList,
    modelList: state.ModelList.modelList,
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setPackage
    };
  };
  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PricingComponent);