import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HomeBannerSlider from './homeBannerSliderComponent';
import SignSubscribeComponent from './signinSubscribeComponent';
import OurMemberSayComponent from './ourMemberSayComponent';
import WatchLoversComponent from './watchLoversComponent';
import OurCientsComponent from './ourClientsComponent';
import HowItWorksComponent from './howItWorksComponent';
import DownloadNowComponent from './downloadNowComponent';
import SubscribeForWatchComponent from './subscribeForWatchesComponent';
import ContentWrapper from '../../../../common/components/contentWrapper';
import SimpleBannerComponent from './simpleBannerComponent';

class IndexComponent extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
    
        }
      }


    getContentView() {
        return (
            <div>
                <HomeBannerSlider
                {...this.props}
                />
                <SimpleBannerComponent/>
                <div className="space-5"></div>
                {this.props.currentUser && this.props.currentUser.userId?'':(
                <SignSubscribeComponent
                    {...this.props}
                  />)
                }
                {this.props.currentUser && this.props.currentUser.userId?'':(
                <div className="space-3"></div>
                )}
                <WatchLoversComponent/>
                <div className="space-3"></div>
                <HowItWorksComponent/>
                <DownloadNowComponent/>
                <div className="space-3"></div>
                {this.props.currentUser && this.props.currentUser.userId?'':(
                 <div className="space-3"></div>
                )}
                <OurMemberSayComponent/>
                <OurCientsComponent/>
                <div ></div>
            </div>
        )
    }
    render(){
        localStorage.setItem("menuTitle","HOME");
        return(<ContentWrapper
            contentView={this.getContentView()}
            />
            )
    }
}

function mapStateToProps({ auth }) {
    return {
      currentUser: auth.currentUser,
    }
  }
  
  export default connect(mapStateToProps, null)(IndexComponent);
