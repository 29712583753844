const initialState = {
     loading: false,
     list: [],
     userTrackingList:[],
     error: null,
     inviteUserSuccess: null,
     inviteUserFailure: null
};
const AdminReducer = (state = initialState, action) => {
    let currentState = state;
    switch (action.type) {
            case 'ADMIN_DASHBOARD_LIST': 
            currentState = {
                ...state,
                loading: true,
                error: null
            };
            break;
            case 'ADMIN_DASHBORDS_LIST_SUCCESS':
                currentState = {
                    ...state,
                    loading: false, 
                    list: action.payload,
                    error: null
                };
                break;
            case 'ADMIN_DASHBORDS_LIST_FAILED':
                currentState = {
                    ...state,
                    loading: false,
                    list: [],
                    error: action.payload 
                };
                break;
                case 'USER_TRACKING_LIST': 
                currentState = {
                    ...state,
                    loading: true,
                    error: null
                };
                break;
                case 'USER_TRACKING_LIST_SUCCESS':
                    currentState = {
                        ...state,
                        loading: false, 
                        userTrackingList: action.payload,
                        error: null
                    };
                    break;
                case 'USER_TRACKING_LIST_FAILED':
                    currentState = {
                        ...state,
                        loading: false,
                        userTrackingList: [],
                        error: action.payload 
                    };
                    break;    
            case 'INVITE_USER_REQUEST':
                    currentState = {
                        ...state,
                        loading: true,
                    };
                    break;
            case 'INVITE_USER_SUCCESS':
                currentState = {
                    ...state,
                    loading: false,
                    inviteUserSuccess: action.payload
                };
                break;
            case 'INVITE_USER_FAILURE':
                currentState = {
                    ...state,
                    loading: false,
                    inviteUserSuccess: null,
                    inviteUserFailure: action.payload
                };
                break;
        default:
    }
    return currentState;
}

export default AdminReducer;