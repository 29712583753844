import React, { Component } from 'react';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Grid, Typography, Button, Link  } from '@material-ui/core';
import rolex from '../../../../assests/images/rolex-watch.png';
import PriceTag1 from '../../../../assests/images/ebay.png';
import PriceTag2 from '../../../../assests/images/chrono.png';
import PriceTag3 from '../../../../assests/images/Group 1824.png';
import plusSign from '../../../../assests/images/Group 1771.png';

class DealerAddNewProductDetailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }


    getContentView() { 
        return ( 
            <div style={{background:'#fff',marginBottom:'20px'}}>
                <div className="space-3">
                    <Container>
                    {/* <div className="space-left-30">
                    <Typography variant="h5" component="h5" className="space-bottom-15 color-454545 font-weight-700">Add New Product Details</Typography>
                    <div className="border-contact-form"></div>
                    </div> */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <div className="space-top-40 space-left-20">
                                    <div>
                                        <img src={rolex} alt="roles-watch" className="width-80"/>
                                    </div>
                                    <div>
                                        <Typography variant="p" component="p" className="space-left-10 color-000000DE font-size-22 font-weight-700"><b>Rolex Submariner 40mm Stainless steel</b></Typography>
                                    </div>
                                    <div className="space-top-20">
                                        <Grid container spacing={3}>
                                            {/* <Grid item xs={4} sm={4} md={3}>
                                                <div>
                                                    <img src={PriceTag1} alt="" style={{width:'50%'}}/>
                                                </div>
                                                <div className="color-848484">
                                                    $9,899.00
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <div>
                                                    <img src={PriceTag2} alt="" style={{width:'50%'}}/>
                                                </div>
                                                <div className="color-848484">
                                                    $9,899.00
                                                </div>
                                            </Grid> */}
                                            <Grid item xs={4} sm={4} md={3}>
                                                {/* <div>
                                                    <img src={PriceTag3} alt="" style={{width:'50%'}}/>
                                                </div>
                                                <div className="color-848484">
                                                    $9,899.00
                                                </div> */}
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={3}>
                                                
                                            </Grid>
                                        </Grid>
                                        
                                    </div>
                                    {/* <div className="space-top-15">
                                        <Typography variant="p" component="p" className="color-000000DE"><b>Description</b></Typography>
                                        <Typography variant="p" component="p" className="font-size-12">When an unknown printer took a galley of type and scrambled it to make … <span className="color-CEA05F">MORE</span></Typography>
                                    </div> */}
                                </div>
                            </Grid>
                            <div style={{position:'fixed',right:'20px',bottom:'70px'}}>
                                <Link href="../add-to-inventory-new-watch-details">
                                    <img src={plusSign} alt="plusSign" width="60px"/>
                                </Link>
                            </div>
                            <Grid item xs={12} sm={12} md={6}>
                                <div className="space-top-30 space-3-hide">
                                    <div className="space-bottom-15 space-top-15 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="color-00000061">REFERENCE NUMBER</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>116610</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-15 space-top-15 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p"className="color-00000061">CASE MATERIAL</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Stainless steel</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-15 space-top-15 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p"className="color-00000061">CASE SIZE</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>40mm</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-15 space-top-15 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p"className="color-00000061">DIAL COLOR</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Black</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-15 space-top-15 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p"className="color-00000061">BAND MATERIAL</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Oystersteel</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-15 space-top-15 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p"className="color-00000061">CASE BACK</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Exhibition</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-15 space-top-15 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p"className="color-00000061">CASE SHAPE</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Round</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-15 space-top-15 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p"className="color-00000061">POWER RESERVE</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Power Reserve</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-top-30">
                                        <Button className="primary-bg text-white text-center1 form-width-100"  href="/add-to-inventory" onClick={this.validateInput}>Next</Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
         );
    }
    render() { 
        localStorage.setItem("menuTitle","CONTRIBUTE NEW WATCH");
        return ( 
            <ContentWrapper
            contentView={this.getContentView()}
            />
         );
    }
}
 
export default DealerAddNewProductDetailComponent;