import axios from 'axios';
import constants from './../../../../utils/constants';
import { getToken } from "./../../../../utils/Authentication";

export function getActivityList(data, callback) {
    return function (dispatch) {
        dispatch({ type: "ACTIVITY_LOGS_LIST" });
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .post(constants.BASE_URL + `accounts/activitylog/dashboard`, data, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';

                } else {
                    console.log('response', response.data);
                    dispatch({
                        type: "ACTIVITY_LOGS_LIST_SUCCESS",
                        payload: response.data.data,

                    });
                    callback(response.data);
                }
            })
            .catch((error) => {
                console.log(error)


            })
    }
};
