import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Grid,
    Button,
    TextField,
    FormControl,
    IconButton,
    Box,
    Typography,
    Modal,
    Select,
    MenuItem,
    InputLabel,
} from '@mui/material';
import SuccessImage from "../../../../assests/images/success.png"
import { removeUser } from '../actions/administration';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function ResendInvitationModal({ open, handleClose, SaveModal, saveModalOpen,userData }) {

    const onSubmit1 = () => {
        const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null
        const inputObject = {
            accountid: userDetails ? userDetails.accountId : null,
            user_id: userData.userid
        }
        console.log(inputObject, "data");

        removeUser(inputObject,(error, result)=>{
            console.log("invite user response", result)
            if(result && result.success){
                saveModalOpen(true);
            }else{
                toast("Error in sending invite to user", {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
            }
        })
      }
      
    return (
        <div>

            <Modal
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '30%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '0px solid #000',

                }}
                >

                    <Grid sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: '1px solid #e0e0e0', paddingBottom: '15px' }}>
                        <Grid>
                            <Typography variant="h6" component="h6" sx={{ mt: 1, ml: 2 }}>Remove User</Typography>
                        </Grid>
                        <Grid >
                            <IconButton onClick={()=> handleClose()} >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid sx={{ display: 'flex', flexDirection: 'column', p: '20px 35px 10px 35px' }}>

                        <Typography variant="h6" component="h6" sx={{ fontSize: '16px' }}>Are You Sure To Want Remove {userData?.email} ?</Typography>



                    </Grid>
                    <Box sx={{
                        pt: 1, pl: 2, pr: 4, pb: 4
                    }}
                    >

                        <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 2, justifyContent: 'space-evenly', justifyItems: 'flex-end' }}>
                            <Grid >
                                <Button variant="text" sx={{ width: '6rem' }} className='customButton' onClick={() => handleClose()} >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid>
                                <Button variant="text" sx={{ width: '6rem' }} className='customButton' onClick={() => onSubmit1()} >
                                    Remove
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
            {SaveModal ?
                <Modal
                    open={SaveModal}
                    onClose={() => handleClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '40%',
                        height: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '0px solid #000',

                    }}
                    >

                        <Grid sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', float: 'right' }}>
                            <Grid>
                                <IconButton onClick={() => handleClose()} >
                                    <ClearIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: '6rem' }}>
                            <Grid >
                                <img src={SuccessImage} sx={{ width: '30%', height: '30%' }}></img>
                            </Grid>
                            <Grid>
                                <Typography variant="h6" component="h2">Removed</Typography>
                            </Grid>
                        </Grid>

                    </Box>
                </Modal>

                : null}

        </div>
    );
}