import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@mui/material/TextField';
import { Button, DialogActions, FormControl, FormHelperText, Typography, Grid } from "@material-ui/core";
import { trim } from "lodash";
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';


export default function StatusChange(props) {

    const { open, handleClose, changeRangeName } = props;

    const [reason, setReason] = React.useState('');
    const [errorText, setErrorText] = React.useState('')

    const validateReason = () => {
        if (reason.length >= 1000) {
            setErrorText('Status cannot exceed 1000 characters.');
            return;
        }
        if (reason !== '') {
            changeRangeName(reason)
        }
        else {
            setErrorText('Status Changes should not be blank.')
        }
    }
    const ReasonValidation = (e) => {
        const regex = /^[0-9a-zA-Z _*-]*$/;
        const reasonValue = e.target.value
        setReason(reasonValue);
        if (!regex.test(reasonValue)) {
            setErrorText('Enter Valid Reason Change');
        } else {
            setErrorText('');
        }
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // fullWidth={true}
            maxWidth={'sm'}

        >
            <DialogTitle style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '0px 0px 20px 0px' }}>

                Status Change
                <IconButton onClick={() => { handleClose() }}>
                    <CloseIcon />
                </IconButton>


            </DialogTitle>
            <DialogContent>
                <div> Are you sure you want to change the status</div>
                <div><FormControl style={{ width: '400px', margin: '25px 0px' }}>
                    <TextField
                        id="outlined-multiline-flexible"
                        label=" Please enter a reason for status change"
                        multiline
                        maxRows={4}
                        required={true}
                        value={reason}
                        style={{ width: '400px !important' }}
                        onChange={ReasonValidation}

                    />
                    <FormHelperText className="error-o">
                        {errorText}
                    </FormHelperText>
                    <Grid style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', alignItems: 'center' }}>
                        <Grid>
                            <VisibilityIcon></VisibilityIcon>
                        </Grid>
                        <Grid>
                            <Typography style={{ marginLeft: '10px', textAlign: 'justify' }}>Original inventory details will be saved including original purchase information. If those need to change then you can edit inventory details.</Typography>
                        </Grid>
                    </Grid>
                </FormControl></div>
            </DialogContent>
            <DialogActions >
                <Button color="primary" autoFocus onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="primary"
                    onClick={validateReason}
                >Ok</Button>
            </DialogActions>
        </Dialog>
    );
}
