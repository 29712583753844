import React, { Component } from 'react';
import ContentWrapper from '../../../../common/components/contentWrapper';
import DealerInventory from './dealerProduct';

class DealerInventoryMainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getContentView() {
        return <DealerInventory />
    }
    render() {
        const userData = JSON.parse(sessionStorage.getItem("userDetails"));
        return (
            <ContentWrapper
                contentView={this.getContentView(this.props)}
                {...this.props}
                showSubscriptionPopup={userData && userData.isPlanExpired}
            />
        )
    }
}

export default DealerInventoryMainPage;