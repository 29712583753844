
import axios from 'axios';
import constants from './../../../../utils/constants';
import { getToken } from "./../../../../utils/Authentication";


export function fetchBrowseWatches(pageID1,pagecontent1) {
    
console.log(pageID1)
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `products/` + pagecontent1 +'/'+ pageID1, { headers })
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_DATA_SUCCESS',
                        payload: response.data
                    });
                }
                // console.log('response.data', response.data);
            }).catch((error) => {
            })
    });
};

export function fetchSearchBrowseWatches(productId,pageID,pagecontent) {
    
console.log(pageID,"==============================")
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        
        axios
            .get(constants.BASE_URL + `products/search/` + productId + '/' + pagecontent +'/'+ pageID, { headers })
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                dispatch({
                    type: 'FETCH_BROWSE_WATCHES_DATA_SUCCESS',
                    payload: response.data
                });
                // console.log('response.data', response.data);
            }
            }).catch((error) => {
            })
    });
};

export function brandList() {
    
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `brands/`, { headers })
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_BRAND_DATA_SUCCESS',
                        payload: response.data
                    });
                }
            }).catch((error) => {
            })
    });
};

export function caseSize() {
    
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `products/case_sizes/`, { headers })
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_CASE_SIZE_DATA_SUCCESS',
                        payload: response.data
                    });
                }
               
            }).catch((error) => {
            })
    });
};

export function caseMaterial() {
    
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `products/case_materials`, { headers })
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_CASE_MATERIAL_DATA_SUCCESS',
                        payload: response.data
                    });
                }
                
            }).catch((error) => {
            })
    });
};

export function brandMaterial() {
    
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `products/band_materials`, { headers })
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_BRAND_MATERIAL_DATA_SUCCESS',
                        payload: response.data
                    });
                }
               
            }).catch((error) => {
            })
    });
};

export function dialColor() {
    
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `products/dial_colors`, { headers })
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_COLOR_DIAL_DATA_SUCCESS',
                        payload: response.data
                    });
                }
               
            }).catch((error) => {
            })
    });
};

export function ModelList() {
    
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .get(constants.BASE_URL + `products/models/` + constants.MODEL_LIMIT + '/' + constants.PAGINATION_START, { headers })
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_MODEL_LIST_DATA_SUCCESS',
                        payload: response.data
                    });
                }
               
            }).catch((error) => {
            })
    });
}

export function filterBySearch(data) {
    
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        var config = {
            method: 'post',
            url: constants.BASE_URL + 'products/filter',
            headers: headers,
            data: data
        };
        axios(config)
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_DATA_SUCCESS',
                        payload: response.data
                    });
                }
               
            }).catch((error) => {
            })
    });
};

export function filterBySearchWatch(page,brandId) {
    
    return (function (dispatch) {
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        var config = {
            method: 'get',
            url: constants.BASE_URL + 'products/search/' + brandId +'/'+ constants.PAGINATION_LIMIT + '/',
            headers: headers,
            data:page,
        };
        axios(config)
            .then((response) => {
                if(response && response.data && response.data.success === 0 && response.data.logout === true ){
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href='/login';
                }else{
                    dispatch({
                        type: 'FETCH_BROWSE_WATCHES_DATA_SUCCESS',
                        payload: response.data
                    });
                }
               
            }).catch((error) => {
            })
    });
};
