import React, { Component } from 'react';
import VerticalTabs from './dashboardTabsComponent';
import ContentWrapper from '../../../../common/components/contentWrapper';
import DashboardComponent from './dashboardComponent';
import DashboardChart from './SalesReport/dashboardChart';
import UpgradePlan from '../../common/components/upgradePlan';
class DashboardPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null
        }
    }
    componentDidMount() {
        console.log(this.props)
    }

    getContentView() {
        return (
            <div>
                <div style={{ margin: '10px' }}>
                    {/* <VerticalTabs
                    {...this.props}
                    /> */}
                    <DashboardChart />
                </div>
            </div>
        );
    }
    render() {
        const userData = JSON.parse(sessionStorage.getItem("userDetails"));

        if (this.props.location.pathname.includes("/myinventory")) {
            localStorage.setItem("menuTitle", "MY INVENTORY");
        } else {
            localStorage.setItem("menuTitle", "");
        }
        return (

            <ContentWrapper
                contentView={this.state.userData && (!this.state.userData?.planDetails || this.state.userData?.planDetails === "Noplan") ?
                    <div style={{ marginTop: "25px" }}> <UpgradePlan /> </div> : this.getContentView(this.props)}
                {...this.props}
                showSubscriptionPopup={userData && userData.isPlanExpired}
            />
        )
    }
}

export default DashboardPageComponent;