
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import AccessRestrictedImage from '../../../../assests/images/AccessRestricted.png';

const handleClick = () => {
    window.open('https://www.watchops.com/check-out', '_blank');
};

const UpgradePlan = () => {
    return (
        <>
            <div style={{ textAlign: 'center', paddingTop: "7%" }}>
                <Typography variant='h4'>Oops! It looks like your access is limited.</Typography>
                <br></br>
                <Typography variant='subtitle2'>Click below to subscribe and ensure uninterrupted access to this feature and much more!</Typography>
                <br></br>
                <Button
                    id="demo-customized-button"
                    aria-haspopup="true"
                    variant="contained"
                    disableElevation
                    className="text-capitalize primary-bg color-white"
                    onClick={handleClick}
                >
                    SUBSCRIBE
                </Button>
            </div>
            {/* <div>
                <img src={AccessRestrictedImage} alt="access restricted" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '35%' }} />
            </div> */}
        </>

    )
};

// const UpgradePlan = () =>{
//     return (
//         <ContentWrapper
//             contentView={AccessRestricted()}
//         />
//     );
// }

export default UpgradePlan;