import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Typography from '@material-ui/core/Typography';

import DealerProductInventoryIntakeComponent from './dealerProductInventoryIntakeComponent';
import DealerProductUploadImagesComponent from './dealerProductUploadImagesComponent';
import DealerAddInventorySuccessFullComponent from './dealerAddInventorySuccessfullComponent';
// import DealerProductMainPageComponent from './dealerProductMainPageComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Step 1 Add Inventory','Step 2 Upload Images'];
}


export default function DealerInventoryComponent() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <DealerProductInventoryIntakeComponent
        {...this.props}
        handleNext={handleNext}
        />;
      case 1:
        return <DealerProductUploadImagesComponent
        {...this.props}
        handleNext={handleNext}
        />;
     
    
      default:
        return <DealerAddInventorySuccessFullComponent/>;
  
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel style={{fontSize:'18px'}}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
        <div>
          <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
          <div className="float-right">
            {/* <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              Back
            </Button> */}
            {/* <Button variant="contained" color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button> */}
          </div>
        </div>
      {/* <div>
        {activeStep === steps.length ? (
          <div>
            <DealerSubscriptionThankYou/>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : ""}
      </div> */}
    </div>
  );
}