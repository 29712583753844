import { Divider } from '@material-ui/core';
import React, { Component } from 'react';
import ContentWrapper from '../../../../common/components/contentWrapper';
import DealerProductInventoryIntakeComponent from './dealerProductInventoryIntakeComponent';
import DealerProductUploadImagesComponent from './dealerProductUploadImagesComponent';
import DealerInventoryComponent from './dealerInventoryComponent';

class DealerProductMainPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryId: null

        }
    }

    inventoryIntakeSubmitted = (inventoryId) => {

        this.setState({
            ...this.state,
            inventoryId: inventoryId
        })

    }



    getContentView() {
        return (

            <div className="bg-white">
                <div className="space-top-30">
                   
                    {this.state.inventoryId ? (
                        <div>
                            <DealerProductUploadImagesComponent
                            {...this.props}
                                inventoryId={this.state.inventoryId} /></div>)
                        :
                        <DealerProductInventoryIntakeComponent
                            {...this.props}
                            submitted={this.inventoryIntakeSubmitted}
                        />}
                </div>
            </div>
        );
    }

    render() {
        localStorage.setItem("menuTitle", "ADD INVENTORY");
        const userData = JSON.parse(sessionStorage.getItem("userDetails"));

        return (
            <ContentWrapper
                contentView={this.getContentView()}
                showSubscriptionPopup={userData && userData.isPlanExpired}
            />
        );
    }
}

export default DealerProductMainPageComponent;