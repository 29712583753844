import React, { Component } from 'react';
import ContentWrapper from "../../../common/components/contentWrapper";
import SideTab from "./adminTabComponent";

class ActivityLogsPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    componentDidMount(){
     console.log(this.props)
    }

    getContentView1() { 
        return ( 
            <div>
                <div style={{margin:'10px'}}>
                    <SideTab
                    {...this.props}
                    />
                </div>
            </div>
         );
    }
    render(){
        
        return(

            <ContentWrapper
           
            contentView={this.getContentView1()}
           {...this.props}
            />
            )
    }
}
 
export default ActivityLogsPageComponent;