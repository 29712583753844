import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Typography, Grid, Card, CardContent, Divider,Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import SignUpComponent from './../../../Auth/signup/components/signUpComponent'
import { packagesList, setPackage } from '../actions/dealerAction';
import '../style/subscription.css';
import { HistoryRounded, SignalCellularNull } from '@material-ui/icons';
import DealerChooseSubscriptionsComponent from './dealerChooseSubscriptionsComponent';
import { login,_action } from "./../../../Auth/reducer/actions";
import { getuser } from "./../../dashboard/actions/dashboardAction";
import { getuserdetails } from "./../../dashboard/actions/dashboardAction";
import getUserdetailsforsubscrption from '../../dashboard/actions/dashboardaapi'
import { SpinnerRoundFilled } from 'spinners-react';
import PricingComponent from './pricingComponent';

class SubscriptionComponent extends Component { 
    constructor(props) {
        super(props)
        this.state = {
            signUpDialog: false,
            userdetailsforsubcplan:[],
            packagesListInfo:[],
            activepackage:[],
            activeselectpackage:[],
            packagesListInfoforsubscrption:[],
            selectedpackagenumber:'',
            loaded: false,

        }
        this.handleClose = this.handleClose.bind(this);
        this.openSignUpDialog = this.openSignUpDialog.bind(this);
        this.onStartToday = this.onStartToday.bind(this);
        this.getuserbusnessdetails = this.getuserbusnessdetails.bind(this)
        this.onSignUP = this.onSignUP.bind(this);
        this.getactivepackages = this.getactivepackages.bind(this)
       this.details = getuserdetails(props.currentUser?.userId);
      this.componentDidMount = this.componentDidMount.bind(this)

   
    }



    componentDidMount() {
       this.userInfoDetails = JSON.parse(localStorage.getItem('UserBusinessDetails'))
        const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));
        const userIds = userDetails?.userId;
        this.props.packagesList();
        this.getpackagedetailsforsubcrption()
        getUserdetailsforsubscrption.getUserDetails(userIds).then((res) => {
            this.setState({
                userdetailsforsubcplan: res.data.data
            })
        })
// this.userbusiness(this.props.currentUser.userId)
this.getAllpackageList()
this.getuserbusnessdetails(userIds)
this.getactivepackages()
    }
    getactivepackages(){
        getUserdetailsforsubscrption.getActivepackage().then((res) => {
            this.setState({
                activepackage: res.data.data,
                activeselectpackage : res.data
            })
            console.log(this.state.activepackage,"activepackage",this.state.activeselectpackage ,res.data.success)
            if(res.data.success ===1){
                this.setState({selectedpackagenumber :this.state.activepackage.membertierid,
                    loaded: true})
                    }else {
                this.setState({selectedpackagenumber :1, loaded: true})

                    }
    })
        
   
}
getAllpackageList(){
    getUserdetailsforsubscrption.getAllpackgesList().then((res) => {
        this.setState({
            packagesListInfo: res.data.data
        })
    })
}
getuserbusnessdetails(){
    const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));
    const userIds = userDetails?.userId;
   if(userIds){
    getUserdetailsforsubscrption.getUserDetails(userIds).then((res) => {
        this.setState({
            userdetailsforsubcplan: res.data.data
        })
    })
   }
}

userbusiness (id) {

}

    onSignUP = (data) => {
        const { history } = this.props;
        this.handleClose();
       let _d=JSON.parse(data)
       const apiInput = {
        email: _d.email,
        password: _d.password,
      };
      this.props._action({ type: "LOGIN_REQUESTED", payload:null });
  
      login(
        apiInput,
        (res) => {
          if (res.success === 1) {
            this.props._action({ type: "LOGIN_SUCCESS", payload:res });
            history.push("/dealersubscribtions");
          } else {
            this.props._action({ type: "LOGIN_FAILED", payload:res });
          }
        },
        (error) => {

            this.props._action({ type: "LOGIN_FAILED", payload:error });
        }
      );
    }

    handleClose = () => {
        this.setState({
            ...this.state, signUpDialog: false
        })
    }
    openSignUpDialog = () => {
        this.setState({
            ...this.state, signUpDialog: true
        })

    }

    onStartToday = (pkg) => { 
        this.props.setPackage(pkg);
        localStorage.setItem('singlepkg', JSON.stringify(pkg));
       localStorage.removeItem("savedbildetails")
            if (this.state.userdetailsforsubcplan.businessname == '' || this.state.userdetailsforsubcplan.businessname == undefined)  {
        this.props.setPackage(pkg);
        this.props.setStep(1);
       localStorage.removeItem("savedbildetails")

        } else {
                this.props.setStep(2);
            // if (!this.props.currentUser) {
                this.openSignUpDialog();
                localStorage.removeItem("savedbildetails")

            // } else {
            //     this.props.setStep(1);
            // }
       }
    }
    getpackagedetailsforsubcrption(){
        getUserdetailsforsubscrption.getAllpackgesList().then((res) => {
            this.setState({
                packagesListInfo: res.data.data
            })
        })  
    }
    onStartTodaylogout(pkg){
       localStorage.removeItem("savedbildetails")
        this.props.setPackage(pkg);
        localStorage.setItem('singlepkg', JSON.stringify(pkg));
        if (this.userbusinessdetails != undefined)  {
        this.props.setPackage(pkg);
        // this.props.setStep(1);
        this.openSignUpDialog();

            // this.props.history.push("/choose-subcription");
            // return <DealerChooseSubscriptionsComponent/>
        } else {
            // if (!this.props.currentUser) {
                this.openSignUpDialog();
            // } else {
            //     this.props.setStep(1);
            // }
       }
    }
    render() {
        const packagesListData = this.props.packagesListInfo ? this.props.packagesListInfo : this.props.packagesListInfo;
        const selectedPackage =  this.props.selectedPackage;
        return (
            <div>
                 {this.state.loaded== false ? 
                 <div class="d-spinner">
        <SpinnerRoundFilled size={51} thickness={125} speed={80} color="rgba(57, 107, 172, 1)" className="spp"  enabled={this.state.loaded == false} /></div> :
        <Paper>
                <Container >
                    
                    <div>
                        <Typography variant="h5" align="center" className="heading-watch-lovers text-uppercase color-383838"  >
                            Subscribe For Luxury Watches
                        </Typography>
                    </div>
                    <div className="border-middle6"></div>
                    <div className="text-center1 padding-1">
                        <Typography variant="p" align="center" className="color-575757">
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
                        </Typography>
                    </div>
                    <div className="padding-1"></div>
                    <div>
                        <Container>
                            <PricingComponent />
                        </Container>
                        {/* <Container>
                            <Grid container spacing={7}>
                                {packagesListData && packagesListData.map((row, i) => ( 
                                    <Grid item={12} sm={6} md={4} className="alignment-4">
                                        {this.props.currentUser ==undefined || this.props.currentUser ==null || this.props.currentUser ==false ?
                                      
                                        <Card className="bg-card-signin-subscribe-color1"style={{minHeight:"392px"}}>
                                        <CardContent align="center padding-0"  className= "text-center1"   >
                                            <Typography variant="h6" align="center" className="text-wrap color-383838">
                                                {(row) && (row.membertiername) ? row.membertiername : ''}
                                            </Typography>
                                            <Divider dark className="Divider-card-signin-subscribe1" />
                                            <div className="padding-1">
                                            {row.membertierid ==1 ?   <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                        <span className="font-size-26 color-383838"><b>2 </b></span>Weeks
                                        </Typography> :
                                                <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                    &#36;<span className="font-size-26 color-383838"><b>{(row) && (row.price) ? row.price : ''}</b></span>/ <span className="small-0">month</span>
                                                </Typography>
                                                }
                                            </div>
                                            <div className="clear-fix"></div>
                                            <Divider dark className="Divider-card-signin-subscribe1" />
                                            <div className="padding-1" style={{height:"64px"}}>
                                                <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                    <b style={{fontSize:"14px"}}>{(row) && (row.shortdesc) ? row.shortdesc :  ''}</b>
                                                </Typography>
                                               
                                            </div>
                                            <div className="clear-fix"></div>
                                            {(row) && (row.longdesc[1]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[1]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                              {(row) && (row.longdesc[2]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[2]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                              {(row) && (row.longdesc[3]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[3]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                              {(row) && (row.longdesc[4]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[4]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                            <div className="clear-fix"></div>
                                            <Divider dark className="Divider-card-signin-subscribe1" />
                                            <div className="padding-1">
                                                <Typography variant="p" align="center" className="text-wrap1 padding-1 color-383838">
                                                    limited access
                                            </Typography>
                                            </div>
                                            <div className="clear-fix"></div>
                                            <div className="padding-1" >
                                                <Button className= {"Button-signingSubscribe2" + (i>0 ? '' : ' ')}  onClick={() => { this.onStartTodaylogout(row) }}>START TODAY</Button>
                            
                                            </div>
                                        </CardContent>
                                    </Card>: <Card className={"bg-card-signin-subscribe-color1"}>
                                            <CardContent align="center padding-0"  className= {"text-center1" + (row?.membertierid === this.state.selectedpackagenumber  ? ' borderbox' : '')}  style={{minHeight:"392px"}}>
                                                <Typography variant="h6" align="center" className="text-wrap color-383838">
                                                    {(row) && (row.membertiername) ? row.membertiername : ''}
                                                </Typography>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                {row.membertierid ==1 ?   <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                        <span className="font-size-26 color-383838"><b>2 </b></span>Weeks
                                        </Typography> :
                                        <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        &#36;<span className="font-size-26 color-383838"><b>{(row) && (row.price) ? row.price : ''}</b></span>/ <span className="small-0">month</span>
                                                    </Typography> 
                                                     }
                                                </div>
                                                <div className="clear-fix"></div>
                                            <div className="padding-1" style={{height:"64px"}}>
                                                <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                    <b style={{fontSize:"14px"}}>{(row) && (row.shortdesc) ? row.shortdesc :  ''}</b>
                                                </Typography>
                                               
                                            </div>
                                            <div className="clear-fix"></div>
                                            {(row) && (row.longdesc[1]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[1]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                              {(row) && (row.longdesc[2]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[2]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                              {(row) && (row.longdesc[3]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[3]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                             {(row) && (row.longdesc[4]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[4]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                   <Typography variant="p" align="center" className="text-wrap1 padding-1 color-383838">
                                                        limited access
                                                </Typography> 
                                                </div>
                                                <div className="clear-fix"></div>
                                                 <div className= {"padding-1" + (this.state.activeselectpackage.success === 1 && row.membertierid ===1 ? ' blue':' green')}>
                                              {i>0 ?<Button className= {"Button-signingSubscribe2" + (row?.membertierid === this.state.selectedpackagenumber ? ' btndisble' : '')} disabled={row?.membertierid === this.state.selectedpackagenumber}  onClick={() => { this.onStartToday(row) }} > {row?.membertierid === this.state.selectedpackagenumber ? 'ACTIVE':'START TODAY'}</Button>:<Button className= {"Button-signingSubscribe2" + (row?.membertierid === this.state.selectedpackagenumber ? ' btndisble' : '')}  onClick={() => { this.onStartToday(row) }} disabled={i<=0}> {row?.membertierid === this.state.selectedpackagenumber ? 'ACTIVE':'START TODAY'}</Button>}  
                                                </div>
                                            </CardContent>
                                        </Card>}
                                        
                                   
                                    </Grid>
                                ))}
                            </Grid>

                        </Container> */}
                    </div>
                   
                </Container>
                </Paper> }
                <Dialog open={this.state.signUpDialog} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" style={{ textAlign: 'right' }}>
                        <Button onClick={this.handleClose} color="warn">
                            close
                 </Button>
                    </DialogTitle>
                    <DialogContent>
                        <SignUpComponent type='popup' onSignUP={this.onSignUP} {...this.props} />
                    </DialogContent>
                </Dialog>

            </div>
        )
    }
}
function mapStateToProps({ dealer, auth }) {
    return {
        currentUser: auth.currentUser,
        packagesListInfo: dealer.packagesListInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        packagesList,
        setPackage,
        getuser,
        _action
    },
        dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionComponent);
