export class MonthlyGraph {
   
    adapt(item){
        this.name=item.month;
        this.visits=item.search_count;
        return this;
    }

    listAdapt(data){
       return data.map((item)=>{
            return new MonthlyGraph().adapt(item);
        })

    }
}

export class WeeklyGraph {
    
    adapt(item){
        this.name=item.week;
        this.visits=item.search_count;
        return this;
    }

    listAdapt(data){
       return data.map((item)=>{
            return new WeeklyGraph().adapt(item);
        })

    }
}

export class dailyGraph {
    
    adapt(item){
        this.name=item.day;
        this.visits=item.search_count;
        return this;
    }

    listAdapt(data){
       return data.map((item)=>{
            return new dailyGraph().adapt(item);
        })

    }
}