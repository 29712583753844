import React, { Component } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InputAdornment, Select, MenuItem, Accordion, TextField, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import {
    Container, Grid, Typography, Card, Checkbox, FormControl, CardContent,
    InputLabel, Input, FormHelperText,IconButton, NativeSelect, FormControlLabel, Button
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import {
    countriesList, packagesList, setPackage, activePackageList
} from '../actions/dealerAction';
import { getuser } from './../../dashboard/actions/dashboardAction';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toast } from "react-toastify";
import getUserdetailsforsubscrption from '../../../pages/dashboard/actions/dashboardaapi'
import { FormatListNumberedOutlined } from '@material-ui/icons';
import { set } from 'lodash';
import { SpinnerRoundFilled } from 'spinners-react';

import tick from '../../../../assests/images/tick-circle.svg'
import InfoIcon from '../../../../assests/images/info.svg'
import CloseIcon from '../../../../assests/images/close.svg'

var Loader = require('react-loader');
class DealerChooseSubscriptionsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Checked: '',
            Cardnumber: '',
            CVVNumber: '',
            type:false,
            ExpMon: '',
            ExpYrs: '',
            Name: '',
            country: 231,
            City: '',
            State: 2183,
            ZipCode: '',
            address1: '',
            address2: '',
            billingAddress: '',
            billingAddress1: '',
            addOn: '',
            addOn1:0,
            onCardNumberErrorText: '',
            onCVVNumberErrorText: '',
            onExpMonErrorText: '',
            onExpYrsErrorText: '',
            onNameErrorText: '',
            onCountryErrorText: '',
            selectedPackage: [],
            onCityErrorText: '',
            onStateErrorText: '',
            onZipCodeErrorText: '',
            onAddress1ErrorText: '',
            onAddress2ErrorText: '',
            statesListData: '',
            citiesListData: '',
            choosePackageerror: '',
            Checked: false,
            PackageId: '',
            activePackageForm: true,
            packageForm: false,
            billingDetailsForm: false,
            loaded: false,
            statesListData: [],
            localsinglepkg: localStorage.getItem('singlepkg') ? localStorage.getItem('singlepkg') : '',
            // savedsubscrptiondetails : JSON.parse(localStorage.getItem('savedbildetails')) ? JSON.parse(localStorage.getItem('savedbildetails')):''

        }

        this.onChangeCardNumber = this.onChangeCardNumber.bind(this);
        this.onChangeCVVNumber = this.onChangeCVVNumber.bind(this);
        this.onchangeExpMon = this.onchangeExpMon.bind(this);
        this.onchangeExpYrs = this.onchangeExpYrs.bind(this);
        this.onChangeNameOnCard = this.onChangeNameOnCard.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeZipCode = this.onChangeZipCode.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.onChangeAddress1 = this.onChangeAddress1.bind(this);
        this.onChangeAddress2 = this.onChangeAddress2.bind(this);
        this.checkedCard = this.checkedCard.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.onChangeOfBillingAddress = this.onChangeOfBillingAddress.bind(this);
        this.onChangeOfAddOn = this.onChangeOfAddOn.bind(this);
        this.onUpgradePackage = this.onUpgradePackage.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getstatebycountyId = this.getstatebycountyId.bind(this);
        this.showHide= this.showHide(this)
        //this.formatDebitCardNumber=this.formatDebitCardNumber(this)
    }

    componentWillReceiveProps(newProps) {
        const billingDetailsData = newProps.billingDetails;
        const userInfoData = newProps.userInfo.data;
        if (billingDetailsData) {
            this.setState({
                ...this.state,
                Cardnumber: billingDetailsData && billingDetailsData.card_number,
                CVVNumber: billingDetailsData && billingDetailsData.cvc,
                ExpMon: billingDetailsData && billingDetailsData.exp_month,
                ExpYrs: billingDetailsData && billingDetailsData.exp_year,
                Name: billingDetailsData && billingDetailsData.name_of_card,
                country: billingDetailsData && billingDetailsData.billing_country,
                City: billingDetailsData && billingDetailsData.billing_city,
                State: billingDetailsData && billingDetailsData.billing_state,
                ZipCode: billingDetailsData && billingDetailsData.billing_zip,
                address1: billingDetailsData && billingDetailsData.billing_addressline1,
                address2: billingDetailsData && billingDetailsData.billing_addressline2,
                billingAddress: billingDetailsData && billingDetailsData.billingAddress,
                activePackageForm: false,
                packageForm: true,
                billingDetailsForm: true,
            });
        } else if (this.state.billingAddress === true) {
            this.setState({
                ...this.state,
                country: userInfoData && userInfoData.country,
                City: userInfoData && userInfoData.city,
                State: userInfoData && userInfoData.state,
                ZipCode: userInfoData && userInfoData.zip,
                address1: userInfoData && userInfoData.addressline1,
                address2: userInfoData && userInfoData.addressline2,


            });
        } else {
            // this.setState({
            //     ...this.state,
            //     country: '',
            //     City: '',
            //     State: '',
            //     ZipCode: '',
            //     address1: '',
            //     address2: '',

            // })

        }
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({ loaded: true })
        }, 100);
        this.getAllcountryList()
        this.getstatebycountyId(this.state.country)
        
    }
    // Default Code will analysic the code need to check and if neccessary uncommand
    // componentDidMount() {
    //     console.log(this.props.currentUser.userId,this.props.userInfo,this.props,"this,props")
    //     setTimeout(() => {
    //         this.setState({ loaded: true })
    //     }, 100);

    //     //this.getAllcountryList();
    //     let activePackageData = JSON.parse(localStorage.getItem('singlepkg'))
    //     const packagesListData = this.props.packagesListInfo;
    //     console.log(packagesListData, "jjj", )
    //     // this.props.countriesList();
    //     this.props.packagesList();
    //     this.props.activePackageList();
    //     var signlepkgdata = JSON.parse(localStorage.getItem('singlepkg'))
    //     console.log(signlepkgdata, "jj")
    //     this.setState({
    //         selectedPackage: signlepkgdata,
    //     })
    //     console.log('signlepkgdata:', this.state.signlepkgdata, this.state.savedsubscrptiondetails);
    //     console.log(this.state.localsinglepkg, localStorage.getItem('singlepkg', "localdta2"))
    //     console.log(this.state.localsinglepkg, "localdta")
    //     var saveddata = JSON.parse(localStorage.getItem('savedbildetails'))
    //     console.log(saveddata, "saved data")
    //     this.setState({
    //         Cardnumber: saveddata && saveddata.card_number ? saveddata && saveddata.card_number : '',
    //         CVVNumber: saveddata && saveddata.cvc ? saveddata && saveddata.cvc : '',
    //         ExpMon: saveddata && saveddata.cvc ? saveddata && saveddata.exp_month : '',
    //         ExpYrs: saveddata && saveddata.cvc ? saveddata && saveddata.exp_year : '',
    //         Name: saveddata && saveddata.cvc ? saveddata && saveddata.name_of_card : '',
    //         country: saveddata && saveddata.cvc ? saveddata && saveddata.billing_country : '',
    //         State: saveddata && saveddata.cvc ? saveddata && saveddata.billing_state : '',
    //         City: saveddata && saveddata.cvc ? saveddata && saveddata.billing_city : '',
    //         ZipCode: saveddata && saveddata.cvc ? saveddata && saveddata.billing_zip : '',
    //         address1: saveddata && saveddata.cvc ? saveddata && saveddata.billing_addressline1 : '',
    //         address2: saveddata && saveddata.cvc ? saveddata && saveddata.billing_addressline2 : '',
    //     })
    //     this.getstatebycountyId( saveddata && saveddata.billing_country ? saveddata && saveddata.billing_country : this.props.userInfo.data && this.props.userInfo.data.country)
    // }
getAllcountryList(){
    getUserdetailsforsubscrption.getAllCountryList().then((res) => {
        console.log('countrylist', res.data.data);
        this.setState({
            countriesAllList: res.data.data
        })
    })
}

formatDebitCardNumber(inputValue) {
    if (!inputValue) return ''; // Handle null or empty input
  
    const cleaned = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{4})(\d{4})(\d{4})(\d{4})$/);
  
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }
    console.log('cleaned',cleaned,match)
    return cleaned;
  };
  
    getstatebycountyId(countryID) {
        console.log(countryID, "countryID", countryID)
        getUserdetailsforsubscrption.getAllStateList(countryID).then((res) => {
            console.log('stateList', res.data.data);
            this.setState({
                statesListData: res.data.data
            })
        })
    }
    onUpgradePackage() {
        this.setState({ packageForm: true });
        this.setState({ activePackageForm: true });
        this.setState({ billingDetailsForm: true });

    }
    getUserdetails(){
        getUserdetailsforsubscrption.getUserDetails(this.props.currentUser.userId ? this.props.currentUser.userId :this.props.userInfo.data.userid).then((res) => {
            console.log('userdetailsnew', res.data.data);
            const response = res.data.data
            this.setState({
                country:response.accountcountry,
                State:response.accountstate,
                City:response.accountcity,
                ZipCode:response.accountzip,
                address1:response.accountaddressline1,
                address2:response.accountaddressline2
            })
            //this.getstatebycountyId(response.accountcountry);
        })
    }
    onChangeOfBillingAddress = (event) => {
        console.log("click1")
        if(event.target.checked){
        //this.getAllcountryList();
        
        this.getUserdetails();
        
    //     console.log(event, this.state.country, "179",this.props.userInfo.data,this.props)
       //this.props.getuser(this.props.currentUser.userId ? this.props.currentUser.userId :this.props.userInfo.data.userid);
    //    this.getstatebycountyId(this.state.country)
    //    this.getstatebycountyId(this.props.currentUser.userId ? this.props.currentUser.userId :this.props.userInfo.data.userid)
        if (this.state)
            this.setState({
                billingAddress: event.target.checked,
                onCardNumberErrorText: '',
                onCVVNumberErrorText: '',
                onExpMonErrorText: '',
                onExpYrsErrorText: '',
                onNameErrorText: '',
                onCountryErrorText: '',
                selectedPackage: [],
                onCityErrorText: '',
                onStateErrorText: '',
                onZipCodeErrorText: '',
                onAddress1ErrorText: '',
                onAddress2ErrorText: '',

            })
        }
        else{
            this.setState({
                City:"",
                ZipCode:"",
                address1:"",
                address2:"",
                billingAddress:false
            })
        }

    }

    onChangeOfAddOn = (event) => {
        
        console.log(event.target.checked ==true)
        let addons = event.target.checked
        if (addons ===true){
            // if (this.state)
                this.setState({
                    addOn: event.target.checked,
                    addon1 : 1
                })
                localStorage.setItem('addons', 1);
            console.log(this.state.addOn1,this.state.addOn)
            }else {
                this.setState({
                    addOn: event.target.checked,
                    addon1 : 0
                })
                localStorage.setItem('addons', 0);
                console.log(this.state.addOn1,this.state.addOn)
            }
            console.log(this.state.addOn1,this.state.addOn)
    }

    onStartToday = (pkg) => {
        if (pkg.membertierid === 1) {
            toast("Your free package has been activated.", {
                autoClose: 5000,
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER,
            });
            this.props.history.push('/add-to-inventory-search')
        } else {
            this.setState({ billingDetailsForm: true });
            this.props.setPackage(pkg);
        }
    }

    onChangeCountry(event) {
        let CountryVal = event.target.value;
        console.log(event.target.value, "role")
        this.getstatebycountyId(event.target.value)
        if (!CountryVal) {
            this.setState({
                ...this.state,
                country: event.target.value,
                onCountryErrorText: "Enter correct Country",
                isValid: false
            })
        }
        else {
            this.setState({
                ...this.state,
                onCountryErrorText: "",
                country: event.target.value,
                isValid: true
            })
        }

    }

    onChangeState(event) {
        let StateVal = event.target.value;
        if (!StateVal) {
            this.setState({
                ...this.state,
                State: event.target.value,
                onStateErrorText: "Enter correct State",
                isValid: false
            })
        }
        else {
            this.setState({
                ...this.state,
                onStateErrorText: "",
                State: event.target.value,
                isValid: true
            })
        }
    }

    onChangeCity(event) {
        let CityVal = event.target.value;
        if (!CityVal) {
            this.setState({
                ...this.state,
                City: event.target.value,
                onCityErrorText: "Enter correct City",
                isValid: false
            })
        }
        else {
            this.setState({
                ...this.state,
                onCityErrorText: "",
                City: event.target.value,
                isValid: true
            })
        }
    }
    formatCardNumber = value => {
        const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
        const onlyNumbers = value.replace(/[^\d]/g, '')
      
        return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
          [$1, $2, $3, $4].filter(group => !!group).join(' ')
        )
      }
    onChangeCardNumber(event) {
        //let CardNumberVal = validator.isCreditCard(event.target.value);
        let CardNumberVal = event.target.value
        if (!CardNumberVal) {
            this.setState({
                ...this.state,
                Cardnumber: event.target.value,
                onCardNumberErrorText: "Enter correct Card Number",
                isValid: false,
                wrongerror: false
            })
        }
        else {
            this.setState({
                ...this.state,
                onCardNumberErrorText: "",
                Cardnumber: event.target.value,
                isValid: true,
                wrongerror: true
            })
        }
    }

    onChangeCVVNumber(event) {
        const regex = "^[0-9]{3,4}$";
        let CVVNumberVal = event.target.value;
        let CVVNumber = CVVNumberVal.match(regex);
        if (!CVVNumber) {
            this.setState({
                ...this.state,
                CVVNumber: event.target.value,
                onCVVNumberErrorText: "Enter correct CVV Number",
                isValid: false,
                cvverror: false
            })
        }
        else {
            this.setState({
                ...this.state,
                onCVVNumberErrorText: "",
                CVVNumber: event.target.value,
                isValid: true,
                cvverror: true,
            })
        }
    }

    onchangeExpMon(event) {
        if (event.target.value === "") {
            this.setState({
                ...this.state,
                ExpMon: event.target.value,
                onExpMonErrorText: "Enter correct Month",
                isValid: false
            })
        } else {
            this.setState({
                ...this.state,
                onExpMonErrorText: "",
                ExpMon: event.target.value,
                isValid: true
            })
        }
    }

    onchangeExpYrs(event) {
        if (event.target.value === "") {
            this.setState({
                ...this.state,
                ExpYrs: event.target.value,
                onExpYrsErrorText: "Enter correct Month",
                isValid: false
            })
        } else {
            this.setState({
                ...this.state,
                onExpYrsErrorText: "",
                ExpYrs: event.target.value,
                isValid: true
            })
        }

    }

    onChangeNameOnCard(event) {
        let NameOnCardVal = event.target.value;
        if (!NameOnCardVal) {
            this.setState({
                ...this.state,
                Name: event.target.value,
                onNameErrorText: "Enter correct Name",
                isValid: false
            })
        }
        else {
            this.setState({
                ...this.state,
                onNameErrorText: "",
                Name: event.target.value,
                isValid: true
            })
        }
    }

    onChangeZipCode(event) {
        if (event.target.value !== "") {
            let zipcodeVal = validator.isNumeric(event.target.value);
            if (!zipcodeVal) {
              this.setState({
                ...this.state,
                onZipCodeErrorText: "Enter correct Zip Code",
                isValid: false,
              })
            }
            else {
              this.setState({
                ...this.state,
                onZipCodeErrorText: "",
                ZipCode: event.target.value,
                isValid: true
              })
            }
          }else {
            this.setState({
                    ...this.state,
                    onZipCodeErrorText: "",
                    ZipCode: event.target.value,
                    isValid: true,
                  })
                }
        // const regex = "^[1-9][0-9]{5}$";
        // let PincodeVal = event.target.value;
        // let Pincode = PincodeVal.match(regex);
        // if (!Pincode) {
        //     this.setState({
        //         ...this.state,
        //         ZipCode: event.target.value,
        //         onZipCodeErrorText: "Enter correct Zip Code",
        //         isValid: false
        //     })
        // }
        // else {
        //     this.setState({
        //         ...this.state,
        //         onZipCodeErrorText: "",
        //         ZipCode: event.target.value,
        //         isValid: true
        //     })
        // }
    }

    onChangeAddress1(event) {
        if (event.target.value === "") {
            this.setState({
                ...this.state,
                address1: event.target.value,
                onAddress1ErrorText: "Enter Address1",
                isValid: false
            })
        } else {
            this.setState({
                ...this.state,
                onAddress1ErrorText: "",
                address1: event.target.value,
                isValid: true
            })
        }

    }

    onChangeAddress2(event) {
        if (event.target.value === "") {
            this.setState({
                ...this.state,
                onAddress2ErrorText: "Enter Address2",
                address2: event.target.value,
                isValid: false
            })
        } else {
            this.setState({
                ...this.state,
                onAddress2ErrorText: "",
                address2: event.target.value,
                isValid: true
            })
        }

    }

    validateInput() {
        console.log("validate")
        let packageerror = '';
        let onCardNumberErrorText = '';
        let onCVVNumberErrorText = '';
        let onExpMonErrorText = '';
        let onExpYrsErrorText = '';
        let onNameErrorText = '';
        let onCountryErrorText = '';
        let onCityErrorText = '';
        let onStateErrorText = '';
        let onZipCodeErrorText = '';
        let onAddress1ErrorText = '';
        // let onAddress2ErrorText = '';
        let choosePackageerror = '';

        let isValid = true;
        if (this.state.Cardnumber == '' && this.state.Cardnumber.length == 0) {
            onCardNumberErrorText = 'Enter Correct Card Number';
            isValid = false;
        }
        if (this.state.CVVNumber == '') {
            onCVVNumberErrorText = 'Enter Correct CVV Number';
            isValid = false;
        }
        if (this.state.ExpMon == '') {
            onExpMonErrorText = 'Enter Correct Expiry Month';
            isValid = false;
        }
        if (this.state.ExpYrs == '') {
            onExpYrsErrorText = 'Enter Correct Expiry Year';
            isValid = false;
        }
        if (this.state.Name == '') {
            onNameErrorText = 'Enter Correct Name';
            isValid = false;
        }
        if (this.state.country == '') {
            onCountryErrorText = 'Enter Correct Country';
            isValid = false;
        }
        // if (this.state.Country == '' && this.state.savedsubscrptiondetails.billing_country ==undefined) {
        //     onCountryErrorText = 'Enter Correct Country';
        //     isValid = false;
        // }
        if (this.state.City == '') {
            onCityErrorText = 'Enter Correct City';
            isValid = false;
        }
        if (this.state.State == '') {
            onStateErrorText = 'Enter Correct State';
            isValid = false;
        }
        if ((this.state.ZipCode && this.state.ZipCode.length < 5) ||this.state.ZipCode == '' ) {
            onZipCodeErrorText = 'Enter Correct Zip Code';
            isValid = false;
        }
        if (this.state.address1 == '') {
            onAddress1ErrorText = 'Enter Address1';
            isValid = false;
        }
        // if (this.state.address2 == '') {
        //     onAddress2ErrorText = 'Enter Address2';
        //     isValid = false;
        // }

        if (packageerror == null) {
            choosePackageerror = 'Select Any Package';
            isValid = false;
        }
        this.setState({
            ...this.state,
            onCardNumberErrorText,
            onCVVNumberErrorText,
            onExpMonErrorText,
            onExpYrsErrorText,
            onNameErrorText,
            onCountryErrorText,
            onCityErrorText,
            onStateErrorText,
            onZipCodeErrorText,
            onAddress1ErrorText,
            // onAddress2ErrorText,
            choosePackageerror,
        })
        return isValid;
    }
    submitForm(event) {
        console.log("hh")

            let addons = localStorage.getItem('addons');
        var signlepkgdata = JSON.parse(localStorage.getItem('singlepkg'))
        console.log(signlepkgdata)
        event.preventDefault()
        console.log("subimt")
        // setTimeout(() => {
        //     this.setState({loaded:false})
        //     }, 2000);
        // this.setState({loaded:false})
        console.log(this.state.selectedPackage, signlepkgdata, "memberId", this.state.loaded)
        console.log(event)
        if (this.validateInput()) {
            let data = {
                "user_id": this.props.currentUser.userId,
                "membertier_id": signlepkgdata.membertierid,
                // "listing_addon": addons,
                "gateway": "stripe",
                "billing_country": this.state.country,
                "billing_state": this.state.State,
                "billing_city": this.state.City,
                "billing_zip": this.state.ZipCode,
                "billing_addressline1": this.state.address1,
                "billing_addressline2": this.state.address2,
                "card_number": this.state.Cardnumber,
                "exp_month": this.state.ExpMon,
                "exp_year": this.state.ExpYrs,
                "cvc": this.state.CVVNumber,
                "name_of_card": this.state.Name,
                "billingAddress": this.state.billingAddress,
                "addOn": this.state.addOn,
            };
            console.log(data, "data---=---=----");
            localStorage.setItem('savedbildetails', JSON.stringify(data));

            if (this.props.submit) {
                // this.props.submit(data);
                this.props.setStep(3);
                // this.setState({loaded:true})
                console.log("after Submit")

            }

        }
        console.log(this.state, "stste")
    }
    checkedCard(membertierid) {
        this.setState({
            Checked: true,
            PackageId: membertierid
        })
    }

    showHide(e) {

        this.setState({
          type: this.state.type ? false : true
        })
      }
    render() {
        const countryListData = this.props.countryList.data;
        const packagesListData = this.props.packagesListInfo;
        // const activePackageData = this.props.activePackagesListInfo;
        const activePackageData = JSON.parse(localStorage.getItem('singlepkg'))
        localStorage.setItem('selectedpackges', JSON.stringify(activePackageData));
        return (
            <div style={{ background: '#fff' }}>
{this.state.loaded== false ? <div class="d-spinner">
        <SpinnerRoundFilled size={51} thickness={125} speed={80} color="rgba(57, 107, 172, 1)" className="spp"  enabled={this.state.loaded == false} /></div> :
                <div>
                    {/* <Loader loaded={this.state.loaded}> */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="space-m-1" >
                                    <Typography variant="h6" component="p" className="primary-text uppercase" style={{ marginLeft: '15px' }}>
                                        Subscribtion Package Details
                               </Typography>
                                </div>
                                <div className="padding-1"></div>
                                <div>
                                    {this.state.activePackageForm ?
                                        <Container>
                                            <Grid item={12} sm={12} md={12}  >
                                                <Card className={"bg-card-signin-subscribe-color1"}>
                                                    <CardContent align="center padding-0" className="text-center1">
                                                    
                                                        <Typography variant="h5" align="center" className="text-wrap color-383838" style={{ float: 'left' }}>
                                                            {activePackageData && activePackageData.membertiername ? activePackageData.membertiername : 'You are using Free Plan'}
                                                        </Typography>
                                                        <Typography variant="p" align="center" className="text-wrap1 padding-1" style={{ float: 'right' }}>
                                                            &#36;<span className="font-size-26 color-383838"><b>
                                                                {activePackageData && activePackageData.pricepermonth ? activePackageData.pricepermonth : '00'}
                                                            </b></span>/ <span className="small-0">month</span>

                                                        </Typography>
                                                        <br />
                                                        <div className="padding-1">
                                                            <Typography variant="p" align="center" className="text-wrap1 padding-1">

                                                            <Grid container spacing={2}>
                                                                {activePackageData && activePackageData.packagedetails && activePackageData.packagedetails.packagedetails.map((item)=>{
                                                                        return(<Grid container spacing={2} style={{margin:"10px 5px"}}>
                                                                                     <Grid item xs={6} sm={6} md={6} className="padding-0" style={{textAlign:'left'}}>{item.feature}</Grid><> { typeof item.limit != 'number' ? <Grid item xs={6} sm={12} md={6} className="padding-0" style={{textAlign:'right'}}><img src={item?.isIncluded ? tick : CloseIcon} style={{ width: '25px' }} /></Grid> : <Grid item xs={6} sm={12} md={6} className="padding-0" style={{textAlign:'right'}}>{item?.limit}</Grid>}</>
                                                                                </Grid>
                                                                )}
                                                                )}
                                                        </Grid>
                                                            </Typography>
                                                        </div>

                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Container>
                                        : ''}
                                    

                                </div>

                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='checkout' >
                                    <Grid container spacing={2}>

                                        <div>
                                            <div className="space-m-1">
                                                <Typography variant="h6" component="p" className="primary-text uppercase">Card Details</Typography>
                                            </div>
                                            <div>
                                                <FormControl className="form-width-100 space-m-1 " >
                                                    <InputLabel id="component-simple" style={{color:'#424242'}}>CARD NUMBER</InputLabel>
                                                    <Input id="component-simple" endAdornment={
                                                        <InputAdornment position="end"
                                                        >
                                                        </InputAdornment>
                                                    }
                                                        onChange={this.onChangeCardNumber}
                                                        value={this.formatCardNumber(this.state.Cardnumber)}
                                                    />
                                                    <FormHelperText className="error-o">{this.state.onCardNumberErrorText}</FormHelperText>
                                                </FormControl>

                                                <FormControl className="form-width-100 space-m-1 "  >
                                                    <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>CVV</InputLabel>

                                                    <Input id="standard-adornment-password"
                                            type={this.state.type ? "text" :"password"}
                                            value={this.state.CVVNumber}
                                            maxLength={4}
                                            endAdornment={<InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" onClick={this.showHide} style={{ color: '#CEA05F' }}>{this.state.type ? 
                                                <VisibilityIcon style={{ cursor: 'pointer' }} /> : 
                                                <VisibilityOffIcon style={{ cursor: 'pointer' }} />}</IconButton>
                                                </InputAdornment>}
                                                onChange={this.onChangeCVVNumber}
                                                    />
                                                    <FormHelperText className="error-o">{this.state.onCVVNumberErrorText}</FormHelperText>
                                                </FormControl>
                                                {/* <Accordion className="form-width-70 margin-t-b-5 margin-p-b-20">
                      <AccordionSummary
                        id="acc"
                        expanded={false}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className="color-BCBCBC">WHAT IS CVV</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                         CVV number is the last three digits at the back of your card.For American Express cards,the CVV is a 
                         4-digits number on the format of the card. 
                       </Typography>
                      </AccordionDetails>
                    </Accordion> */}
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl className="form-width-100 space-m-1">
                                                            <InputLabel htmlFor="demo-customized-select-native" style={{color:'#424242'}}></InputLabel>
                                                            <NativeSelect
                                                                id="demo-customized-select-native"
                                                                 value={this.state.ExpMon}
                                                                onChange={this.onchangeExpMon}
                                                            >
                                                                 <option value="" >EXP MONTH</option>
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                                <option value={11}>11</option>
                                                                <option value={12}>12</option>
                                                            </NativeSelect>
                                                            <FormHelperText className="error-o">{this.state.onExpMonErrorText}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControl className="form-width-100 space-m-1">
                                                            <InputLabel htmlFor="demo-customized-select-native"style={{color:'#424242'}}></InputLabel>
                                                            <NativeSelect
                                                                id="demo-customized-select-native"
                                                                value={this.state.ExpYrs}
                                                                onChange={this.onchangeExpYrs}
                                                            >
                                                                <option value="">EXP YEAR</option>
                                                               
                                                                <option value={2023}>2023</option>
                                                                <option value={2024}>2024</option>
                                                                <option value={2025}>2025</option>
                                                                <option value={2026}>2026</option>
                                                                <option value={2027}>2027</option>
                                                                <option value={2028}>2028</option>
                                                                <option value={2029}>2029</option>
                                                                <option value={2030}>2030</option>
                                                                <option value={2031}>2031</option>
                                                                <option value={2032}>2032</option>
                                                                <option value={2034}>2034</option>
                                                                <option value={2035}>2035</option>
                                                                <option value={2036}>2036</option>
                                                                <option value={2037}>2037</option>
                                                            </NativeSelect>
                                                            <FormHelperText className="error-o">{this.state.onExpYrsErrorText}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <FormControl className="form-width-100 space-m-1">
                                                    <InputLabel htmlFor="component-simple"style={{color:'#424242'}}>NAME ON CARD</InputLabel>
                                                    <Input id="component-simple" onChange={this.onChangeNameOnCard} value={this.state.Name} />
                                                    <FormHelperText className="error-o">{this.state.onNameErrorText}</FormHelperText>
                                                </FormControl>

                                                <div>
                                                    <div className="space-m-1">
                                                        <Typography variant="h6" component="p" className="primary-text uppercase">Billing Address</Typography>
                                                    </div>

                                                    <div>
                                                        <FormControlLabel
                                                            style={{color:'#424242'}}
                                                            control={
                                                                <Checkbox
                                                                style={{color:'#424242'}}
                                                                    name="checkedB"
                                                                    color="black"
                                                                    checked={this.state.billingAddress ? true : false}
                                                                    onChange={this.onChangeOfBillingAddress}
                                                                    // defaultValue={this.state.billingAddress}
                                                                />
                                                            }
                                                            label="SAME AS BUSINESS ADDRESS "
                                                        />
                                                    </div>

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl className="registerSpace form-width-100 space-m-1">
                                                                <label id="component-simple" style={{color:'#424242'}}>COUNTRY  </label>
                                                                <Select
                                                                    //  style={{ marginTop: "0px" }}
                                                                    labelId="component-simple"
                                                                    id="demo-simple-select"
                                                                    defaultValue={this.state.country}
                                                                    value={this.state.country}
                                                                    onChange={this.onChangeCountry}
                                                                // label="Country"
                                                                >
                                                                    {
                                                                        this.state.countriesAllList && this.state.countriesAllList.map((item) => {
                                                                            return <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                        })
                                                                    }

                                                                </Select>
                                                                <FormHelperText className="error-o">{this.state.onCountryErrorText}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>


                                                       

                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl className="registerSpace form-width-100 space-m-1">
                                                                <label id="component-simple" style={{color:'#424242'}}>STATE </label>
                                                                <Select
                                                                    //  style={{ marginTop: "0px" }}
                                                                    labelId="component-simple"
                                                                    id="demo-simple-select"
                                                                    defaultValue={this.state.State}
                                                                    value={this.state.State}
                                                                    onChange={this.onChangeState}
                                                                >
                                                                    {
                                                                        this.state.statesListData && this.state.statesListData.map((item) => {
                                                                            return <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                        })
                                                                    }

                                                                </Select>
                                                                <FormHelperText className="error-o">{this.state.onStateErrorText}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>

                       


                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6}>
                                                            <div >
                                                                <FormControl className="registerSpace form-width-100 space-m-1">
                                                                    <InputLabel id="component-simple" style={{color:'#424242'}}>CITY</InputLabel>
                                                                    <Input id="component-simple"
                                                                        value={this.state.City}
                                                                        onChange={this.onChangeCity}
                                                                    />

                                                                    <FormHelperText className="error-o">{this.state.onCityErrorText}</FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <div >
                                                                <FormControl className="registerSpace form-width-100 space-m-1">
                                                                    <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>ZIP CODE</InputLabel>
                                                                    <Input id="component-simple" value={this.state.ZipCode} onChange={this.onChangeZipCode} 
                                                                    inputProps={{ maxLength: 5 }}/>
                                                                    <FormHelperText className="error-o">{this.state.onZipCodeErrorText}</FormHelperText>
                                                                </FormControl>
                                                            </div>

                                                        </Grid>
                                                    </Grid>
                                                    <FormControl className="registerSpace form-width-100 space-m-1">
                                                        <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>ADDRESS1</InputLabel>
                                                        <Input id="component-simple" value={this.state.address1} onChange={this.onChangeAddress1} />
                                                        <FormHelperText className="error-o">{this.state.onAddress1ErrorText}</FormHelperText>
                                                    </FormControl>

                                                    <FormControl className="registerSpace form-width-100 space-m-1">
                                                        <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>ADDRESS2</InputLabel>
                                                        <Input id="component-simple" value={this.state.address2} onChange={this.onChangeAddress2} />
                                                        {/* <FormHelperText className="error-o">{this.state.onAddress2ErrorText}</FormHelperText> */}
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <Grid container spacing={3}>
                                                        {/* <Grid item xs={12} sm={6}>
                                                        <Button className="font-size-12 Button-outline-border form-width-100">Cancel</Button>
                                                    </Grid> */}
                                                        <Grid item xs={12} sm={12}>
                                                            {/* {this.state.wrongerror  || this.state.cvverror ? <FormHelperText className="error-o">{this.state.wrongerror}true</FormHelperText> :<FormHelperText className="error-o">{this.state.wrongerror}false</FormHelperText>}
                                                       {this.state.cvverror ? <FormHelperText className="error-o">{this.state.wrongerror}true</FormHelperText> :<FormHelperText className="error-o">{this.state.wrongerror}false</FormHelperText>} */}
                                                            <div>
                                                                <Button type="submit" className="bg-card-signin-subscribe-color form-width-100" disabled={this.state.wrongerror == false || this.state.cvverror == false} >SAVE & CONTINUE</Button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </form>
                            </Grid>
                            {/* : ''} */}
                        </Grid>
                        {/* </Loader> */}
                                                    </div> }
            </div>
        );
    }
}
function mapStateToProps({ dealer, InventoryList, auth }) {
    return {
        countryList: dealer.countryList,
        currentUser: auth.currentUser,
        packagesListInfo: dealer.packagesListInfo,
        activePackagesListInfo: dealer.activePackagesListInfo,
        selectedpackage: dealer.selectedPackage,
        selectedpackageReducData: dealer.selectedPackage,
        billingDetails: dealer.billingDetails,
        userInfo: InventoryList.userInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        countriesList,
        packagesList,
        setPackage,
        activePackageList,
        getuser
    },
        dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerChooseSubscriptionsComponent);