import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = JSON.parse(window.sessionStorage.getItem('userDetails'));
  const {restrict} = {...rest}
  let allow = true;
  //Comment on Jan 25
  // if(auth && auth.membertierId && restrict && restrict.length > 0){ 
  //   const index = restrict.indexOf(auth.membertierId)
  //   if(index !== -1){
  //     allow = false
  //   }
  // }
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        auth  ? (
          allow ? <Component {...props} /> :  <Redirect to="/not-found" />
        )
           : 
            <Redirect to="/login" />
      }
    />
  );
};
export default PrivateRoute;
