import axios from "axios";
import constants from "../../../../utils/constants";
import { getToken } from "../../../../utils/Authentication";

export async function getDealerInventoryDetailsAPI(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  const config = {
    method: "post",
    url: constants.BASE_URL + `dealerinventory/inventory`,
    headers: headers,
    data: data, //payload
  };
  return axios(config)
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.success === 0 &&
        response.data.logout === true
      ) {
        localStorage.removeItem("token");
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = "/login";
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export async function getDealerGroupInventoryAPI(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  const config = {
    method: "post",
    url: constants.BASE_URL + `dealerinventory/groupingdealer`,
    headers: headers,
    data: data, //payload
  };
  return axios(config)
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.success === 0 &&
        response.data.logout === true
      ) {
        localStorage.removeItem("token");
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = "/login";
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}
export async function getDealerSingleMiscAPI(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  const config = {
    method: "post",
    url: constants.BASE_URL + `dealerinventory/inventory`,
    headers: headers,
    data: data, //payload
  };
  return axios(config)
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.success === 0 &&
        response.data.logout === true
      ) {
        localStorage.removeItem("token");
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = "/login";
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}


export async function getDealerFilterDetails(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  const config = {
    method: "post",
    url: constants.BASE_URL + `dealerfilter/list`,
    headers: headers,
    data: data, //payload
  };
  return axios(config)
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.success === 0 &&
        response.data.logout === true
      ) {
        localStorage.removeItem("token");
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = "/login";
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export async function saveFilterDetails(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  const config = {
    method: "post",
    url: constants.BASE_URL + `dealerfilter/create`,
    headers: headers,
    data: data, //payload
  };
  return axios(config)
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.success === 0 &&
        response.data.logout === true
      ) {
        localStorage.removeItem("token");
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = "/login";
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export async function deleteFilterDetails(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  const config = {
    method: "post",
    url: constants.BASE_URL + `dealerfilter/delete`,
    headers: headers,
    data: data, //payload
  };
  return axios(config)
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.success === 0 &&
        response.data.logout === true
      ) {
        localStorage.removeItem("token");
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = "/login";
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export async function getinventryDetailsByID(id, callback) {
  const headers = {
    'Content-Type': 'application/json'
  };
  var config = {
    method: 'get',
    url: constants.BASE_URL + `inventories/` + id,
    headers: headers,
  };
  return axios(config)
    .then((response) => {
      return callback(null, response.data);
    }).catch((error) => {
      return callback(error, null);
    })
}

export async function communicationActivityLog(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  const config = {
    method: "post",
    url: constants.BASE_URL + `dealerinventory/communications`,
    headers: headers,
    data: data, //payload
  };
  return axios(config)
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.success === 0 &&
        response.data.logout === true
      ) {
        localStorage.removeItem("token");
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = "/login";
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}