import React, { Component } from 'react';
import Header from '../../common/components/Header';
 import SubHeaderComponent from './subHeader';
import FooterComponent from '../../common/components/Footer';
import { withRouter } from 'react-router';
const { innerWidth: width, innerHeight: height } = window;

class NewContentWrapper extends Component {
    render() {
        return (
            <div>
                <Header/>
                {/* <SubHeaderComponent/> */}
                <div style={{backgroundColor:'white', minHeight: (height-114)+'px'}}>
                    {this.props.contentView}
                </div>
                <FooterComponent/>
            </div>
        );
    }
}
export default withRouter(NewContentWrapper);