import React, { Component } from 'react';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Divider } from '@material-ui/core';
import ContactUsBannerComponent from './ContactusBannerComponent';
import ContactUsFormComponent from './ContactUsFormComponent';
import LoveWatchesComponent from './loveWatchesComponent';



class ContactUsComponent extends Component {
    constructor(props) {
        super(props)
        this.state = { 
         }
    }

    getContentView() {
        console.log('this.state',this.state);
        return (
            <div className="bg-white">
                <ContactUsBannerComponent/> 
                <Container maxWidth="lg">
                    <div className="space-2"></div>
                    <ContactUsFormComponent
                      {...this.props}/>
                </Container>
                <div className="space-2"></div>
                    <Divider/>
                <Container maxWidth="lg">
                    <div className="space-2"></div>
                    <LoveWatchesComponent/>
                </Container>
                <Divider/>
                <div className="space-2"></div>
            </div>
        );
    }
    
    render() { 
        localStorage.setItem("menuTitle","CONTACT US");
        return ( 
            <ContentWrapper
            contentView={this.getContentView()}
            />
         );
    }
}
 
export default ContactUsComponent;