import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid, Typography, Card, FormControlLabel, Checkbox, Button, FormGroup, FormHelperText, TextField } from '@material-ui/core';
import AddIcon from '../../../../assests/images/add.png';
import XLS from '../../../../assests/images/xls.png';
import '../style/style.css';
import { toast } from "react-toastify";
import { bulkuploadProduct } from '../actions/dashboardAction';
import RightIcon from '../../../../assests/images/Group 911.svg';
import { SpinnerRoundFilled } from 'spinners-react';
import constants from '../../../../utils/constants';
import MaterialTable from 'material-table';
import { Link } from '@material-ui/core';
import PubNub from 'pubnub';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { InfoIconWithTooltip } from 'icon-with-tooltip';
import AccessRestricted from '../../common/components/accessRestricted';
import { isActionAllowed, getComponentActionList } from '../../../../common/components/Helper/Utils';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Autocomplete } from '@material-ui/lab';

const steps = [
    {
        status: "Validating the File",
        step: 1,
        info: "Checking the header, file extension and data types of each fields"
    },
    {
        status: "Processing Data",
        step: 2,
        info: "Collect the Valid data and convert the data into processing format"
    },
    {
        status: "Loading Data",
        step: 3,
        info: "Creating New Watches into Inventory"
    },
    {
        status: "Checking Completeness",
        step: 4,
        info: "Inventory Created with Valid Data and Error / Failure File Prepared"
    }
];

function MiscProductBulkUpload(props) {
    const [bulkUploadFile, setBulkUploadFile] = useState("")
    const [bulkUploadCheckBox, setBulkUploadCheckBox] = useState(false)
    const [errorBulkUploadCheckBox, setErrorBulkUploadCheckBox] = useState("")
    const [loading, setLoading] = useState(false)
    const [sampleData, setSampleData] = useState([])
    const [successFeedback, setSuccessFeedback] = useState(false)
    const [inventoryxlid, setInventoryxlid] = useState(null)
    const [statusMessage, setStatusMessage] = useState("Processing the File")
    const [transfer, setTransfer] = useState({ step: 0 })
    const [position, setPosition] = useState(0.1);
    const [accessData, setAccessData] = useState([]);
    const [category, setSelectedCategory] = useState('');
    const [errorModel, setErrorModel] = useState('');
    const categoryList = [
        {
            category: "WATCH RELATED ACCESSORIES",
            label: "Watch Related Accessories"
        },
        {
            category: "APPAREL",
            label: "Apparel"
        },
        {
            category: "WATCH PARTS",
            label: "Watch Parts"
        },
        {
            category: "JEWELRY",
            label: "Jewelry"
        },
        {
            category: "STONES",
            label: "Stones"
        },
        {
            category: "HAND BAGS",
            label: "Hand Bags"
        },
        {
            category: "WALLETS",
            label: "Wallets"
        },
        {
            category: "BAG RELATED ACCESSORIES",
            label: "Bag Related Accessories"
        },
        {
            category: "SERVICE WATCHES",
            label: "Service Watches"
        },
        {
            category: "OTHERS",
            label: "Others"
        },
    ]

    const pubnub = useMemo(() => {
        return new PubNub({
            publishKey: constants.PUBNUB_PUBLISHKEY,
            subscribeKey: constants.PUBNUB_SUBSCRIBEKEY,
            uuid: constants.PUBNUB_UUID,
            autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
            restore: true,
        })
    }, []);
    useEffect(() => {
        const accessDataFromObject = getComponentActionList('bulk_upload');
        setAccessData(accessDataFromObject);
    }, [])
    const getStepPosition = (transferStep) => {
        let index = steps.findIndex(({ step }) => step === transferStep) + 1;
        return index / steps.length
    };

    const validateInput = () => {
        let errorBulkUploadCheckBox = '';
        let errorCategoy = '';
        let isValid = true;

        if (bulkUploadCheckBox === false) {
            errorBulkUploadCheckBox = 'Please Check It';
            isValid = false;
        }
        if (!category) {
            errorCategoy = 'Please Select Category';
            isValid = false;
        }
        setErrorBulkUploadCheckBox(errorBulkUploadCheckBox)
        setErrorModel(errorCategoy);
        return isValid;
    };

    const bulkUploadFileFun = ({ target }) => {
        const imageFile = target.files[0];
        setBulkUploadFile(imageFile);
    }

    const onChangeOfCheckBox = (event) => {
        setBulkUploadCheckBox(event.target.checked)
        if (event.target.checked === true) {
            setErrorBulkUploadCheckBox('')
        } else {
            setErrorBulkUploadCheckBox('Please Check It')
        }
    }
    const handleMessage = (event) => {
        if (event && event.channel && event.channel === `${inventoryxlid}_status`) {
            setStatusMessage(event.message.data);
            if (event.message.step > transfer.step) {
                setTransfer({ step: event.message.step })
                const position = getStepPosition(event.message.step);
                setPosition(position)
            }

        }
        if (event && event.channel && event.channel === `${inventoryxlid}_data`) {
            setTransfer({ step: event.message.step })
            const position = getStepPosition(event.message.step);
            setPosition(position)
            if (event.message && event.message.inventoryxlid) {
                const data = event.message;
                let successFeedback = data && data.data.filter(function (el) {
                    return el.status === 'Success' && el.watchCount > 0;
                });
                setSuccessFeedback(successFeedback.length > 0)
                setLoading(false);
                setSampleData(data.data);
            }
        }
    };

    useEffect(() => {

        pubnub.addListener({ message: handleMessage });
        pubnub.subscribe({ channels: [`${inventoryxlid}_status`, `${inventoryxlid}_data`] });
        return () => {
            pubnub.removeListener(handleMessage);
            pubnub.unsubscribe({
                channels: [`${inventoryxlid}_status`, `${inventoryxlid}_data`],
            });
        };

    }, [inventoryxlid, pubnub]);

    const onSubmit = () => {
        if (validateInput()) {
            let fd = new FormData();
            fd.set('bulk_xl', bulkUploadFile);
            fd.set('category', category && category.toLowerCase());

            props.bulkuploadProduct(
                fd,
                (success) => {
                    toast(success.message, {
                        autoClose: 5000,
                        type: success.success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_CENTER,
                    });
                    setTransfer({ step: success.success ? 1 : 4 })
                    const position = getStepPosition(success.success ? 1 : 4);
                    setPosition(position)
                    setLoading(success.success ? true : false);
                    if (success.data === null) {
                        setLoading(false);
                    }
                    if (success.data) {
                        setInventoryxlid(success.data?.inventoryxlid)
                    }
                },
                (error) => {
                    toast("Bulk Upload Failed", {
                        autoClose: 5000,
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.BOTTOM_CENTER,
                    });
                }
            );
        }
    }
    const getContentView = () => {
        return (
            <>
                {isActionAllowed(accessData, 'upload_inventory') ?
                    (
                        <div className="">
                            <div>
                                {loading === false && sampleData && sampleData.length === 0 && (
                                    <div>
                                        <Typography variant='h6' style={{ display: "block", textAlign: 'center' }}>Misc Product Bulk Upload</Typography>
                                        <Container style={{ marginTop: '20px' }}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="body1" className="color-575757 text-center1 space-bottom-10"><b>Download the excel sample file by clicking on the xls icon below before you bulk upload your products</b></Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <div style={{ width: '45px', height: '71px', textAlign: 'center', margin: "0 auto" }}>
                                                        <Card>
                                                            <a href="https://impresari-images.s3.amazonaws.com/Misc-Product-Sample-File.xlsx">
                                                                <img src={XLS} alt="XLS" style={{ width: '45px', height: '71px', textAlign: 'center', margin: "0 auto" }} />
                                                            </a>
                                                        </Card>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                        <Container maxWidth="md">
                                            <Grid item xs={12} sm={12}>
                                                <Card className="space-marign-20">
                                                    <div style={{ padding: '10px 10px 10px 20px' }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <div className="space-top-20">
                                                                    <input className="input-upload" id="icon-button-file9" type="file" onChange={bulkUploadFileFun} />
                                                                    <div class="margin-120">
                                                                        <label htmlFor="icon-button-file9">
                                                                            <Card className="Primary-Border">
                                                                                <div className="space-3 primary-border bg-color-F5F5F5">
                                                                                    <div className="space-5 primary-border-dashed text-center1" style={{ width: "100%" }}>
                                                                                        {(bulkUploadFile) ?
                                                                                            <div className="text-center1">
                                                                                                Selected File :{" "}
                                                                                                {bulkUploadFile && bulkUploadFile.name}
                                                                                            </div>
                                                                                            :
                                                                                            <div className="text-center1">
                                                                                                <img src={AddIcon} alt="Addicon" style={{ cursor: 'pointer' }} />
                                                                                                <Typography variant="body2">Upload Bulk XLS/CSV file</Typography>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Card>
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-width-50" style={{ margin: "20px 120px 10px" }}>
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            id="brand"
                                                                            options={categoryList}
                                                                            getOptionLabel={(option) => (option ? option.label : "")}
                                                                            sx={{ width: 300 }}
                                                                            onChange={(event, newValue) => {
                                                                                setSelectedCategory(newValue.category);
                                                                                setErrorModel('');
                                                                            }}
                                                                            renderInput={(params) => <TextField {...params} label="Category" InputLabelProps={{ style: { color: "rgb(66, 66, 66)" } }} />}
                                                                        />
                                                                    </div>
                                                                    {errorModel && (
                                                                        <FormHelperText style={{ color: "red", textIndent: "120px" }}>
                                                                            {errorModel}
                                                                        </FormHelperText>
                                                                    )}
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            style={{ margin: '10px 110px' }}
                                                                            className="margin-110"
                                                                            control={<Checkbox
                                                                                name="checkedA"
                                                                                onChange={onChangeOfCheckBox}
                                                                            />
                                                                            }
                                                                            label="Check the box to confirm you have authority to upload these data."
                                                                        />
                                                                    </FormGroup>
                                                                    <FormHelperText style={{ color: "red", textIndent: "120px" }}>
                                                                        {errorBulkUploadCheckBox}
                                                                    </FormHelperText>
                                                                    <Container maxWidth="xs">
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Button className="font-size-12 bg-card-signin-subscribe-color form-width-50 float-right" onClick={onSubmit}>Upload</Button>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Button className="font-size-12 Button-outline-border form-width-50">Cancel</Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Container>
                                                                </div>
                                                            </Grid>

                                                        </Grid>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        </Container>
                                    </div>
                                )}

                                {loading === true &&
                                    (<div>
                                        <div id="ProgressWrapper" style={{ margin: 'auto', marginTop: '50px', width: '1000px' }}>
                                            <ProgressBar
                                                width={830}
                                                percent={100 * (position)}
                                                filledBackground="linear-gradient(to right, rgba(57, 107, 172, 1), rgba(57, 107, 172, 1))"
                                            >
                                                {steps.map((step, index, arr) => {
                                                    return (
                                                        <Step
                                                            position={100 * (index / arr.length)}
                                                            transition="scale"
                                                            style={{ left: '6% !important' }}
                                                            children={({ accomplished }) => (
                                                                <div>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            borderRadius: "50%",
                                                                            width: 20,
                                                                            height: 20,
                                                                            color: "white",
                                                                            marginTop: 45,
                                                                            marginBottom: 20,
                                                                            marginLeft: index === steps.length - 1 ? 45 : 57,
                                                                            backgroundColor: accomplished ? "rgba(57, 107, 172, 1)" : "gray"
                                                                        }}
                                                                    >
                                                                        {index + 1}
                                                                    </div>
                                                                    <div className="steps-text" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <b>{steps[index].status}</b>
                                                                        <InfoIconWithTooltip text={steps[index].info} placement="right" />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        />
                                                    );
                                                })}
                                            </ProgressBar>
                                        </div>
                                        <div class="d-spinner">
                                            <SpinnerRoundFilled size={51} thickness={125} speed={80} color="rgba(57, 107, 172, 1)" />
                                        </div>
                                    </div>)}
                                {sampleData && sampleData.length > 0 &&
                                    <div>
                                        <Container>
                                            <MaterialTable
                                                title={`Bulk Upload Summary for the Id: ${inventoryxlid}`}
                                                data={sampleData}
                                                columns={[
                                                    { field: "status", title: 'STATUS' },
                                                    { field: "watchCount", title: 'WATCH COUNT' },
                                                    { field: "filePath", title: 'DOWNLOAD FILE', render: rowData => <Link href={`${rowData.filePath}`} target="_blank">{rowData.filePath}</Link> }
                                                ]} />
                                        </Container>
                                        <Container>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={3}></Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    {successFeedback && (
                                                        <div className="space-top-30">
                                                            <div className="text-center1">
                                                                <img src={RightIcon} alt="right-Icon" />
                                                            </div>
                                                            <div className="text-center1 space-top-20 space-bottom-20">
                                                                <Typography variant="h5" className="font-weight-500 font-size-24">
                                                                    Your watches has been added into our database,
                                                                    now you can upload the images in My Inventory</Typography>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3}></Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4} md={4}></Grid>
                                                <Grid item xs={12} sm={4} md={4}>
                                                    <div className="space-3">
                                                        <Button className="bg-card-signin-subscribe-color text-center1 form-width-100"
                                                            href={`/myinventory`}>GO TO INVENTORY</Button>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4}></Grid>
                                            </Grid>

                                        </Container>
                                    </div>
                                }
                            </div>
                        </div>
                    ) :
                    (
                        <AccessRestricted />
                    )

                }
            </>
        );
    }
    return (
        <ContentWrapper
            contentView={getContentView()}
        />
    );
}

function mapStateToProps({ dealer }) {
    return {
        BulkUploadInfo: dealer.BulkUploadInfo,
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        bulkuploadProduct
    },
        dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MiscProductBulkUpload);
