import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import {
    Button,
    Card,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { Stack, Breadcrumbs } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import FileUploadGroup from "../../../../common/components/FileuploadGroup";
import { createContactList, updateContactList } from "../actions/contactApi";
import getUserdetailsforsubscrption from "../../dashboard/actions/dashboardaapi";
import { Autocomplete } from "@material-ui/lab";
import {
    showErrorToast,
    showSuccessToast,
} from "../../../../common/components/Helper/Utils";
import ContentWrapper from "../../../../common/components/contentWrapper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialFormState = {
    billingAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        country: "United States",
        countryCode: "US",
    },
    shippingAddress: {
        sameAsBilling: false,
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        country: "United States",
        countryCode: "US",
    },
};

const relationTypeOptions = [
    { id: "supplier", value: "Supplier" },
    { id: "customer", value: "Customer" },
    { id: "partner", value: "Partner" },
    { id: "memo_supplier", value: "Memo Supplier" },
];

const paymentMethods = [
    "Wire",
    "Check",
    "Cash",
    "Credit Card",
    "Trade",
    "Zelle",
    "Venmo",
    "Paypal",
    "Affirm",
];

const paymentTermsOptions = [
    { id: "Immediate", value: "Immediate" },
    { id: "Net 7 Days", value: "Net 7 Days" },
    { id: "Net 14 Days", value: "Net 14 Days" },
    { id: "Net 21 Days", value: "Net 21 Days" },
    { id: "Net 30 Days", value: "Net 30 Days" },
    { id: "Net 60 Days", value: "Net 60 Days" },
    { id: "Net 90 Days", value: "Net 90 Days" },
];

const AddContactModal = ({
    currentContactPage,
    updateContactPage,
    contactViewData,
}) => {
    const ContentView = () => {
        const history = useHistory();
        const [classificationType, setClassificationType] = useState("");
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [businessName, setBusinessName] = useState("");
        const [email, setEmail] = useState("");
        const [phoneCode, setPhoneCode] = useState("+1");
        const [otherPhoneCode, setOtherPhoneCode] = useState("+1");
        const [phoneNumber, setPhoneNumber] = useState("");
        const [otherPhoneNumber, setOtherPhoneNumber] = useState("");
        const [relationshipType, setRelationshipType] = useState([]);
        const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
        const [paymentTerms, setPaymentTerms] = useState("");
        const [businessCard, setBusinessCard] = useState([]);
        const [businessFileArray, setBusinessFileArray] = useState([]);
        const [salesTaxID, setSalesTaxID] = useState("");
        const [salesTaxCertificate, setSalesTaxCertificate] = useState([]);
        const [salesFileArray, setSalesFileArray] = useState([]);
        const [invoiceAmount, setInvoiceAmount] = useState(0);
        const [timeStampNotes, setTimeStampNotes] = useState("");
        const [uniqueCountryCode, setUniqueCountryCode] = useState([]);
        const [countryListData, setCountryListData] = useState([]);
        const [statesListData, setStatesListData] = useState([]);
        const [shippingStatesListData, setShippingStatesListData] = useState([]);
        const [formData, setFormData] = useState(initialFormState);
        const [disabled, setDisabled] = useState(false);
        const [error, setError] = useState({});
        let userData = sessionStorage.getItem("userDetails")
            ? JSON.parse(sessionStorage.getItem("userDetails"))
            : null;

        const billingFields = [
            { label: "Address Line 1", field: "addressLine1", type: "input" },
            { label: "Address Line 2", field: "addressLine2", type: "input" },
            { label: "City", field: "city", type: "input" },
            {
                label: "Country",
                field: "countryCode",
                type: "select",
                options: countryListData,
            },
            { label: "State", field: "state", type: "select", options: statesListData },
            { label: "Zip Code", field: "zipCode", type: "input" },
        ];

        const shippingFields = [
            { label: "Address Line 1", field: "addressLine1", type: "input" },
            { label: "Address Line 2", field: "addressLine2", type: "input" },
            { label: "City", field: "city", type: "input" },
            {
                label: "Country",
                field: "countryCode",
                type: "select",
                options: countryListData,
            },
            {
                label: "State",
                field: "state",
                type: "select",
                options: shippingStatesListData,
            },
            { label: "Zip Code", field: "zipCode", type: "input" },
        ];

        const handleChange = (event, section, field) => {
            const { value, checked, type } = event.target;
            setFormData((prevState) => {
                const fields =
                    section === "billingAddress" ? billingFields : shippingFields;

                if (field === "sameAsBilling") {
                    if (checked) {
                        // Copy billing address to shipping when "Same as billing" is checked
                        return {
                            ...prevState,
                            shippingAddress: {
                                ...prevState.billingAddress,
                                sameAsBilling: true,
                            },
                        };
                    } else {
                        // Clear shipping address when "Same as billing" is unchecked
                        return {
                            ...prevState,
                            shippingAddress: {
                                sameAsBilling: false,
                                addressLine1: "",
                                addressLine2: "",
                                city: "",
                                state: "",
                                zipCode: "",
                                country: "",
                                countryCode: "",
                            },
                        };
                    }
                }

                if (field === "countryCode") {
                    const selectedState = fields
                        .find((f) => f.field === "countryCode")
                        .options.find((option) => option.sortname === value);
                    return {
                        ...prevState,
                        [section]: {
                            ...prevState[section],
                            [field]: value,
                            country: selectedState ? selectedState.name : "",
                        },
                    };
                } else {
                    return {
                        ...prevState,
                        [section]: {
                            ...prevState[section],
                            [field]: type === "checkbox" ? checked : value,
                        },
                    };
                }
            });

            // If the country field is changed, trigger the state API call
            if (field === "countryCode") {
                const countryCode = countryListData.find((f) => f.sortname === value);
                getUserdetailsforsubscrption
                    .getAllStateList(countryCode?.id)
                    .then((res) => {
                        if (section === "billingAddress") {
                            setStatesListData(res.data.data);
                        } else if (section === "shippingAddress") {
                            setShippingStatesListData(res.data.data);
                        }
                    });
            }
        };

        const handleInputChange = (event, setState) => {
            const { value } = event.target;
            setState(value);
        };

        const handleBusinessFiles = (value, disablevalue) => {
            const imageFile = disablevalue;
            let fileArray = [];
            for (let i = 0; i < imageFile.length; i++) {
                if (imageFile?.name) {
                    fileArray.push(URL.createObjectURL(imageFile[i]));
                    continue;
                }
            }
            setBusinessCard(imageFile);
            setBusinessFileArray(fileArray);
        };

        const handleSalesFiles = (value, disablevalue) => {
            const imageFile = disablevalue;
            let fileArray = [];
            for (let i = 0; i < imageFile.length; i++) {
                if (imageFile?.name) {
                    fileArray.push(URL.createObjectURL(imageFile[i]));
                    continue;
                }
            }
            setSalesTaxCertificate(imageFile);
            setSalesFileArray(fileArray);
        };

        useEffect(() => {
            getcountryList();
        }, []);

        const getcountryList = () => {
            getUserdetailsforsubscrption.getAllCountryList().then((res) => {
                setCountryListData(res?.data?.data);
                let countries = res?.data?.data;
                let countryCode = [];
                countries.filter(({ phonecode }) => countryCode.push(phonecode));
                const UniqueCountryCode = [...new Set(countryCode)];
                setUniqueCountryCode(UniqueCountryCode.sort());
            });

            // Fetch state list for billing and shipping country
            if (
                formData.billingAddress.countryCode &&
                formData.shippingAddress.countryCode
            ) {
                getUserdetailsforsubscrption.getAllStateList(231).then((res) => {
                    setStatesListData(res.data.data);
                    setShippingStatesListData(res.data.data);
                });
            }

            // // If shipping country code is different from billing, fetch states for shipping country
            // if (formData.shippingAddress.countryCode && formData.billingAddress.countryCode !== formData.shippingAddress.countryCode) {
            //     getUserdetailsforsubscrption
            //         .getAllStateList(formData.shippingAddress.countryCode)
            //         .then((res) => {
            //             setShippingStatesListData(res.data.data);
            //         });
            // }
        };

        useEffect(() => {
            getViewData();
        }, [contactViewData, countryListData]);

        const getViewData = () => {
            if (
                Object.keys(contactViewData).length > 0 &&
                countryListData &&
                countryListData.length > 0
            ) {
                if (contactViewData.billingcountrycode) {
                    const countryCode = countryListData.find(
                        (f) => f.sortname === contactViewData?.billingcountrycode
                    );
                    getUserdetailsforsubscrption
                        .getAllStateList(countryCode?.id)
                        .then((res) => {
                            setStatesListData(res.data.data);
                        });
                }
                if (contactViewData.shippingcountrycode) {
                    const countryCode = countryListData.find(
                        (f) => f.sortname === contactViewData?.shippingcountrycode
                    );
                    getUserdetailsforsubscrption
                        .getAllStateList(countryCode?.id)
                        .then((res) => {
                            setShippingStatesListData(res.data.data);
                        });
                }
                setClassificationType(contactViewData?.classification_type || "");
                setBusinessName(contactViewData?.businessname || "");
                setFirstName(contactViewData?.firstname || "");
                setLastName(contactViewData?.lastname || "");
                setEmail(contactViewData?.emailid || "");
                setPhoneCode(contactViewData?.phonecode || "+1");
                setPhoneNumber(contactViewData?.phoneno || "");
                setOtherPhoneCode(contactViewData?.otherphonecode || "+1");
                setOtherPhoneNumber(contactViewData?.otherphone || "");
                setRelationshipType(contactViewData?.contact_relationtype || []);
                setSalesTaxID(contactViewData?.sales_taxid || "");
                setInvoiceAmount(Math.round(contactViewData?.invoice_amount) || "");
                setTimeStampNotes(contactViewData?.timestamp_notes || "");
                setSelectedPaymentMethods(contactViewData?.acceptable_payment);
                setPaymentTerms(contactViewData?.preferred_payment);
                setBusinessFileArray(contactViewData?.businesscard_imagepath?.images);
                setSalesFileArray(contactViewData?.salestax_imagepath?.images);

                // Map billing address
                setFormData((prevState) => ({
                    ...prevState,
                    billingAddress: {
                        addressLine1: contactViewData?.billingaddress1 || "",
                        addressLine2: contactViewData?.billingaddress2 || "",
                        city: contactViewData?.billingcity || "",
                        state: contactViewData?.billingstate || "",
                        zipCode: contactViewData?.billingzipcode || "",
                        country: contactViewData?.billingcountry || "",
                        countryCode: contactViewData?.billingcountrycode || "",
                    },
                    // Map shipping address
                    shippingAddress: {
                        sameAsBilling: contactViewData?.is_same_billing,
                        addressLine1: contactViewData?.shippingaddress1 || "",
                        addressLine2: contactViewData?.shippingaddress2 || "",
                        city: contactViewData?.shippingcity || "",
                        state: contactViewData?.shippingstate || "",
                        zipCode: contactViewData?.shippingzipcode || "",
                        country: contactViewData?.shippingcountry || "",
                        countryCode: contactViewData?.shippingcountrycode || "",
                    },
                }));
            }
        };

        // useEffect(() => {
        //     if (formData.shippingAddress.sameAsBilling) {
        //         Copy billing address to shipping when "Same as billing" is checked
        //         setFormData((prevState) => ({
        //             ...prevState,
        //             shippingAddress: {
        //                 ...prevState.billingAddress,
        //                 sameAsBilling: true,
        //             },
        //         }));
        //     }
        //     else {
        //         setFormData((prevState) => ({
        //             ...prevState,
        //             shippingAddress: {
        //                 sameAsBilling: false,
        //                 addressLine1: "",
        //                 addressLine2: "",
        //                 city: "",
        //                 state: "",
        //                 zipCode: "",
        //                 country: "",
        //                 countryCode: "",
        //             },
        //         }));
        //     }
        // }, [formData.shippingAddress.sameAsBilling]);

        const validateInput = () => {
            let error = {};
            let isValid = true;

            if (!classificationType) {
                error["classificationType"] = "Please select any value";
                isValid = false;
            }

            if (
                classificationType === "walk_in" ||
                classificationType === "end_customer"
            ) {
                if (!firstName) {
                    error["firstName"] = "Please enter First Name";
                    isValid = false;
                }
                if (!lastName) {
                    error["lastName"] = "Please enter Last Name";
                    isValid = false;
                }
            }

            if (
                classificationType === "end_customer" ||
                classificationType === "business_client"
            ) {
                if (classificationType === "business_client" && !businessName) {
                    error["businessName"] = "Please enter Business Name";
                    isValid = false;
                }

                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!email && !phoneNumber) {
                    error["contactInfo"] = "Please provide either Email or Phone Number";
                    isValid = false;
                } else if (email && !emailPattern.test(email)) {
                    error["email"] = "Please enter a valid email address";
                    isValid = false;
                }
            }

            setError(error);
            return isValid;
        };

        const handleSave = (value) => {
            if (validateInput()) {
                setDisabled(true);
                let payload = {
                    classification_type: classificationType,
                    businessname: businessName,
                    firstname: firstName,
                    lastname: lastName,
                    emailid: email,
                    phonecode: phoneCode,
                    phoneno: phoneNumber,
                    otherphonecode: otherPhoneCode,
                    otherphone: otherPhoneNumber,
                    sales_taxid: salesTaxID,
                    userid: userData?.userId,
                    accountid: userData?.accountId,
                    billingdetails: {
                        address1: formData.billingAddress.addressLine1,
                        address2: formData.billingAddress.addressLine2,
                        city: formData.billingAddress.city,
                        state: formData.billingAddress.state,
                        country: formData.billingAddress.country,
                        countrycode: formData.billingAddress.countryCode,
                        zipcode: formData.billingAddress.zipCode,
                    },
                    shippingdetails: {
                        address1: formData.shippingAddress.addressLine1,
                        address2: formData.shippingAddress.addressLine2,
                        city: formData.shippingAddress.city,
                        state: formData.shippingAddress.state,
                        country: formData.shippingAddress.country,
                        countrycode: formData.shippingAddress.countryCode,
                        zipcode: formData.shippingAddress.zipCode,
                    },
                    relationtype: relationshipType,
                    invoice_amount: invoiceAmount,
                    timestamp_notes: timeStampNotes,
                    acceptable_payment: selectedPaymentMethods,
                    preferred_payment: paymentTerms,
                    is_same_billing: formData.shippingAddress.sameAsBilling,
                };
                let formDataType = new FormData();
                for (const key of Object.keys(businessCard)) {
                    formDataType.append("businesscard", businessCard[key]);
                }
                for (const key of Object.keys(salesTaxCertificate)) {
                    formDataType.append("salescard", salesTaxCertificate[key]);
                }
                for (const key in payload) {
                    if (Array.isArray(payload[key])) {
                        if (payload[key].length > 0 && typeof payload[key][0] === "object") {
                            payload[key].forEach((item, index) => {
                                for (const prop in item) {
                                    formDataType.append(`${key}[${index}][${prop}]`, item[prop]);
                                }
                            });
                        } else {
                            payload[key].forEach((item) => {
                                formDataType.append(`${key}[]`, item);
                            });
                        }
                    } else if (typeof payload[key] === "object" && payload[key] !== null) {
                        for (const nestedKey in payload[key]) {
                            formDataType.append(
                                `${key}[${nestedKey}]`,
                                payload[key][nestedKey]
                            );
                        }
                    } else {
                        formDataType.set(key, payload[key]);
                    }
                }
                if (value === "save") {
                    createContactList(formDataType, (error, response) => {
                        if (response && response.status == 1) {
                            showSuccessToast(
                                response?.message ?? "Contact has been created successfully"
                            );
                            setDisabled(false);
                            updateContactPage("CONTACT_HOME");
                            history.push("/contactmanagement");
                        } else {
                            setDisabled(false);
                            showErrorToast(response?.message ?? "Something went wrong");
                        }
                        if (error) {
                            setDisabled(false);
                            showErrorToast(error);
                        }
                    });
                } else if (value === "edit") {
                    formDataType.set("contactid", contactViewData?.contactid);
                    updateContactList(formDataType, (error, response) => {
                        if (response && response.status == 1) {
                            setDisabled(false);
                            showSuccessToast(
                                response?.message ?? "Contact has been updated successfully"
                            );
                            updateContactPage("CONTACT_HOME");
                            history.push("/contactmanagement");
                        } else {
                            setDisabled(false);
                            showErrorToast(response?.message ?? "Something went wrong");
                        }
                        if (error) {
                            setDisabled(false);
                            showErrorToast(error);
                        }
                    });
                }
            } else {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        };

        const handleRedirect = () => {
            updateContactPage("CONTACT_HOME");
            history.push("/contactmanagement");
        };

        const renderFormFields = (fields, section) => {
            return fields.map((field, index) => (
                <Grid item xs={12} key={index}>
                    <FormControl variant="standard" className="formControl">
                        <InputLabel
                            htmlFor={`${section}-${field.field}`}
                            style={{ color: "#424242" }}
                        >
                            {field.label}
                        </InputLabel>
                        {field.type === "input" ? (
                            <Input
                                id={`${section}-${field.field}`}
                                value={formData[section][field.field]}
                                onChange={(event) => handleChange(event, section, field.field)}
                                style={{ marginBottom: "8px" }}
                                disabled={
                                    section === "shippingAddress" &&
                                    formData.shippingAddress.sameAsBilling
                                }
                                inputProps={{
                                    maxLength: field.field === "zipCode" ? 10 : 120,
                                    autoComplete: "off",
                                }}
                                onKeyPress={(event) => {
                                    if (field.field === "zipCode" && !/^\d+$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        ) : (
                            <Select
                                id={`${section}-${field.field}`}
                                value={formData[section][field.field]}
                                onChange={(event) => handleChange(event, section, field.field)}
                                style={{ marginBottom: "8px" }}
                                disabled={
                                    section === "shippingAddress" &&
                                    formData.shippingAddress.sameAsBilling
                                }
                            >
                                <MenuItem value="">Select</MenuItem>
                                {field.options.map((option, idx) => {
                                    // let value = option.sortname
                                    //     ? option.sortname
                                    //     : option.country_id;
                                    return (
                                        <MenuItem key={idx} value={option.sortname}>
                                            {option.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                    </FormControl>
                </Grid>
            ));
        };

        const handleAutoComplete = (event, newValue) => {
            setRelationshipType(newValue);
        };

        const getOptionSelected = (option, value) => option.id === value.id;

        const handlePaymentMethodChange = (event) => {
            const { value } = event.target;
            setSelectedPaymentMethods((prev) =>
                prev?.includes(value)
                    ? prev.filter((method) => method !== value)
                    : [...prev, value]
            );
        };

        return (
            <div className="space-bottom-30 someextrahight extra-space-10">
                <Container>
                    <Stack spacing={1}>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<span className="sperator">›</span>}
                        >
                            <Link
                                style={{
                                    fontSize: "14px",
                                    color: "#CEA05F",
                                    textDecoration: "underline !important",
                                }}
                                onClick={() => handleRedirect()}
                            >
                                Contact Management
                            </Link>
                            <Grid
                                style={{
                                    fontSize: "14px",
                                    color: "#9e9e9e",
                                    backgroundColor: "white",
                                }}
                                aria-current="page"
                            >
                                {currentContactPage === "contactEditPage"
                                    ? "Edit Contact"
                                    : "Add Contact"}
                            </Grid>
                        </Breadcrumbs>
                    </Stack>
                    <Card className="padding-20 d-pt">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} className="space-bottom">
                                        <Typography variant="h6">Contact Classification</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="space-bottom">
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            >
                                                Type <span style={{ color: "red" }}> * </span>
                                            </InputLabel>
                                            <Select
                                                id="component-simple"
                                                value={classificationType}
                                                onChange={(e) =>
                                                    handleInputChange(e, setClassificationType)
                                                }
                                            >
                                                <MenuItem value="walk_in">Walk In</MenuItem>
                                                <MenuItem value="business_client">Business Client</MenuItem>
                                                <MenuItem value="end_customer"> End Customer </MenuItem>
                                            </Select>
                                            <FormHelperText className="error-o">
                                                {error["classificationType"]}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                className="space-marign-top-10 space-bottom"
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">General Information</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            >
                                                First Name
                                                {classificationType !== "business_client" && (
                                                    <span style={{ color: "red" }}> * </span>
                                                )}
                                            </InputLabel>
                                            <Input
                                                id="component-simple"
                                                style={{ marginBottom: "8px" }}
                                                value={firstName}
                                                onChange={(e) => handleInputChange(e, setFirstName)}
                                                inputProps={{
                                                    maxLength: 50,
                                                    autoComplete: "off",
                                                }}
                                            />
                                            <FormHelperText className="error-o">
                                                {error["firstName"]}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            >
                                                Last Name
                                                {classificationType !== "business_client" && (
                                                    <span style={{ color: "red" }}> * </span>
                                                )}
                                            </InputLabel>
                                            <Input
                                                id="component-simple"
                                                style={{ marginBottom: "8px" }}
                                                value={lastName}
                                                onChange={(e) => handleInputChange(e, setLastName)}
                                                inputProps={{
                                                    maxLength: 50,
                                                    autoComplete: "off",
                                                }}
                                            />
                                            <FormHelperText className="error-o">
                                                {error["lastName"]}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            >
                                                Business Name
                                                {classificationType === "business_client" && (
                                                    <span style={{ color: "red" }}> * </span>
                                                )}
                                            </InputLabel>
                                            <Input
                                                id="component-simple"
                                                style={{ marginBottom: "8px" }}
                                                value={businessName}
                                                onChange={(e) => handleInputChange(e, setBusinessName)}
                                                inputProps={{
                                                    maxLength: 150,
                                                    autoComplete: "off",
                                                }}
                                            />
                                            <FormHelperText className="error-o">
                                                {error["businessName"]}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            >
                                                Email
                                                {classificationType !== "walk_in" && (
                                                    <span style={{ color: "red" }}> * </span>
                                                )}
                                            </InputLabel>
                                            <Input
                                                id="component-simple"
                                                style={{ marginBottom: "8px" }}
                                                value={email}
                                                onChange={(e) => handleInputChange(e, setEmail)}
                                            />
                                            <FormHelperText className="error-o">
                                                {error["contactInfo"] ?? error["email"]}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: "center" }}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            ></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={phoneCode}
                                                onChange={(e) => handleInputChange(e, setPhoneCode)}
                                            >
                                                {uniqueCountryCode &&
                                                    uniqueCountryCode.map((country) => (
                                                        <MenuItem key={country} value={country}>
                                                            {`${country}`}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            >
                                                Phone Number
                                                {classificationType !== "walk_in" && (
                                                    <span style={{ color: "red" }}> * </span>
                                                )}
                                            </InputLabel>
                                            <Input
                                                id="component-simple"
                                                style={{ marginBottom: "8px" }}
                                                value={phoneNumber}
                                                onChange={(e) => handleInputChange(e, setPhoneNumber)}
                                                inputProps={{
                                                    maxLength: 14,
                                                    autoComplete: "off",
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/^\d+$/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <FormHelperText className="error-o">
                                                {error["contactInfo"]}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: "center" }}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            ></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={otherPhoneCode}
                                                onChange={(e) => handleInputChange(e, setOtherPhoneCode)}
                                            >
                                                {uniqueCountryCode &&
                                                    uniqueCountryCode.map((country) => (
                                                        <MenuItem key={country} value={country}>
                                                            {`${country}`}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            >
                                                Other Phone Number
                                            </InputLabel>
                                            <Input
                                                id="component-simple"
                                                style={{ marginBottom: "8px" }}
                                                value={otherPhoneNumber}
                                                onChange={(e) => handleInputChange(e, setOtherPhoneNumber)}
                                                inputProps={{
                                                    maxLength: 14,
                                                    autoComplete: "off",
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/^\d+$/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                <FormControl variant="standard" className="formControl">
                                    <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                    >
                                        Contact Relationship Type
                                    </InputLabel>
                                    <Select
                                        id="component-simple"
                                        value={relationshipType}
                                        onChange={(e) => handleInputChange(e, setRelationshipType)}
                                    >
                                        <MenuItem value="supplier">Supplier</MenuItem>
                                        <MenuItem value="customer">Customer</MenuItem>
                                        <MenuItem value="partner"> Partner </MenuItem>
                                        <MenuItem value="memo_supplier"> Memo Supplier </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" className="formControl">
                                            <Autocomplete
                                                multiple
                                                name="relationshipType"
                                                limitTags={1}
                                                options={relationTypeOptions}
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            checked={selected}
                                                        />
                                                        {option.value}
                                                    </React.Fragment>
                                                )}
                                                getOptionLabel={(option) => option.value}
                                                getOptionSelected={getOptionSelected}
                                                value={relationshipType}
                                                onChange={handleAutoComplete}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Relationship Type"
                                                        InputLabelProps={{
                                                            className:
                                                                "MuiFormControlLabel-root contact-add-field",
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {relationshipType.some((type) => type.value === "Customer") && (
                                        <>
                                            <Grid item xs={12} className="space-marign-top-10">
                                                <FormControl variant="standard" className="formControl">
                                                    <FormLabel
                                                        component="label"
                                                        htmlFor="component-simple"
                                                        style={{ color: "#424242" }}
                                                    >
                                                        Acceptable Payment Methods
                                                    </FormLabel>
                                                    <FormGroup row>
                                                        {paymentMethods.map((method) => (
                                                            <FormControlLabel
                                                                key={method}
                                                                control={
                                                                    <Checkbox
                                                                        value={method}
                                                                        checked={selectedPaymentMethods?.includes(
                                                                            method
                                                                        )}
                                                                        onChange={handlePaymentMethodChange}
                                                                    />
                                                                }
                                                                label={method}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="space-marign-top-10 space-bottom"
                                            >
                                                <FormControl variant="standard" className="formControl">
                                                    <InputLabel
                                                        htmlFor="component-simple"
                                                        style={{ color: "#424242" }}
                                                    >
                                                        Preferred Payment Terms
                                                    </InputLabel>
                                                    <Select
                                                        id="component-simple"
                                                        value={paymentTerms}
                                                        onChange={(e) => handleInputChange(e, setPaymentTerms)}
                                                    >
                                                        {paymentTermsOptions.map((option, idx) => {
                                                            return (
                                                                <MenuItem key={idx} value={option.id}>
                                                                    {option.value}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <InputLabel
                                            htmlFor="component-simple"
                                            style={{
                                                color: "#424242",
                                                marginBottom: "20px",
                                                float: "left",
                                                marginTop: "20px",
                                            }}
                                        >
                                            Image Upload for ID or Business Card&nbsp;
                                        </InputLabel>
                                        {/* <Tooltip
                                    title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/PDF/DOC"
                                    style={{ position: "relative", top: 3, cursor: "pointer" }}
                                >
                                    <HelpIcon
                                        fontSize="10px"
                                        style={{ color: "#CEA05F", marginTop: "14px" }}
                                    />
                                </Tooltip> */}
                                        {currentContactPage === "contactEditPage" ?
                                            <FileUploadGroup
                                                imageDetails={businessFileArray}
                                                page={"Edit"}
                                                onFilesChange={handleBusinessFiles}
                                                id={"BusinessCard"}
                                            />
                                            :
                                            <FileUploadGroup
                                                onFilesChange={handleBusinessFiles}
                                                id={"BusinessCard"}
                                            />
                                        }
                                    </Grid>
                                    {classificationType === "business_client" && (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControl variant="standard" className="formControl">
                                                    <InputLabel
                                                        htmlFor="component-simple"
                                                        style={{ color: "#424242" }}
                                                    >
                                                        Sales Tax ID
                                                    </InputLabel>
                                                    <Input
                                                        id="component-simple"
                                                        style={{ marginBottom: "8px" }}
                                                        value={salesTaxID}
                                                        onChange={(e) => handleInputChange(e, setSalesTaxID)}
                                                        inputProps={{
                                                            type: "phone",
                                                            maxLength: 14,
                                                            autoComplete: "off",
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (!/^\d+$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel
                                                    htmlFor="component-simple"
                                                    style={{
                                                        color: "#424242",
                                                        marginBottom: "20px",
                                                        float: "left",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    Upload Sales Tax Certificate Image (or PDF)&nbsp;
                                                </InputLabel>
                                                {/* <Tooltip
                                            title="Please attach images separately as high resolution JPG/PNG/JPEG/GIF/PDF/DOC"
                                            style={{
                                                position: "relative",
                                                top: 3,
                                                cursor: "pointer",
                                            }}
                                        >
                                            <HelpIcon
                                                fontSize="10px"
                                                style={{ color: "#CEA05F", marginTop: "14px" }}
                                            />
                                        </Tooltip> */}
                                                {currentContactPage === "contactEditPage" ?
                                                    <FileUploadGroup
                                                        imageDetails={salesFileArray}
                                                        page={"Edit"}
                                                        onFilesChange={handleSalesFiles}
                                                        id={"SalesTaxCertificate"}
                                                    />
                                                    :
                                                    <FileUploadGroup
                                                        onFilesChange={handleSalesFiles}
                                                        id={"SalesTaxCertificate"}
                                                    />
                                                }
                                            </Grid>
                                        </>
                                    )}
                                    {classificationType && classificationType !== "walk_in" && (
                                        <Grid item xs={12}>
                                            <FormControl variant="standard" className="formControl">
                                                <InputLabel
                                                    htmlFor="component-simple"
                                                    style={{ color: "#424242" }}
                                                >
                                                    Total Allowed Open Invoices Amount
                                                </InputLabel>
                                                <Input
                                                    id="component-simple"
                                                    style={{ marginBottom: "8px" }}
                                                    value={invoiceAmount}
                                                    onChange={(e) => handleInputChange(e, setInvoiceAmount)}
                                                />
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <FormControl variant="standard" className="formControl">
                                            <InputLabel
                                                htmlFor="component-simple"
                                                style={{ color: "#424242" }}
                                            >
                                                Notes
                                            </InputLabel>
                                            <Input
                                                id="component-simple"
                                                style={{ marginBottom: "8px" }}
                                                value={timeStampNotes}
                                                onChange={(e) => handleInputChange(e, setTimeStampNotes)}
                                                multiline
                                                maxRows={4}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                className="space-marign-top-10 space-bottom"
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{ marginBottom: "7px" }}>
                                            Billing Address
                                        </Typography>
                                        <Grid container spacing={1}>
                                            {renderFormFields(billingFields, "billingAddress")}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Shipping Address</Typography>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} justifyContent="flex-end">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="sameAsBilling"
                                                            checked={formData.shippingAddress.sameAsBilling}
                                                            onChange={(event) =>
                                                                handleChange(
                                                                    event,
                                                                    "shippingAddress",
                                                                    "sameAsBilling"
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label="Same as billing address"
                                                />
                                            </Grid>
                                            {renderFormFields(shippingFields, "shippingAddress")}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{ display: "flex", justifyContent: "center", gap: "20px" }}
                            >
                                <Button
                                    type="submit"
                                    className="SubmitColor space-marign-20"
                                    onClick={handleRedirect}
                                >
                                    Cancel
                                </Button>
                                {currentContactPage === "contactEditPage" ? (
                                    <Button
                                        type="submit"
                                        className="SubmitColor space-marign-20"
                                        onClick={() => handleSave("edit")}
                                        disabled={disabled}
                                    >
                                        Update
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        className="SubmitColor space-marign-20"
                                        onClick={() => handleSave("save")}
                                        disabled={disabled}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </div>
        );
    };
    return <ContentWrapper contentView={ContentView()} />;
}

const mapStateToProps = ({ ContactManagement }) => {
    return {
        currentContactPage: ContactManagement.contactManagement,
        contactViewData: ContactManagement.contactViewData,
    };
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateContactPage: (type) => ({ type: type }),
            updateContactData: (payload) => ({ type: "CONTACT_VIEW_DATA", payload }),
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContactModal);
