import React, { useState, useEffect, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Menu from "@mui/material/Menu";
import { alpha } from "@mui/material/styles";
import searchinventry from "../../actions/dashboardaapi";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import { Container, Grid, Typography, Card, TextField, Paper, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress, } from '@material-ui/core';
import Instagram from '../../../../../assests/images/instagram.png';
import watchImg from '../../../../../assests/images/dashboard/dashboardwatch.png';
import { inventoryCount, filterByInvoicePrice, filterBydboardAccordion, downloadcharts } from '../../actions/dashboardAction';
import addinvetrysearchdataa from '../../actions/dashboardaapi';
import Collapse from '@mui/material/Collapse';
import ViewInvoicemodel from "../ViewInvoicemodel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ReportPieChart from './ReportPieChart';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { makeStyles } from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { toast } from "react-toastify";
import TableBody from '@mui/material/TableBody';
import DownloadIcon from '@mui/icons-material/Download';
import InputAdornment from "@material-ui/core/InputAdornment";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DatePicker from "react-multi-date-picker"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { SpinnerRoundFilled } from "spinners-react";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Arrow,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import Example from './ReportPieChart';
import moment from 'moment/moment';

import amountFormatter from '../../../common/components/amountFormat';
import { formatDate } from '../../../../../common/components/Helper/Utils';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgba(211, 47, 47, 0.08)',
        // color: theme.palette.common.white,
        color: '#d32f2f'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


const StyledMenu = styled((props) => (
    <Menu
        // elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];
class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-15)">
                    {payload.value}
                </text>
            </g>
        );
    }
}
const useStyles = makeStyles({
    root: {
        backgroundColor: '#f3f3f3 !important',
        color: '#ccc4c4 !important',
    },
});

const CustomizedMenus = ({ downloadInventory }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (data, value) => {
        setAnchorEl(null);
        if (data) {
            downloadInventory(data, value)
        }
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                className={
                    "text-capitalize primary-bg color-white"
                }
                style={{ float: "right" }}
                endIcon={<KeyboardArrowDownOutlined />}
            >
                Download
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleClose('ALL', '')} disableRipple>
                    All
                </MenuItem>
                <MenuItem onClick={() => handleClose('0', '')} disableRipple>
                    In Stock
                </MenuItem>
                <MenuItem onClick={() => handleClose("1", '')} disableRipple>
                    Sold
                </MenuItem>
                <MenuItem onClick={() => handleClose("Void", '')} disableRipple>
                    Void
                </MenuItem>
            </StyledMenu>
        </div>
    );
};

const CustomizedDownloadText = ({ downloadInventory }) => {
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open = Boolean(anchorEl2);
    const handleClick = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose = (data, value) => {
        setAnchorEl2(null);
        if (data) {
            downloadInventory(data, value)
        }
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                className={
                    "text-capitalize primary-bg color-white"
                }
                style={{ float: "right" }}
                endIcon={<KeyboardArrowDownOutlined />}
            >
                Download
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl2}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleClose("0", '5')} disableRipple>
                    Available to Order
                </MenuItem>
                <MenuItem onClick={() => handleClose("0", '4')} disableRipple>
                    On Hold/Reserved
                </MenuItem>
                <MenuItem onClick={() => handleClose("0", '3')} disableRipple>
                    Out on Memo
                </MenuItem>
                <MenuItem onClick={() => handleClose("0", "2")} disableRipple>
                    Incoming
                </MenuItem>
                <MenuItem onClick={() => handleClose("0", '1')} disableRipple>
                    Inhand
                </MenuItem>
                <MenuItem onClick={() => handleClose("0", '6')} disableRipple>
                    Needs Service / Polish
                </MenuItem>
                <MenuItem onClick={() => handleClose("0", '7')} disableRipple>
                    At Service
                </MenuItem>
                <MenuItem onClick={() => handleClose("0", "8")} disableRipple>
                    Need to Ship
                </MenuItem>
                <MenuItem onClick={() => handleClose("0", '9')} disableRipple>
                    Shipped waiting on payment
                </MenuItem>
            </StyledMenu>
        </div>
    );
};
function DashboardChart(props) {
    const [countData, setcountData] = useState('');
    const classes = useStyles()
    let globalInvoiceId = {
        id: ''
    };
    const [viewInvoiceModal, setviewInvoiceModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState({ id: null });
    const [totalInv, settotalInv] = useState('');
    const [uniqueAcc, setuniqueAcc] = useState([])
    const userDetails = sessionStorage.getItem('userDetails');
    const accountid = sessionStorage.getItem('accountId')
    const [count, setcount] = useState('year');
    const [payloadtrack1, setpayloadtrack1] = useState('');
    const [payloadtrack2, setpayloadtrack2] = useState('');
    const [payloadtrack3, setpayloadtrack3] = useState('');
    const [unsoldStatus, setunsoldStatus] = useState('');
    const [loaded, setloaded] = useState(true);
    const [duration, setduration] = useState('')
    const [data, setdata] = useState('');
    const [Downloadloading, setDownloadloading] = useState(false);
    const [toggleValue, setToggleValue] = useState('CHART')
    const [stendDate, setstendDate] = useState([]);
    const [open, setOpen] = useState(false);
    const [accordionloader, setaccordionloader] = useState(true);
    const [AccordEnable, setAccordEnable] = React.useState(false);
    const [AccordionData, setAccordionData] = useState([])
    // const [selectedDate, setselectedDate] = useState([moment().startOf('month').format('YYYY-MM-DD'),
    //  moment(new Date()).format('YYYY-MM-DD')])
    const [selectedDate, setselectedDate] = useState([
        formatDate(moment.utc().startOf('month'), 'YYYY-MM-DD'),
        formatDate(new Date(), 'YYYY-MM-DD')
    ]);
    const invetoryCountInfo = props.invetoryCountInfo;
    const filterByInvoice = props.dashboardaccordionInfo;

    useEffect(() => {
        const label = calculateLabelFormat()
        const payload = {
            // startDate: moment().startOf('month').format('YYYY-MM-DD'),
            // endDate: moment(new Date()).format('YYYY-MM-DD'),
            startDate: formatDate(moment.utc().startOf('month'), 'YYYY-MM-DD'),
            endDate: formatDate(new Date(), 'YYYY-MM-DD'),
            type: label.toLocaleLowerCase(),
            accountId: accountid,

        }

        props.inventoryCount();
        props.filterByInvoicePrice(payload)
        getCount();
    }, [])



    const downloadInventory = (sold, invtype) => {
        const parseDetails = JSON.parse(userDetails);
        const id = parseDetails?.userId ? parseDetails?.userId : null;
        let data = {
            brand_id: '',
            brand_name: '',
            key: '',
            sold: sold,
            // inventory_status: this.state.inventoryStatus,
            inventoryxlid: '',
            inventory_type: '',
            inventory_status: invtype,
            purchasedate: '',
            userid: parseDetails?.userId,
            accountid: parseDetails?.accountId ? parseDetails?.accountId : null,
            type: '',
            category: '',
            fileType: 'EX'
        };
        // const inventoryDetails = this.props.SearchInventoryWatches(data, this.state.currentUser.userId);
        searchinventry.downloadDashboardInventory(data, id).then((res) => {
            if (res && res.status === 0) {
                toast("No Inventories for download.", {
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }
        });
    }
    const numberFormatter = (number) => {
        return amountFormatter(number);
    }
    function Row(props) {
        const { row, index } = props;

        return (
            <>

                <React.Fragment>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' }, maxHeight: '200px !important' }}>
                        <TableCell>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => { setAccordEnable(!AccordEnable); accordionOnChangeNew(row.name, index, !AccordEnable) }}
                            >
                                {uniqueAcc[index] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row" onClick={() => setAccordEnable(!AccordEnable)}>
                            {row.name}
                        </TableCell>
                        <TableCell align="center" onClick={() => setAccordEnable(!AccordEnable)}>{amountFormatter(row['Inventory Total'])}</TableCell>
                        <TableCell align="center" onClick={() => setAccordEnable(!AccordEnable)}>{amountFormatter(row['Invoice Total'])}</TableCell>
                        <TableCell align="center" onClick={() => setAccordEnable(!AccordEnable)}>{amountFormatter(Number(row['Profit']))}</TableCell>
                        <TableCell align="center" onClick={() => setAccordEnable(!AccordEnable)}>{amountFormatter(Number(row['Profit Percentage']))}</TableCell>
                    </TableRow>
                    <TableRow>

                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={uniqueAcc[index]} timeout="auto" unmountOnExit >
                                <Box sx={{ margin: 1 }}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Details
                                    </Typography>
                                    <Table size="small" aria-label="purchases" sx={{ maxHeight: 200 }}>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Invoice #</StyledTableCell>
                                                <StyledTableCell align="center">Invoice Date</StyledTableCell>
                                                <StyledTableCell align="center">Customer Name</StyledTableCell>
                                                <StyledTableCell align="center">Business Name</StyledTableCell>
                                                <StyledTableCell align="center">Invoice Amount ($)</StyledTableCell>
                                                {/* <StyledTableCell align="center">Tracking Number</StyledTableCell> */}
                                                <StyledTableCell align="center">Payment Status</StyledTableCell>
                                                {/* <StyledTableCell align="center">Payment Date</StyledTableCell> */}
                                                <StyledTableCell align="center">Balance</StyledTableCell>
                                                <StyledTableCell align="center">Profit</StyledTableCell>
                                                <StyledTableCell align="center">Profit %</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {AccordionData && AccordionData.length > 0 ?
                                                AccordionData?.map((value) => (
                                                    <TableRow key={value.id}>
                                                        <TableCell component="th" style={{ cursor: 'pointer' }} scope="row" onClick={() => {
                                                            globalInvoiceId.id = value.invoicenumber
                                                            setSelectedInvoice({ ...selectedInvoice, id: value.invoicenumber })
                                                            setviewInvoiceModal(!viewInvoiceModal)
                                                        }}>
                                                            {value.invoicenumber}
                                                        </TableCell>
                                                        {/* <TableCell align="center">{moment(value.createdon).format('MM/DD/YYYY')}</TableCell> */}
                                                        <TableCell align="center">{formatDate(value.createdon, 'MM/DD/YYYY')}</TableCell>
                                                        <TableCell align="center">{value.contactname}</TableCell>
                                                        <TableCell align="center">{value.businessname}</TableCell>
                                                        <TableCell align="center">
                                                            {amountFormatter(value.totalprice)}
                                                        </TableCell>
                                                        {/* <TableCell align="center">{value.trackingnumber}</TableCell> */}
                                                        <TableCell align="center">{value.paymentstatus}</TableCell>
                                                        {/* <TableCell align="center">
                              {value.paymentdate}
                            </TableCell> */}
                                                        <TableCell align="center">{amountFormatter(value.balance)}</TableCell>
                                                        <TableCell align="center">{value.profitdifrerence ? amountFormatter(Number(value.profitdifrerence)) : '-'}</TableCell>
                                                        <TableCell align="center">{value.profitpercentage ? amountFormatter(Number(value.profitpercentage)) : '-'}</TableCell>
                                                    </TableRow>
                                                )) : <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell>{accordionloader ? <CircularProgress></CircularProgress> : 'No records Found'}</TableCell>
                                                    <TableCell></TableCell>

                                                </TableRow>}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            </>
        );
    }

    useEffect(() => {
        if (props.invoicePriceInfo) {
            setloaded(false);
            const invoiceData = props.invoicePriceInfo.data;
            const filterBasedata = invoiceData.sort((a, b) => new Date(b.dates) - new Date(a.dates))
        }
        const structdata = structuredData(props.invoicePriceInfo)
        let arr = []
        for (let i = 0; i < structdata.length; i++) {
            arr[i] = false;
        }
        setuniqueAcc(arr)
        setdata(structdata)
    }, [props.invoicePriceInfo])

    useEffect(() => {
        if (props.dashboardaccordionInfo?.data?.length > 0 && AccordionData.length == 0) {
            setaccordionloader(false);
            setAccordionData(props.dashboardaccordionInfo.data);
        }
        else if (props.dashboardaccordionInfo?.data?.length == 0 && props.dashboardaccordionInfo?.status == 1) {
            setaccordionloader(false);
        }

    }, [props.dashboardaccordionInfo]
    )
    const chartApicall = async (selectedDate, duration) => {
        if (selectedDate && selectedDate) {
            setloaded(true)
            const payload = {
                startDate: stendDate.length > 0 ? stendDate && stendDate[0]?.format() : selectedDate && selectedDate[0],
                endDate: stendDate.length > 0 ? (stendDate[1]?.format() ? stendDate[1]?.format() : stendDate[0]?.format()) : selectedDate[1] ? selectedDate[1] : selectedDate[0],
                type: duration ? duration : "year",
                accountId: accountid,

            }
            if (duration == 'month') {
                setcount('month');
            }
            else if (duration == 'week') {
                setcount('week');
                setpayloadtrack1(payload)
            }
            else if (duration == 'days') {
                setcount('days');
                setpayloadtrack2(payload)
            }

            await props.filterByInvoicePrice(payload)
        }

    }
    // const DateApicall = async (selectedDate, duration) => {
    //     if (selectedDate && selectedDate.length == 2) {
    //         setloaded(true)
    //         setstendDate(selectedDate)
    //         const payload = {
    //             startDate: selectedDate && selectedDate[0].format(),
    //             endDate: selectedDate[1].format(),
    //             type: duration ? duration : "year"

    //         }
    //         await props.filterByInvoicePrice(payload)
    //     }

    // }

    const DateApicall = async (selectedDate, duration) => {
        if (selectedDate && selectedDate) {
            const type = calculateLabelFormat(selectedDate)
            setloaded(true)
            setselectedDate([selectedDate[0]?.format(), selectedDate[1] ? selectedDate[1]?.format() : null])
            setstendDate(selectedDate)
            const payload = {
                startDate: selectedDate && selectedDate[0]?.format(),
                endDate: selectedDate[1] ? selectedDate[1]?.format() : selectedDate[0]?.format(),
                type: duration ? duration : type,
                accountId: accountid,

            }
            await props.filterByInvoicePrice(payload)
        }

    }
    function isYearOrDate(value) {
        const yearRegex = /^\d{4}$/;
        if (yearRegex.test(value)) {
            return 'Year';
        }
        return 'Unknown';
    }

    const BackTracking = async (value) => {
        setloaded(true)
        if (count == 'days') {
            //validation check
            const payload = {
                startDate: payloadtrack3 ? payloadtrack3.startDate : payloadtrack2.startDate,
                endDate: payloadtrack3 ? payloadtrack3.endDate : payloadtrack2.endDate,
                type: 'week',
                accountId: accountid,
            }
            setduration('week')
            setcount('week')
            setselectedDate([payload.startDate, payload.endDate])
            await props.filterByInvoicePrice(payload)
        }
        else if (count == 'week') {
            const payload = {
                // startDate: payloadtrack1 ? payloadtrack1.startDate : payloadtrack2 ? payloadtrack2.startDate : moment().startOf('month').format('YYYY-MM-DD'),
                // endDate: payloadtrack1.endDate ? payloadtrack1.endDate : payloadtrack2 ? payloadtrack2.endDate : moment(new Date()).format('YYYY-MM-DD'),
                startDate: payloadtrack1 ? payloadtrack1.startDate : payloadtrack2 ? payloadtrack2.startDate : formatDate(moment.utc().startOf('month'), 'YYYY-MM-DD'),
                endDate: payloadtrack1.endDate ? payloadtrack1.endDate : payloadtrack2 ? payloadtrack2.endDate : formatDate(new Date(), 'YYYY-MM-DD'),
                type: 'month',
                accountId: accountid,
            }
            setduration('month')
            setcount('month')
            setselectedDate([payload.startDate, payload.endDate])
            await props.filterByInvoicePrice(payload)
        }
        else if (count == 'month') {
            const payload = {
                // startDate: moment().startOf('year').format('YYYY-MM-DD'),
                // endDate: moment.utc(new Date()).format('YYYY-MM-DD'),
                startDate: formatDate(moment.utc().startOf('year'), 'YYYY-MM-DD'),
                endDate: formatDate(new Date(), 'YYYY-MM-DD'),
                type: 'year',
                accountId: accountid,
            }
            setduration('year')
            setcount('year')
            setselectedDate([payload.startDate, payload.endDate])
            await props.filterByInvoicePrice(payload)
        }
    }
    const BarChartchange = async (value) => {
        const validation = isYearOrDate(value.name);
        const monthFormat = ['Jan', 'Feb', "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const splitvalues = value.name.split(' ')
        if (validation == 'Year') {
            setloaded(true)
            setcount("month")
            const payload = {
                // startDate: moment(new Date(value.name, 0, 1)).format('YYYY-MM-DD'),
                // endDate: moment(new Date(value.name, 11, 31)).format('YYYY-MM-DD'),
                startDate: formatDate(new Date(value.name, 0, 1), 'YYYY-MM-DD'),
                endDate: formatDate(new Date(value.name, 11, 31), 'YYYY-MM-DD'),
                type: 'month',
                accountId: accountid,
            }
            setduration('month')
            const previouspayload = {
                startDate: selectedDate && selectedDate[0],
                endDate: selectedDate && selectedDate[1],
            }
            setpayloadtrack1(previouspayload)
            setselectedDate([payload.startDate, payload.endDate])
            await props.filterByInvoicePrice(payload)
        }
        else if (monthFormat.includes(splitvalues[0])) {
            setloaded(true)
            setcount('week')
            const month = formatDate(value.name, 'MM');
            const year = formatDate(value.name, 'YYYY');
            // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
            const start = moment.utc([year, month - 1]);
            const end = moment.utc(start).endOf('month');
            const payload = {
                startDate: formatDate(new Date(start), 'YYYY-MM-DD'),
                endDate: formatDate(new Date(end), 'YYYY-MM-DD'),
                type: 'week',
                accountId: accountid,
            }
            const previouspayload = {
                startDate: selectedDate && selectedDate[0],
                endDate: selectedDate && selectedDate[1],
            }
            setpayloadtrack2(previouspayload)
            setduration('week')
            setselectedDate([payload.startDate, payload.endDate])
            await props.filterByInvoicePrice(payload)
        }
        else if (['Week'].includes(splitvalues[0])) {
            setloaded(true)
            setcount("days")
            // const splitString = splitvalues[1].substring(1, splitvalues[1].length - 1);
            // const month = moment(splitString).format('MM');
            // const year = moment(splitString).format('YYYY');
            // const start = moment([year, month - 1]);
            // const end = moment(start).endOf('month');
            // const ToDate = moment(splitString).add(7, 'days');
            const splitString = splitvalues[1].substring(1, splitvalues[1].length - 1);
            const month = formatDate(splitString, 'MM');
            const year = formatDate(splitString, 'YYYY');
            const start = moment.utc([year, month - 1]);
            const end = moment.utc(start).endOf('month');
            const ToDate = moment.utc(splitString).add(7, 'days');
            const payload = {
                startDate: formatDate(new Date(splitString), 'YYYY-MM-DD'),
                endDate: formatDate(new Date(ToDate), 'YYYY-MM-DD'),
                type: 'day',
                accountId: accountid,
            }
            const previouspayload = {
                startDate: selectedDate && selectedDate[0],
                endDate: selectedDate && selectedDate[1],
            }
            setpayloadtrack3(previouspayload)
            setduration('days')
            setselectedDate([payload.startDate, payload.endDate])
            await props.filterByInvoicePrice(payload)
        }
        // setloaded(false);
    }
    function destructedStatus(data) {
        const values = data.map((value) => {
            let properties = {};
            properties['InStock'] = value.instock_count;
            properties['Sold'] = value.sold_count;
            properties['Void'] = value.void_count;

            return properties
        })
        settotalInv(data && data[0])
        setcountData(values)
        const unsolddata = data.map((value) => {
            let properties = {};
            properties['Inhand'] = value?.inventorystatus_inhand;
            properties['Incoming'] = value?.inventorystatus_incoming;
            properties['Out on Memo'] = value?.inventorystatus_out_to_memo;
            properties['On Hold/Reserved'] = value?.inventorystatus_onhold;
            properties['Available to order'] = value?.inventorystatus_availableto_order;
            properties['Not Assigned'] = value?.inventorystatus_notassigned;
            properties['Need Service/Polish'] = value?.inventorystatus_needservice_polish;
            properties['At Service'] = value?.inventorystatus_atservice;
            properties['Need to Ship'] = value?.inventorystatus_needship;
            properties['Shipped waiting on payment'] = value?.inventorystatus_shipping_wating;

            return properties
        })
        setunsoldStatus(unsolddata)

    }
    function getCount() {
        const payload = {
            accountid: accountid
        }
        addinvetrysearchdataa.dashboardInventoryCount(payload).then((res) => {
            if (res.data.success === 1) {
                destructedStatus(res.data.data)
                // setcountData(res.data.data)
            } else if (res.data.success === 0) {
                setloaded(true);
                window.location.href = '/login';

            }
        }
        );
    }
    const calculateLabelFormat = (input = null) => {
        let payload = {}
        if (input) {
            payload = {
                startDate: input && input[0]?.format(),
                endDate: input && input[1] ? input[1]?.format() : input[0]?.format()
            }
        }
        else {
            payload = {
                startDate: selectedDate && selectedDate[0],
                endDate: selectedDate && selectedDate[1] ? selectedDate[1] : selectedDate[0]
            }
        }
        let label = 'Days'
        if (payload.startDate && payload.endDate) {
            const a = payload?.startDate;
            const b = payload?.endDate;
            let startDate = moment.utc(a);
            const endDate = moment.utc(b);
            const differentDays = endDate.diff(startDate, 'days');

            if (differentDays <= 7) {
                label = 'Days'
            } else if (differentDays > 7 && differentDays <= 31) {
                label = 'Week'
            } else if (differentDays > 31 && differentDays <= 365) {
                label = 'Month'
            } else if (differentDays > 365) {
                label = 'Year'
            }
        }
        return label
    }
    const downloadChartTotal = (type) => {
        const payload = {
            startDate: selectedDate && selectedDate[0],
            endDate: selectedDate && selectedDate[1] ? selectedDate[1] : selectedDate[0],
            type: duration ? duration : calculateLabelFormat(),
            accountId: accountid
        }
        setDownloadloading(true)
        downloadcharts(
            payload
        ).then((res) => {
            setDownloadloading(false)
            if (res && res.status === 0) {
                toast(res.message || 'Something went wrong', {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }
        });
    }
    const structuredData = (dayBasedReport) => {
        const labelFormat = duration ? duration : calculateLabelFormat();
        if (labelFormat?.toLowerCase() === 'days') {
            const values = dayBasedReport.data.map((value, index) => {
                let properties = {};
                // const day = moment(value.dates).format("ddd")
                // const date = moment(value.dates).format("YYYY-MM-DD")
                const day = formatDate(value.dates, "ddd");
                const date = formatDate(value.dates, "YYYY-MM-DD");
                properties['name'] = `${day} ${date}`
                properties['Profit'] = (value.profitdifrerence) ? (Number(value.profitdifrerence)) : 0.00
                properties['Profit Percentage'] = value.profitpercentage == 0.00 ? 0.00 : (Number(value.profitpercentage))
                properties['Invoice Total'] = (value.invoicetotal)
                properties['Inventory Total'] = (value.inventorytotal)
                return properties
            })
            return values
        }

        else if (labelFormat?.toLocaleLowerCase() === 'week') {
            const values = dayBasedReport.data.map((value, index) => {
                let properties = {};
                const date = formatDate(value.dates, "YYYY-MM-DD");
                properties['name'] = `Week (${date})`
                properties['Invoice Total'] = value.invoicetotal
                properties['Profit'] = (value.profitdifrerence) ? Number(value.profitdifrerence) : 0.00
                properties['Profit Percentage'] = value.profitpercentage == 0.00 ? 0.00 : Number(value.profitpercentage)
                properties['Inventory Total'] = value.inventorytotal
                return properties
            })
            return values
        }
        else if (labelFormat?.toLocaleLowerCase() === 'month') {
            const values = dayBasedReport.data.map((value, index) => {
                let properties = {};
                // const id = index % 12;
                const month = formatDate(value.dates, "MMM")
                const year = formatDate(value.dates, "YYYY")
                properties['name'] = `${month} ${year}`
                properties['Profit'] = (value.profitdifrerence) ? Number(value.profitdifrerence) : 0.00
                properties['Profit Percentage'] = value.profitpercentage == 0.00 ? 0.00 : Number(value.profitpercentage)
                properties['Invoice Total'] = value.invoicetotal
                properties['Inventory Total'] = value.inventorytotal
                return properties
            })
            return values
        } else if (labelFormat?.toLocaleLowerCase() === 'year') {
            const values = dayBasedReport.data.map((value, index) => {
                let properties = {};

                properties['name'] = formatDate(value.dates, "YYYY");
                properties['Profit'] = (value.profitdifrerence) ? Number(value.profitdifrerence) : 0.00
                properties['Profit Percentage'] = value.profitpercentage == 0.00 ? 0.00 : Number(value.profitpercentage)
                properties['Invoice Total'] = value.invoicetotal
                properties['Inventory Total'] = value.inventorytotal
                return properties
            })
            return values
        }


    }

    const handleChange = (el) => {
        setduration(el.target.value);
        chartApicall(selectedDate, el.target.value)
    }
    function CustomInput({ onFocus, value, onChange }) {
        return (
            <TextField
                id="outlined-basic"
                label="Start Date to End Date"
                variant="outlined"
                onFocus={onFocus}
                placeholder="from MM/DD/YYY to MM/DD/YYYY"
                value={value}
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CalendarMonthIcon />
                        </InputAdornment>
                    ),
                }}
            />
        )
    }
    const accordionOnChange = async (value, index, enable) => {

        if (enable) {
            setaccordionloader(true);
            const newArr = [];
            for (let i = 0; i < uniqueAcc.length; i++) {
                if (i === index) {
                    newArr[i] = true
                }
                else {
                    newArr[i] = false
                }
                //newArr.push(uniqueAcc[i]);
            }
            setuniqueAcc(newArr)
            const validation = isYearOrDate(value);
            const monthFormat = ['Jan', 'Feb', "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            const dayFormat = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            const splitvalues = value.split(' ');
            const parseDetails = JSON.parse(userDetails);
            setAccordionData([])
            if (validation == 'Year') {

                const payload = {
                    invoiceDateDetails: {
                        from: formatDate(new Date(value, 0, 1), 'YYYY/MM/DD'),
                        to: formatDate(new Date(value, 11, 31), 'YYYY/MM/DD'),
                    },
                    accountId: parseDetails?.accountId,
                    inventoryType: null
                }

                await props.filterBydboardAccordion(payload)
            }
            else if (monthFormat.includes(splitvalues[0])) {

                const month = formatDate(value, 'MM');
                const year = formatDate(value, 'YYYY');
                // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
                const start = moment.utc([year, month - 1]);
                const end = moment.utc(start).endOf('month');
                const payload = {
                    invoiceDateDetails: {
                        from: formatDate(new Date(start), 'YYYY/MM/DD'),
                        to: formatDate(new Date(end), 'YYYY/MM/DD'),
                    },
                    accountId: parseDetails?.accountId,
                    inventoryType: null
                }
                await props.filterBydboardAccordion(payload)
            }
            else if (['Week'].includes(splitvalues[0])) {
                const splitString = splitvalues[1].substring(1, splitvalues[1].length - 1);
                const month = formatDate(splitString, 'MM');
                const year = formatDate(splitString, 'YYYY');
                const start = moment.utc([year, month - 1]);
                const ToDate = moment.utc(splitString).add(7, 'days');
                const payload = {
                    invoiceDateDetails: {
                        from: formatDate(new Date(splitString), 'YYYY/MM/DD'),
                        to: formatDate(new Date(ToDate), 'YYYY/MM/DD'),
                    },
                    accountId: parseDetails?.accountId,
                    inventoryType: null
                }
                await props.filterBydboardAccordion(payload)
            }
            else if (dayFormat.includes(splitvalues[0])) {

                const payload = {
                    invoiceDateDetails: {
                        from: formatDate(new Date(splitvalues[1]), 'YYYY/MM/DD'),
                        to: formatDate(new Date(splitvalues[1]), 'YYYY/MM/DD'),
                    },
                    accountId: parseDetails?.accountId,
                    inventoryType: null
                }
                await props.filterBydboardAccordion(payload)
            }
        }
        else {
            setAccordionData([])
            let newArr = []
            for (let i = 0; i < uniqueAcc.length; i++) {
                if (i === index) {
                    newArr[i] = false
                }
                else {
                    newArr[i] = false
                }
                //newArr.push(uniqueAcc[i]);
            }
            setuniqueAcc(newArr)
        }
    }

    const performAPIOperation = async (value) => {
        setaccordionloader(true);
        const validation = isYearOrDate(value);
        const monthFormat = ['Jan', 'Feb', "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const dayFormat = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        const splitvalues = value.split(' ');
        const parseDetails = JSON.parse(userDetails);
        setAccordionData([])
        if (validation == 'Year') {

            const payload = {
                invoiceDateDetails: {
                    from: formatDate(new Date(value, 0, 1), 'YYYY/MM/DD'),
                    to: formatDate(new Date(value, 11, 31), 'YYYY/MM/DD'),
                },
                accountId: accountid,
                inventoryType: null,
                dashboardInvoiceStatus: 'Active',
            }

            await props.filterBydboardAccordion(payload)
        }

        else if (monthFormat.includes(splitvalues[0])) {

            const month = formatDate(value, 'MM');
            const year = formatDate(value, 'YYYY');
            // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
            const start = moment.utc([year, month - 1]);
            const end = moment.utc(start).endOf('month');
            const payload = {
                invoiceDateDetails: {
                    from: formatDate(new Date(start), 'YYYY/MM/DD'),
                    to: formatDate(new Date(end), 'YYYY/MM/DD'),
                },
                accountId: accountid,
                inventoryType: null,
                dashboardInvoiceStatus: 'Active',
            }
            await props.filterBydboardAccordion(payload)
        }
        else if (['Week'].includes(splitvalues[0])) {
            const splitString = splitvalues[1].substring(1, splitvalues[1].length - 1);
            const month = formatDate(splitString, 'MM');
            const year = formatDate(splitString, 'YYYY');

            const start = moment.utc([year, month - 1]);
            const ToDate = moment.utc(splitString).add(7, 'days');
            const payload = {
                invoiceDateDetails: {
                    from: formatDate(new Date(splitString), 'YYYY/MM/DD'),
                    to: formatDate(new Date(ToDate), 'YYYY/MM/DD'),
                },
                accountId: accountid,
                inventoryType: null,
                dashboardInvoiceStatus: 'Active',
            }
            await props.filterBydboardAccordion(payload)
        }
        else if (dayFormat.includes(splitvalues[0])) {
            const payload = {
                invoiceDateDetails: {
                    from: formatDate(new Date(splitvalues[1]), 'YYYY/MM/DD'),
                    to: formatDate(new Date(splitvalues[1]), 'YYYY/MM/DD'),
                },
                accountId: accountid,
                inventoryType: null,
                dashboardInvoiceStatus: 'Active',
            }
            await props.filterBydboardAccordion(payload)
        }

    }


    const accordionOnChangeNew = async (value, index, enable) => {
        const checkAccortianAlreadyOpen = uniqueAcc.find((data, i) => { return i === index })
        if (checkAccortianAlreadyOpen) {
            const newUniqueArray = [...uniqueAcc];
            newUniqueArray[index] = false;
            setuniqueAcc(newUniqueArray)
        } else {
            let existingIndex = null;
            const result = uniqueAcc.find((data, i) => {
                if (data === true) {
                    existingIndex = i;
                    return i
                }
            })
            const newUniqueArray = [...uniqueAcc];
            if (existingIndex !== null) {
                newUniqueArray[existingIndex] = false
            }
            newUniqueArray[index] = true;
            setuniqueAcc(newUniqueArray)
            performAPIOperation(value)
        }
    }
    const handleCloseAction = () => {
        setviewInvoiceModal(false)
        setSelectedInvoice(null);
    }
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {

            return (
                <div className="custom-tooltip" style={{ border: '1px solid black', padding: '10px' }}>
                    <p className="label" >{`${label}`}</p>
                    <p className="label" style={{ color: payload[0].color }}>{`${payload[0].dataKey} : ${amountFormatter(payload[0].payload.Profit)}`}</p>
                    <p className="label" style={{ color: payload[1].color }}>{`${payload[1].dataKey} : ${amountFormatter(payload[1].payload['Profit Percentage'])}`}</p>
                    <p className="label" style={{ color: payload[2].color }}>{`${payload[2].dataKey} : ${amountFormatter(payload[2].payload['Invoice Total'])}`}</p>
                    <p className="label" style={{ color: payload[3].color }}>{`${payload[3].dataKey} : ${amountFormatter(payload[3].payload['Inventory Total'])}`}</p>

                </div>
            );


        }

        return null;
    };
    return (
        <div className="">
            <Container>
                <Grid container spacing={2}>
                    <Grid container item xs={12} sm={12} md={12}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card>
                                <Grid container style={{ padding: '10px' }}>
                                    <Grid item md={6}>
                                        <Typography variant="h6" style={{ fontSize: '16px', marginBottom: '25px' }} className="text-center1 font-weight-600 color-383838">Inventory Details</Typography>
                                        <Typography variant="h6" style={{ fontSize: '15px' }} className="text-center1 font-weight-100 color-383838">Total Inventory: {totalInv.inventory_count}</Typography>
                                        <Typography variant="h6" style={{ fontSize: '15px' }} className="text-center1 font-weight-100 color-383838">Total Inventory Price: $ {amountFormatter(totalInv?.totalinventoryprice)}</Typography>

                                    </Grid>

                                    <Grid item md={5}>
                                        <CustomizedMenus downloadInventory={(data, value) => downloadInventory(data, value)} ></CustomizedMenus>
                                    </Grid>
                                </Grid>
                                <Example data={countData} totalInventoryCount={totalInv} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card style={{ marginLeft: "20px" }}>
                                <Grid container style={{ padding: '10px' }}>
                                    <Grid item md={7}>
                                        <Typography variant="h6" style={{ fontSize: '16px' }} className="text-center1 font-weight-500 color-383838">Unsold Inventory based on the Inventory Status</Typography>
                                        <Typography variant="h6" style={{ fontSize: '15px' }} className="text-center1 font-weight-100 color-383838">Total Unsold Inventory: {totalInv.instock_count}</Typography>
                                        <Typography variant="h6" style={{ fontSize: '15px' }} className="text-center1 font-weight-100 color-383838">Total Unsold Inventory Price: $ {amountFormatter(totalInv?.instock_inventoryprice)}</Typography>
                                    </Grid>

                                    <Grid item md={4}>
                                        <CustomizedDownloadText downloadInventory={(data, value) => downloadInventory(data, value)} ></CustomizedDownloadText>
                                    </Grid>
                                </Grid>
                                <Example data={unsoldStatus} totalInventoryCount={totalInv} />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Card className="space-marign-20">
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>


                                        <Paper style={{ padding: 20 }}>
                                            <Grid container spacing={1} style={{ marginBottom: 20 }}>
                                                <Grid item md={2} style={{ fontSize: 'large' }}> Sales and Profits</Grid>
                                                <Grid item md={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Button
                                                        id="demo-customized-button"
                                                        aria-haspopup="true"
                                                        variant='outlined'
                                                        disableElevation
                                                        // classes={{disabled: count =='year' ? classes.root : null}}
                                                        classes={{ disabled: classes.root }}
                                                        // disabled={true}
                                                        disabled={count == 'year' ? true : false}
                                                        style={{ marginRight: 20 }}
                                                        startIcon={<ArrowBackIcon />}
                                                        // endIcon={Downloadloading ? <CircularProgress style={{ width: 22, height: 22, color: '#fff' }} /> : <DownloadIcon/>}
                                                        onClick={() => {
                                                            BackTracking()
                                                        }}

                                                        className={
                                                            "text-capitalize primary-bg color-white"
                                                        }

                                                    // style={{ marginLeft: 20 }}

                                                    >
                                                        Back
                                                    </Button>
                                                    <FormControl variant='outlined' style={{ width: '20%', marginRight: 20 }}>
                                                        <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={duration}
                                                            label="Age"
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value={"days"}>Days</MenuItem>
                                                            <MenuItem value={"month"}>Month</MenuItem>
                                                            <MenuItem value={"week"}>Week</MenuItem>
                                                            <MenuItem value={"year"}>Year</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <Grid style={{ marginRight: '20px' }}>
                                                        <DatePicker
                                                            range
                                                            render={<CustomInput />}
                                                            value={selectedDate}
                                                            onChange={dateObjects => {
                                                                setselectedDate(dateObjects);
                                                                DateApicall(dateObjects, duration)
                                                            }}

                                                            rangeHover
                                                            dateSeparator=" to "
                                                            plugins={[
                                                                <DatePanel />
                                                            ]}
                                                        />
                                                    </Grid>


                                                    <ToggleButtonGroup
                                                        value={toggleValue}
                                                        color="error"
                                                        exclusive
                                                        onChange={(e) => { setToggleValue(e.target.value) }}
                                                        aria-label="Platform"

                                                    >
                                                        <ToggleButton value="CHART" sx={{ width: "100px" }}>CHART</ToggleButton>
                                                        <ToggleButton value="TABLE" sx={{ width: "100px" }}>TABLE</ToggleButton>
                                                    </ToggleButtonGroup>
                                                    <Button
                                                        id="demo-customized-button"
                                                        // classes={{disabled:classes.root}}
                                                        // aria-controls={openButton ? 'demo-customized-menu' : undefined}
                                                        aria-haspopup="true"
                                                        // aria-expanded={openButton ? 'true' : undefined}
                                                        variant="contained"
                                                        disableElevation
                                                        endIcon={Downloadloading ? <CircularProgress style={{ width: 22, height: 22, color: '#fff' }} /> : <DownloadIcon />}
                                                        onClick={() => {
                                                            downloadChartTotal()
                                                        }}

                                                        className={
                                                            "text-capitalize primary-bg color-white"
                                                        }

                                                        style={{ marginLeft: 20 }}

                                                    >
                                                        Download
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            {loaded ?
                                                <div class="d-spinner">
                                                    <SpinnerRoundFilled
                                                        size={51}
                                                        thickness={125}
                                                        speed={80}
                                                        color="rgba(57, 107, 172, 1)"
                                                        className="spp"
                                                        enabled={loaded}
                                                    />
                                                </div> :
                                                toggleValue == 'CHART' ?

                                                    <ComposedChart

                                                        width={1100}
                                                        height={500}
                                                        data={data}
                                                        margin={{
                                                            top: 20,
                                                            right: 20,
                                                            bottom: 20,
                                                            left: 20,
                                                        }}
                                                        style={{ marginBottom: '20px' }}
                                                    >
                                                        <CartesianGrid stroke="#f5f5f5" />
                                                        <XAxis dataKey="name" va label={{ value: '', position: 'insideBottomRight', offset: 0 }} scale="band" tick={<CustomizedAxisTick />} />

                                                        <YAxis tickFormatter={numberFormatter} data label={{ value: '', angle: -90, position: 'insideLeft' }} />
                                                        <Tooltip content={<CustomTooltip />} />
                                                        {/* <Tooltip /> */}
                                                        <Legend verticalAlign="top" />
                                                        <Line type='monotone' dataKey="Profit" stroke="#ff7300" />
                                                        <Line type='monotone' dataKey="Profit Percentage" stroke="" />

                                                        {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                                                        <Bar type='monotone' style={{ cursor: 'pointer' }} onClick={(e) => BarChartchange(e)} dataKey="Invoice Total" barSize={20} fill="#87CEEB" />
                                                        <Bar type='monotone' style={{ cursor: 'pointer' }} dataKey="Inventory Total" onClick={(e) => BarChartchange(e)} barSize={20} fill="#90EE90" />
                                                        {/* <Bar type='monotone' dataKey="profit" barSize={20} fill="#82ca9d" /> */}
                                                    </ComposedChart>
                                                    :













                                                    //---------------------------------------
                                                    <Table sx={{ minWidth: 700, marginTop: '20px' }} aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell style={{ width: '7%' }}></StyledTableCell>
                                                                <StyledTableCell>Date</StyledTableCell>
                                                                <StyledTableCell align="center">COGS(cost of goods sold)</StyledTableCell>
                                                                <StyledTableCell align="center">Invoice Total</StyledTableCell>
                                                                <StyledTableCell align="center">Profit</StyledTableCell>
                                                                <StyledTableCell align="center">Profit %</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {data.map((row, index) => (
                                                                <Row key={row.name} row={row} index={index} />
                                                            ))}

                                                        </TableBody>
                                                    </Table>
                                            }
                                        </Paper>
                                        {viewInvoiceModal && (
                                            <ViewInvoicemodel
                                                open={viewInvoiceModal}
                                                handleClose={handleCloseAction}
                                                invoiceid={selectedInvoice}

                                            />
                                        )}                      </Grid>
                                </Grid>
                            </div>
                        </Card>
                    </Grid>

                </Grid>
            </Container>
            <div style={{ clear: 'both' }}></div>
        </div>
    );

}


function mapStateToProps(state) {
    const { browseWatchesInfo } = state
    return {
        // invetoryCountInfo: InventoryList.invetoryCountInfo,
        invoicePriceInfo: browseWatchesInfo.invoicePriceInfo,
        dashboardaccordionInfo: browseWatchesInfo.dashboardaccordionInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        inventoryCount,
        filterByInvoicePrice,
        filterBydboardAccordion,

    },
        dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardChart);
