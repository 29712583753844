import React, { useState, useEffect } from "react";
import {
    Grid,
    Container,
    Typography,
    Paper,
    TableContainer,
    Link,
    InputAdornment,
    IconButton,
    InputBase,
    FormControl,
    TextField,
    Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import {
    Table,
    TableBody,
    TableRow,
    TableHead,
    TablePagination,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { dealerCommunicationTackingList } from "./actions/administration";
import { connect } from "react-redux";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { SpinnerRoundFilled } from "spinners-react";
import constants from "../../../utils/constants";
import { formatDate } from "../../../common/components/Helper/Utils";

const columns = [
    { id: "modelnumber", label: "Product", minWidth: 100 },
    // { id: "username", label: "Name", minWidth: 100 },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "accountname", label: "Business Name", minWidth: 100 },
    { id: "contactedaccountname", label: "Contacted Account", minWidth: 100 },
    { id: "communication_type", label: "Communication Channel", minWidth: 100 },
    { id: "from_source", label: "Accessed From", minWidth: 100 },
    { id: "createdon", label: "Accessed Date", minWidth: 100 },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderBottom: "none",
        padding: 0,
    },
}));

const CustomTypography = styled(TableCell)({
    color: "#3f51b5 !important",
    textDecoration: "none",
    "&:hover": {
        textDecoration: "underline",
    },
    borderBottom: "none",
    padding: 0,
});

function CustomInput({ onFocus, value, onChange }) {
    return (
        <TextField
            id="outlined-basic"
            label="Accessed Date"
            variant="outlined"
            onFocus={onFocus}
            classes={{}}
            style={{ width: "100%" }}
            placeholder="from YYYY/MM/DD to YYYY/MM/DD"
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <CalendarMonthIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
}

const DealerCommunicationTracking = () => {
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [TrackingDateValue, setTrackingDateValue] = useState([]);
    const [selectedTrackingDate, setSelectedTrackingDate] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getDealerCommunicationTackingList();
    }, []);

    useEffect(() => {
        if (TrackingDateValue && TrackingDateValue.length === 2) {
            setSelectedTrackingDate(TrackingDateValue);
            getDealerCommunicationTackingList(TrackingDateValue);
        }
        if (
            TrackingDateValue?.length === 0 &&
            selectedTrackingDate &&
            selectedTrackingDate.length === 2
        ) {
            setSelectedTrackingDate([]);
            getDealerCommunicationTackingList("clear");
        }
    }, [TrackingDateValue]);

    const getDealerCommunicationTackingList = (from = null) => {
        setLoader(true);
        const dashboardData = {
            search: from === "clear" ? "" : searchKeyword.trim(),
        };
        if (Array.isArray(from) && from?.length > 0) {
            dashboardData["userCreatedInterval"] = {
                from: from[0].format(),
                to: from[1].format(),
            };
        }
        dealerCommunicationTackingList(dashboardData, (err, response) => {
            if (response?.status == 1) {
                setData(response.data);
                setLoader(false);
            } else {
                setLoader(false);
            }
            if (err) {
                setLoader(false);
                console.log("ERROR", err);
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRedirect = (inventoryId = "") => {
        let baseURL = constants.SHARE_INVENTORY;
        window.open(
            baseURL + `dealerinventory/inventory-view/${inventoryId}`,
            "_blank"
        );
    };

    const handleEnterEvent = (event) => {
        if (event.key == "Enter") {
            getDealerCommunicationTackingList();
        }
    };

    const handleClearButton = () => {
        setSearchKeyword("");
        getDealerCommunicationTackingList("clear");
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const endAdornment = () => {
        const values = searchKeyword;
        if (values) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleClearButton}
                        onMouseDown={handleMouseDown}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={getDealerCommunicationTackingList}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        } else {
            return (
                <InputAdornment position="end">
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={getDealerCommunicationTackingList}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        }
    };

    return (
        <div className="space-bottom-30 someextrahight">
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Dealer Tracking</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            range
                            render={<CustomInput />}
                            value={TrackingDateValue}
                            containerStyle={{ display: "block" }}
                            onChange={(dateObjects) => {
                                setTrackingDateValue(dateObjects);
                            }}
                            rangeHover
                            dateSeparator=" to "
                            plugins={[<DatePanel />]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl className="form-width-99">
                            <div style={{ marginLeft: "5px" }}>
                                <Paper
                                    sx={{ p: "2px 0px" }}
                                    style={{ height: "55px", display: "flex", width: "100%" }}
                                >
                                    <InputBase
                                        fullWidth
                                        style={{ flex: 1, marginLeft: "20px" }}
                                        inputProps={{
                                            "aria-label": "Search by Name / Email",
                                        }}
                                        placeholder="Search by Business Name / Email"
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value);
                                        }}
                                        onKeyPress={handleEnterEvent}
                                        value={searchKeyword}
                                        endAdornment={endAdornment()}
                                    />
                                </Paper>
                            </div>
                        </FormControl>
                    </Grid>
                    {loader ? (
                        <div className="d-spinner">
                            <SpinnerRoundFilled
                                size={51}
                                thickness={125}
                                speed={80}
                                color="rgba(57, 107, 172, 1)"
                                className="spp"
                                enabled={loader}
                            />
                        </div>
                    ) : (
                        <Grid item xs={12}>
                            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <StyledTableCell key={column.id} align={column.align}>
                                                        {column.label}
                                                    </StyledTableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data && data.length ? (
                                                <>
                                                    {data
                                                        .slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage
                                                        )
                                                        .map((row) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                >
                                                                    {columns.map((column) => {
                                                                        const value = row[column.id];
                                                                        return (
                                                                            <TableCell
                                                                                key={column.id}
                                                                                align={column.align}
                                                                            >
                                                                                {column.id === "modelnumber" ? (
                                                                                    <Link
                                                                                        component="button"
                                                                                        onClick={() =>
                                                                                            handleRedirect(row.inventoryid)
                                                                                        }
                                                                                    >
                                                                                        <StyledTableCell>
                                                                                            <Tooltip title="Click to view">
                                                                                                <CustomTypography>
                                                                                                    <b>{value ?? ""}</b>
                                                                                                </CustomTypography>
                                                                                            </Tooltip>
                                                                                        </StyledTableCell>
                                                                                    </Link>
                                                                                ) : column.id === "createdon" ? (
                                                                                    <StyledTableCell>
                                                                                        {/* {row.createdon
                                                                                            ? moment(row.createdon).format(
                                                                                                "MM/DD/YYYY"
                                                                                            )
                                                                                            : ""} */}
                                                                                        {row.createdon
                                                                                            ? formatDate(row.createdon, "MM/DD/YYYY")
                                                                                            : ""}
                                                                                    </StyledTableCell>
                                                                                ) : (
                                                                                    <StyledTableCell>
                                                                                        {value ?? ""}
                                                                                    </StyledTableCell>
                                                                                )}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                </>
                                            ) : (
                                                <TableCell colspan={12} style={{ textAlign: "center" }}>
                                                    <Typography variant="h6" className="color-BCBCBC">
                                                        No Records Found
                                                    </Typography>
                                                </TableCell>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={data.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </div>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DealerCommunicationTracking);
