import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validator from "validator";
import ContentWrapper from "../../../../common/components/contentWrapper";
import HorizontalLabelPositionBelowStepper from "./dealerStepperComponent";
import HorizontalLabelPositionBelowStepperWith from "./dealerBusinessNameStepperComponent";
import { getuser } from "./../../dashboard/actions/dashboardAction";
import "../style/dealer.css";

class DealerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
    };
  }

  componentWillReceiveProps(newProps) {
    // if (newProps.currentUser && !this.state.currentUser) {
    //   this.setState({
    //     ...this.state,
    //     currentUser: newProps.currentUser,
    //   });
      const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"))
      if(userDetails?.userId){
        this.props.getuser(userDetails.userId);
      }
    // }
  }

  getContentView() {
    let userInfoDatausername = "";
    if (this.props.userInfo) {
      userInfoDatausername =
        this.props.userInfo.data && this.props.userInfo.data;
    // const detalsuserdata = this.props.userInfo
    // localStorage.setItem('UserBusinessDetailssomething', JSON.stringify(detalsuserdata));
       
    }
    const detalsuserdata = this.props.userInfo
    if(detalsuserdata){
      localStorage.setItem('UserBusinessDetails', JSON.stringify(detalsuserdata));
    }
    return (
    
      <div className= {"space-top-30" + (this.props.currentUser==null ? ' whitebackground' : '')}>
        {/* {userInfoDatausername ? (
          <div>
            {userInfoDatausername ? (
              <div>
                <HorizontalLabelPositionBelowStepperWith
                  history={this.props.history}
                />
              </div>
            ) : (
              <div>
                <HorizontalLabelPositionBelowStepper
                  history={this.props.history}
                />
              </div>
            )}
          </div>
        ) : ( */}
          <div className= "">
                <HorizontalLabelPositionBelowStepper
                  history={this.props.history}
                />
              </div>
        {/* )} */}
      </div>
    );
  }

  render() {
    localStorage.setItem("menuTitle", "SUBSCRIPTION");
    return <ContentWrapper contentView={this.getContentView()} />;
  }
}

function mapStateToProps({ InventoryList, auth }) {
  return {
    userInfo: InventoryList.userInfo,
    currentUser: auth.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getuser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerComponent);
