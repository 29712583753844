import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Container, Grid, Popover, Button, InputLabel, FormControl, Input, Typography, FormControlLabel, Checkbox, Modal,Box, Select, MenuItem, Tooltip} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { createInvoiceDetails, getModelSerialList, getBillShipContactList, checkContactInDB,getInvoiceDetails, updateInvoiceDetails } from '../actions/dashboardAction';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "../style/style.css";
import Header from "../../../../common/components/Header";
import FooterComponent from "../../../../common/components/Footer";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import "../../../../common/style/SearchBar.css";
// import {formatPhoneNumber, unformatPhoneNumber} from "../../../../common/components/Helper/Utils";
import { SpinnerRoundFilled } from "spinners-react";
import getUserdetailsforsubscrption from '../actions/dashboardaapi';
import ContactListModal from './ContactListModal';
const { innerWidth: width, innerHeight: height } = window;

const invoiceObj = [
  { id: "modelNumber", label: "Model", minWidth: 100, type:"search", required: true, errMsg: "Please Enter Model" },
  { id: "image", label: "Image", minWidth: 80, type:"image", required: false },
  { id: "serialNumber", label: "Serial No", minWidth: 80 , type:"search", required: true, errMsg: "Please Enter Serial No" },
  { id: "productName", label: "Product Name", minWidth: 140 , type:"text", required: false},
  { id: "quantity", label: "Qty", minWidth: 80 , type:"number", required: true, errMsg: "Please enter quantity" },
  { id: "currency", label: "Currency", minWidth: 30, type:"select", required: true, errMsg: "Please enter rate" },
  { id: "amount", label: "Amount", minWidth: 65 , type:"amount", required: true, errMsg: "Please Enter Amount" },
  { id: "action", label: "Action", minWidth: 70, align:"center" },
];

const billingAddress = [
    { id: "billingToName", label: "Contact Name", minWidth: 80, type:"text_search", required: true, errMsg: "Please enter contact name" },
    { id: "billingToBusinessName", label: "Business Name", minWidth: 80, type:"text", required: true, errMsg: "" },
    { id: "billingToPhoneNo", label: "", minWidth: 80, type:"phone", required: true, errMsg: "" },
    { id: "billingToEmail", label: "Email", minWidth: 80, type:"email", required: true, errMsg: "Please enter valid email" },
    { id: "billingToAddressLine1", label: "Address Line1", minWidth: 80, type:"text", required: false, errMsg: "" },
    { id: "billingToAddressLine2", label: "Address Line2", minWidth: 80, type:"text", required: false, errMsg: "" },
    { id: "billingToCity", label: "City", minWidth: 80, type:"text", required: false, errMsg: "" },
    { id: "billingToCountry", label: "Country", minWidth: 80, type:"select", value:"United States", required: false, errMsg: "" },
    { id: "billingToState", label: "State", minWidth: 80, type:"select", required: false, errMsg: "" },
    { id: "billingToZip", label: "Zipcode", minWidth: 80, type:"number", required: false, errMsg: "" },
];

const shippingAddress = [
    { id: "shippingToName", label: "Contact Name", minWidth: 80, type:"text_search", required: true, errMsg: "Please enter contact name" },
    { id: "shippingToBusinessName", label: "Business Name", minWidth: 80, type:"text", required: true, errMsg: "" },
    { id: "shippingToPhoneNo", label: "", minWidth: 80, type:"phone", required: true, errMsg: "" },
    { id: "shippingToEmail", label: "Email", minWidth: 80, type:"email", required: true, errMsg: "Please enter valid email" },
    { id: "shippingToAddressLine1", label: "Address Line1", minWidth: 80, type:"text", required: false, errMsg: "" },
    { id: "shippingToAddressLine2", label: "Address Line2", minWidth: 80, type:"text", required: false, errMsg: "" },
    { id: "shippingToCity", label: "City", minWidth: 80, type:"text", required: false, errMsg: "" },
    { id: "shippingToCountry", label: "Country", minWidth: 80, type:"select", value:"United States", required: false, errMsg: "" },
    { id: "shippingToState", label: "State", minWidth: 80, type:"select", required: false, errMsg: "" },
    { id: "shippingToZip", label: "Zipcode", minWidth: 80, type:"number", required: false, errMsg: "" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: 600,
    overflowY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function CreateInvoiceComponent(props) {
    const { register, handleSubmit, setValue, getValues, formState: { errors }, reset } = useForm();
    const { register:register1, handleSubmit:handleSubmit1, setValue:setValue1, getValues:getValues1, formState: { errors:errors1 }, watch: watch1, reset:reset1 } = useForm();

    const addressForm = watch1();
    const [rows, setRows] = useState([]);
    const [openModal, setModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [isSameAddress, setSameAddress] = useState(false);
    const [amount, setAmount] = useState(null);
   const [shippingDate, setShippingDate] = useState(null);
   const [trackingNumber, setTrackingNumber] = useState(null);
    const [shippingMethod, setShippingMethod] = useState(null);
    const [wireDetails, setWireDetails] = useState(false);

    const [shipping, setShipping] = useState(null);
    const [tax, setTax] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [deposit, setDeposit] = useState(null);
    const [qtyShrink, setQtyShrink] = useState(true);
    const [descShrink, setDescShrink] = useState(false);
    const [editIdx, setEditIdx] = useState(null);
    const [model, setModelList] = useState(props?.modelList);
    const [serialList, setSerialList] = useState([]);
    const [searchObj, setSearchObj]=useState({});
    const [modelNo, setModel] = useState(null);
    const [serial, setSerial] = useState(null);
    const [image, setImage] = useState(null);
    const [desc, setDesc] = useState(null);
    const [inventoryid,setInventoryId] = useState(null);
    const [productid,setProductId] = useState(null);
    const [noData, setNoData] = useState(false);
    const [noBillData, setNoBillData] = useState(false);
    const [noShipData, setNoShipData] = useState(false);
    const [currentSearch, setCurrentSearch] = useState(null);
    const [isSubmitInvoice, setIsSubmitInvoice] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorTarget, setAnchorTarget] = useState(null);
    const [loading, setLoading] = useState(false);
    const [billCntctNm, setBillCntctNm] = useState(null);
    const [shipCntctNm, setShipCntctNm] = useState(null);
    const [billCntctSearchTxt, setBillCntctSearchTxt] = useState(null);
    const [shipCntctSearchTxt, setShipCntctSearchTxt] = useState(null);
    const [isBillTextInput, setIsBillTextInput] = useState(false);
    const [isShipTextInput, setIsShipTextInput] = useState(false);
    const [billCntcts, setBillCntctLst] = useState([]);
    const [shipCntcts, setShipCntcts] = useState([]);
    const [countryListData, setCountryListData] = useState([]);
    const [statesListData, setStatesListData] = useState([]);
    const [shippingStatesListData , setShippingStatesListData] = useState([])
    const [contactListConfirmationModal, SetContactListConfirmationModal] = useState(false)
    const [finalInvoiceData, setFinalInvoiceData] = useState(null);
    const [shippingCountryCode, setShippingCountryCode] = useState("+1")
    const [billingCountryCode, setBillingCountryCode] = useState("+1")
    const [modalText, setModalText] = useState("This is not in your contacts, Do you want to save it?")
    const [UniqueCountryCode, setUniqueCountryCode] = useState([]);
    const [currency,setCurrency] = useState("USD");
    const open = Boolean(anchorEl);
    const [terms, setPaymentTerms] = useState("Immediate")

    const userId = props?.currentUser?.userId ? props?.currentUser?.userId : null;
    const history = useHistory();

      useEffect(()=>{
          const invoiceId = props?.match?.params?.invoiceId || null

          if(invoiceId){
            setLoading(true)
            getInvoiceDetails(invoiceId)
              .then((response)=>{
                console.log("getInvoiceDetails:getInvoiceDetails", response)
               
                const result = response && response.data && response.data.length > 0 ? response.data[0] : null;
                const productDetails = result?.invoiceproductdetails?.product || [];
                const billingAddress = result?.billingdetails;
                const shippingAddress = result?.shippingdetails;
                productDetails.map((product) => {
                  product['modelNumber'] = product.modelnumber
                  product['serialNumber'] = product.serialnumber
                  product['productName'] = product.productname
                  product['image'] = product.image?.images
                  product['inventoryId'] = product.inventoryid
                  product['productId'] = product.productid
                  delete  product.modelnumber;
                  delete product.serialnumber;
                  delete product.productname;
                  delete product.inventoryid;
                  delete product.productid;
                })
                console.log(productDetails);
                setRows(productDetails)
                setValue1("billingToName",billingAddress?.contactname);
                setValue1("billingToBusinessName",billingAddress?.businessname);
                setValue1("billingToPhoneNo",billingAddress?.phone);
                setValue1("billingToEmail",billingAddress?.email);
                setValue1("billingToAddressLine1",billingAddress?.address1);
                setValue1("billingToAddressLine2",billingAddress?.address2);
                setValue1("billingToCity",billingAddress?.city);
                setValue1("billingToCountry",billingAddress?.country);
                setValue1("billingToZip",billingAddress?.zipcode);
                setBillingCountryCode(billingAddress?.countrycode)
                setValue1("shippingToName",shippingAddress?.contactname);
                setValue1("shippingToBusinessName",shippingAddress?.businessname);
                setValue1("shippingToPhoneNo",shippingAddress?.phone);
                setValue1("shippingToEmail",shippingAddress?.email);
                setValue1("shippingToAddressLine1",shippingAddress?.address1);
                setValue1("shippingToAddressLine2",shippingAddress?.address2);
                setValue1("shippingToCity",shippingAddress?.city);
                setValue1("shippingToCountry",shippingAddress?.country);
                setValue1("shippingToZip",shippingAddress?.zipcode);
                setShippingCountryCode(shippingAddress?.countrycode)
                getcountryList(billingAddress,shippingAddress)
                setBillCntctNm(billingAddress?.contactname);
                setShipCntctNm(shippingAddress?.contactname)
                setValue1("shippingPrice", result?.shippingprice)
                setValue1("discount", result?.discount)
                setValue1("tax",result?.tax)
                setValue1("deposit",result?.deposit)
                setValue1("terms",result?.paymentterm)
                setValue1("shippingMethod",result?.shippingmethod)
                setValue1("trackingNumber",result?.trackingnumber)
                setShipping(result?.shippingprice)
                setDiscount(result?.discount)
                setTax(result?.tax)
                setDeposit(result?.deposit)
                setShippingMethod(result?.shippingmethod)
                setPaymentTerms(result?.paymentterm)
                setTrackingNumber(result?.trackingnumber)
                setShippingDate(result?.shippingdate)
                setWireDetails(result?.wiredetails)
                setLoading(false)
                if(
                  ((billingAddress?.contactname && billingAddress.contactname.toLowerCase()) !== (shippingAddress.contactname && shippingAddress.contactname.toLowerCase())) || 
                  ((billingAddress?.email && billingAddress.email.toLowerCase()) !== (shippingAddress?.email && shippingAddress.email.toLowerCase()))|| 
                  ((billingAddress?.phone && billingAddress.phone) !== (shippingAddress?.phone && shippingAddress?.phone)) 
                  ) {
                    setSameAddress(false)
                    
                  }else{
                    setSameAddress(true)
                  }
              })
          }
          
    },[props?.match?.params]);
   
    const getcountryList = (billingAddress, shippingAddress) => {
        getUserdetailsforsubscrption.getAllCountryList().then((res) => {
            setCountryListData(res.data.data);
            let countries = res.data.data;
            let countryCode = [];
            countries.filter(({phonecode})=> countryCode.push(phonecode) )
            const UniqueCountryCode = [...new Set(countryCode)];
            setUniqueCountryCode(UniqueCountryCode.sort())
            if(billingAddress.country){
              const selectedCountry = billingAddress.country;
              const filterCountry = res.data.data.find((country) => {
                return country.name === selectedCountry
              });
              if(filterCountry && filterCountry.id){ 
                  getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
                      setStatesListData(res.data.data)
                      setValue1("billingToState",billingAddress?.states);
                  })
                }
            }

            if(shippingAddress.country){
              const selectedCountry = shippingAddress.country;
              const filterCountry = res.data.data.find((country) => {
                return country.name === selectedCountry
              });
              if(filterCountry && filterCountry.id){ 
                  getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
                    setShippingStatesListData(res.data.data)
                    setValue1("shippingToState",shippingAddress?.states);
                  })
                }
            }
         })
      }


      const isTwoAddressSame = (input, dbData, type) => {
        console.log('isTwoAddressSame')
        console.log(input, dbData)
          let isChanged = false;
          if(type === "shipping"){
              if(
                ((dbData.businessname && dbData.businessname.toLowerCase()) !== (input.shippingToBusinessName && input.shippingToBusinessName.toLowerCase())) || 
                ((dbData.address1 && dbData.address1.toLowerCase()) !== (input.shippingToAddressLine1 && input.shippingToAddressLine1.toLowerCase()))|| 
                ((dbData.address2 && dbData.address2.toLowerCase()) !== (input.shippingToAddressLine2 && input.shippingToAddressLine2.toLowerCase())) || 
                ((dbData.city && dbData.city.toLowerCase()) !== (input.shippingToCity && input.shippingToCity.toLowerCase())) || 
                ((dbData.states && dbData.states.toLowerCase()) !== (input.shippingToState && input.shippingToState.toLowerCase())) || 
                ((dbData.country && dbData.country.toLowerCase()) !== (input.shippingToCountry && input.shippingToCountry.toLowerCase())) || 
                ((dbData.zipcode && dbData.zipcode.toLowerCase()) !== (input.shippingToZip && input.shippingToZip.toLowerCase())) ||
                ((dbData.countrycode && dbData.countrycode.toLowerCase()) !== (shippingCountryCode && shippingCountryCode.toLowerCase())) 
                ) {
                    isChanged = true;
                }
              return isChanged;
          } else{
            if(
              ((dbData.businessname && dbData.businessname.toLowerCase()) !== (input.billingToBusinessName && input.billingToBusinessName.toLowerCase())) || 
              ((dbData.address1 && dbData.address1.toLowerCase()) !== (input.billingToAddressLine1 && input.billingToAddressLine1.toLowerCase()))|| 
              ((dbData.address2 && dbData.address2.toLowerCase()) !== (input.billingToAddressLine2 && input.billingToAddressLine2.toLowerCase())) || 
              ((dbData.city && dbData.city.toLowerCase()) !== (input.billingToCity && input.billingToCity.toLowerCase())) || 
              ((dbData.states && dbData.states.toLowerCase()) !== (input.billingToState && input.billingToState.toLowerCase())) || 
              ((dbData.country && dbData.country.toLowerCase()) !== (input.billingToCountry && input.billingToCountry.toLowerCase())) || 
              ((dbData.zipcode && dbData.zipcode.toLowerCase()) !== (input.billingToZip && input.billingToZip.toLowerCase())) ||
              ((dbData.countrycode && dbData.countrycode.toLowerCase()) !== (billingCountryCode && billingCountryCode.toLowerCase())) 
              
              ) {
                  isChanged = true;
              }
              return isChanged;
          }
      };

    const onCreateInvoiceSubmit = (formData) => {
        console.log(formData,'formData')
        setIsSubmitInvoice(true);
        if (!(formData?.billingToBusinessName || formData?.billingToName) && !(formData?.billingToBusinessName || formData?.billingToName))  {
            setIsSubmitInvoice(true);
            return false;
        }        
        const data = {
            'userId':userId,
            'inventoryid':  inventoryid, 
            'billingToBusinessName': formData?.billingToBusinessName,
            'billingToName': formData?.billingToName,
            'billingToAddressLine1': formData?.billingToAddressLine1,
            'billingToAddressLine2': formData?.billingToAddressLine2,
            'billingToPhoneNo' : formData?.billingToPhoneNo ? formData?.billingToPhoneNo :null,
            'billingToCountryCode': billingCountryCode,
            'billingToEmail' : formData?.billingToEmail,
            'billingToCity': formData?.billingToCity,
            'billingToState': formData?.billingToState,
            'billingToCountry': formData?.billingToCountry,
            'billingToZip': formData?.billingToZip,
            'shippingToBusinessName': formData?.shippingToBusinessName,
            'shippingToName': formData?.shippingToName,
            'shippingToAddressLine1': formData?.shippingToAddressLine1,
            'shippingToAddressLine2': formData?.shippingToAddressLine2,
            'shippingToPhoneNo' : formData?.shippingToPhoneNo ? (formData?.shippingToPhoneNo) :null,
            'shippingToCountryCode': shippingCountryCode,
            'shippingToEmail' : formData?.shippingToEmail,
            'shippingToCity': formData?.shippingToCity,
            'shippingToState': formData?.shippingToState,
            'shippingToCountry': formData?.shippingToCountry,
            'shippingToZip': formData?.shippingToZip,
            'productDetails': JSON.stringify(rows),
            'shippingPrice': formData?.shippingPrice ? formatPrice(formData?.shippingPrice) : "0.00",
            'discount': formData?.discount ? formatPrice(formData?.discount) : "0.00",
            'tax': formData?.tax ? formatPrice(formData?.tax) : "0.00",
            'deposit': formData?.deposit ? formatPrice(formData?.deposit) : "0.00",
            'terms': terms,
            'shippingMethod': formData?.shippingMethod,
            'shippingDate': shippingDate,
            'trackingNumber': formData?.trackingNumber,
            'wireDetails': wireDetails,
            'isSameAddress': isSameAddress

        };

        let contactValidation = [
            {
                'userId':userId,
                'billingToBusinessName': formData?.billingToBusinessName,
                'billingToName': formData?.billingToName,
                'billingToAddressLine1': formData?.billingToAddressLine1,
                'billingToAddressLine2': formData?.billingToAddressLine2,
                'billingToPhoneNo' : formData?.billingToPhoneNo ? (formData?.billingToPhoneNo):null,
                'billingToCountryCode' : billingCountryCode,
                'billingToEmail' : formData?.billingToEmail,
                'billingToCity': formData?.billingToCity,
                'billingToState': formData?.billingToState,
                'billingToCountry': formData?.billingToCountry,
                'billingToZip': formData?.billingToZip,
                'type': 'billing'
            },
            {
                'userId':userId,
                'shippingToBusinessName': formData?.shippingToBusinessName,
                'shippingToName': formData?.shippingToName,
                'shippingToAddressLine1': formData?.shippingToAddressLine1,
                'shippingToAddressLine2': formData?.shippingToAddressLine2,
                'shippingToPhoneNo' : formData?.shippingToPhoneNo  ? (formData?.shippingToPhoneNo):null,
                'shippingToCountryCode': shippingCountryCode,
                'shippingToEmail' : formData?.shippingToEmail,
                'shippingToCity': formData?.shippingToCity,
                'shippingToState': formData?.shippingToState,
                'shippingToCountry': formData?.shippingToCountry,
                'shippingToZip': formData?.shippingToZip,
                'type': 'shipping'
            }

        ];
      console.log("checkContactInDB:checkContactInDB")
        props.checkContactInDB(contactValidation, (success,error) => {
            if(error){
              console.log(error)
              setIsSubmitInvoice(false);
              toast("Invoice update failed", {
              autoClose: 2000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
              });
            }else{
            console.log(success, "success:success")
              const filterNewData = success.data.filter((item)=>  item.isPresent === false)
              const filterExistingData = success.data.filter((item)=>  item.isPresent === true)
              let isNeedConfirmationModal = false;
              filterExistingData.map((input) => {
                  console.log(input)
                    const isChanged = isTwoAddressSame(data,input.contactData ? input.contactData[0] : null, input.type);
                    input.isChanged = isChanged
                    if(isChanged){
                      isNeedConfirmationModal = true;
                    }
                } );
              console.log(filterNewData.length);
              console.log(filterExistingData.length)
              if(!isSameAddress){
                console.log("STEP 1")
                if(filterNewData.length > 0 && filterExistingData.length <=0 ){
                  console.log("STEP 1A")
                  data['filterData'] =  filterNewData;
                  setModalText("This is not in your contacts, Do you want to save it?")
                  setFinalInvoiceData(data);
                  SetContactListConfirmationModal(!contactListConfirmationModal)
                } 
                else if( filterExistingData.length > 0 && filterNewData.length <= 0 && isNeedConfirmationModal){
                  console.log("STEP 1B")
                    data['filterData'] =  filterExistingData;
                    setModalText("Contact data is changed. Do you want to update it?")
                    setFinalInvoiceData(data);
                    SetContactListConfirmationModal(!contactListConfirmationModal)
                } 
                else if(filterNewData.length > 0 && filterExistingData.length > 0){
                  console.log("STEP 1C")
                  const isChanged = isTwoAddressSame(data,filterExistingData[0].contactData[0], filterExistingData[0].type);
                  filterExistingData[0].isChanged = isChanged;
                  data['filterData'] =  filterNewData.concat(filterExistingData);
                  setModalText("This is not in your contacts, Do you want to save it?");
                  setFinalInvoiceData(data);
                  SetContactListConfirmationModal(!contactListConfirmationModal)
                }
                else{
                  console.log("STEP 1D")
                  createNewInvoiceAPI(data);
                }
              }
              else if (isSameAddress){
                console.log("STEP 2")
                let isChanged = false;
                if(filterExistingData && filterExistingData.length > 0){
                  isChanged = isTwoAddressSame(data,filterExistingData[0].contactData[0], filterExistingData[0].type);
                }

                if(filterNewData.length > 0){
                  console.log("STEP 2A")
                  data['filterData'] =  filterNewData;
                  setModalText("This is not in your contacts, Do you want to save it?");
                  setFinalInvoiceData(data);
                  SetContactListConfirmationModal(!contactListConfirmationModal)
                }
                else if(filterExistingData.length > 0 && isChanged){
                    console.log("STEP 2B")
                    filterExistingData[0].isChanged = true;
                    filterExistingData[1].isChanged = true;
                    data['filterData'] =  filterExistingData;
                    setModalText("Contact data is changed. Do you want to update it?")
                    setFinalInvoiceData(data);
                    SetContactListConfirmationModal(!contactListConfirmationModal)
                }
                else{
                  console.log("STEP 2C")
                  createNewInvoiceAPI(data)
                }
              }
              else{
                console.log("STEP 3")
                createNewInvoiceAPI(data)
              }
            }
           
        })
    };    
    const handleCloseContactConfirmation = () => {
        setFinalInvoiceData(null);
        SetContactListConfirmationModal(!contactListConfirmationModal)
        createNewInvoiceAPI(finalInvoiceData)

    };

    const handleSuccessContactConfirmation = () => {
        const data = finalInvoiceData;
        const filterObject = data.filterData;
        setFinalInvoiceData(null);
        SetContactListConfirmationModal(!contactListConfirmationModal);
        filterObject.map(item => {
          console.log("handleSuccessContactConfirmation")
          console.log(item)
            if(!item.isPresent){
                if(item.type === 'billing'){
                    data['isBillNew'] = 1
                }else{
                    data['isShipNew'] = 1
                }
            }else{
              if(item.isChanged && isSameAddress){
                data['isBillChanged'] = 1
                data['isShipChanged'] = 1
               } else if(item.isChanged){
                  if(item.type === 'billing'){
                      data['isBillChanged'] = 1
                  } else{
                      data['isShipChanged'] = 1
                  }
              }else{
                data.isChanged = 0;
              }
            }

        })
        createNewInvoiceAPI(data)
    }

    const createNewInvoiceAPI = (data) => {
      console.log('createNewInvoiceAPI = (data')
      console.log(data)
      if(data && data.filterData ) delete data.filterData 
      const invoiceId = props?.match?.params?.invoiceId || null
        updateInvoiceDetails(
            data,
            invoiceId,
            (success) => { console.log(success)
                if(success.status === 1){
                setIsSubmitInvoice(false);
                toast("Invoice updated successfully", {
                    autoClose: 1000,
                    type: success.status === 1 ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                // setLoading(true);            
               
                    // setLoading(false);
                    setTimeout(()=>{
                        localStorage.setItem("tabInvoiceDashboard", "yes");
                        props.history.push({pathname: '/myinventory',state: { tab: "invoice" }});
                        localStorage.removeItem("tabInvoiceDashboard", true);
                       
                    },1000);
                }
                else
                {

                setIsSubmitInvoice(false);
                toast(success.message, {
                autoClose: 3000,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_CENTER,
                });
                }
            },
            (error) => { 
                          

                setIsSubmitInvoice(false);
                toast("Invoice update failed", {
                autoClose: 2000,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_CENTER,
                });


            }
        );
    }
  const formatPrice = (val) =>{
    if(val.includes('.')) {
        if(val.split('.')[1].length >= 2) {
            const num = parseFloat(val);
            const cleanNum = num.toFixed(2);
            return cleanNum.toString();
        }
    }
    return `${val}.00`;
  }

  const handleAddRow = (invoiceObj) => {
    //   console.log(currentItem)
    if (currentItem) {    
        let oldRows = rows;
        oldRows[editIdx] = invoiceObj;
        setRows(oldRows);
    } else {
        setRows((prevArray) => [...prevArray, invoiceObj]);
    }
  };  
 
  const onSubmit = (data) => {
    console.log("onSubmit,onSubmit")
    console.log(data);

    setModalOpen(false);
    const amt = parseFloat(data.amount)?.toFixed(2);
    const rowData = data;
    rowData.amount = amt;
    rowData.inventoryId = inventoryid;
    rowData.productId = productid;
    rowData.currency = currency;
    if(rowData && rowData.from){
      delete rowData.from
    }
    // rowData.inventoryid = inventoryid;
   // rowData.shippingDate = shippingDate;
    handleAddRow({...rowData, ...{modelNumber:modelNo}, ...{serialNumber:serial}, ...{productName: desc || getValues('productName')} }); // , ...{dueDate}, ...{isInvoiced: invd}, ...{isShipped: shpd}
  };

  const onChange = (e)=>{      
    setSameAddress(!isSameAddress);
  };
 
  useEffect(()=>{
      console.log("getValues1()")
      console.log(getValues1())
    if (isSameAddress) {
        setShipCntctNm(getValues1()['billingToName']);
        shippingAddress.map((item,i)=>{
            console.log(item.id)
            console.log(billingAddress[i].id)
            console.log(getValues1()[billingAddress[i].id])
            console.log(":::::::::::::")
            setValue1(item.id, getValues1()[billingAddress[i].id]);
        })
        setShippingCountryCode(billingCountryCode)
        setShipCntctNm(getValues1()['billingToName']);
        console.log(shippingAddress)
    } else {
        setNoShipData(false);
        setShipCntctSearchTxt(null);
        setShipCntctNm(null);
        shippingAddress.map((item,i)=>{
            setValue1(item.id, "");
        })
    }
  },[isSameAddress]);

useEffect(()=>{
    if (props.billShipCntctLst && props.billShipCntctLst?.length > 0) {
        setAnchorEl(anchorTarget);    
        if (billCntctSearchTxt) {
            setBillCntctLst(props.billShipCntctLst);
            setNoBillData(false);
        }
        if (shipCntctSearchTxt) {
            setShipCntcts(props.billShipCntctLst);
            setNoShipData(false);
        }
    } else if (props.billShipCntctLst?.length === 0){        
        if (billCntctSearchTxt) {
            setBillCntctLst([]);
            setNoBillData(true);
        }
        if (shipCntctSearchTxt) {
            setShipCntcts([]);
            setNoShipData(true);
        }
    }
},[props.billShipCntctLst]);

useEffect(()=>{
    console.log(props.modelList)
    if (props.modelList && props.modelList.length) {
        setAnchorEl(anchorTarget);
    } else {
        if (searchObj?.searchVal) {
            setNoData(true)
        }
    }
    console.log(searchObj);
    if (searchObj?.column === "modelNumber") {
        // setInventoryId(props.inventoryid)
        setModelList(props.modelList);
    } else {
        setSerialList(props.modelList)
           }
},[props.modelList]);

useEffect(()=>{
    const timer = setTimeout(() => {
        if (Object.keys(searchObj).length && searchObj?.searchVal?.length > 2) {
            props.getModelSerialList(searchObj,userId);
        }
    }, 500);
    return () => clearTimeout(timer);  
},[searchObj]);

  const handleAddItem =()=>{
    setModalOpen(!openModal);
    setCurrentItem(null);
    // setDueDate(null);
   setShippingDate(null);
    setAmount(null);
    setInventoryId(null);
    setProductId(null);
    setCurrency("USD")
    setQtyShrink(true);
    setDescShrink(false);
    setEditIdx(null);
    setModel(null);
    setSerial(null);
    setDesc(null);
    setNoData(false);
    reset({
        modelNumber:"",
        serialNumber:"",
        image:"",
        productName:"",
        quantity:1,
        // rate:"",
        amount:"",
        inventoryId: "",
        productId: "",
        currency: "USD"
        // shippingDate:"",
        //   shippingMethod:"",
        //   trackingNumber:""
        // isShipped:"",
        // isInvoiced:"",
        // dueDate:""
    });
  }

  const handleCloseModal=()=>{
    setModalOpen(false);
    if(currentItem && currentItem.from){
      handleRemoveSpecificRow(0);
    }
  }

  const handleRemoveSpecificRow = (idx) => {
    setEditIdx(null);
    const temp = [...rows];
    temp.splice(idx, 1);
    if (temp.length === 0) {
        setShipping(null);
        setDeposit(null);
        setTax(null);
        setDeposit(null);
    }
    setRows(temp);
  };
  
  const onChangeOfCheckBox = (event) =>{
    if(event.target.checked === true){
       setWireDetails(true);
    }else{
        setWireDetails(false);
    }
}

  const handleSearch = (e, column) => {
    setNoData(false);
    setAnchorTarget(e.currentTarget);
    setCurrentSearch(column);
    if (column === 'modelNumber') {
        setModel(e.target.value)
    } else {
        setSerial(e.target.value);
    }
    setSearchObj({column, searchVal:e.target.value});
  }

  useEffect(()=>{
    // const timer = setTimeout(() => {
        if (!isBillTextInput && billCntctSearchTxt && billCntctSearchTxt?.length > 2) {
            const search = {type: 'bill', searchVal:billCntctSearchTxt};
            props.getBillShipContactList(search,userId);
        }
    // }, 500);
    // return () => clearTimeout(timer);  
  },[billCntctSearchTxt]);

  useEffect(()=>{
    // const timer = setTimeout(() => {
        if (!isShipTextInput && shipCntctSearchTxt && shipCntctSearchTxt?.length > 2) {
            const search = {type: 'ship', searchVal:shipCntctSearchTxt};
            props.getBillShipContactList(search,userId);
        }
    // }, 500);
    // return () => clearTimeout(timer);  
  },[shipCntctSearchTxt]);

  const handleContactSearch = (e, column,type) => {
    setAnchorTarget(e.currentTarget);
    if (type === "bill" && column === "billingToName") {
        setBillCntctNm(e.target.value);
        setBillCntctSearchTxt(e.target.value);    
    } else if (type === "ship" && column === "shippingToName") {
        setShipCntctNm(e.target.value);
        setShipCntctSearchTxt(e.target.value);    
    }
    if (type === "bill" && isSameAddress) {
        const changeColum = column.split("billing")[1];
        setValue1(`shipping${changeColum}`,e.target.value);
    }
  }

  const clearSearchInput =()=>{
    setModel(null);
    setSerial(null);
    setDesc(null);
    setAnchorEl(null);
    setAnchorTarget(null);
    setModelList([]);
    setSerialList([]);
    setCurrentItem(null);
    setCurrency("USD")
    setDescShrink(false);
    setNoData(false);
    setSearchObj((prevState)=>({ ...prevState, searchVal:''}));
    reset({ modelNumber:null, serialNumber:null,productName:null});
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
 
  const handleSelection=(val,columnId)=>{
    console.log("handleSelection");
    console.log(val)
    setModel(val.model);
    setSerial(val.serialno);
    setDesc(val.description);
    setImage(val?.product_imagepaths?.images);
    setProductId(val?.productid);
    setAnchorEl(null);
    setAnchorTarget(null);
    setValue('modelNumber',val.model);
    setValue('serialNumber',val.serialno);
    setValue('productName',val.description);
    setValue("image",val?.product_imagepaths?.images);
    setDescShrink(val.description ? true : false);    
    setSearchObj((prevState)=>({ ...prevState, searchVal:''}));
    setModelList([]);
    setSerialList([]);
    setInventoryId(val.inventoryid);
     }

  const clearCntctSearchInput = (type)=>{
     if (type === "bill") {
        setNoBillData(false);
        setBillCntctLst([]);
        setBillCntctSearchTxt(null);
        setBillCntctNm(null);
        if (isSameAddress) {
            setNoShipData(false);
            setShipCntctSearchTxt(null);
            setShipCntctNm(null);
            reset1();
        } else {
            setValue1("billingToName",null);
            setValue1("billingToBusinessName",null);
            setValue1("billingToPhoneNo",null);
            setValue1("billingToEmail",null);
            setValue1("billingToAddressLine1",null);
            setValue1("billingToAddressLine2",null);
            setValue1("billingToCity",null,);
            setValue1("billingToState",null,);
            setValue1("billingToCountry","United States");
            setValue1("billingToZip",null);
        }            
     } else {
        setNoShipData(false);
        setShipCntcts([]);
        setShipCntctSearchTxt(null);
        setShipCntctNm(null);
        setValue1("shippingToName",null);
        setValue1("shippingToBusinessName",null);
        setValue1("shippingToPhoneNo",null);
        setValue1("shippingToEmail",null);
        setValue1("shippingToAddressLine1",null);
        setValue1("shippingToAddressLine2",null);
        setValue1("shippingToCity",null);
        setValue1("shippingToState",null);
        setValue1("shippingToCountry","United States");
        setValue1("shippingToZip",null);
     }
  }

//   const changeTextMode = (type)=>{
//       if(type ==="bill") {
//         setIsBillTextInput(!isBillTextInput);
//         setNoBillData(false);
//       } else {
//         setIsShipTextInput(!isShipTextInput);
//         setNoShipData(false);
//       }
//   }

  const handleContactSelection= async (val,columnId)=>{
      if (billCntctSearchTxt && columnId === 'bill') {
        setNoBillData(false);
        setBillCntctNm(val?.contactname);
        setBillCntctSearchTxt("");
        setBillCntctLst([]);
        setBillingCountryCode(val?.countrycode)
        let phoneCode = '';
        if(val.country){
          const selectedCountry =val.country;
          const filterCountry = countryListData.find((country) => country.name === selectedCountry);
          phoneCode = filterCountry?filterCountry.phonecode : null;
          if(filterCountry && filterCountry.id){ 
            console.log(filterCountry);
              getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
                  setStatesListData(res.data.data)
                  // setBillingCountryCode(filterCountry.phonecode)
              })
            }
        }
        
        let phoneNumber= val? val.phone : null;
        if(phoneNumber && val.country){
          // phoneNumber = unformatPhoneNumber(phoneNumber, val.country,phoneCode)
          phoneNumber = phoneNumber.replace(/\W+/g, '')
        }
        setValue1("billingToName", val?.contactname);
        setValue1("billingToBusinessName", val?.businessname);
        setValue1("billingToPhoneNo", phoneNumber);
        // setValue1("billingToPhoneNo", (val?.phone ? val?.phone.replace(/\W+/g, '') : null));
        setValue1("billingToEmail", val?.email);
        setValue1("billingToAddressLine1", val?.address1);
        setValue1("billingToAddressLine2", val?.address2);
        setValue1("billingToCity", val?.city);
        setValue1("billingToState", val?.states);
        setValue1("billingToCountry", val?.country);
        setValue1("billingToZip", val?.zipcode);
        if (isSameAddress) {
            shippingFormPatch(val);
        }
      } else {
        setNoShipData(false);
        setShipCntctSearchTxt("");
        setShipCntcts([]);
        shippingFormPatch(val);    
      }      
  }

  const shippingFormPatch=(val)=>{
    setShipCntctNm(val?.contactname);
    setShippingCountryCode(val?.countrycode)
    let phoneCode = '';
    if(val.country){
      const selectedCountry =val.country;
      const filterCountry = countryListData.find((country) => country.name === selectedCountry)
      phoneCode = filterCountry ? filterCountry.phonecode: null;
      if(filterCountry && filterCountry.id){
        getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
          setShippingStatesListData(res.data.data)
          // setShippingCountryCode(filterCountry.phonecode)
          
      })
      }
         
    }
    let phoneNumber= val? val.phone : null;
    // if(phoneNumber && val.country){
    //   // phoneNumber = unformatPhoneNumber(phoneNumber, val.country, phoneCode)
    // }
    phoneNumber = phoneNumber ? phoneNumber.replace(/\W+/g, '') : null;
    setValue1("shippingToName", val?.contactname);
    setValue1("shippingToBusinessName", val?.businessname);
    setValue1("shippingToPhoneNo", (phoneNumber));
    // setValue1("shippingToPhoneNo", (val?.phone ? val?.phone.replace(/\W+/g, '') : null));
    setValue1("shippingToEmail", val?.email);
    setValue1("shippingToAddressLine1", val?.address1);
    setValue1("shippingToAddressLine2", val?.address2);
    setValue1("shippingToCity", val?.city);
    setValue1("shippingToState", val?.states);
    setValue1("shippingToCountry", val?.country);
    setValue1("shippingToZip", val?.zipcode);
  }

  const onChangeCounry = (e, id, type=null) => {
    const selectedCountry = e.target.value;
    const filterCountry = countryListData.find((country) => country.name === selectedCountry)
    if(type){
        handleContactSearch(e,id,"bill");
        setValue1(id, selectedCountry);
        if(filterCountry && filterCountry.id){ 
          getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
            setStatesListData(res.data.data)
            // setBillingCountryCode(filterCountry.phonecode)
        })
        }
        
    }
    else{
          setValue1(id, selectedCountry);
          if(filterCountry && filterCountry.id){ 
            getUserdetailsforsubscrption.getAllStateList(filterCountry.id).then((res) => {
                setShippingStatesListData(res.data.data)
                // setShippingCountryCode(filterCountry.phonecode)
            })
          }
            
        }
  }
  const handleContactAddress = (val)=>{
      let address = "";
      if (val?.contactname) {
        address += val?.contactname;
      }
      if (val?.businessname) {
        address +=  address ? ", "+val?.businessname : val?.businessname;
      }
      if (val?.address1) {
        address +=  address ? ", "+val?.address1 : val?.address1;
      }
      if (val?.address2) {
        address +=  address ? ", "+val?.address2 : val?.address2;
      }
      if (val?.city) {
        address +=  address ? ", "+val?.city : val?.city;
      }
      if (val?.states) {
        address +=  address ? ", "+val?.states : val?.states;
      }
      if (val?.country) {
        address +=  address ? ", "+val?.country : val?.country;
      }
      if (val?.zipcode) {
        address +=  address ? ", "+val?.zipcode : val?.zipcode;
      }
      return address;
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <Container maxWidth="md" style={{backgroundColor:'white', minHeight: (height-114)+'px'}}>
      <Grid container xs={12} sm={12}>
            <h1 style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon
                style={{
                  color: "action",
                  paddingRight: "15px",
                  fontSize: 40,
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.goBack();
                }}
              >
                {" "}
              </ArrowBackIcon>
              Edit an invoice
            </h1>
          </Grid>
          {loading ? (
            <CircularProgress  style={{display:'flex', margin: 'auto', marginTop: '20%'}}/>
            ) : (
                <>
                    <form key={1} onSubmit={handleSubmit1(onCreateInvoiceSubmit)}>
                    
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="text"
                          style={{
                            background: "#000",
                            color: "#fff",
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                          onClick={handleAddItem}
                        >
                          Add Item
                        </Button>
                      </Grid>
                      <TableContainer
                        sx={{ maxHeight: 440, marginTop: "20px", marginBottom: "20px" }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {invoiceObj
                                .filter((item) => item.id !== "currency")
                                .map((column) => (
                                  <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </StyledTableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows?.length === 0 && (
                              <StyledTableRow>
                                <TableCell colspan={11} style={{ textAlign: "center" }}>
                                  No record found
                                </TableCell>
                              </StyledTableRow>
                            )}
                            {rows?.map((row, idx) => {
                              return (
                                <>
                                  <StyledTableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.code}
                                  >
                                    {invoiceObj
                                      ?.filter((item) => item.id !== "currency")
                                      .map((column) => {
                                        return (
                                          <>
                                            {column.id !== "action" && (
                                              <TableCell
                                                key={column.id}
                                                align={column.align}
                                              >
                                                {
                                                  // (column.id === "isShipped" || column.id === "isInvoiced") ? (row[column.id] ? "Yes" : "No") :
                                                  column.type === "image" ? (
                                                    <img
                                                      src={row[column.id]}
                                                      width="35"
                                                      height="55"
                                                    />
                                                  ) : column.id === "rate" ||
                                                    column.id === "amount" ? (
                                                    `${rows[idx].currency}${row[column.id]}`
                                                  ) : column.id === "productName" &&
                                                    row[column.id]?.length > 50 ? (
                                                    row[column.id]?.slice(0, 50) + "..."
                                                  ) : (
                                                    row[column.id]
                                                  )
                                                }
                                              </TableCell>
                                            )}
                                            {column.id === "action" && (
                                              <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ padding: 10 }}
                                              >
                                                <EditIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    width: "19px",
                                                  }}
                                                  onClick={() => {
                                                    setModalOpen(!openModal);
                                                    setAnchorEl(null);
                                                    setCurrentItem(rows.at(idx));
                                                    setCurrency(rows[idx].currency)
                                                    reset(rows.at(idx));
                                                    setEditIdx(idx);
                                                    setModel(rows[idx].modelNumber);
                                                    setSerial(rows[idx].serialNumber);
                                                    setInventoryId(rows[idx].inventoryId);
                                                    setProductId(rows[idx].productId)
                                                    if (rows[idx].productName) {
                                                      setDescShrink(true);
                                                    }
                                                  }}
                                                />
                                                &nbsp;&nbsp;&nbsp;
                                                <DeleteIcon
                                                  style={{
                                                    cursor: "pointer",
                                                    width: "19px",
                                                  }}
                                                  onClick={() => {
                                                    handleRemoveSpecificRow(idx);
                                                  }}
                                                />
                                              </TableCell>
                                            )}
                                          </>
                                        );
                                      })}
                                  </StyledTableRow>
                                </>
                              );
                            })}
                            {rows.length > 0 && (
                              <>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    SHIPPING
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <input
                                      type="text"
                                      className="form-width-100"
                                      {...register1("shippingPrice")}
                                      placeholder="$0.00"
                                      style={{ height: "30px" }}
                                      value={shipping}
                                      onChange={(e) =>
                                        setShipping(e.target.value.replace(/[^0-9\.]/g, ""))
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    DISCOUNT
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <input
                                      type="text"
                                      className="form-width-100"
                                      {...register1("discount")}
                                      placeholder="$0.00"
                                      style={{ height: "30px" }}
                                      value={discount}
                                      onChange={(e) =>
                                        setDiscount(e.target.value.replace(/[^0-9\.]/g, ""))
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    TAX
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <input
                                      className="form-width-100"
                                      type="text"
                                      {...register1("tax")}
                                      placeholder="$0.00"
                                      style={{ height: "30px" }}
                                      value={tax}
                                      onChange={(e) =>
                                        setTax(e.target.value.replace(/[^0-9\.]/g, ""))
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    DEPOSIT
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <input
                                      type="text"
                                      className="form-width-100"
                                      {...register1("deposit")}
                                      placeholder="$0.00"
                                      style={{ height: "30px" }}
                                      value={deposit}
                                      onChange={(e) =>
                                        setDeposit(e.target.value.replace(/[^0-9\.]/g, ""))
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    PAYMENT TERM
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <Select
                                      {...register1("terms")}
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      fullWidth
                                      value={terms}
                                    >
                                      <MenuItem value="Immediate">Immediate</MenuItem>
                                      <MenuItem value="Net 7 Days">Net 7 Days</MenuItem>
                                      <MenuItem value="Net 30 Days">Net 30 Days</MenuItem>
                                      <MenuItem value="Net 60 Days">Net 60 Days</MenuItem>
                                      <MenuItem value="Net 90 Days">Net 90 Days</MenuItem>
                                    </Select>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    SHIPPING DATE
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <div>
                                      <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <KeyboardDatePicker
                                            // className="color-BCBCBC"
                                            margin="normal"
                                            id="date-picker-dialog"
                                            format="MM/dd/yyyy"
                                            // disableFuture={true}
                                            value={shippingDate}
                                            onChange={(date, e) => {
                                              setShippingDate(e);
                                            }}
                                            isClearable
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                            placeholder="MM/DD/YYYY"
                                            helperText={""}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </FormControl>
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    SHIPPING METHOD
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <Select
                                      {...register1("shippingMethod")}
                                      onChange={(e) => setShippingMethod(e.target.value)}
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      fullWidth
                                      value={shippingMethod}
                                    >
                                      <MenuItem value="USPS">USPS</MenuItem>
                                      <MenuItem value="Fedex">Fedex</MenuItem>
                                      <MenuItem value="UPS">UPS</MenuItem>
                                      <MenuItem value="Ferrary">Ferrary</MenuItem>
                                    </Select>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    TRACKING NUMBER
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <input
                                      className="form-width-100"
                                      type="text"
                                      {...register1("trackingNumber")}
                                      placeholder="Tracking Number"
                                      style={{ height: "30px" }}
                                      value={trackingNumber}
                                      onChange={(e) => setTrackingNumber(e.target.value)}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colspan={4} style={{ textAlign: "right" }}>
                                    Include Wire Details
                                  </TableCell>
                                  <TableCell colspan={3}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="checkedB1"
                                          className="float-right"
                                          checked={wireDetails}
                                          onChange={onChangeOfCheckBox}
                                        />
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Grid container style={{ marginBottom: "20px" }}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <div>
                            <div>
                              <Typography className="color-232323">
                                <Grid
                                  container
                                  style={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    height: "42px",
                                  }}
                                >
                                  <Grid item xs={6} sm={6} md={6}>
                                    <b>BILL TO</b>
                                  </Grid>
                                  {/* <Grid item xs={6} sm={6} md={6}>
                                            { !isBillTextInput ?
                                              <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('bill')}}>Click to Add Contact</Button>
                                              :
                                              <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('bill')}}>Click to Search Contact</Button>
                                            }
                                        </Grid>
                                        */}
                                </Grid>
                              </Typography>
                            </div>
                            {isSubmitInvoice && !addressForm?.billingToName && (
                              <Grid item xs={12} lg={12} md={12}>
                                <span style={{ color: "red" }}>
                                  You must fill out contact name
                                </span>
                              </Grid>
                            )}
                            {billingAddress.map((column) => (
                              <>
                                {column.type === "text_search" && (
                                  <>
                                    <div
                                      className="searchInputs"
                                      style={{ marginTop: "30px" }}
                                    >
                                      <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                                        {billCntctNm ? (
                                          <InputLabel
                                            htmlFor="billCntctNm"
                                            shrink={true}
                                            style={{ color: "#424242" }}
                                          >
                                            {column.label}
                                          </InputLabel>
                                        ) : (
                                          <InputLabel
                                            htmlFor="billCntctNm"
                                            shrink={false}
                                            style={{ color: "#424242" }}
                                          >
                                            {column.label}
                                          </InputLabel>
                                        )}
                                        <Input
                                          id="billCntctNm"
                                          inputProps={{ type: "text" }}
                                          autoComplete={"off"}
                                          value={billCntctNm}
                                          {...register1(column.id, {
                                            required: column.required
                                              ? column.errMsg
                                              : false,
                                          })}
                                          style={{ marginBottom: "10px" }}
                                          onChange={(e) => {
                                            handleContactSearch(e, column.id, "bill");
                                          }}
                                        />
                                        {errors[column.id] && (
                                          <span style={{ color: "red" }}>
                                            {column.errMsg}
                                          </span>
                                        )}
                                        <div className="contactSearchIcon">
                                          <Grid container>
                                            <Grid item xs={6} sm={6} md={6}>
                                              {!isBillTextInput && (
                                                <>
                                                  {!billCntctNm ? (
                                                    <SearchIcon
                                                      style={{ cursor: "pointer" }}
                                                    />
                                                  ) : (
                                                    <CloseIcon
                                                      id="clearBtn"
                                                      onClick={() => {
                                                        clearCntctSearchInput("bill");
                                                      }}
                                                      style={{ cursor: "pointer" }}
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </FormControl>
                                      {billCntcts && billCntcts.length > 0 && (
                                        <Popover
                                          id={column.id}
                                          open={open}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          PaperProps={{
                                            style: { height: "200px", overflow: "auto" },
                                          }}
                                        >
                                          {billCntcts?.slice(0, 15).map((value, key) => {
                                            return (
                                              <Typography
                                                style={{ padding: 10, width: 300 }}
                                                onClick={() => {
                                                  handleContactSelection(value, "bill");
                                                }}
                                              >
                                                {handleContactAddress(value)}
                                              </Typography>
                                            );
                                          })}
                                        </Popover>
                                      )}
                                    </div>
                                    {/* {noBillData && (
                                      <div className="dataResult">
                                        <a className="dataItem">No data found</a>
                                      </div>
                                    )} */}
                                  </>
                                )}
                                {column.type !== "text_search" && (
                                  <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                                    {getValues1()[column.id] ? (
                                      <InputLabel
                                        shrink={true}
                                        htmlFor="component-simple"
                                        style={{ color: "#818080" }}
                                      >
                                        {column.label}
                                      </InputLabel>
                                    ) : (
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        {column.label}
                                      </InputLabel>
                                    )}
                                    {column.type === "select" &&
                                      column.id === "billingToState" && (
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={
                                            getValues1()[column.id]
                                              ? getValues1()[column.id]
                                              : ""
                                          }
                                          {...register1(column.id)}
                                          onChange={(e) => {
                                            handleContactSearch(e, column.id, "bill");
                                            setValue1(column.id, e.target.value);
                                          }}
                                        >
                                          {statesListData &&
                                            statesListData.map((State) => (
                                              <MenuItem key={State.id} value={State.name}>
                                                {State.name}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      )}
                                    {column.type === "select" &&
                                      column.id === "billingToCountry" && (
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={
                                            getValues1()[column.id]
                                              ? getValues1()[column.id]
                                              : "United States"
                                          }
                                          {...register1(column.id)}
                                          onChange={(e) => {
                                            onChangeCounry(e, column.id, "bill");
                                          }}
                                        >
                                          {countryListData &&
                                            countryListData.map((Country) => (
                                              <MenuItem
                                                key={Country.id}
                                                value={Country.name}
                                              >
                                                {Country.name}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      )}
                                    {column.type === "phone" && (
                                      <>
                                      <Grid container>
                                        <Grid item xs={2}>
                                          <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={
                                              billingCountryCode
                                                ? billingCountryCode
                                                : "+1"
                                            }
                                            onChange={(e) => {
                                              console.log(e.target);
                                              setBillingCountryCode(e.target.value);
                                              if(isSameAddress){
                                                setShippingCountryCode(e.target.value)
                                              }
                                            }}
                                          >
                                            {UniqueCountryCode &&
                                              UniqueCountryCode.map((Country) => (
                                                <MenuItem key={Country} value={Country}>
                                                  {`${Country}`}
                                                </MenuItem>
                                              ))}
                                          </Select>
                                        </Grid>
                                        <Grid item xs={10}>
                                          <Input
                                        id="component-simple"
                                        style={{ marginBottom: "10px", width:'100%' , marginLeft: '10px'}}
                                        {...register1(column.id, {
                                          required: "Please enter phone number",
                                          pattern: {
                                            value: /^(?!0+$)[0-9]{1,14}$/,
                                            message: "Invalid phone number",
                                          },
                                        })}
                                        placeholder="Enter your phone number"
                                        inputProps={{
                                          type: column.type,
                                          maxLength: 14,
                                          autoComplete: "off",
                                        }}
                                        onKeyPress={(event) => {
                                          if (!/^\d+$/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          handleContactSearch(e, column.id, "bill");
                                        }}
                                      />
                                        </Grid>
                                      </Grid>
                                    </>

                                    )}
                                    {column.type === "email" && (
                                      <Input
                                        id="component-simple"
                                        style={{ marginBottom: "10px" }}
                                        {...register1(column.id, {
                                          required: "Please enter email",
                                          pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Invalid email address",
                                          },
                                        })}
                                        inputProps={{
                                          type: column.type,
                                          autoComplete: "off",
                                        }}
                                        onChange={(e) => {
                                          handleContactSearch(e, column.id, "bill");
                                        }}
                                      />
                                    )}
                                    {column.type === "number" && (
                                      <Input
                                        id="component-simple"
                                        style={{ marginBottom: "10px" }}
                                        {...register1(column.id, {
                                          // required: "Please enter zipcode",
                                        })}
                                        inputProps={{
                                          type: "text",
                                          autoComplete: "off",
                                        }}
                                        onKeyPress={(event) => {
                                          if (!/^[A-Za-z\d\-_\s]$/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          handleContactSearch(e, column.id, "bill");
                                        }}
                                      />
                                    )}
                                    {column.type === "text" && (
                                      <Input
                                        id="component-simple"
                                        style={{ marginBottom: "10px" }}
                                        {...register1(column.id, {
                                          required: column.errMsg,
                                        })}
                                        inputProps={{
                                          type: column.type,
                                          autoComplete: "off",
                                        }}
                                        onChange={(e) => {
                                          handleContactSearch(e, column.id, "bill");
                                        }}
                                      />
                                    )}
                                    {errors1[column.id] && errors1[column.id]?.message && (
                                      <span style={{ color: "red" }}>
                                        {errors1[column.id].message}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </>
                            ))}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <div>
                            <div>
                              <Typography className="color-232323">
                                <Grid container>
                                  <Grid item xs={6} sm={6} md={6}>
                                    <b>SHIP TO</b>
                                  </Grid>
                                  {/* <Grid item xs={6} sm={6} md={6}>
                                        { !isShipTextInput ?
                                            <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('ship')}}>Click to Add Contact</Button>
                                            :
                                            <Button variant="text" style={{ background: "gray", color: "#fff", fontSize:"12px", padding:"4px" }} onClick={()=>{changeTextMode('ship')}}>Click to Search Contact</Button>
                                    }
                                    </Grid>   */}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    justifyContent="flex-end"
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="checkedB1"
                                          className="float-right"
                                          checked={isSameAddress}
                                          onChange={onChange}
                                        />
                                      }
                                      label="Same as bill address"
                                    />
                                  </Grid>
                                </Grid>
                              </Typography>
                            </div>
                            {isSubmitInvoice &&
                              !addressForm?.shippingToBusinessName &&
                              !addressForm?.shippingToName && (
                                <Grid item xs={12} lg={12} md={12}>
                                  <span style={{ color: "red" }}>
                                    You must fill out either business/contact name
                                  </span>
                                </Grid>
                              )}
                            {shippingAddress.map((column, i) => (
                              <>
                                {column.type === "text_search" && (
                                  <>
                                    <div className="searchInputs">
                                      <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                                        {shipCntctNm ? (
                                          <InputLabel
                                            htmlFor="shipCntctNm"
                                            shrink={true}
                                            style={{ color: "#424242" }}
                                          >
                                            {column.label}
                                          </InputLabel>
                                        ) : (
                                          <InputLabel
                                            htmlFor="shipCntctNm"
                                            shrink={false}
                                            style={{ color: "#424242" }}
                                          >
                                            {column.label}
                                          </InputLabel>
                                        )}
                                        <Input
                                          id="shipCntctNm"
                                          inputProps={{ type: "text" }}
                                          disabled={isSameAddress}
                                          autoComplete={"off"}
                                          value={shipCntctNm}
                                          {...register1(column.id, {
                                            required: column.required
                                              ? column.errMsg
                                              : false,
                                          })}
                                          style={{ marginBottom: "10px" }}
                                          onChange={(e) => {
                                            handleContactSearch(e, column.id, "ship");
                                          }}
                                        />
                                        {errors[column.id] && (
                                          <span style={{ color: "red" }}>
                                            {column.errMsg}
                                          </span>
                                        )}
                                        <div className="contactSearchIcon">
                                          <Grid container>
                                            <Grid item xs={6} sm={6} md={6}>
                                              {!isShipTextInput && (
                                                <>
                                                  {!shipCntctNm ? (
                                                    <SearchIcon
                                                      style={{ cursor: "pointer" }}
                                                    />
                                                  ) : (
                                                    <CloseIcon
                                                      id="clearBtn"
                                                      onClick={() => {
                                                        clearCntctSearchInput("ship");
                                                      }}
                                                      style={{ cursor: "pointer" }}
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </FormControl>
                                      {shipCntcts && shipCntcts.length > 0 && (
                                        <Popover
                                          id={column.id}
                                          open={open}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          PaperProps={{
                                            style: { height: "200px", overflow: "auto" },
                                          }}
                                        >
                                          {shipCntcts?.slice(0, 15).map((value, key) => {
                                            return (
                                              <Typography
                                                style={{ padding: 10, width: 300 }}
                                                onClick={() => {
                                                  handleContactSelection(value, "ship");
                                                }}
                                              >
                                                {handleContactAddress(value)}
                                              </Typography>
                                            );
                                          })}
                                        </Popover>
                                      )}
                                    </div>
                                    {/* {noShipData && (
                                      <div className="dataResult">
                                        <a className="dataItem">No data found</a>
                                      </div>
                                    )} */}
                                  </>
                                )}
                                {column.type !== "text_search" && (
                                  <FormControl className="form-width-85 margin-t-b-20 margin-p-b-20">
                                    {getValues1()[column.id] ? (
                                      <InputLabel
                                        shrink={true}
                                        htmlFor="component-simple"
                                        style={{ color: "#818080" }}
                                      >
                                        {column.label}
                                      </InputLabel>
                                    ) : (
                                      <InputLabel
                                        htmlFor="component-simple"
                                        style={{ color: "#424242" }}
                                      >
                                        {column.label}
                                      </InputLabel>
                                    )}
                                    {column.type === "select" &&
                                      column.id === "shippingToCountry" && (
                                        <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          // value={country}
                                          value={
                                            getValues1()[column.id]
                                              ? getValues1()[column.id]
                                              : "United States"
                                          }
                                          {...register1(column.id)}
                                          onChange={(e) => {
                                            setValue1(column.id, e.target.value);
                                            onChangeCounry(e, column.id);
                                          }}
                                          disabled={isSameAddress}
                                        >
                                          {countryListData &&
                                            countryListData.map((Country) => (
                                              <MenuItem
                                                key={Country.id}
                                                value={Country.name}
                                              >
                                                {Country.name}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      )}
                                    {column.type === "select" &&
                                      column.id === "shippingToState" && (
                                        <Select
                                          labelId="demo-simple-select-outlined-label-1"
                                          id="demo-simple-select-outlined-1"
                                          // value={country}
                                          value={
                                            getValues1()[column.id]
                                              ? getValues1()[column.id]
                                              : ""
                                          }
                                          {...register1(column.id)}
                                          onChange={(e) => {
                                            setValue1(column.id, e.target.value);
                                          }}
                                          disabled={isSameAddress}
                                        >
                                          {!isSameAddress &&
                                            shippingStatesListData &&
                                            shippingStatesListData.map((state) => (
                                              <MenuItem key={state.id} value={state.name}>
                                                {state.name}
                                              </MenuItem>
                                            ))}
                                          {isSameAddress &&
                                            statesListData &&
                                            statesListData.map((state) => (
                                              <MenuItem key={state.id} value={state.name}>
                                                {state.name}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      )}
                                    {column.type === "phone" && (
                                      <>
                                        <Grid container>
                                          <Grid item xs={2}>
                                            <Select
                                              labelId="demo-simple-select-outlined-label"
                                              id="demo-simple-select-outlined"
                                              value={
                                                shippingCountryCode
                                                  ? shippingCountryCode
                                                  : "+1"
                                              }
                                              onChange={(e) => {
                                                console.log(e.target);
                                                setShippingCountryCode(e.target.value);
                                              }}
                                              disabled={isSameAddress}
                                            >
                                              {UniqueCountryCode &&
                                                UniqueCountryCode.map((Country) => (
                                                  <MenuItem key={Country} value={Country}>
                                                    {`${Country}`}
                                                  </MenuItem>
                                                ))}
                                            </Select>
                                          </Grid>
                                          <Grid item xs={10}>
                                            <>
                                            <Input
                                              id="component-simple"
                                              style={{ marginBottom: "10px", width:'100%' , marginLeft: '10px'}}
                                              {...register1(column.id, {
                                                required: "Please enter phone number",
                                                pattern: {
                                                  value: /^(?!0+$)[0-9]{1,14}$/,
                                                  message: "Invalid phone number",
                                                },
                                              })}
                                              placeholder="Enter your phone number"
                                              inputProps={{
                                                type: column.type,
                                                maxLength: 14,
                                                minlength: 1,
                                                autoComplete: "off",
                                              }}
                                              onKeyPress={(event) => {
                                                if (!/^\d+$/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              disabled={isSameAddress}
                                            />
                                            </>
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                    {column.type === "email" && (
                                      <Input
                                        id="component-simple"
                                        style={{ marginBottom: "10px" }}
                                        {...register1(column.id, {
                                          required: "Please enter email",
                                          pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Invalid email address",
                                          },
                                        })}
                                        inputProps={{
                                          type: column.type,
                                          autoComplete: "off",
                                        }}
                                        disabled={isSameAddress}
                                      />
                                    )}
                                    {column.type === "number" && (
                                      <Input
                                        id="component-simple"
                                        style={{ marginBottom: "10px" }}
                                        {...register1(column.id, {
                                          // required: "Please enter zipcode",
                                        })}
                                        inputProps={{
                                          type: "text",
                                          autoComplete: "off",
                                        }}
                                        onKeyPress={(event) => {
                                          if (!/^[A-Za-z\d\-_\s]$/.test(event.key)) {
                                            event.preventDefault();
                                          }
                                        }}
                                        disabled={isSameAddress}
                                      />
                                    )}
                                    {column.type === "text" && (
                                      <Input
                                        id="component-simple"
                                        style={{ marginBottom: "10px" }}
                                        {...register1(column.id, {
                                          required: column.errMsg,
                                        })}
                                        inputProps={{
                                          type: column.type,
                                          autoComplete: "off",
                                        }}
                                        disabled={isSameAddress}
                                      />
                                    )}
                                    {errors1[column.id] &&
                                      errors1[column.id].message &&
                                      !isSameAddress && (
                                        <span style={{ color: "red" }}>
                                          {errors1[column.id].message}
                                        </span>
                                      )}
                                  </FormControl>
                                )}
                              </>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                      {isSubmitInvoice && (
                        <div class="d-spinner">
                          <SpinnerRoundFilled
                            size={51}
                            thickness={125}
                            speed={80}
                            color="rgba(57, 107, 172, 1)"
                            className="spp"
                            enabled={isSubmitInvoice}
                          />
                        </div>
                      )}
                      {!isSubmitInvoice && rows.length > 0 && (
                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Button
                            type="submit"
                            variant="text"
                            style={{
                              background: "#000",
                              color: "#fff",
                              float: "right",
                              marginTop: 20,
                              marginBottom: 20,
                            }}
                          >
                            Update Invoice
                          </Button>
                        </Grid>
                      )}
                    </form>
                    <Modal
                      open={openModal}
                      onClose={handleCloseModal}
                      aria-labelledby="modal-modal-title"
                    >
                      <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          {currentItem ? "Edit Item" : "Add Item"}
                        </Typography>
                        <form key={3} onSubmit={handleSubmit(onSubmit)}>
                          {invoiceObj.map((column) => {
                            return (
                              <>
                                {column.id === "modelNumber" && (
                                  <>
                                    <div className="searchInputs">
                                      <FormControl className="form-width-100">
                                        {modelNo ? (
                                          <InputLabel
                                            htmlFor="model"
                                            shrink={true}
                                            style={{ color: "#424242" }}
                                          >
                                            {column.label}
                                          </InputLabel>
                                        ) : (
                                          <InputLabel
                                            htmlFor="model"
                                            shrink={false}
                                            style={{ color: "#424242" }}
                                          >
                                            {column.label}
                                          </InputLabel>
                                        )}
                                        <Input
                                          id="model"
                                          inputProps={{ type: "text" }}
                                          autoComplete={"off"}
                                          value={modelNo}
                                          {...register(column.id, {
                                            required: column.required
                                              ? column.errMsg
                                              : false,
                                          })}
                                          style={{ marginBottom: "10px" }}
                                          onChange={(e) => {
                                            handleSearch(e, column.id);
                                          }}
                                        />
                                        {errors[column.id] && (
                                          <span style={{ color: "red" }}>
                                            {column.errMsg}
                                          </span>
                                        )}
                                      </FormControl>
                                      <div className="searchIcon">
                                        {!modelNo ? (
                                          <SearchIcon />
                                        ) : (
                                          <CloseIcon
                                            id="clearBtn"
                                            onClick={clearSearchInput}
                                          />
                                        )}
                                      </div>
                                      {model && model.length > 0 && (
                                        <Popover
                                          id={column.id}
                                          open={open}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          PaperProps={{
                                            style: { height: "200px", overflow: "auto" },
                                          }}
                                        >
                                          {model?.slice(0, 15).map((value, key) => {
                                            return (
                                              <Typography
                                                style={{ padding: 10, width: 300 }}
                                                onClick={() => {
                                                  handleSelection(value, "model");
                                                }}
                                              >
                                                {value["model"]}
                                              </Typography>
                                            );
                                          })}
                                        </Popover>
                                      )}
                                    </div>
                                    {noData && currentSearch === "modelNumber" && (
                                      <div className="dataResult">
                                        <a
                                          className="dataItem"
                                          onClick={() => {
                                            props.history.push("/dealer");
                                          }}
                                        >
                                          Click here to create new Inventory item
                                        </a>
                                      </div>
                                    )}
                                  </>
                                )}
                                {column.id === "serialNumber" && (
                                  <>
                                    <div className="searchInputs">
                                      <FormControl className="form-width-100">
                                        {serial ? (
                                          <InputLabel
                                            htmlFor="model"
                                            shrink={true}
                                            style={{ color: "#424242" }}
                                          >
                                            {column.label}
                                          </InputLabel>
                                        ) : (
                                          <InputLabel
                                            htmlFor="model"
                                            shrink={false}
                                            style={{ color: "#424242" }}
                                          >
                                            {column.label}
                                          </InputLabel>
                                        )}
                                        <Input
                                          id="model"
                                          inputProps={{ type: "text" }}
                                          autoComplete={"off"}
                                          value={serial}
                                          {...register(column.id, {
                                            required: column.required
                                              ? column.errMsg
                                              : false,
                                          })}
                                          style={{ marginBottom: "10px" }}
                                          onChange={(e) => {
                                            handleSearch(e, column.id);
                                          }}
                                        />
                                        {errors[column.id] && (
                                          <span style={{ color: "red" }}>
                                            {column.errMsg}
                                          </span>
                                        )}
                                      </FormControl>
                                      <div className="searchIcon">
                                        {!serial ? (
                                          <SearchIcon />
                                        ) : (
                                          <CloseIcon
                                            id="clearBtn"
                                            onClick={clearSearchInput}
                                          />
                                        )}
                                      </div>
                                      {serialList && serialList.length > 0 && (
                                        <Popover
                                          id={column.id}
                                          open={open}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          PaperProps={{
                                            style: { height: "200px", overflow: "auto" },
                                          }}
                                        >
                                          {serialList?.slice(0, 15).map((value, key) => {
                                            return (
                                              <Typography
                                                style={{ padding: 10, width: 300 }}
                                                onClick={() => {
                                                  handleSelection(value, "serial");
                                                }}
                                              >
                                                {value["serialno"]}
                                              </Typography>
                                            );
                                          })}
                                        </Popover>
                                      )}
                                    </div>
                                    {noData && currentSearch === "serialNumber" && (
                                      <div className="dataResult">
                                        <a
                                          className="dataItem"
                                          onClick={() => {
                                            props.history.push("/dealer");
                                          }}
                                        >
                                          Click here to create new Inventory item
                                        </a>
                                      </div>
                                    )}
                                  </>
                                )}
                                {column.id !== "action" &&
                                  (column.type === "text" || column.type === "number") && (
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                                      {column.id === "quantity" ? (
                                        <InputLabel
                                          shrink={qtyShrink}
                                          htmlFor="component-simple"
                                          className="color-292C2F"
                                        >
                                          {column.label}
                                        </InputLabel>
                                      ) : column.id === "productName" ? (
                                        <InputLabel
                                          shrink={descShrink}
                                          htmlFor="component-simple"
                                          className="color-292C2F"
                                        >
                                          {column.label}
                                        </InputLabel>
                                      ) : (
                                        <InputLabel
                                          htmlFor="component-simple"
                                          className="color-292C2F"
                                        >
                                          {column.label}
                                        </InputLabel>
                                      )}
                                      <Input
                                        id="component-simple"
                                        value={
                                          column.id === "productName"
                                            ? desc
                                            : column.id === "quantity"
                                            ? 1
                                            : null
                                        }
                                        {...register(column.id, {
                                          required: column.required ? column.errMsg : false,
                                        })}
                                        inputProps={{
                                          type: column.type,
                                          autoComplete: "off",
                                        }}
                                        readOnly={column.id === "quantity" ? true : false}
                                        disableUnderline={false}
                                      />
                                      {errors[column.id] && (
                                        <span style={{ color: "red" }}>
                                          {column.errMsg}
                                        </span>
                                      )}
                                    </FormControl>
                                  )}
                                {(column.type === "select" || column.type === "amount") && (
                                  <FormControl
                                    className={`form-width-${column.minWidth} margin-t-b-20 margin-p-b-20`}
                                    style={{
                                      marginRight: column.type === "select" ? "16px" : 0,
                                    }}
                                  >
                                    <InputLabel
                                      htmlFor="component-simple"
                                      className="color-292C2F"
                                    >
                                      {column.label}
                                    </InputLabel>
                                    {column.type === "select" ? (
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={currency}
                                        onChange={(e) => {
                                          console.log(e.target);
                                          setCurrency(e.target.value)
                                        }}
                                      >
                                        <MenuItem value="USD">USD</MenuItem>
                                        <MenuItem value="HK$">HK$</MenuItem>
                                        <MenuItem value="Euro">Euro</MenuItem>
                                        <MenuItem value="AED">AED</MenuItem>
                                      </Select>
                                    ) : (
                                      <Input
                                        id="component-simple"
                                        {...register(column.id, { required: true })}
                                        inputProps={{
                                          type: "text",
                                          maxLength: "15",
                                          autoComplete: "off",
                                        }}
                                        disableUnderline={false}
                                        value={amount}
                                        onChange={(e) => {
                                          const val = e.target.value.replace(
                                            /[^0-9\.]/g,
                                            ""
                                          );
                                          setAmount(val);
                                        }}
                                      />
                                    )}
                                    {errors[column.id] && (
                                      <span style={{ color: "red" }}>{column.errMsg}</span>
                                    )}
                                  </FormControl>
                                )}
                              </>
                            );
                          })}
                          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                              type="submit"
                              variant="text"
                              style={{
                                background: !noData ? "#000" : "#cccc",
                                color: "#fff",
                                marginTop: "20px",
                                marginRight: "10px",
                              }}
                              disabled={noData}
                            >
                              Submit
                            </Button>
                            &nbsp;
                            <Button
                              variant="outlined"
                              onClick={handleCloseModal}
                              style={{
                                background: "#fff",
                                color: "#000",
                                marginTop: "20px",
                                paddingTop: "5 !important",
                                paddingBottom: "5 !important",
                              }}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    </Modal>
                </>
            )}
      </Container>
      <FooterComponent />
      {contactListConfirmationModal && (
        <ContactListModal
          open={contactListConfirmationModal}
          close={handleCloseContactConfirmation}
          confirm={handleSuccessContactConfirmation}
          text={modalText}
        />
      )}
    </div>
  );
}

function mapStateToProps({auth, InvoiceDetails,ModelList,BillShipCntctLst }) {
    return {
        invoiceDetails:InvoiceDetails.invoiceDetails,
        currentUser: auth.currentUser,
        modelList: ModelList.modelList,
        billShipCntctLst: BillShipCntctLst.billShipCntctLst
    }
}

 
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createInvoiceDetails,
        getModelSerialList,
        checkContactInDB,
        getBillShipContactList
    }, dispatch)
}

CreateInvoiceComponent.propTypes = {
    modelList: PropTypes.arrayOf(PropTypes.object).isRequired,
    getModelSerialList: PropTypes.func.isRequired,
    getBillShipContactList: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoiceComponent);