import { combineReducers } from "redux";
import auth from "./../../components/Auth/reducer/reducer";
// import browseWatchesInfo from './../../components/pages/browse-watches/reducers/browseWatchesReducers'
import individualBrowseWatchesInfo from './../../components/pages/dealer/reducers/dealerReducer'
// import dashboard from './../../components/pages/dashboard/reducers/dashboardReducers'
// import home from './../../components/pages/home/reducers/homeReducer'



import BrowseWatchesReducers from '../../components/pages/browse-watches/reducers/browseWatchesReducers';
import IndividualBrowseWatchInfo from '../../components/pages/dealer/reducers/dealerReducer';
import BrandInfo from '../../components/pages/dealer/reducers/dealerReducer';
import BrandInfoByBrandName from '../../components/pages/browse-watches/reducers/browseWatchesReducers';
import caseMaterialInfo from '../../components/pages/dealer/reducers/dealerReducer';
import CaseMaterialByMaterial from '../../components/pages/browse-watches/reducers/browseWatchesReducers';
import dealer from '../../components/pages/dealer/reducers/dealerReducer';
import ModelInfoByModelName from '../../components/pages/dealer/reducers/dealerReducer';
import dialColorInfo from '../../components/pages/dealer/reducers/dealerReducer';
import DialColorInfoByDialColor from '../../components/pages/dealer/reducers/dealerReducer';
import ModelListInfo from '../../components/pages/dealer/reducers/dealerReducer';
import caseSizeInfo from '../../components/pages/dealer/reducers/dealerReducer';
import CaseSizeBySize from '../../components/pages/browse-watches/reducers/browseWatchesReducers';
import SubscriptionPlanInfo from '../../components/pages/dealer/reducers/dealerReducer';
import UserDetailsInfo from '../../components/pages/home/reducers/homeReducer';
import CountryListInfo from "../../components/pages/signup/reducers/SignUpReducer";
import UserCountryIdInfo from '../../components/pages/home/reducers/homeReducer';
import StateListInfo from '../../components/pages/signup/reducers/SignUpReducer';
import UserStateIdInfo from '../../components/pages/home/reducers/homeReducer';
import CityListInfo from '../../components/pages/signup/reducers/SignUpReducer';
import UserCityIdInfo from '../../components/pages/home/reducers/homeReducer';
import InventoryList from './../../components/pages/dashboard/reducers/dashboardReducers';
import InvoiceList from '../../components/pages/dealer/reducers/dealerReducer';
import InvoiceDetails from '../../components/pages/dashboard/reducers/dashboardReducers';
import ModelList from '../../components/pages/dashboard/reducers/dashboardReducers';
import InventoryidList from '../../components/pages/dashboard/reducers/dashboardReducers';
import BrandModelList from '../../components/pages/dashboard/reducers/dashboardReducers';
import BillShipCntctLst from '../../components/pages/dashboard/reducers/dashboardReducers';
import AdminReducer from "../../components/pages/administration/reducers/adminReducer";
import ContactReducer from "../../components/pages/administration/reducers/contactReducer";

export default combineReducers({
  auth,
  browseWatchesInfo: BrowseWatchesReducers,
  individualBrowseWatchesInfo: IndividualBrowseWatchInfo,
  brandInfo: BrandInfo,
  BrandInfoByBrandName: BrandInfoByBrandName,
  caseSizeInfo: caseSizeInfo,
  CaseSizeBySize: CaseSizeBySize,
  caseMaterialInfo: caseMaterialInfo,
  CaseMaterialByMaterial: CaseMaterialByMaterial,
  dealer,
  ModelInfoByModelName: ModelInfoByModelName,
  dialColorInfo: dialColorInfo,
  DialColorInfoByDialColor: DialColorInfoByDialColor,
  ModelListInfo: ModelListInfo,
  SubscriptionPlanInfo: SubscriptionPlanInfo,
  userDeatilsInfo: UserDetailsInfo,
  CountryListInfo: CountryListInfo,
  UserCountryIdInfo: UserCountryIdInfo,
  StateListInfo: StateListInfo,
  UserStateIdInfo: UserStateIdInfo,
  CityListInfo: CityListInfo,
  UserCityIdInfo: UserCityIdInfo,
  InventoryList: InventoryList,
  InvoiceList: InvoiceList,
  InvoiceDetails: InvoiceDetails,
  ModelList: ModelList,
  InventoryidList: InventoryidList,
  brandInfo: BrandModelList,
  BillShipCntctLst: BillShipCntctLst,
  Admin: AdminReducer,
  ContactManagement: ContactReducer
});

