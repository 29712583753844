import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DealerChooseSubscriptionsComponent from './dealerChooseSubscriptionsComponent';
import DealerPayment from './dealerPayment';
import { bindActionCreators } from 'redux';
import { toast } from "react-toastify";
import DealerSubscriptionSuccessfullDealerPayment from './dealerSubscriptionSuccessfull'
import DealerSubscriptionSuccessfull from './dealerSubscriptionSuccessfull';
import DealerSubscriptionThankYou from './dealerSubscriptionThankYou';
import SubscriptionComponent from './subscriptionComponent';
import RegisterComponent from './../../../Auth/register/components/registerComponent';
import {
  updateBusinessDetails, updateBillingDetails,
  saveBillingDetails, updatePaymentDetails
} from './../actions/dealerAction';
import { getuser, getuserdetails } from "./../../dashboard/actions/dashboardAction";
const Loader = require('react-loader');


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(props) {
  if (props.currentUser && props.currentUser.userId) {
    // return ['Step 1 Change Subscription', 'Step 2 Make Payment/Checkout', 'Step 3 Confirmation Details'];
    return ['Choose Subscription', 'Business Details', 'Billing Details', 'Make Payment/Checkout', 'Confirmation Details'];
  }
  else {
    return [];
    // 'Step 1 Choose Subscription', 'Step 2 Business Details', 'Step 3 Billing Details', 'Step 4 Make Payment/Checkout', 'Step 5 Confirmation Details'
  }

}


function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(props);
  const setStep = (value) => {
    setActiveStep(value);
  };


  const addBusinessDetails = (value) => {
    if (value) {
      updateBusinessDetails(value, (error, res) => {
        if (error) {
          console.log(error, "dattta");
        } else {
          setActiveStep(1);
        }
      });
    } else {
      setActiveStep(1);
    }

  };



  const addBillingDetails = (value) => {
    if (value) {
      props.saveBillingDetails(value)
      setActiveStep(1);
    } else {
      setActiveStep(1);
    }

  };

  const addPaymentDetails = (value) => {
    
    if (value) {
      updateBillingDetails(value, (error, res) => {
        const checkoutdata = res.data
        localStorage.setItem("checkoutdetails", JSON.stringify(checkoutdata))
        if (error) {
        } else {
          if (res.success == 1) {
            let attributes = {
              "card_number": value.card_number,
              "exp_month": value.exp_month,
              "exp_year": value.exp_year,
              "cvc": value.cvc
            }
            let data = {
              "user_id": value.user_id,
              "membertier_id": value.membertier_id,
              "order_id": res.data.order_id,
              "gateway": value.gateway,
              "listing_addon": true,
              "card": attributes
            };
            updatePaymentDetails(data, (error, res) => {
              if (error) {
                toast(res && res.message, {
                  autoClose: 5000,
                  type: toast.TYPE.ERROR,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              } else {
                if (res && res.success === 1) {
                  toast(res.message, {
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  setActiveStep(5);
                  localStorage.removeItem("savedbildetails")
                  localStorage.removeItem("singlepkg")
                  const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null
                  if(userDetails && userDetails?.id){
                      getuser(userDetails?.id)
                  }
                }
                else if (res && res.success === 0) {
                  toast(res && res.message, {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                }
                else {
                  toast("Somthing went wrong", {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                }
              }
            })

          }
          else {
            toast("Somthing went wrong", {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        }
      });

    } else {
      setActiveStep(2);
    }
  };



  const confirmtDetails = (value) => {
    //setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };




  function getStepContent(stepIndex) {
    console.log(props.currentUser,"dsds")
    const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"))
    if (userDetails && userDetails?.userId) {
      switch (stepIndex) {

        case 0:
          return <SubscriptionComponent
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addBillingDetails}
            selectedPackage={props.selectedPackage}
          />;
        case 1:
          return <RegisterComponent
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addBillingDetails}
          />;
        case 2:
          return <DealerChooseSubscriptionsComponent
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addPaymentDetails}
            backSubmit={handleBack}
            selectedPackage={props.addBusinessDetails}
          />;
        case 3:
          return <DealerPayment
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addPaymentDetails}
          // submit={confirmtDetails}
          // selectedPackage={props.addBusinessDetails}

          />;
        case 4:
          return <DealerSubscriptionSuccessfullDealerPayment
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={confirmtDetails}
          />;
        case 5:
          return <DealerSubscriptionThankYou
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={confirmtDetails}
          />;

        default:
          return <DealerSubscriptionThankYou />;

      }
    }
    else {
      switch (stepIndex) {
        case 0:
          return <SubscriptionComponent
            history={props.history}
            handleNext={handleNext}
            setStep={setStep}
          />;
        case 1:
          return <RegisterComponent
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addBusinessDetails}
          />;
        case 2:
          return <DealerChooseSubscriptionsComponent
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addBillingDetails}
            selectedPackage={props.selectedPackage}
          />;
        case 3:
          return <DealerPayment
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addPaymentDetails}
            selectedPackage={props.selectedPackage}
          />;
        case 4:
          return <DealerSubscriptionSuccessfull
            handleNext={handleNext}
            setStep={setStep}
            submit={confirmtDetails}
          />;
        case 5:
          return <DealerSubscriptionThankYou
            handleNext={handleNext}
            setStep={setStep}
            submit={confirmtDetails}
          />;

        default:
          return <DealerSubscriptionThankYou />;
      }
    }
  }

  return (

    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel style={{ fontSize: '18px' }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
        <div className="float-right">
            </div>
      </div>
    </div>
  );
}

function mapStateToProps({ dealer, auth }) {
  return {
    selectedPackage: dealer.selectedPackage,
    currentUser: auth.currentUser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveBillingDetails,
    updatePaymentDetails
  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(HorizontalLabelPositionBelowStepper);