import React, { Component } from 'react';
import validator from 'validator';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Paper, Typography, TextField, Grid, Button, FormHelperText, FormControl, InputLabel,Input,Link,Select, MenuItem} from '@material-ui/core';
import google from '../../../../assests/images/google.png';
import facebook from '../../../../assests/images/facebook.png';
import insta from '../../../../assests/images/insta.png';
// import '../../../common/style/main.css'
import '../../../../common/style/main.css'
import getUserdetailsforsubscrption from '../../../pages/dashboard/actions/dashboardaapi'
import { countriesList, statesList, updateUserData } from "./../../../Auth/reducer/actions";


class RegisterDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name:'',
      businessName:'',
      emailId:'',
      phoneNumber:'',
      businessStreetAddress:'',
      businessStreetAddress2:'',
      code:'',
      city:'',
      state:'',
      country:231,
      countryId:231,
      onNameErrorText:'',
      onBusinessNameErrorText:'',
      onEmailIdErrorText:'',
      onPhoneNumberErrorText:'',
      onBusinessStreetAddressErrorText2:'',
      onCountryErrorText:'',
      onCodeErrorText:'',
      onCityErrorText:'',
      onStateErrorText:'',
      countryListData:[],
      statesListData:[],
      isValid : false,
    }
    
    this.onBusinessNameChange = this.onBusinessNameChange.bind(this);
 
  
    this.onBusinessStreetAddressChange = this.onBusinessStreetAddressChange.bind(this);
    this.onCityChange = this.onCityChange.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this)
   
    this.onBusinessStreetAddressChange2 = this.onBusinessStreetAddressChange2.bind(this)
    this.getstatebycountyId = this.getstatebycountyId.bind(this);
    this.getStatesList    =  this.getStatesList.bind(this);
    this.getUsersList    =  this.getUsersList.bind(this);
  }
 
  onBusinessNameChange(event){
    let BusinessNameVal = event.target.value;
    if(!BusinessNameVal){
      this.setState({
        onBusinessNameErrorText:"Enter proper Business Name"
      })
    }
    else{
      this.setState({
        onBusinessNameErrorText:"",
        businessName:event.target.value
      })
    }
  }

  componentDidMount() {
    this.getcountryList();
    this.getStatesList();
    this.getUsersList()
  }
  getcountryList(){
    getUserdetailsforsubscrption.getAllCountryList().then((res) => {
      console.log('countrylist', res.data.data);
      this.setState({
          countryListData: res.data.data
      })
  })
  }

  onBusinessStreetAddressChange(event){
    let BusinessStreetAddressVal = event.target.value;
    if(!BusinessStreetAddressVal){
      this.setState({
        onBusinessStreetAddressErrorText:"Enter Proper Address"
      })
    }
    else{
      this.setState({
        onBusinessStreetAddressErrorText:"",
        businessStreetAddress:event.target.value
      })
    }
  }
  onBusinessStreetAddressChange2(event){
    let BusinessStreetAddressVal = event.target.value;
    if(!BusinessStreetAddressVal){
      this.setState({
        onBusinessStreetAddressErrorText2:"Enter Proper Address"
      })
    }
    else{
      this.setState({
        onBusinessStreetAddressErrorText2:"",
        businessStreetAddress2:event.target.value
      })
    }
  }
  getstatebycountyId(event){
    console.log(event.target.value)
    this.setState({
      country:event.target.value
    })
    console.log(this.state.country)
    getUserdetailsforsubscrption.getAllStateList(event.target.value).then((res) => {
      console.log('stateList', res.data.data);
      this.setState({
        statesListData: res.data.data
      })
    })
    // let countryVal = validator.isAlpha(event.target.value);

    // if(!countryVal){
    // console.log(countryVal)

    //   this.setState({
    //     onCountryErrorText:"Enter correct City"
    //   })
    // }
    // else{
    // console.log(event.target.value)
    //   this.setState({
    //     onCountryErrorText:"",
    //     country:event.target.value
    //   })
    // }
    console.log(this.state.statesListData,"kk",this.state)
  }
  
  getUsersList(){
    const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"))
    getUserdetailsforsubscrption.getUserDetails(userDetails?.userId).then((res) => {
        console.log('getUsersList',res.data.data)
        const response = res.data.data

      this.setState({
        businessName: response?.businessname,
        businessStreetAddress:response?.accountaddressline1,
        businessStreetAddress2:response?.accountaddressline2,
        countryId:response?.accountcountry,
        state:response?.accountstate,
        city:response?.accountcity,
        code:response?.accountzip
      })
  })
  }
  getStatesList(){
    getUserdetailsforsubscrption.getAllStateList(this.state.countryId).then((res) => {
      this.setState({
        statesListData: res.data.data
      })
  })
  }

  onCityChange(event){
    let cityVal = event.target.value;
    if(!cityVal){
      this.setState({
        onCityErrorText:"Enter correct City"
      })
    }
    else{
      this.setState({
        onCityErrorText:"",
        city:event.target.value
      })
    }
  }
  onStateChange(event){
    console.log(event.target.value)
    // let stateVal = validator.isAlpha(event.target.value);
    // if(!stateVal){
    //   this.setState({
    //     onStateErrorText:"Enter correct State"
    //   })
    // }
    // else{
      this.setState({
        state:event.target.value
      })
    // }
    console.log(this.state.state)
  }



  onChangeOfZipcode = (event) => {
    if (event.target.value !== "") {
    let codeVal = validator.isNumeric(event.target.value);
    if (!codeVal) {
      this.setState({
        ...this.state,
        onCodeErrorText: "Enter correct Zip Code",
        isValid: false,
      })
    }
    else {
      this.setState({
        ...this.state,
        onCodeErrorText: "",
        code: event.target.value,
        isValid: true
      })
    }
  }else {
    this.setState({
            ...this.state,
            onCodeErrorText: "",
            code: event.target.value,
            isValid: true,
          })
        }
  }

  onCodeChange(event){
    // let codeVal = event.target.value;
    // if(!codeVal){
    //   this.setState({
    //     onCodeErrorText:"Enter correct Code"
    //   })
    // }
    // else{
    //   this.setState({
    //     onCodeErrorText:"",
    //     code:event.target.value
    //   })
    // }
  }

  validateInput() {
    console.log(this.state)
    let onNameErrorText = '';
    let onBusinessNameErrorText = '';
    let onBusinessStreetAddressErrorText = '';
    // let onBusinessStreetAddressErrorText2 = '';
    let onCodeErrorText = '';
    let onCityErrorText = '';
    let onStateErrorText = '';
    let onCountryErrorText = '';

    let isValid = true;
    // if (this.state.name.length === 0) {
    //   onNameErrorText = 'Enter Correct Email Id';
    //     isValid = false;
    // }
    if (this.state.businessName.length === 0) {
      onBusinessNameErrorText = 'Enter Correct Business Name';
        isValid = false;
    }
    if (this.state.businessStreetAddress.length === 0) {
      onBusinessStreetAddressErrorText = 'Enter Correct Business Street Address';
        isValid = false;
    }
    // if (this.state.businessStreetAddress2.length === 0) {
    //   onBusinessStreetAddressErrorText2 = 'Enter Correct Business Street Address';
    //     isValid = false;
    // }
 
    if (this.state.code.length < 5 ) {
      onCodeErrorText = 'Enter Code';
        isValid = false;
    }
    if (this.state.city.length === 0) {
      onCityErrorText = 'Enter Correct City';
        isValid = false;
    }
    if (this.state.country.length === 0) {
      onCountryErrorText = 'Enter country';
        isValid = false;
    }
    if (this.state.state.length === 0) {
      onStateErrorText = 'Enter Correct State';
        isValid = false;
    }
    this.setState({...this.state,
      onNameErrorText,
      onBusinessNameErrorText,
      onBusinessStreetAddressErrorText,
      // onBusinessStreetAddressErrorText2,
      onCountryErrorText,
      onCityErrorText,
      onStateErrorText,
      onCodeErrorText
   });
   return isValid;
  }
  submitForm(event) {
    console.log("data",this.state)
    if (this.validateInput()) {
      let data = {
        "business_name": this.state.businessName,
        "address_line1": this.state.businessStreetAddress,
        // "address_line2": this.state.businessStreetAddress2,
        "country": (this.state.country ) ? this.state.country : this.state.countryId,
        "state": this.state.state,
        "city": this.state.city,
        "zip": this.state.code,
     
      };
      console.log(data);

      if (this.props.submit) {
        this.props.submit(data);
        console.log(data)
        this.props.setStep(2);
      } else {
        updateUserData(JSON.stringify(data), (error, res) => {
          if (!error) {

          }
        });
      }
    }
  }

  getContentView() {
    console.log('this.state',this.state);
    const countryListData = this.props.countryList;
    const statesListData = this.props.statesListInfo;
    console.log(countryListData,statesListData)
    return (
        <div>
          <Container maxWidth="sm" className="space-3">
              <Paper className="border-radius">
              {/* <div className="text-center">
              <Typography variant="h5" gutterBottom align="center" className="text-white">
                  Tell us a little bit about yourself 
              </Typography>
              </div> */}
                 <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='subscribe_user_update_form'>
                  {/* <div className="float-right">
                    <Link href="/search" className="font-size-18 font-weight-600">Skip</Link>
                  </div> */}
                  <Grid container spacing={1}>

                      <Grid item xs={12} sm={12} md={12}>
                      <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                            <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>BUSINESS NAME</InputLabel>
                             <Input id="component-simple"
                             value={this.state.businessName} 
                                onChange={this.onBusinessNameChange}
                             />
                             <FormHelperText className="error-o" >{this.state.onBusinessNameErrorText}</FormHelperText>
                      </FormControl>
                      <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                            <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>ADDRESS LINE 1</InputLabel>
                             <Input id="component-simple"
                             value={this.state.businessStreetAddress}  
                                onChange={this.onBusinessStreetAddressChange}
                             />
                             <FormHelperText className="error-o" >{this.state.onBusinessStreetAddressErrorText}</FormHelperText>
                      </FormControl>
                         
                      <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                            <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>ADDRESS LINE 2</InputLabel>
                             <Input id="component-simple" 
                             value={this.state.businessStreetAddress2}  
                                onChange={this.onBusinessStreetAddressChange2}
                             />
                             {/* <FormHelperText className="error-o" >{this.state.onBusinessStreetAddressErrorText2}</FormHelperText> */}
                      </FormControl>
                      
                      <FormControl className="registerSpace form-width-100">
                    <InputLabel id="demo-simple-select-label" style={{color:'#424242'}}>COUNTRY</InputLabel>
                    
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.countryId}
                      defaultValue={this.state.countryId}
                      onChange={this.getstatebycountyId}
                      label="Country"
                    >
                      {
                        this.state.countryListData && this.state.countryListData.map((item) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem> 
                        })
                      }
                    </Select>
                    <FormHelperText className="error-o">{this.state.countryErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="registerSpace form-width-100">
                    <InputLabel id="demo-simple-select-label" style={{color:'#424242'}}>STATE</InputLabel>
                    
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.state}
                      defaultValue={this.state.state}
                      onChange={this.onStateChange}
                      label="Country"
                    >
                      {
                        this.state.statesListData && this.state.statesListData.map((item) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>
                        })
                      }
                    </Select>
                    <FormHelperText className="error-o">{this.state.stateErrorText}</FormHelperText>
                  </FormControl>
                         
                      {/* <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                            <InputLabel htmlFor="component-simple" className="color-BCBCBC">COUNTRY</InputLabel>
                             <Input id="component-simple" 
                                onChange={this.onCountryChange}
                             />
                             <FormHelperText className="error-o" >{this.state.onCountryErrorText}</FormHelperText>
                      </FormControl> */}
                      
                      {/* <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                            <InputLabel htmlFor="component-simple" className="color-BCBCBC">STATE</InputLabel>
                             <Input id="component-simple" 
                                onChange={this.onStateChange}
                             />
                             <FormHelperText className="error-o" >{this.state.onStateErrorText}</FormHelperText>
                      </FormControl> */}
                      </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                      <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                            <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>CITY</InputLabel>
                             <Input id="component-simple" 
                             value={this.state.city}
                                onChange={this.onCityChange}
                              
                             />
                             <FormHelperText className="error-o" >{this.state.onCityErrorText}</FormHelperText>
                      </FormControl>
                      
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                      <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                            <InputLabel htmlFor="component-simple" style={{color:'#424242'}}> ZIP CODE</InputLabel>
                             <Input id="component-simple" 
                              inputProps={{ maxLength: 5 }}
                              value={this.state.code}
                                onChange={this.onChangeOfZipcode}
                             />
                             <FormHelperText className="error-o" >{this.state.onCodeErrorText}</FormHelperText>
                      </FormControl>
                        
                      </Grid>
                      <div style={{display:'flex',width:'100%'}}>
                      {/* <Button style={{backgroundColor:'white'}} >Back</Button> */}
                      <Button type="submit" className="SubmitColor  space-marign-20" fullWidth >Next</Button>
                      </div>
                  </Grid>
                  </form>
                      {/* <div className="text-center1 padding1">
                        <Typography variant="small" gutterBottom align="center" className="color-p text-center1">
                              Already have an account?&nbsp;
                                <Link href="/" className="notVendorLink">
                                  Login
                                </Link>
                        </Typography>
                      </div> */}
                      {/* <div>
                        <Container maxWidth="sm">
                        <Typography variant="h6" align="center" className="space-1">With your social network</Typography>
                        <Grid container spacing={3} className="padding">
                          <Grid item xs={4} sm={4} md={3}></Grid>
                          <Grid item xs={4} sm={4} md={2}>
                            <div>
                              <img src={google} width="40px" />
                            </div>
                            <Box className="box-login-google">
                            <i class="fa fa-google" aria-hidden="true"></i>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4} md={2}>
                            <div>
                              <img src={facebook} width="40px"/>
                            </div>
                            <Box className="box-login-facebook">
                              <i class="fa fa-facebook" aria-hidden="true"></i>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <div>
                              <img src={insta} width="40px"/>
                            </div>
                          <Box className="box-login-twitter">
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4} md={1}></Grid>
                          <Grid item xs={4} sm={4} md={3}>
                          <Box className="box-login-pinterest">
                          <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                            </Box>
                          </Grid>
                        </Grid>
                        </Container>
                      </div>
                      <div className="space-2"></div> */}
              </Paper>
          </Container>
        </div>
    );
  }
  render(){
    return(
      <>{this.getContentView()}</>
    // <ContentWrapper
    //     contentView={this.getContentView(
          
    //     )}
    //     /> 
        )
}
}


export default RegisterDetails;





