import React ,{useState}from 'react'
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@mui/material/TextField';
import { toast } from "react-toastify";


import { Button, DialogActions, FormControl, FormHelperText ,DialogContentText,Box} from "@material-ui/core";
import {
  removeAccount
  } from "./../../dashboard/actions/dashboardAction";
  import { addDays, format } from 'date-fns';

export default function RemoveAccountModal(props) {
    const { open, handleClose} = props;

    const [reason, setReason] = useState('')
    const [errorText, setErrorText] = useState('')

    const futureDate = addDays(new Date(), 30); // Add 30 days to current date
    const futureDateString = format(futureDate, ' d,MMMM, yyyy'); // Format future date as string

    const onChangeOfRemoveReason = () => {
      const userDetails = window.sessionStorage.getItem('userDetails');
      const removeuser = JSON.parse(userDetails).userId;
        // const removeuser= localStorage.getItem('UserId');
    console.log (removeuser,"removeuserid")
    const reasonval=reason
          console.log(reasonval,"reason val")
        if (reason !== '') {
          
          const data = {
    
            'deletedReason': reason,
            
          }
          removeAccount(removeuser, data, (success, error) => {
            if (success) {
              console.log('remove account success',success);
            
              handleClose(true);
            }else{
              toast("Somthing went wrong", {
                autoClose: 5000,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          })
        } else {
   
          setErrorText('Remove reason should not be blank.')
        }
      }
    

    
  return (
    <Dialog
    open={open}
    onClose={handleClose}
  // aria-labelledby="alert-dialog-title"
  // aria-describedby="alert-dialog-description"
  >
    <DialogTitle >
      {"Delete Account"}
      <IconButton onClick={handleClose} style={{ float: "right" }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
     
      <DialogContentText
      >
        Your profile and account details will be deleted
        on <b>{futureDateString}</b> .You won't be visible on Watchops
        between now and then .If you change Your mind you can log back in
        before the deletion date and choose to keep your account.
      </DialogContentText>

      <DialogContentText

      >Why do you want to delete Watchops? </DialogContentText>

      <Box sx={{
        '& .MuiFormControl-root': { width: 500 },
      }}>
        <FormControl>
          <TextField
            id="outlined-multiline-flexible"

            multiline
            maxRows={10}


            variant="outlined"
            placeholder="..anyreason"

            onChange={(e)=>{setReason(e.target.value);setErrorText('')}}
            // onChange={(event) => {

            //   this.setState({
            //     removeReason: event.target.value,
            //     RemoveReasonErrortext: ""
            //   })

            // }}
          />

          <FormHelperText className="error-o">{errorText}</FormHelperText>

        </FormControl>
      </Box>

    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onChangeOfRemoveReason}>Delete Account</Button>
      <Button color="primary" onClick={handleClose} autoFocus>
        cancel
      </Button>
    </DialogActions>
  </Dialog>

  )
}

