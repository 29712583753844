import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from 'redux';
import { toast } from "react-toastify";

import DealerChooseSubscriptionsComponent from './dealerChooseSubscriptionsComponent';
import DealerPayment from './dealerPayment';
import DealerSubscriptionSuccessfull from './dealerSubscriptionSuccessfull';
import DealerSubscriptionThankYou from './dealerSubscriptionThankYou';
import {
  updateBillingDetails,
  saveBillingDetails, updatePaymentDetails
} from './../actions/dealerAction';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps(props) {
    return ['Step 1 Billing Details', 'Step 2 Make Payment/Checkout', 'Step 3 Confirmation Details'];

}


function HorizontalLabelPositionBelowStepperWith(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(props);
  const setStep = (value) => {
    setActiveStep(value);
  };


  const addBillingDetails = (value) => {
    if (value) {
      props.saveBillingDetails(value)
      setActiveStep(1);
    } else {
      setActiveStep(1);
    }

  };

  const addPaymentDetails = (value) => {
    
    if (value) {
      updateBillingDetails(value, (error, res) => {
        console.log(value,"something123")
        if (error) {
          console.log(error,);
        } else {
          if (res.success == 1) {
            let attributes = {
              "card_number": value.card_number,
              "exp_month": value.exp_month,
              "exp_year": value.exp_year,
              "cvc": value.cvc
            }
            let data = {
              "user_id": value.user_id,
              "membertier_id": value.membertier_id,
              "order_id": res.data.order_id,
              "gateway": value.gateway,
              "listing_addon": true,
              "card": attributes
            };
            updatePaymentDetails(data, (error, res) => {
              console.log(res,"checkoutdetails==")
              if (error) {
                toast("Please Check Card Details", {
                  autoClose: 5000,
                  type: toast.TYPE.ERROR,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }else{
              if (res.success == 1) {
                setActiveStep(3);
              }
              else {
                toast("Somthing went wrong", {
                  autoClose: 5000,
                  type: toast.TYPE.ERROR,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
            }
            })

          }
          else {
            toast("Somthing went wrong", {
              autoClose: 5000,
              type: toast.TYPE.SUCCESS,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        }
      });

    } else {
      setActiveStep(2);
    }
  };



  const confirmtDetails = (value) => {
    //setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };




  function getStepContent(stepIndex) {
      switch (stepIndex) {
        case 0:
          return <DealerChooseSubscriptionsComponent
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addBillingDetails}
            selectedPackage={props.selectedPackage}
          />;
        case 1:
          return <DealerPayment
            {...props}
            handleNext={handleNext}
            setStep={setStep}
            submit={addPaymentDetails}
            backSubmit={handleBack}
            selectedPackage={props.selectedPackage}
          />;
        case 2:
          return <DealerSubscriptionSuccessfull
            handleNext={handleNext}
            setStep={setStep}
            submit={confirmtDetails}
          />;

        default:
          return <DealerSubscriptionThankYou />;
      }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel style={{ fontSize: '18px' }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
        <div className="float-right">
          {/* <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Back
            </Button> */}
          {/* <Button variant="contained" color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button> */}
        </div>
      </div>
      {/* <div>
        {activeStep === steps.length ? (
          <div>
            <DealerSubscriptionThankYou/>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : ""}
      </div> */}
    </div>
  );
}

function mapStateToProps({ dealer, auth }) {
  return {
    selectedPackage: dealer.selectedPackage,
    currentUser: auth.currentUser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveBillingDetails,
    updatePaymentDetails
  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(HorizontalLabelPositionBelowStepperWith);