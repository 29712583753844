export const isLogin = () => {
  return true;
};

export const getToken = () => {
  return localStorage.getItem("token");
}

export const isLoggedIn = (token = null) => {
  if (!token) {
    token = getToken();
  }
  if (token) {
    let tokenData = JSON.parse(atob(token.split('.')[1]));
    if (tokenExpired(tokenData.exp)) {
      return {
        userId: tokenData.result.userid,
        countryId: tokenData.result.country,
        email: tokenData.result.email,
        firstName: tokenData.result.firstname,
        lastName: tokenData.result.lastname,
        roleId: tokenData.result.roleid,
      }
    } else {
      return false;
    }

  } else {
    return false;
  }
};

export const tokenExpired = (exp) => {
  let today = new Date().getTime();
  today = (today - (today % 1000)) / 1000;
  return today < exp;

}

export const getAuth = () => {
  return function (dispatch) {
    console.log(dispatch);
  }
}
