import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Paper, Typography, TextField, Grid, Button,FormControl,InputLabel, Input, FormHelperText, Card } from '@material-ui/core';
import '../../../../common/style/main.css';
import { toast } from "react-toastify";
import { updatePassword, getInvitationExpiryStatus } from "./../../reducer/actions";
import { InputAdornment, Select, MenuItem } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import queryString from "query-string";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const CryptoJS = require("crypto-js");

class ResetPasswordComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'password',
      score: 'null',
      newPassword: '',
      repeatPassword: '',
      newpasswordErrorText: '',
      repeatpasswordErrorText: '',
      isValid:false,
      isInviteExpired: false
    }
    this.showHide = this.showHide.bind(this);
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
    this.onRepeatPasswordChange = this.onRepeatPasswordChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
  }
  showHide(e){
    
    e.preventDefault();
    e.stopPropagation();
    this.setState({
     
      type: this.state.type === 'input' ? 'password' : 'input'
    })  
  }
  onNewPasswordChange(event){
    let newPasswordVal =event.target.value;
    if(!newPasswordVal){
      this.setState({...this.state,
        newpasswordErrorText:"Enter Valid Password",
        isValid:false
      });
    }else{
      this.setState({...this.state,
        newPassword:event.target.value,
        newpasswordErrorText:"",
        isValid:true
      });
    }
  }
  onRepeatPasswordChange(event){
    let repeatPasswordVal = event.target.value;
    if(!repeatPasswordVal){
      this.setState({...this.state,
        repeatpasswordErrorText:"Enter Valid Password",
        isValid:false
      });
    }else{
      this.setState({...this.state,
        repeatPassword:event.target.value,
        repeatpasswordErrorText:"",
        isValid:true
      });
    }
  }
  validateInput() {
    let newpasswordErrorText = '';
    let repeatpasswordErrorText =  '';
   
    let isValid = true;
    if (this.state.newPassword.length === 0) {
      newpasswordErrorText = 'Enter Correct New Password';
        isValid = false;
    }
    if (this.state.repeatPassword.length === 0) {
      repeatpasswordErrorText = 'Enter Correct Repeat Password';
        isValid = false;
    }

    if (this.state.newPassword != this.state.repeatPassword) {
      repeatpasswordErrorText = 'Passwords do not match';
      isValid = false;
    }
    this.setState({...this.state,
      newpasswordErrorText,
      repeatpasswordErrorText
   });
   return isValid;
  }
  componentDidMount(){
    let queriesNew = this.props?.location?.search || "";
    let uuid = queriesNew ? queriesNew.replace('?uuid=','') : "";
    let decryptedData = '';
    if(uuid){
      const bytes = CryptoJS.AES.decrypt(uuid.toString(), 'YxMvw3SsTEmk0eoIPBfmmg');
      console.log(bytes)
      decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      console.log(decryptedData)
    }
    const data = {
      email : decryptedData
    }
    if(decryptedData){
      console.log("decrypted data")
      getInvitationExpiryStatus(data, (error,result)=>{
        if(error){
          console.log("data:error", result)
        }else if(result && result.success && result.success === 1){
          this.setState({
           isInviteExpired : result.data?.inviteUserExipre
          })
        }
      })
        
    }
    
  }
  submitForm(event) {
    if (this.validateInput()) 
    {
      let queriesNew = this.props?.location?.search || "";
      let uuid = queriesNew ? queriesNew.replace('?uuid=','') : "";
      let decryptedData = '';
      if(uuid){
        const bytes = CryptoJS.AES.decrypt(uuid.toString(), 'YxMvw3SsTEmk0eoIPBfmmg');
        console.log(bytes)
        decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        console.log(decryptedData)
      }
      let data ={};
        data = {
          'new_password': this.state.newPassword,
          'email':decryptedData,
         };
     
      const { history } = this.props;
      updatePassword(data, (error, res) => {
        if(error){
          toast("Somthing went wrong", {
                autoClose: 5000,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_CENTER,
              });

        }else{
        if(res.success == 1){
          toast("Password reset successfully", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
          history.push("/login");
        }else{
          toast('Somthing went wrong', {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
       }
        }
      })
      
    }
  }

  getContentView() {
    console.log(this.props)
   
    return (
      <>
      { this.state.isInviteExpired ?  (
      <Card  style={{display: 'flex', width:'600px', alignItems:'center', margin: 'auto', marginTop: '150px', padding:'40px'}}>
      <div style={{display: 'flex', flexDirection:'column', alignItems:'center',}}>
      <ErrorOutlineIcon color="primary" sx={{ fontSize: 40 }} />
      <h1>Invitation Expired</h1>
      <h4>This invitation link has expired. Please request a new link from administrator or login.</h4>
      </div>
           </Card>): (
            <div>
              <Container maxWidth="sm" className="space-3">
                  <Paper className="border-radius">
                  <div className="text-center">
                    <Typography variant="h5" gutterBottom align="center" className="text-white">
                      Change Password
                    </Typography>
                  </div>
                  <div className="text-center1">
                    <Typography variant="small">
                      To change your account password, please fill in <br/>the fields below
                    </Typography>
                  </div>
                  <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='otp' >
                      <Grid container spacing={1}>

                          <Grid item xs={12} sm={12} md={12}>
                          {/* <FormControl className="form-width-100  ">
                            <InputLabel htmlFor="component-simple" className="color-BCBCBC">PASSWORD</InputLabel>
                              <Input id="component-simple"
                               type={this.state.type}
                               onChange={this.onChangeOfPassword}
                               defaultValue={this.state.password}
                              />
                      <FormHelperText className="error-o">{this.state.passwordError}</FormHelperText>
                    </FormControl> */}
                          <FormControl className="form-width-100  ">
                             <InputLabel htmlFor="component-simple" className="color-BCBCBC">NEW PASSWORD</InputLabel>
                               <Input id="component-simple"
                                 type={this.state.type}

                                  onChange={this.onNewPasswordChange}
                                  endAdornment={<InputAdornment position="end">
                               <span onClick={this.showHide} style={{ color: '#CEA05F' }}>{this.state.type === 'input' ? <VisibilityIcon style={{cursor:'pointer'}}/> : <VisibilityOffIcon style={{cursor:'pointer'}}/>}</span></InputAdornment>}
                                 />
                            <FormHelperText className="error-o">{this.state.newpasswordErrorText}</FormHelperText>
                          </FormControl>
                             
                          <FormControl className="form-width-100  ">
                            <InputLabel htmlFor="component-simple" className="color-BCBCBC">CONFIRM PASSWORD</InputLabel>
                              <Input id="component-simple"
                               type={this.state.type}
                               
                                onChange={this.onRepeatPasswordChange}
                                
                              />
                             <FormHelperText className="error-o">{this.state.repeatpasswordErrorText}</FormHelperText>
                           </FormControl>
                              {/* <TextField
                              required
                              id="standard-password-input"
                              label="REPEAT PASSWORD"
                              type="Password"
                              className="registerSpace"
                              onChange={this.onRepeatPasswordChange}
                              fullWidth
                              />
                              <FormHelperText className="error-o">{this.state.repeatpasswordErrorText}</FormHelperText> */}
                              <Button type='submit' className="SubmitColor space-m-1" fullWidth={true} >Submit</Button>
                          </Grid>
                      </Grid>
                      </form>
                      <div className="space-1"></div>
                  </Paper>
              </Container>
            </div>
            )}
            </>
    );
  }
  render(){
    return(<ContentWrapper
        contentView={this.getContentView()}
        />
        )
}
}

function mapStateToProps({ auth }) {
  return {
    recoveryPasswordInfo: auth.recoveryPasswordInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent);




