import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UpgradeImage from './utils/upgrade-plan.png';
import { Typography } from '@material-ui/core';
import logo from "../../../assests/images/logof.png";
import { useHistory } from "react-router-dom";


export default function UpgradePlanComponent({isOpen}) {
  const [open, setOpen] = React.useState(isOpen);
  const history = useHistory();

  const handleClose = (event,reason) => {
    if (reason === "backdropClick") {
        console.log(reason);
      } else {
        setOpen(false);

        history.push("/dealersubscribtions");
        // window.location.href='/dealersubscribtions';
      }
    
  };
  const handleBackdropClick = (event) => {
    //these fail to keep the modal open
    event.stopPropagation();
    return false;
  };
  
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown
      >
        <DialogContent>
        <DialogContentText>
                <div><img src={logo} alt="watchops-logo" style={{display:'flex',margin: 'auto'}}/></div>
                <div style={{textAlign: 'center', margin: '10px'}}><Typography variant="h6">Your free trail has ended</Typography></div>
                <div style={{background: '#E6E7EA', padding: '10px', borderRadius: '10px'}}>
                    <img
                        src={UpgradeImage} 
                        alt="upgrade Subscription Pla dddn"
                        style={{display:'flex',margin: 'auto'}}
                    />
                </div>
                <br></br>
                <div>
                <Typography variant="subtitle1" className="color-383838">
                But it's not too late to take next step. Upgrade to a paid plan to keep
                        using features like Product Search and Inventories.                      </Typography>  
                 </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-evenly', marginBottom:'20px'}}>
          <Typography variant="subtitle1">Please Contact Support info@watchops.com For Further Assistance</Typography>
          {/* <Button style={{width: '250px'}} className="SubmitColor" onClick={handleClose}><b>Subscribe</b></Button> */}
        </DialogActions>
      </Dialog>
  );
}