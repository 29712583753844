import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import ContentWrapper from '../../../../common/components/contentWrapper';
import ologo from '../../../../assests/images/ologo.png';
import { TextField, Button, Grid, Container, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { searchProducts } from '../actions/dealerAction';
import IconButton from "@material-ui/core/IconButton";
import { fetchSearchBrowseWatches, } from "../../browse-watches/actions/browseWatchesActions";
import CircularProgress from "@material-ui/core/CircularProgress"
import _ from "lodash";

class DealerSearchProductComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchedTerm: '',
            newValue: '',
            cursor: 0,
            searchString: "",
            searchItems:[],
            nonsearchstring: false,
            searchStringdetails: "",
            searchSubString:"",
            newInputValue: '',
            autocomplete: {
                currentInput: '',
                loading: false,
                data: []
            },
            selected: { product: null },
            loading: false,

        }
        this.submitSearchTerm = this.submitSearchTerm.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.autocomplete = this.autocomplete.bind(this);
        this.hanldeKeyup = this.hanldeKeyup.bind(this);
        this.hanldeKeydown = this.hanldeKeydown(this)
        this.handleListKeydown = this.handleListKeydown.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getAutocomplete = _.debounce(this.getAutocomplete, 500);

    }
    componentWillReceiveProps(newProps) {
        this.state.autocomplete.data = newProps.autocompleteProducts.list
        this.setState({
            ...this.state,
        })
    }



    onchangeSearch(event) {
        this.setState({
            searchedTerm: event.target.value
        })
    }

    submitSearchTerm() {
        console.log(this.state.searchString)
        this.props.history.push('/browsewatches/' + this.state.searchSubString)

    }
    handleListKeydown(evt) {
        console.log(evt);
        console.log(this.state.searchString);
    
      }
    
      handleChange(evt) {
        console.log(this.state.searchString);
        this.setState({ item: { [evt.target.name]: evt.target.value } });
      }

    autocomplete = (evt) =>{

        // console.log("autocomplte",evt.target.value);
        let text = evt.target.value;
        // if(/^[A-Za-z0-9\d\s]+$/.test(text)){
        //     console.log("true",text);
        //     this.setState({ searchString: text });
        //     this.setState({ loading: true });
        // }else{
        //     console.log("false");
        //     this.setState({ searchString: (text.length > 0) ? text : "" });
        //     this.setState({ loading: true });
        // }
        var string = text.replace(/[&\/\\#,+!@^&()$~%.'":;*?<>{}]/g, '');
        console.log("string", string);
        this.setState({ searchString: text, searchSubString:string });
        this.setState({ loading: true });
        this.getAutocomplete(string);
      }
      getAutocomplete = (evt) => {
        console.log(evt,"getcom")
        console.log(this.state.searchString.length,evt);
        const texytt = evt
        this.props.searchProducts({ searchText: evt }, (error, res) => {
          this.setState({ searchItems: this.state.autocomplete.data });
          this.setState({ loading: false });
          if (this.state.autocomplete.data.length > 0) {
            this.setState({
              nonsearchstring: true,
            })
          } else {
            this.setState({
              nonsearchstring: false,
            })
    
          }
        });
    
        console.log(this.state.autocomplete.data);
      };
    selectItem(item) {
        console.log(item)
        this.setState({
            nonsearchstring: false,
        })
        console.log(this.state.searchString);
        const { searchItems } = this.state;
        let selectedItem = searchItems.find((item) => item.model === item.model);

        this.setState({ searchString: selectedItem.productname });
        this.setState({ searchStringdetails: selectedItem.productname });
        console.log(this.state.searchString, "somedata");
        this.setState({ autocomplete: { data: [] } });
        this.props.history.push('/browsewatches/' + item.model + "/" + item.productname)

        // this.props.fetchSearchBrowseWatches(id);

    }
    hanldeKeyup(evt) {

    }
    hanldeKeydown(evt) {


    }


    onProductChange = _.debounce((e, v, r) => {
        // this.props.onTextInputAdd(text);
        console.log(e);
        if (v) {
          switch (e) {
            case "product_selected":
              this.setState({
                ...this.state,
                selected: { product: v },
              });
              // this.props.history.push('/browsewatches/' + v.model)
              break;
            case "input_changed":
              this.state.autocomplete.currentInput = v;
    
              this.state.autocomplete.loading = true;
              this.setState({
                ...this.state,
              });
              if (v.length >= 3 && r == "input") {
                this.props.searchProducts({ searchText: v }, (error, res) => { });
              }
              break;
          }
        } else {
          console.log("dddd");
        }
      }, 600);


    getContentView() {
        const { cursor } = this.state;
        const browseWatchesInfo = this.props.browseWatchesInfo;
        return (
            <div style={{ background: "#fff" }}>
                <Container maxWidth="lg">
                    <Grid container spacing={3}>
                        <Grid item xs={0} sm={2} md={4}></Grid>

                        <Grid item xs={9} sm={12} md={4}>
                            <div>
                                <div className="text-center1" style={{marginLeft:'30px'}}>
                                    <img src={ologo} alt="impresari_logo" />
                                </div>
                                <div style={{ margin: "0 auto"}}>
                                    <div className="form-group">
                                        
                                        <input
                                            type="text"
                                            id="autocomplete"
                                            placeholder="Choose a Watch"
                                            onChange={this.autocomplete}
                                            onKeyUp={this.hanldeKeyup}
                                            onKeyDown={this.hanldeKeydown}
                                            value={this.state.searchString}
                                            className="custom-input form-control searchkeyword"
                                        />
                                        {/* {console.log(this.state.autocomplete.data.length,this.state.searchString.length,)} */}
                                        {this.state.loading ? (
                                            <CircularProgress />
                                        ) : (

                                                this.state.autocomplete.data.length > 1 && this.state.nonsearchstring ? (

                                                    <ul className="list-group displaydropdown ">
                                                        {/* {console.log(this.state.nonsearchstring,this.state.autocomplete.data)} */}
                                                        {this.state.autocomplete.data.map((item, idx) => (
                                                            <li
                                                                className={
                                                                    cursor === idx
                                                                        ? "active list-group-item"
                                                                        : "list-group-item"
                                                                }
                                                                key={idx}
                                                                onClick={() => this.selectItem(item)}
                                                                onKeyDown={(evt) =>
                                                                    this.handleListKeydown(evt, item.model)
                                                                }
                                                            >
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container spacing={0}>
                                                                            <Grid item xs={12} sm={6} md={6}>
                                                                                <IconButton color="primary">
                                                                                    <img
                                                                                        width="45"
                                                                                        src={
                                                                                            item.imagepaths.images.toString().split(",")[0]
                                                                                        }
                                                                                    />{" "}
                                                                                    {/*Mock image, attribute in option*/}
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} md={12}>
                                                                                        <li style={{ marginTop: "25px" }}>{item.brandname + " " + item.model}</li>
                                                                                        {item.family && <li>{item.family}</li>}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <hr></hr>

                                                                    </Grid>
                                                                </Grid>


                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : null
                                            )}
                                    </div>
                                </div>
                                </div>
                               </Grid>
                                <Grid>
                                <div style={{display:"flex",justifyContent:"center",marginTop:'174px'}}>                                    {/* <br /> */}
                                    <Button   id="search"    variant="contained"
                        className="button-slider2 buttonslider3"  onClick={this.submitSearchTerm}>SEARCH</Button>
                                </div>
                                </Grid>
                                

                                <br />
                                <br />
                           
                        
                        <Grid container spacing={2}>
                    
                  </Grid>

                        <Grid item xs={0} sm={2} md={4}></Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
    render() {
        return (
            <ContentWrapper
                contentView={this.getContentView()}
            />
        );
    }
}
function mapStateToProps({ dealer }) {
    return {
        autocompleteProducts: dealer.autoCompleteProducts
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        searchProducts,
        fetchSearchBrowseWatches,

    },
        dispatch)
}



// navigator.geolocation.getCurrentPosition(showMap);
export default connect(mapStateToProps, mapDispatchToProps)(DealerSearchProductComponent);