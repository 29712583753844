const constants = {
    BASE_URL: 'https://wopstestapi.watchops.com/',
    ENDPOINT: 'https://wopstestapi.watchops.com/',
    SHARE_INVENTORY: 'https://wopstest.watchops.com/',
    PAGINATION_LIMIT: 12,
    PAGINATION_START: 1,
    MODEL_LIMIT: 100,
    DEALER_LIMIT: 50,
    DASHBOARD_DEALER_LIMIT: 1000,
    USER_INACTIVE_TIME: 43200000, // 12hours 43200000
    PUBNUB_PUBLISHKEY: 'pub-c-81dee72f-fe65-43da-9784-9b42743498c6',
    PUBNUB_SUBSCRIBEKEY: 'sub-c-22d82d4f-6813-4ee5-bedc-8c557ebb0f1e',
    PUBNUB_UUID: 'sec-c-ZWY3YjA1YjktNDU3My00Njk3LWE4MzctZmQ0OWU4N2ExMzA0'
}
export default constants;