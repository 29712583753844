import React, { useState } from "react";
import {
  Grid,
  Paper,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import * as Styled from '../../components/styled-components/Styled-Components';
import FileUploadIcon from "@mui/icons-material/FileUpload";
// import appConfig from "../../config/appConfig";
import Close from "@mui/icons-material/Close";
import NoPreview from "../../assests/images/no-preview.png";
import PreviewPdfmodel from "./previewComponent";
// import PdfUpload from './pdfloaderComponent';

const FileUploadGroup = ({
  onFilesChange,
  imageDetails,
  page,
  deleteExistingData,
  id = "fileInput"
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [isVideo, setIsVideo] = useState(false);
  const [pdfpreview, setPdfpreview] = useState(false);
  const userDetails = JSON.parse(window.sessionStorage.getItem("userDetails"));
  const [pdfUploadCheck, setpdfUploadCheck] = useState(false);

  const [pdf, setpdf] = useState("");

  const handlePreviewOpen = (src, isVideo) => {
    setPreviewSrc(src);
    setIsVideo(isVideo);
    if (userDetails.roleId == 2 || userDetails.roleId == 3) {
      setOpenPreview(true);
    }
  };

  const handlePreviewClose = () => {
    setPreviewSrc("");
    setIsVideo(false);
    setOpenPreview(false);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files);
    const formData = new FormData();
    formData.set("charitableEventId", 20);
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "webp",
      "svg",
      "jfif",
    ];
    const videoExtensions = ["mp4", "webm", "ogg", "mkv", "avi"];
    const presentationExtensions = ["ppt", "pptx"];
    const documentExtensions = ["doc", "docx", "pdf", "xls", "xlsx", "Xlsx"];

    let alertErr = "";

    const exceedSizeLimit = newFiles.some((file) => {
      const fileExtension = getFileExtension(file?.name);
      if (imageExtensions.includes(fileExtension)) {
        alertErr = "Please upload a file with a size less than 25MB.";
        return file.size > 25 * 1000 * 1000;
      } else if (videoExtensions.includes(fileExtension)) {
        alertErr = "Please upload a file with a size less than 500MB.";
        return file.size > 500 * 1000 * 1000;
      } else if (presentationExtensions.includes(fileExtension)) {
        alertErr = "Please upload a presentation with a size less than 100MB."; // Set your size limit
        return file.size > 100 * 1000 * 1000;
      } else if (documentExtensions.includes(fileExtension)) {
        alertErr = "Please upload a document with a size less than 100MB."; // Set your size limit
        return file.size > 100 * 1000 * 1000;
      }
    });

    if (exceedSizeLimit) {
      alert(alertErr);
      return;
    }
    //multiple files logic
    // const fileData = [...selectedFiles, ...newFiles];
    const singleFilter = [];
    singleFilter.push(newFiles[0]);
    const fileData = [...singleFilter];
    fileData?.forEach((file, index) => {
      formData.append("uploadFiles", file);
    });
    //multiple files logic
    // setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setSelectedFiles([...singleFilter]);
    onFilesChange(formData, fileData);
  };

  const handleDownload = (file) => {
    const blobUrl = URL.createObjectURL(file);
    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    downloadLink.download = file.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blobUrl);
  };

  const handleDownloadUrl = (fileUrl) => {
    // You can directly use the file URL for the image source
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl; // Assuming fileUrl is the direct HTTPS image URL
    downloadLink.download = "downloaded_image.jpg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleDelete = (fileToDelete) => {
    const formData = {};
    const deletedFiles = selectedFiles.filter((file) => file !== fileToDelete);
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToDelete)
    );
    onFilesChange(formData, deletedFiles);
  };

  const getFileType = (fileName) => {
    const fileExtension = getFileExtension(fileName);
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "webp",
      "svg",
      "jfif",
    ];
    const videoExtensions = ["mp4", "webm", "ogg", "mkv", "avi"];
    const presentationExtensions = ["ppt", "pptx"];
    const zipExtensions = ["zip", "rar"];
    const documentExtensions = ["doc", "docx", "pdf", "xls", "xlsx", "Xlsx"];
    if (imageExtensions.includes(fileExtension)) {
      return "image";
    } else if (videoExtensions.includes(fileExtension)) {
      return "video";
    } else if (presentationExtensions.includes(fileExtension)) {
      return "presentation";
    } else if (documentExtensions.includes(fileExtension)) {
      return "document";
    } else if (zipExtensions.includes(fileExtension)) {
      return "zip";
    } else {
      return "unknown";
    }
  };

  const getFileExtension = (fileName) => {
    const file_name = fileName?.toLowerCase();
    return file_name?.slice(((file_name.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const fileFormatDecide = (file) => {
    const splitvalues = file?.split("/");
    const lastValue = splitvalues[splitvalues.length - 1]?.split("-");
    return lastValue[lastValue.length - 1];
  };

  const handleclose = () => {
    setPdfpreview(false);
  };

  const documentOnPreview = (file) => {
    if (userDetails.roleId == 2 || userDetails.roleId == 3) {
      setPdfpreview(!pdfpreview);
      setpdf(URL.createObjectURL(file));
    }
  };

  const imageFormatDecide = (file) => {
    const splitvalues = file?.split("/");
    const lastValue = splitvalues[splitvalues.length - 1]?.split(".");
    const formatter = lastValue[lastValue.length - 1];
    if (formatter == "xlsx" || formatter == "pdf") {
      return NoPreview;
    }
    return file;
  };

  const imageFormatOnclick = (file) => {
    const splitvalues = file?.split("/");
    const lastValue = splitvalues[splitvalues.length - 1]?.split(".");
    const formatter = lastValue[lastValue.length - 1];
    if (userDetails.roleId == 2 || userDetails.roleId == 3) {
      if (formatter == "pdf") {
        setPdfpreview(!pdfpreview);
        //setpdfUploadCheck(!pdfUploadCheck)
        if (file?.imageUrl) {
          setpdf(file?.imageUrl);
        } else {
          const blobConv = file;
          setpdf(blobConv);
        }
      } else {
        handlePreviewOpen(file, false);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      {page !== "view" && (
        <Grid item xs={12} md={8.7}>
          <input
            type="file"
            id={id}
            multiple
            onChange={handleFileChange}
            accept="image/*, video/*, .pdf, .zip, .rar, .ppt, .pptx, .doc, .docx, .xls, .xlsx, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword"
            style={{ display: "none" }}
          />
          <label htmlFor={id}>
            <Button
              variant="outlined"
              component="span"
              sx={{
                mt: 1,
                mb: 3,
                textTransform: "none !important",
                width: "100%",
                height: "35px",
              }}
              style={{
                color: "#fff",
                border: "1px solid #fff",
                background: "#CEA05F",
              }}
              // className="xxs-normal"
              startIcon={<FileUploadIcon sx={{ fontSize: 15 }} />}
            >
              Click here to upload
            </Button>
          </label>
          {selectedFiles?.length !== 0 ? (
            <Typography
              component="p"
              sx={{ color: "black" }}
              className="xxs-normal"
            >
              Attached Files
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      )}
      <Grid
        container
        spacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="files-container"
        style={{ margin: "-5px 0 20px 0", maxHeight: 450, }}
      >
        {page === "view" &&
          imageDetails &&
          imageDetails?.length !== 0 &&
          imageDetails?.map((file, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  position: "relative",
                  boxShadow: "none",
                  border: "1px dashed #D6D6D6",
                }}
              >
                <div className="file-preview" style={{ position: "relative" }}>
                  {file?.fileName &&
                    getFileType(file?.fileName) === "image" && (
                      <img
                        src={file.imageUrl}
                        onClick={() => handlePreviewOpen(file.imageUrl, false)}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "140px",
                          objectFit: "cover",
                          borderRadius: 5,
                          cursor:
                            userDetails.roleId == 2 || userDetails.roleId == 3
                              ? "pointer"
                              : "default",
                        }}
                      />
                    )}
                  {file?.fileName &&
                    getFileType(file?.fileName) === "video" && (
                      <video
                        style={{
                          width: "100%",
                          height: "140px",
                          objectFit: "cover",
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                        height="auto"
                        onClick={() => {
                          handlePreviewOpen(file.imageUrl, true);
                        }}
                        controls
                      ></video>
                    )}
                  {((file?.fileName &&
                    getFileType(file?.fileName) === "presentation") ||
                    (file?.fileName &&
                      getFileType(file?.fileName) === "document")) && (
                      <img
                        src={NoPreview}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "140px",
                          objectFit: "cover",
                          borderRadius: 5,
                          cursor: "default",
                        }}
                      />
                    )}
                </div>
                <div
                  className="file-actions"
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleDownloadUrl(file.imageUrl);
                    }}
                    style={{
                      width: 30,
                      height: 30,
                      background: "#fff",
                      marginRight: 5,
                    }}
                  >
                    <CloudDownloadIcon
                      sx={{
                        fontSize: 15,
                        borderRadius: "50%",
                        color: "#123261",
                      }}
                    />
                  </IconButton>
                </div>
                <div
                  className="file-name xxs-normal"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                >
                  {file.fileName}
                </div>
              </Paper>
            </Grid>
          ))}
        {page === "Edit" &&
          imageDetails &&
          imageDetails?.length !== 0 &&
          imageDetails?.map((file, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  position: "relative",
                  boxShadow: "none",
                  border: "1px dashed #D6D6D6",
                }}
              >
                <div className="file-preview" style={{ position: "relative" }}>
                  {file && (
                    <img
                      src={imageFormatDecide(file)}
                      onClick={() => imageFormatOnclick(file)}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "140px",
                        objectFit: "cover",
                        borderRadius: 5,
                        cursor:
                          userDetails.roleId == 2 || userDetails.roleId == 3
                            ? "pointer"
                            : "default",
                      }}
                    />
                  )}
                  {file?.fileName &&
                    getFileType(file?.fileName) === "image" && (
                      <img
                        src={file.imageUrl}
                        onClick={() => handlePreviewOpen(file.imageUrl, false)}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "140px",
                          objectFit: "cover",
                          borderRadius: 5,
                          cursor:
                            userDetails.roleId == 2 || userDetails.roleId == 3
                              ? "pointer"
                              : "default",
                        }}
                      />
                    )}
                  {file?.fileName &&
                    getFileType(file?.fileName) === "video" && (
                      <video
                        style={{
                          width: "100%",
                          height: "140px",
                          objectFit: "cover",
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                        height="auto"
                        onClick={() => {
                          handlePreviewOpen(file.imageUrl, true);
                        }}
                        controls
                      ></video>
                    )}
                  {((file?.fileName &&
                    getFileType(file?.fileName) === "presentation") ||
                    (file?.name && getFileType(file?.name) === "zip")) && (
                      <img
                        src={NoPreview}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "140px",
                          objectFit: "cover",
                          borderRadius: 5,
                          cursor:
                            userDetails.roleId == 2 || userDetails.roleId == 3
                              ? "pointer"
                              : "default",
                        }}
                      />
                    )}
                  {/* <img src={file.imageUrl} alt="Preview" style={{ width: '100%', height: '140px', objectFit: 'cover', borderRadius: 5  }} /> */}
                  <div
                    className="file-actions"
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  >
                    {userDetails.roleId == 2 || userDetails.roleId == 3 ? (
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownloadUrl(file);
                        }}
                        style={{
                          width: 30,
                          height: 30,
                          background: "#fff",
                          marginRight: 5,
                        }}
                      >
                        <CloudDownloadIcon
                          sx={{
                            fontSize: 15,
                            borderRadius: "50%",
                            color: "#123261",
                          }}
                        />
                      </IconButton>
                    ) : null}
                    {/* <IconButton onClick={(e) => { e.preventDefault();  deleteExistingData(file)}} style={{width: 30, height: 30, background: '#fff'}}>
                    <DeleteIcon sx={{fontSize: 15, borderRadius:"50%", color:'#123261'}}/>
                  </IconButton> */}
                  </div>
                  <div
                    className="file-name xxs-normal"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    {fileFormatDecide(file) || "-"}
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}

        {page === "Edit" &&
          selectedFiles &&
          selectedFiles.map((file, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  position: "relative",
                  boxShadow: "none",
                  border: "1px dashed #D6D6D6",
                }}
              >
                <div className="file-preview" style={{ position: "relative" }}>
                  {getFileType(file?.name) === "image" && (
                    <img
                      src={URL.createObjectURL(file)}
                      onClick={() =>
                        handlePreviewOpen(URL.createObjectURL(file), false)
                      }
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "140px",
                        objectFit: "cover",
                        borderRadius: 5,
                        cursor:
                          userDetails.roleId == 2 || userDetails.roleId == 3
                            ? "pointer"
                            : "default",
                      }}
                    />
                  )}

                  {file?.name && getFileType(file?.name) === "video" && (
                    <video
                      style={{
                        width: "100%",
                        height: "140px",
                        objectFit: "cover",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                      height="auto"
                      src={URL.createObjectURL(file)}
                      onClick={() =>
                        handlePreviewOpen(URL.createObjectURL(file), true)
                      }
                      controls
                    ></video>
                  )}
                  {file?.name && getFileType(file?.name) === "document" && (
                    <img
                      src={NoPreview}
                      alt="Preview"
                      onClick={() => documentOnPreview(file)}
                      style={{
                        width: "100%",
                        height: "140px",
                        objectFit: "cover",
                        borderRadius: 5,
                        cursor:
                          userDetails.roleId == 2 || userDetails.roleId == 3
                            ? "pointer"
                            : "default",
                      }}
                    />
                  )}
                  {file?.name && getFileType(file?.name) === "presentation" && (
                    <img
                      src={NoPreview}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "140px",
                        objectFit: "cover",
                        borderRadius: 5,
                        cursor:
                          userDetails.roleId == 2 || userDetails.roleId == 3
                            ? "pointer"
                            : "default",
                      }}
                    />
                  )}
                  <div
                    className="file-actions"
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  >
                    {userDetails.roleId == 2 || userDetails.roleId == 3 ? (
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownload(file);
                        }}
                        style={{
                          width: 30,
                          height: 30,
                          background: "#fff",
                          marginRight: 5,
                          cursor:
                            userDetails.roleId == 2 || userDetails.roleId == 3
                              ? "pointer"
                              : "default",
                        }}
                      >
                        <CloudDownloadIcon
                          sx={{
                            fontSize: 15,
                            borderRadius: "50%",
                            color: "#123261",
                          }}
                        />
                      </IconButton>
                    ) : null}
                    {/* <IconButton onClick={(e) => { e.preventDefault();  handleDelete(file)}} style={{width: 30, height: 30, background: '#fff'}}>
                    <DeleteIcon sx={{fontSize: 15, borderRadius:"50%", color:'#123261'}}/>
                  </IconButton> */}
                  </div>
                  <div
                    className="file-name xxs-normal"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    {file.name}
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}
        {page !== "view" &&
          page !== "Edit" &&
          selectedFiles.map((file, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <Paper
                elevation={3}
                style={{
                  padding: "10px",
                  position: "relative",
                  boxShadow: "none",
                  border: "1px dashed #D6D6D6",
                }}
              >
                {((file?.name && getFileType(file?.name) === "image") ||
                  (file?.name && getFileType(file?.name) === "video") ||
                  (file?.name && getFileType(file?.name) === "presentation") ||
                  (file?.name && getFileType(file?.name) === "document") ||
                  (file?.name && getFileType(file?.name) === "zip")) && (
                    <div
                      className="file-preview"
                      style={{ position: "relative" }}
                    >
                      {file?.name && getFileType(file?.name) === "image" && (
                        <img
                          src={URL.createObjectURL(file)}
                          onClick={() =>
                            handlePreviewOpen(URL.createObjectURL(file), false)
                          }
                          alt="Preview"
                          style={{
                            width: "100%",
                            height: "140px",
                            objectFit: "cover",
                            borderRadius: 5,
                            cursor:
                              userDetails.roleId == 2 || userDetails.roleId == 3
                                ? "pointer"
                                : "default",
                          }}
                        />
                      )}
                      {file?.name && getFileType(file?.name) === "video" && (
                        <video
                          style={{
                            width: "100%",
                            height: "140px",
                            objectFit: "cover",
                            borderRadius: 5,
                            cursor:
                              userDetails.roleId == 2 || userDetails.roleId == 3
                                ? "pointer"
                                : "default",
                          }}
                          height="auto"
                          src={URL.createObjectURL(file)}
                          onClick={() =>
                            handlePreviewOpen(URL.createObjectURL(file), true)
                          }
                          controls
                        ></video>
                      )}
                      {file?.name && getFileType(file?.name) === "document" && (
                        <img
                          src={NoPreview}
                          alt="Preview"
                          onClick={() => documentOnPreview(file)}
                          style={{
                            width: "100%",
                            height: "140px",
                            objectFit: "cover",
                            borderRadius: 5,
                            cursor:
                              userDetails.roleId == 2 || userDetails.roleId == 3
                                ? "pointer"
                                : "default",
                          }}
                        />
                      )}
                      {((file?.name &&
                        getFileType(file?.name) === "presentation") ||
                        (file?.name && getFileType(file?.name) === "zip")) && (
                          <img
                            src={NoPreview}
                            alt="Preview"
                            style={{
                              width: "100%",
                              height: "140px",
                              objectFit: "cover",
                              borderRadius: 5,
                              cursor: "default",
                            }}
                          />
                        )}
                      <div
                        className="file-actions"
                        style={{ position: "absolute", top: "5px", right: "5px" }}
                      >
                        {userDetails.roleId == 2 || userDetails.roleId == 3 ? (
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              handleDownload(file);
                            }}
                            style={{
                              width: 30,
                              height: 30,
                              background: "#fff",
                              marginRight: 5,
                            }}
                          >
                            <CloudDownloadIcon
                              sx={{
                                fontSize: 15,
                                borderRadius: "50%",
                                color: "#123261",
                              }}
                            />
                          </IconButton>
                        ) : null}
                        {/* <IconButton onClick={(e) => { e.preventDefault();  handleDelete(file)}} style={{width: 30, height: 30, background: '#fff'}}>
                    <DeleteIcon sx={{fontSize: 15, borderRadius:"50%", color:'#123261'}}/>
                  </IconButton> */}
                      </div>
                    </div>
                  )}
                <div
                  className="file-name xxs-normal"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                >
                  {file.name || "-"}
                </div>
              </Paper>
            </Grid>
          ))}
        <Dialog
          open={openPreview}
          onClose={handlePreviewClose}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <Typography
              className="mb-20 mt-10 md-bold"
              sx={{ fontWeight: "500 !important", fontSize: "18px !important" }}
            >
              Purchase Invoice Preview
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handlePreviewClose}
              aria-label="close"
              sx={{ position: "absolute", top: 6, right: 30 }}
            >
              <Close />
            </IconButton>
            {isVideo ? (
              <video src={previewSrc} style={{ width: "100%" }} controls />
            ) : (
              <img
                src={previewSrc}
                alt="Preview Image"
                style={{ width: "100%" }}
              />
            )}
          </DialogContent>
        </Dialog>
      </Grid>
      {pdfpreview ? (
        <PreviewPdfmodel
          url={pdf}
          handleClose={handleclose}
          open={pdfpreview}
        />
      ) : null}

      {/* {pdfUploadCheck?<PdfUpload url={pdf}/>:null} */}
    </Grid>
  );
};
export default FileUploadGroup;