import React, { Component } from 'react'; 
import { Typography } from '@material-ui/core';
import AccessRestrictedImage from '../../../../assests/images/AccessRestricted.png';

const AccessRestricted = () => {
    return(
        <div>
            <div style={{textAlign: 'center'}}>
                    <Typography variant='h4'>Access Denied</Typography>
                    <br></br>
                    <Typography variant='h6'>you don't have permission to access request page</Typography>
                </div>
            <div>
                <img src={AccessRestrictedImage} alt="access restricted" style={{  display: 'block',marginLeft: 'auto',marginRight: 'auto', width: '45%'}}/>
            </div>
        </div>
       
    )
};

export default AccessRestricted;