const initialState = {
    CountryListInfo:{
        data:[]
    },
    StateListInfo:{
        data:[]
    },
    CityListInfo:{
        data:[]
    }
 };
     export default  (state = initialState, action) => {
         let currentState = state;
         switch(action.type) {
             case 'FETCH_COUNTRY_LIST_DATA_SUCCESS':
                 currentState = {...state,
                    CountryListInfo : action.payload
                 };
             break;
             case 'FETCH_STATE_LIST_DATA_SUCCESS':
                 currentState = {...state,
                    StateListInfo : action.payload
                 };
             break;
             case 'FETCH_CITY_LIST_DATA_SUCCESS':
                 currentState = {...state,
                    CityListInfo : action.payload
                 };
             break;
             default:
         }
         return currentState;
 }
 