import React, { useEffect,useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';

import amountFormatter from '../../../common/components/amountFormat';
const data = [
  { name: 'In Stock', value: 300 },
  { name: 'Sold Out', value: 400 },
];


const COLORS = ['#008000', '#FF0000','blue','#33FFFB','#622569','brown','#ff80ff','#ffa64d','#ffdf80','#6666ff'];
export default function Example(props) {
const [activeIndex,setactiveIndex] = useState(0);
const [chartData,setchartData] = useState([]);
const[totalCount,settotalCount] = useState(props?.totalInventoryCount)
const totalCountFunction=(value)=>{
  if(value=='InStock'){
    return totalCount?.instock_inventoryprice ? amountFormatter(totalCount?.instock_inventoryprice) : '0.00'
  }
  else if(value=='Sold'){
    return totalCount?.sold_inventoryprice ? amountFormatter(totalCount?.sold_inventoryprice) : '0.00'
  }
  else if(value=='Void'){
    return totalCount?.void_inventoryprice ? amountFormatter(totalCount?.void_inventoryprice) : '0.00'
  }
  else if(value == 'Inhand'){
    return totalCount?.inventorystatus_inhand_price ? amountFormatter(totalCount?.inventorystatus_inhand_price) : '0.00'
  }
  else if(value=='On Hold/Reserved'){
    return totalCount?.inventorystatus_onhold_price ? amountFormatter(totalCount?.inventorystatus_onhold_price) : '0.00'
  }
  else if(value == 'Out on Memo'){
    return totalCount?.inventorystatus_out_to_memo_price ? amountFormatter(totalCount?.inventorystatus_out_to_memo_price) : '0.00'
  }
  else if(value == 'Incoming'){
  return totalCount?.inventorystatus_incoming_price ? amountFormatter(totalCount?.inventorystatus_incoming_price) :'0.00'
  }
  else if(value == 'Available to order'){
    return totalCount?.inventorystatus_availableto_order_price ? amountFormatter(totalCount?.inventorystatus_availableto_order_price) : '0.00'
  }
  else if(value == 'Not Assigned'){
    return totalCount?.inventorystatus_notassigned_price ? amountFormatter(totalCount?.inventorystatus_notassigned_price) : '0.00'
  }
  else if(value == 'Need Service/Polish'){
    return totalCount?.inventorystatus_needservice_polish_price ? amountFormatter(totalCount?.inventorystatus_needservice_polish_price) : '0.00'
  }
  else if(value == 'At Service'){
    return totalCount?.inventorystatus_atservice_price ? amountFormatter(totalCount?.inventorystatus_atservice_price) : '0.00'
  }
  else if(value == 'Need to Ship'){
    return totalCount?.inventorystatus_needtoship_price ? amountFormatter(totalCount?.inventorystatus_needtoship_price) : '0.00'
  }
  else if(value == 'Shipped waiting on payment'){
    return totalCount?.inventorystatus_shippingwating_price ? amountFormatter(totalCount?.inventorystatus_shippingwating_price) : '0.00'
  }


  
}
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  console.log('payload',payload,value)
  console.log('valuefghjkl',value)
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 5) * cos;
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 11;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g style={{ width: '0 !important', height: '0 !important' }}>
      <text x={cx} y={cy} dy={8} textAnchor="middle" style={{ fontSize: '16px' }} fill={fill}>      
        $ {totalCountFunction(payload.name)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" style={{ fontSize: '15px' }}>{`${payload.name} ${value}`}</text>
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} style={{fontSize:'8px'}} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};
useEffect(()=>{
  if(props?.totalInventoryCount){
    settotalCount(props?.totalInventoryCount)
  }
},[props?.totalInventoryCount])
useEffect(()=>{
  const filterobj = props?.data;
  console.log('filterobjjkjkk',filterobj)
  if (filterobj && filterobj.length > 0) {
    const arr = [];
    for (const key in filterobj[0]) {
      arr.push({ name:key,value: Number(filterobj[0][key]) });
    }
    console.log('filterobjaefefdedf', arr)
    setchartData(arr);
  }
  
},[props])

  const onPieEnter = (_, index) => {
    setactiveIndex(index)
  };

    return (
      // <ResponsiveContainer style={{width:"100%" ,height:"100%"}}>
      <PieChart width={550} height={300}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={80}
          outerRadius={100}
          fill="rgb(206, 160, 95)"
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      // </ResponsiveContainer>
    );
  }