import * as React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { bindActionCreators } from "redux";
import {
    Grid,
    Button,
    TextField,
    FormControl,
    IconButton,
    Box,
    Typography,
    Modal,
    Select,
    MenuItem,
    InputLabel,
    Input,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import SuccessImage from "../../../../assests/images/success.png";
import FormLabel from "@mui/material/FormLabel";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
    updateUserRole,
    getAllAcountList,
    getAccountBasedList,
    getOwnerBasedList,
    updateSubscriptionDetails,
} from "../actions/administration";
import { useState } from "react";
import { getuser } from "../../dashboard/actions/dashboardAction";
import { connect } from "react-redux";
import { FormHelperText } from "@material-ui/core";
const UpdateSubscriptionStatusModal = ({
    open,
    handleClose,
    SaveModal,
    saveModalOpen,
    userData,
    getuser,
}) => {
    const [accountList, setAccountList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [planDetails, setPlanDetails] = useState(null);
    const [paymentDate, setPaymentdDate] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [planExpiryDate, setPlanExpiryDate] = useState(null);
    const [dealerInventorySubscription, setDealerInventorySubscription] = useState(null);
    const [dealerInventoryExpiryDate, setDealerInventoryExpiryDate] = useState(null);
    const [error, setError] = useState({});
    const [owner, setOwner] = useState("")
    const [businessenable, setBusinessenable] = useState(false);
    const userDetails = window.sessionStorage.getItem("userDetails")
  ? JSON.parse(window.sessionStorage.getItem("userDetails"))
  : null;
    const handlePlanDetailsChange = (e) => {
        const selectedPlan = e.target.value;
        setDealerInventorySubscription(null);
        setPlanDetails(selectedPlan);
        if (selectedPlan === 'Premium') {
            setDealerInventorySubscription('early_adopters');
        } else if (selectedPlan === 'Free') {
            setDealerInventorySubscription('free_trial');
        }
    };

    const handleDealerInventorySubscriptionChange = (e) => {
        const selectedInventorySubscription = e.target.value;
        setDealerInventorySubscription(selectedInventorySubscription);
        setError({});
    };

    const validateInput = () => {
        let error = {};
        let isValid = true;
        if (planDetails && planDetails === "Noplan" && !dealerInventorySubscription) {
            error["dealerInventorySubscription"] = 'Please select any value';
            isValid = false;
        }
        setError(error);
        return isValid;
    };

    const onSubmitPaymentDetails = () => {
        if (validateInput()) {
            const payload = {
                accountid: selectedAccount.accountid,
                userid: userDetails.userId,
                plandetails: planDetails,
                planexpirydate: planExpiryDate,
                paymentdate: paymentDate,
                paymentmethod: paymentMethod,
                is_business_enable: businessenable,
                dealerinventory_plan: dealerInventorySubscription,
                dealerinventory_expirydate: dealerInventoryExpiryDate
            };
            updateSubscriptionDetails(payload, (error, result) => {
                if (result && result.status === 1) {
                    toast("Subscription Updated Successfully", {
                        autoClose: 5000,
                        type: toast.TYPE.SUCCESS,
                        position: toast.POSITION.BOTTOM_CENTER,
                    });
                    handleClose('updateSuccess');
                } else {
                }
            }).catch((e) => {
                console.log("error");
            });
        }
    };

    const getAccountList = async () => {
        const payload = {};
        getAllAcountList((error, result) => {
            if (result && result.status === 1) {
                const finalData = result.data.filter((account) => {
                    if (account.businessname != null) {
                        return account.businessname;
                    }
                });
                const ids = finalData.map(({ businessname }) => businessname);
                const filtered = finalData.filter(
                    ({ businessname }, index) => !ids.includes(businessname, index + 1)
                );
                setAccountList(filtered);
            } else {
            }
        }).catch((e) => {
            console.log("error");
        });
    };

    useEffect(() => {
        getAccountList();
    }, []);

    useEffect(async () => {
        if (selectedAccount) {
            let accountId = "";
            accountList.filter((account) => {
                if (account.businessname == selectedAccount.businessname) {
                    accountId = account.accountid;
                }
            });
            getOwnerBasedList(accountId, (result) => {
                if (result && result.status === 1) {
                    console.log("resultklmlmm", result.data)
                    const resultdata = result.data.length > 0 ? result.data[0].email : []
                    setOwner(resultdata)
                }
            }).catch((e) => {
                console.log("error");
            });
            getAccountBasedList(accountId, (result) => {
                if (result && result.status === 1) {
                    const destrutureData = result.data[0];
                    addcontactlist(destrutureData);
                } else {
                }
            }).catch((e) => {
                console.log("error");
            });

        }
    }, [selectedAccount]);

    const addcontactlist = (destrutureData) => {
        setPlanDetails(destrutureData?.plandetails || null);
        setPaymentdDate(destrutureData?.paymentdate || null);
        setPaymentMethod(destrutureData?.paymentmethod || null);
        setPlanExpiryDate(destrutureData?.planexpirydate || null);
        setBusinessenable(destrutureData?.is_business_enable);
        setDealerInventorySubscription(destrutureData?.dealerinventory_plan || null);
        setDealerInventoryExpiryDate(destrutureData?.dealerinventory_expirydate || null);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "30%",
                        maxHeight: "90%",
                        overflowY: 'scroll',
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        border: "0px solid #000",
                    }}
                >
                    <Grid
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            borderBottom: "1px solid #e0e0e0",
                            paddingBottom: "15px",
                        }}
                    >
                        <Grid>
                            <Typography variant="h6" component="h6" sx={{ mt: 1, ml: 2 }}>
                                Update Subscription Details
                            </Typography>
                        </Grid>
                        <Grid>
                            <IconButton onClick={() => handleClose()}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            p: "20px 35px 10px 35px",
                        }}
                    >
                        <FormControl variant="standard" className="formControl">
                            <InputLabel id="demo-simple-select-standard-label">
                                Account Name
                            </InputLabel>
                            <Select
                                style={{ color: "black", marginBottom: "15px" }}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Account Name"
                                value={selectedAccount}
                                onChange={(e) => {
                                    setSelectedAccount(e.target.value);
                                }}
                            >
                                {accountList &&
                                    accountList.map((account) => {
                                        return (
                                            <MenuItem value={account}>
                                                {account.businessname}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                        {owner &&
                            <FormControl variant="standard" className="formControl">
                                <FormLabel>Account Owner</FormLabel>
                                <Select
                                    style={{ color: "black", marginBottom: "15px" }}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    disabled
                                    value={owner}
                                    onChange={handlePlanDetailsChange}
                                >
                                    <MenuItem value={owner}>{owner}</MenuItem>
                                </Select>
                            </FormControl>}
                        <FormControl variant="standard" className="formControl">
                            <InputLabel
                                id="demo-simple-select-standard-label"
                                shrink={planDetails}
                            >
                                Plan Details
                            </InputLabel>
                            <Select
                                style={{ color: "black", marginBottom: "15px" }}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Plan Details"
                                value={planDetails}
                                onChange={handlePlanDetailsChange}
                            >
                                {/* <MenuItem value={null}>Select</MenuItem> */}
                                <MenuItem value="Free">Free</MenuItem>
                                <MenuItem value="Basic">Basic</MenuItem>
                                <MenuItem value="Premium">Premium</MenuItem>
                                <MenuItem value="Noplan">No Plan</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                            {/* <FormLabel style={{ color: "#C2BCBC" }}>Plan Expiry Date</FormLabel> */}
                            <FormLabel>Plan Expiry Date</FormLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // className="color-BCBCBC"
                                    style={{ marginBottom: "15px" }}
                                    margin="dense"
                                    id="date-picker-dialog"
                                    format="MM/dd/yyyy"
                                    value={planExpiryDate}
                                    disablePast={true}
                                    // minDate={invoiceDate ? invoiceDate : null}
                                    onChange={(date, e) => setPlanExpiryDate(e)}
                                    isClearable
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    // TextFieldComponent={TextFieldComponent}
                                    placeholder="MM/DD/YYYY"
                                    helperText={""}
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>

                        <FormControl variant="standard" className="formControl">
                            <InputLabel
                                id="demo-simple-select-standard-label"
                                shrink={dealerInventorySubscription}
                            >
                                Dealer Inventory Subscription
                            </InputLabel>
                            <Select
                                style={{ color: "black" }}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Dealer Inventory Subscription"
                                value={dealerInventorySubscription}
                                onChange={handleDealerInventorySubscriptionChange}
                            >
                                {/* <MenuItem value={null} disabled={planDetails === "Premium"}>Select</MenuItem> */}
                                <MenuItem value="access_only" disabled={planDetails === "Premium"}>Access Only</MenuItem>
                                <MenuItem value="flagship_suppliers" disabled={planDetails === "Premium"}>Flag Ship Suppliers</MenuItem>
                                <MenuItem value="paid_subscribers" disabled={planDetails === "Premium"}>Paid Subscribers</MenuItem>
                                <MenuItem value="free_trial" disabled={planDetails === "Premium"}>Free Trial</MenuItem>
                                <MenuItem value="early_adopters">Early Adopters</MenuItem>
                                <MenuItem value="no_subscription"
                                    disabled={planDetails === 'Noplan' || planDetails === null || planDetails === "Premium"}
                                >No Subscription</MenuItem>
                            </Select>
                            <FormHelperText className="error-o" style={{ marginBottom: '10px' }}>{error["dealerInventorySubscription"]}</FormHelperText>
                        </FormControl>

                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                            {/* <FormLabel style={{ color: "#C2BCBC" }}>Plan Expiry Date</FormLabel> */}
                            <FormLabel>Dealer Inventory Expiry Date</FormLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // className="color-BCBCBC"
                                    margin="dense"
                                    id="date-picker-dialog"
                                    format="MM/dd/yyyy"
                                    value={dealerInventoryExpiryDate}
                                    // minDate={invoiceDate ? invoiceDate : null}
                                    onChange={(date, e) => setDealerInventoryExpiryDate(e)}
                                    disablePast={true}
                                    isClearable
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    // TextFieldComponent={TextFieldComponent}
                                    placeholder="MM/DD/YYYY"
                                    helperText={""}
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                        <FormControl className="form-width-100 " style={{ display: 'flex', flexFlow: 'row' }}  >
                            <FormLabel id="demo-radio-buttons-group-label" style={{ margin: '20px 20px 20px 0px' }}>Multi Profile Subscription</FormLabel>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedB1"
                                        value={businessenable}
                                        checked={businessenable}
                                        className="float-right"
                                        onChange={(e) => setBusinessenable(e.target.checked)}
                                    />
                                }
                            // label="Permission allowed"
                            />

                        </FormControl>
                        <FormControl
                            className="form-width-100 margin-t-b-20 margin-p-b-20"
                            style={{ margin: "10px 0" }}
                        >
                            {/* <FormLabel style={{ color: "#C2BCBC" }}>Payment Date</FormLabel> */}
                            <FormLabel>Payment Date</FormLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // className="color-BCBCBC"
                                    margin="dense"
                                    id="date-picker-dialog"
                                    format="MM/dd/yyyy"
                                    disableFuture={true}
                                    value={paymentDate}
                                    maxDate={new Date()}
                                    onChange={(date, e) => setPaymentdDate(e)}
                                    isClearable
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    // TextFieldComponent={TextFieldComponent}
                                    placeholder="MM/DD/YYYY"
                                    helperText={""}
                                />
                            </MuiPickersUtilsProvider>
                        </FormControl>
                        <FormControl
                            variant="standard"
                            className="formControl"
                        >
                            <InputLabel
                                id="demo-simple-select-standard-label"
                                shrink={paymentMethod}
                            >
                                Payment Method
                            </InputLabel>
                            <Select
                                style={{ color: "black" }}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                label="Payment Method"
                                value={paymentMethod}
                                onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                }}
                            >
                                <MenuItem value="Wire">Wire</MenuItem>
                                <MenuItem value="Check">Check</MenuItem>
                                <MenuItem value="Cash">Cash</MenuItem>
                                <MenuItem value="Credit Card">Credit Card</MenuItem>
                                <MenuItem value="Trade">Trade</MenuItem>
                                <MenuItem value="Zelle">Zelle</MenuItem>
                                <MenuItem value="Venmo">Venmo</MenuItem>
                                <MenuItem value="Paypal">Paypal</MenuItem>
                                <MenuItem value="Affirm">Affirm</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Box
                        sx={{
                            pt: 1,
                            pl: 2,
                            pr: 4,
                            pb: 4,
                        }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                mt: 2,
                                justifyContent: "space-evenly",
                                justifyItems: "flex-end",
                            }}
                        >
                            <Grid>
                                <Button
                                    variant="text"
                                    sx={{ width: "6rem" }}
                                    className="customButton"
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="text"
                                    sx={{ width: "6rem" }}
                                    className="customButton"
                                    disabled={!selectedAccount}
                                    onClick={() => onSubmitPaymentDetails()}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>

            {SaveModal ? (
                <Modal
                    open={SaveModal}
                    onClose={() => handleClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            width: "40%",
                            height: "50%",
                            transform: "translate(-50%, -50%)",
                            bgcolor: "background.paper",
                            border: "0px solid #000",
                        }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                float: "right",
                            }}
                        >
                            <Grid>
                                <IconButton onClick={() => handleClose()}>
                                    <ClearIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                mt: "6rem",
                            }}
                        >
                            <Grid>
                                <img
                                    src={SuccessImage}
                                    sx={{ width: "30%", height: "30%" }}
                                ></img>
                            </Grid>
                            <Grid>
                                <Typography variant="h6" component="h2">
                                    Role changed Successfully
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            ) : null}
        </div>
    );
};

function mapStateToProps({ }) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getuser,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateSubscriptionStatusModal);
