import React, { Component } from 'react';
import ContentWrapper from "../../../common/components/contentWrapper";
import SideTab from "./adminTabComponent";
import UpgradePlan from '../common/components/upgradePlan';

class AdminPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {}
        }
    }
    componentDidMount() {
        const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        this.setState({ userData: userDetails });
    }

    getContentView1() {
        return (
            <div>
                <div style={{ margin: '10px' }}>
                    <SideTab
                        {...this.props}
                    />
                </div>
            </div>
        );
    }
    render() {

        return (
            <ContentWrapper contentView={this.state.userData && (!this.state.userData?.planDetails || this.state.userData?.planDetails === "Noplan") ?
                <div style={{ marginTop: "25px" }}> <UpgradePlan /> </div> : this.getContentView1()} {...this.props}>
            </ContentWrapper>
            //     <ContentWrapper

            //     contentView={this.getContentView1()}
            //    {...this.props}
            //     />
        )
    }
}

export default AdminPageComponent;