import React, { Component } from 'react';

import { Container, Grid, Typography, Link, Button } from '@material-ui/core';
import ologo from '../../../../assests/images/ologo.png';
import logo1 from '../../../../assests/images/Group 1769.png';
import google from '../../../../assests/images/Group 1398.png';
import google1 from '../../../../assests/images/Group 1399.png';
import { getuser } from '../../dashboard/actions/dashboardAction';

class DealerSubscriptionThankYou extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    // componentDidMount(){
    //     const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null
    //     console.log("userDetails",userDetails?.userId)
    //     if(userDetails && userDetails?.userId){
    //         getuser(userDetails?.userId)
    //     }
    // }
    render() {
        return (
            <div className="space-top-30 space-bottom-50">
                <Container maxWidth="md">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div>
                            <div className="text-center1">
                                    <img src={ologo} alt="logo"/>
                                    </div>
                                <div className="text-center1 space-top-20">
                                    <Typography variant="h6"><b>Thanks for joining</b></Typography>
                                </div>
                               
                                <div className="text-center1">
                                    <img src={logo1} alt="logo"/>
                                </div>
                                <div className="text-center1 space-top-10">
                                    <Typography variant="h5"><b>Your subscription has been confirmed. You've been added to our list <br/> and will hear from us soon.</b></Typography>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div className="space-3">
                                        <Typography variant="h4" className="button-bg-color2 text-center1">
                                            <Link href="/"><b>DOWNLOAD</b></Link>
                                        </Typography>
                                        <Typography variant="h5" className="button-bg-color2 text-center1">
                                            <Link href="/">OUR APP</Link>
                                        </Typography>
                                    </div>
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <div className="float-right">
                                                <a href="https://play.google.com/store">    <img src={google} alt="google" style={{width:'120px'}}/></a>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                            <div style={{float:'left'}}>
                                            <a href="https://www.apple.com/in/app-store/">  <img src={google1} alt="google" style={{width:'120px'}}/></a>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}></Grid>
                        <Grid item xs={12} sm={4} md={4}>
                        <div className="space-3">
                            <Button className="bg-card-signin-subscribe-color text-center1 form-width-100" href="/search">SEARCH WATCHES</Button>
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}></Grid>
                    </Grid>
                </Container>
            </div>
         );
    }

}
 
export default DealerSubscriptionThankYou;