import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Card, Grid, Typography, TextField, Button, FormControl, Input, FormHelperText, InputLabel, } from '@material-ui/core';
import validator from 'validator';
import { toast } from "react-toastify";
import { addUserData, login } from "./../../../Auth/reducer/actions";
import { InputAdornment, Select, MenuItem } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

class SignSubscribeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'password',

      score: 'null',
      firstName: '',
      lastName: '',
      email: '',
      countryCode: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',

    }
    this.onChangeOfFirstName = this.onChangeOfFirstName.bind(this);
    this.onChangeOfLastName = this.onChangeOfLastName.bind(this);
    this.onChangeOfEmail = this.onChangeOfEmail.bind(this);
    this.onChangeOfPhoneNumber = this.onChangeOfPhoneNumber.bind(this);
    this.onChangeOfCountryCode = this.onChangeOfCountryCode.bind(this);
    this.onChangeOfPassword = this.onChangeOfPassword.bind(this);
    this.onChangeOfConfirmPassword = this.onChangeOfConfirmPassword.bind(this);
    this.showHide = this.showHide.bind(this);

    this.passwordStrength = this.passwordStrength.bind(this);
    this.validateInput = this.validateInput.bind(this);

  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  passwordStrength(e) {
    if (e.target.value === '') {
      this.setState({
        score: 'null'
      })
    }
    else {
      let pw = e.target.value;
      this.setState({
        score: pw.score
      });
    }
  }
  onChangeOfFirstName(event) {
    let fullNameVal = validator.isEmpty(event.target.value);
    if (fullNameVal) {
      this.setState({
        onFirstNameErrorText: "Enter correct Full Name",
        isValid: false
      })
    }
    else {
      this.setState({
        onNameErrorText: "",
        firstName: event.target.value,
        isValid: true
      })
    }
  }
  onChangeOfLastName(event) {
    let fullNameVal = validator.isEmpty(event.target.value);
    if (fullNameVal) {
      this.setState({
        onLastNameErrorText: "Enter correct Full Name",
        isValid: false
      })
    }
    else {
      this.setState({
        onNameErrorText: "",
        lastName: event.target.value,
        isValid: true
      })
    }
  }
  onChangeOfPhoneNumber(event) {
    let PhoneNumberVal = validator.isMobilePhone(event.target.value);
    if (!PhoneNumberVal) {
      this.setState({
        onPhoneNumberErrorText: "Enter correct Phone Number",
        isValid: false
      })
    }
    else {
      this.setState({
        onPhoneNumberErrorText: "",
        phoneNumber: event.target.value,
        isValid: true
      })
    }
  }
  onChangeOfCountryCode(event) {
    let CountryCodeVal = event.target.value.length;
    if (CountryCodeVal > 4) {
      this.setState({
        onCountryCodeErrorText: "Enter minimum 3 characters for country code",
        isValid: false
      })
    }
    else {
      this.setState({
        onCountryCodeErrorText: "",
        countryCode: event.target.value,
        isValid: true
      })
    }
  }
  onChangeOfEmail(event) {
    let EmailVal = validator.isEmpty(event.target.value);
    if (EmailVal) {
      this.setState({
        onEmailErrorText: "Enter proper Email",
        isValid: false
      })
    }
    else {
      this.setState({
        onEmailErrorText: "",
        email: event.target.value,
        isValid: true
      })
    }
  }
  onChangeOfPassword = (event) => {
    let passwordVal = event.target.value;
    if (passwordVal < 8) {
      this.setState({
        passwordError: "Enter correct Password",
        isValid: false
      })
    }
    else {
      this.setState({
        passwordError: "",
        password: event.target.value,
        isValid: true
      })
    }
  }
  onChangeOfConfirmPassword = (event) => {
    let confirmPasswordVal = event.target.value;
    if (!confirmPasswordVal) {
      this.setState({
        confirmPasswordError: "Enter Confirm Password",
        isValid: false
      })
    }
    else {
      this.setState({
        confirmPasswordError: "",
        confirmPassword: event.target.value,
        isValid: true
      })
    }
  }
  validateInput() {
    let onFirstNameErrorText = '';
    let onEmailErrorText = '';
    let onPhoneNumberErrorText = '';
    let onCountryCodeErrorText = '';
    let onLastNameErrorText = '';
    let passwordError = '';
    let confirmPasswordError = '';

    let isValid = true;

    if (this.state.firstName.length === 0) {
      onFirstNameErrorText = 'Enter First Name';
      isValid = false;
    }
    if (this.state.lastName.length === 0) {
      onLastNameErrorText = 'Enter Last Name';
      isValid = false;
    }
    if (this.state.email.length === 0) {
      onEmailErrorText = 'Enter Proper Email ';
      isValid = false;
    }
    if (this.state.password.length < 8) {
      passwordError = 'Enter password with minimum 8 characters';
      isValid = false;
    }
    if (this.state.confirmPassword.length < 8) {
      confirmPasswordError = 'Enter Confirm Password';
      isValid = false;
    }


    if (this.state.phoneNumber.length === 0) {
      onPhoneNumberErrorText = 'Enter Phone Number';
      isValid = false;
    }
    if (this.state.countryCode.length > 4) {
      onCountryCodeErrorText = 'Enter minimum 3 characters for country code';
      isValid = false;
    }
    if (this.state.confirmPassword != this.state.password) {
      confirmPasswordError = 'Passwords do not match';
      isValid = false;
    }

    this.setState({
      ...this.state,
      onFirstNameErrorText,
      onLastNameErrorText,
      onEmailErrorText,
      onPhoneNumberErrorText,
      onCountryCodeErrorText,
      passwordError,
      confirmPasswordError,

    });
    return isValid;
  }

  submitForm(event) {
    const { signUpRequested, signUpSuccess, signUpFailed, history } = this.props;
    const { loginRequested, loginSuccess, loginFailed } = this.props;
    if (this.validateInput()) {
      let data = JSON.stringify({
        "first_name": this.state.firstName,
        "last_name": this.state.lastName,
        "country_code": this.state.countryCode,
        "phone": this.state.phoneNumber,
        "email": this.state.email,
        "password": this.state.password,
      });

      const apiInput = {
        email: this.state.email,
        password: this.state.password,
      };

      signUpRequested(null);
      addUserData(data, (error, res) => {
        if (res.success === 1) {
          loginRequested(null);
          login(
            apiInput,
            (res) => {
              if (res.success === 1) {
                toast("User Login successfully", {
                  autoClose: 5000,
                  type: toast.TYPE.SUCCESS,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
                loginSuccess(res);
                history.push("/add-to-inventory-search");
              } else {
                loginFailed(res);
                toast(res.message, {
                  autoClose: 5000,
                  type: toast.TYPE.SUCCESS,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
            },
            (error) => {
              loginFailed(error);
              toast(res.message, {
                autoClose: 5000,
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          );
        } else {
          toast(res.message, {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }

      });

    }
  }
  render() {

    return (
      <div>
        <div className="signin-subscribe-bg">
          <div className="padding1"></div>
          <div className="padding1"></div>
          <form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='subscribe_signup_form' >
            <Container maxWidth="md" >
              <Card className="Signinsubscribe-card" >

                <Typography variant="h5" align="center" className="color-383838 font-weight-600" style={{ marginTop: "10px" }}><b>Sign in and Subscribe to Browse <br /> Luxury Watches</b></Typography>


                <div className="Form-subscribe">
                  <Container maxWidth="sm">
                    <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10"  >
                      <InputLabel htmlFor="component-simple" className="color-BCBCBC"> FIRST NAME </InputLabel>
                      <Input id="component-simple"
                        onChange={this.onChangeOfFirstName}
                      />
                      <FormHelperText className="error-o" >{this.state.onFirstNameErrorText}</FormHelperText>
                    </FormControl>


                    <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10"  >
                      <InputLabel htmlFor="component-simple" className="color-BCBCBC">LAST NAME </InputLabel>
                      <Input id="component-simple"
                        onChange={this.onChangeOfLastName}
                      />
                      <FormHelperText className="error-o" >{this.state.onLastNameErrorText}</FormHelperText>
                    </FormControl>

                    <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10"  >
                      <InputLabel htmlFor="component-simple" className="color-BCBCBC">E-MAIL </InputLabel>
                      <Input id="component-simple"
                        onChange={this.onChangeOfEmail}
                      />
                      <FormHelperText className="error-o" >{this.state.onEmailErrorText}</FormHelperText>
                    </FormControl>
                    <Grid container spacing={2}>
                      <Grid item={12} sm={6} md={6} xs={12}>
                        <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10"  >
                          <InputLabel htmlFor="component-simple" className="color-BCBCBC">PASSWORD </InputLabel>
                          <Input id="component-simple"
                            type={this.state.type}
                            endAdornment={<InputAdornment position="end">
                            <span onClick={this.showHide} style={{ color: '#CEA05F' }}>{this.state.type === 'input' ? <VisibilityIcon style={{cursor:'pointer'}}/> : <VisibilityOffIcon style={{cursor:'pointer'}}/>}</span></InputAdornment>}
                            onChange={this.onChangeOfPassword}
                            defaultValue={this.state.password}
                          />

                          <FormHelperText className="error-o" >{this.state.passwordError}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item={12} sm={6} md={6} xs={12}>
                        <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10"  >
                          <InputLabel htmlFor="component-simple" className="color-BCBCBC">CONFIRM PASSWORD </InputLabel>
                          <Input id="component-simple"
                            type={this.state.type}
                           
                            onChange={this.onChangeOfConfirmPassword}
                            defaultValue={this.state.confirmPassword}
                          />
                          <FormHelperText className="error-o" >{this.state.confirmPasswordError}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item={12} sm={6} md={6} xs={12}>
                        <FormControl className="registerSpace form-width-100">
                          <InputLabel id="demo-simple-select-outlined-label" className="color-BCBCBC">COUNTRY CODE</InputLabel>
                          <Input id="component-simple"
                            onChange={this.onChangeOfCountryCode}
                          />

                          <FormHelperText className="error-o">{this.state.onCountryCodeErrorText}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item={12} sm={6} md={6} xs={12}>
                        <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10"  >
                          <InputLabel htmlFor="component-simple" className="color-BCBCBC">PHONE NUMBER</InputLabel>
                          <Input id="component-simple"
                            onChange={this.onChangeOfPhoneNumber}
                          />
                          <FormHelperText className="error-o" >{this.state.onPhoneNumberErrorText}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className="pt-1 center-margin">
                      <Button type="submit" size="large" className="bg-card-signin-subscribe-color signinsubscribe-button-padding" style={{ marginTop: "5px", marginBottom: "5px" }}>Submit</Button>
                    </div>
                    <div className="pt-1"></div>

                  </Container>
                </div>
              </Card>
            </Container>
          </form>
          <div className="padding2"></div>
        </div>

      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signUpRequested: (payload) => dispatch({ type: "SIGNUP_REQUESTED", payload }),
    signUpSuccess: (payload) => dispatch({ type: "SIGNUP_SUCCESS", payload }),
    signUpFailed: (payload) => dispatch({ type: "SIGNUP_FAILED", payload }),
    loginRequested: (payload) => dispatch({ type: "LOGIN_REQUESTED", payload }),
    loginSuccess: (payload) => dispatch({ type: "LOGIN_SUCCESS", payload }),
    loginFailed: (payload) => dispatch({ type: "LOGIN_FAILED", payload }),
  };
}

export default connect(null, mapDispatchToProps)(SignSubscribeComponent);