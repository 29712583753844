import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Grid, Typography, FormControl, Input, InputLabel, NativeSelect, FormHelperText, TextField, Slider, Button, InputAdornment } from '@material-ui/core';
import watchimg from '../../../../assests/images/Bitmap.png';
import { brandList, addProduct, caseMaterial, bandMaterial,getBrandId } from '../actions/dealerAction';
import { toast } from "react-toastify";
import '../style/dealer.css';

const  
 
 followersMarks1 = [

  {
    value: 0,
    scaledValue: 1,
    label: "0"
  },
  {
    value: 8,
    scaledValue: 8,
    label: "8 mm"
  },
  {
    value: 16,
    scaledValue: 16,
    label: "16 mm"
  },
  {
    value: 24,
    scaledValue: 24,
    label: "24 mm"
  },
  {
    value: 32,
    scaledValue: 32,
    label: "32 mm"
  },
  {
    value: 40,
    scaledValue: 40,
    label: "40 mm"
  },
  {
    value: 48,
    scaledValue: 48,
    label: "48 mm"
  },
  {
    value: 56,
    scaledValue: 56,
  },
  {
    value: 60,
    scaledValue: 60,
    label: "60 mm"
  },
 
];
const followersMarks = [
  {
    value: 0,
    scaledValue: 1,
    label: "0"
  },
  {
    value: 2,
    scaledValue: 2,
    label: "2 Inc"
  },
  {
    value: 4,
    scaledValue: 4,
    label: "4 Inc"
  },
  {
    value: 6,
    scaledValue: 6,
    label: "6 Inc"
  },
  {
    value: 8,
    scaledValue: 8,
    label: "8 Inc"
  },
  {
    value: 10,
    scaledValue: 10,
    label: "10 Inc"
  },
  {
    value: 12,
    scaledValue: 12,
    label: "12 Inc"
  },
  {
    value: 14,
    scaledValue: 14,
    label: "14 Inc"
  },
 
];
const scale = value => {
  return (value * 25.4).toFixed(2) + ' mm';
};
const scale1 = value => {
  return (value * 25.4).toFixed(2) + ' mm';
};
function numFormatter(num) {
  return num.toFixed(1) + " Inc"; // convert to K for number from > 1000 < 1 million
}
function numFormatter1(num) {
  return num.toFixed(1) + " Inc"; // convert to K for number from > 1000 < 1 million
}
class DealerProductDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      handleResponse: null,
      imageUrl: null,
      invalidImage: null,
      Brand: '',
      ReferenceNumber: '',
      ModelName: '',
      CaseSize: null,
      CaseMaterial: '',
      BandMaterial: '',
      BandSize: null,
      BandFactoryLinks: '',
      value:4,
      value1:16,
      Movement: '',
      UploadPic: false,
      file: null,
      KnownListPrice: '',
      onBrandErrorText: '',
      onReferenceNumberErrorText: '',
      onModelNameErrorText: '',
      onCaseSizeErrorText: '',
      onCaseMaterialErrorText: '',
      onBandMaterialErrorText: '',
      onBandSizeErrorText: '',
      onBandFactoryLinksErrorText: '',
      onMovementErrorText: '',
      onUploadPicErrorText: '',
      onKnownListPriceErrorText: '',

    }
    this.reader = new FileReader();
    this.onchangeBrand = this.onchangeBrand.bind(this);
    this.onchangeReferenceNumber = this.onchangeReferenceNumber.bind(this);
    this.onchangeModelName = this.onchangeModelName.bind(this);
    this.onchangeCaseSize = this.onchangeCaseSize.bind(this);
    this.onchangeCaseMaterial = this.onchangeCaseMaterial.bind(this);
    this.onchangeBandMaterial = this.onchangeBandMaterial.bind(this);
    this.onchangeBandSize = this.onchangeBandSize.bind(this);
    this.onchangeBandFactoryLinks = this.onchangeBandFactoryLinks.bind(this);
    this.onchangeMovement = this.onchangeMovement.bind(this);
    this.UploadPhoto = this.UploadPhoto.bind(this);
    this.onchangeKnownListPrice = this.onchangeKnownListPrice.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.valuetext = this.valuetext.bind(this);
  }

  componentDidMount() {
    this.props.brandList();
    this.props.caseMaterial();
    this.props.bandMaterial();
  }

  componentWillReceiveProps(newProps) {
    
    const _brandId = newProps.brandIdInfo;
    let individualBrowseWatchesInfo ='';
        let productId='';
        let individualBrowseWatchesDetails='';
        if(newProps.brandIdInfo.success == 0){
             individualBrowseWatchesInfo = newProps.brandIdInfo;
        }else{
             individualBrowseWatchesInfo = newProps.brandIdInfo.data[0];
             individualBrowseWatchesDetails = (individualBrowseWatchesInfo && individualBrowseWatchesInfo.prodattr) ? individualBrowseWatchesInfo.prodattr : "";
        }
        const referenceid =individualBrowseWatchesDetails.reference?individualBrowseWatchesDetails.reference:'';
   if(referenceid){
    this.setState({
      onReferenceNumberErrorText: "This model number already exit",
      isValid: false
    })
  }
   
  }
  valuetext=(value)=> {
    return 'mm';
  }

  onchangeBrand(event) {
    let BrandVal = validator.isNumeric(event.target.value);
    if (!BrandVal) {
      this.setState({
        onBrandErrorText: "Please Choose Brand",
        isValid: false
      })
    }
    else {
      this.setState({
        onBrandErrorText: "",
        Brand: event.target.value,
        isValid: true
      })
    }
  }
  onchangeReferenceNumber(event) {
    let ReferenceNumberVal = event.target.value;
    if (ReferenceNumberVal === "") {
      this.setState({
        onReferenceNumberErrorText: "Enter Reference Numbers",
        isValid: false
      })
    }
    else {
      this.props.getBrandId(event.target.value);
      this.setState({
        onReferenceNumberErrorText: "",
        ReferenceNumber: event.target.value,
        isValid: true
      })
    }
  }


  onchangeModelName(event) {
    let ModelNameVal = event.target.value;
    if (ModelNameVal === "") {
      this.setState({
        onModelNameErrorText: "Enter Proper Model Number",
        isValid: false
      })
    }
    else {
      this.setState({
        onModelNameErrorText: "",
        ModelName: event.target.value,
        isValid: true
      })
    }
  }

  onchangeCaseSize = (event, value) => {
    if (this.state.CaseSize === "") {
      this.setState({
        onCaseSizeErrorText: "Choose Proper Case Size",
        isValid: false
      })
    }
    else {
      this.setState({
        onCaseSizeErrorText: "",
        CaseSize: value,
        isValid: true
      })
    }
  }

  onchangeCaseMaterial(event) {
    let CaseMaterialVal = event.target.value;
    if (CaseMaterialVal === "") {
      this.setState({
        onCaseMaterialErrorText: "Please Choose Case Material",
        isValid: false
      })
    }
    else {
      this.setState({
        onCaseMaterialErrorText: "",
        CaseMaterial: event.target.value,
        isValid: true
      })
    }
  }

  onchangeBandMaterial(event) {
    let BandMaterialVal = event.target.value;
    if (BandMaterialVal === "") {
      this.setState({
        onBandMaterialErrorText: "Please Choose Band Material",
        isValid: false
      })
    }
    else {
      this.setState({
        onBandMaterialErrorText: "",
        BandMaterial: event.target.value,
        isValid: true
      })
    }
  }

  onchangeBandSize = (event, value) => {
    if (this.state.BandSize === "") {
      this.setState({
        onBandSizeErrorText: "Select Band Size",
        isValid: false
      })
    }
    else {
      this.setState({
        onBandSizeErrorText: "",
        BandSize: value,
        isValid: true
      })
    }
  }

  onchangeBandFactoryLinks(event) {
    let BandFactoryLinksVal = event.target.value;
    if (BandFactoryLinksVal === "") {
      this.setState({
        onBandFactoryLinksErrorText: "Enter correct Band Factory Links",
        isValid: false
      })
    }
    else {
      this.setState({
        onBandFactoryLinksErrorText: "",
        BandFactoryLinks: event.target.value,
        isValid: true
      })
    }
  }

  onchangeMovement(event) {
    let MovementVal = event.target.value;
    if (MovementVal === "") {
      this.setState({
        onMovementErrorText: "Enter correct Movement",
        isValid: false
      })
    }
    else {
      this.setState({
        onMovementErrorText: "",
        Movement: event.target.value,
        isValid: true
      })
    }
  }

  UploadPhoto = ({ target }) => {

    const imageFile = target.files[0];
    this.setState({ onUploadPicErrorText: '', UploadPic: false, isValid: true });
    if (!imageFile) {
      console.log("Image");
      this.setState({ onUploadPicErrorText: 'Please select image.', isValid: false });
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({ onUploadPicErrorText: 'Please select only jpg,png or jpeg format images', isValid: false });
      return false;
    }
    var file = target.files[0];
    if (file === "") {
      this.setState({
        onUploadPicErrorText: "Please Upload Photo",
        isValid: false

      })
    }
    else {
      this.setState({
        UploadPic: URL.createObjectURL(file),
        file: file,
        isValid: true
      })
    }

  }

  onchangeKnownListPrice(event) {
    let KnownListPriceVal = validator.isNumeric(event.target.value);
    if (!KnownListPriceVal) {
      this.setState({
        onKnownListPriceErrorText: "Enter correct Known List Price",
        isValid: false
      })
    }
    else {
      this.setState({
        onKnownListPriceErrorText: "",
        KnownListPrice: event.target.value,
        isValid: true
      })
    }
  }


  validateInput() {
    console.log('this.state', this.state);

    let onBrandErrorText = '';
    let onReferenceNumberErrorText = '';
    let onModelNameErrorText = '';
    let onCaseSizeErrorText = '';
    let onCaseMaterialErrorText = '';
    let onBandMaterialErrorText = '';
    let onBandSizeErrorText = '';
    let onBandFactoryLinksErrorText = '';
    let onMovementErrorText = '';
    let onKnownListPriceErrorText = '';
    let onUploadPicErrorText = '';

    let isValid = true;

    if (this.state.Brand.length === 0) {
      onBrandErrorText = 'Please Choose Brand';
      isValid = false;
    }
    if (this.state.ReferenceNumber.length === 0) {
      onReferenceNumberErrorText = 'Enter  Reference Number';
      isValid = false;
    }
    if (this.state.ModelName.length === 0) {
      onModelNameErrorText = 'Enter Model Name';
      isValid = false;
    }
    if (this.state.CaseMaterial.length === 0) {
      onCaseMaterialErrorText = 'Please Choose Case Material';
      isValid = false;
    }
    if (this.state.BandMaterial.length === 0) {
      onBandMaterialErrorText = 'Please Choose Band Material';
      isValid = false;
    }
    if (this.state.BandFactoryLinks.length === 0) {
      onBandFactoryLinksErrorText = 'Enter Band Factory Links';
      isValid = false;
    }
    if (this.state.Movement.length === 0) {
      onMovementErrorText = 'Enter Movement';
      isValid = false;
    }
    if (this.state.KnownListPrice.length === 0) {
      onKnownListPriceErrorText = 'Enter Known List Price';
      isValid = false;
    }
    if (this.state.UploadPic == false) {
      onUploadPicErrorText = 'Please Upload Pic';
      isValid = false;
    }
    if (this.state.CaseSize === 0) {
      onCaseSizeErrorText = 'Please Select Case Size';
      isValid = false;
    }

    if (this.state.BandSize === 0) {
      onBandSizeErrorText = 'Please Select Band Size';
      isValid = false;
    }


    this.setState({
      ...this.state,
      onBrandErrorText,
      onReferenceNumberErrorText,
      onModelNameErrorText,
      onCaseSizeErrorText,
      onCaseMaterialErrorText,
      onBandMaterialErrorText,
      onBandSizeErrorText,
      onBandFactoryLinksErrorText,
      onMovementErrorText,
      onKnownListPriceErrorText,
      onUploadPicErrorText
     

    })
    return isValid;
  }

  submitForm(event) {
    if (this.validateInput()) {
      if(this.state.CaseSize === 0){
        this.setState({
          CaseSize:""
        })
      }
      if(this.state.BandSize === 0){
        this.setState({
          BandSize:""
        })
      }
      let attributes = {
        "reference": this.state.ReferenceNumber,
        "model": this.state.ModelName,
        "bezel": "",
        "gender": "",
        "glass": "",
        "shape": "",
        "waterresitant": "",
        "calibre": "",
        "movement": this.state.Movement,
        "casesize": this.state.CaseSize+ " mm",
        "bandsize": this.state.BandSize+ " IN",
        "powerreserve": "",
        "indices": "",
        "casematerial": this.state.CaseMaterial,
        "bandmaterial": this.state.BandMaterial,
        "dial": "",
        "bandfactorylinks": this.state.BandFactoryLinks,
      };
      let fd = new FormData();
      fd.set('brand_id', this.state.Brand);
      fd.set('name', this.state.ModelName);
      fd.set('attributes', JSON.stringify(attributes));
      fd.set('image', this.state.file);
      fd.set('retail_price', this.state.KnownListPrice);
      fd.set('created_by', this.props.currentUser.userId);
      const { history } = this.props;
      addProduct(fd, (error, res) => {
       if(res.success === 1){
        history.push("/add-to-product-successful");
       }else{
           toast('Somthing went wrong', {
                autoClose: 5000,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_CENTER,
            });
       }

      });
    }

  }


  getContentView() {
    const brandList = this.props.brandInfo.data;
    const caseMaterialList = this.props.caseMaterialInfo.data;
    const brandMaterialList = this.props.brandMaterialList;
    const { handleResponse, imageUrl, invalidImage } = this.state;

    const handleChange = (event, newValue) => {
      this.setState({
        onBandSizeErrorText: "",
        BandSize: newValue,
        isValid: true,
         value: newValue,
        });
    };
    const handleChange1 = (event, newValue) => {
      this.setState({ 
       
          onCaseSizeErrorText: "",
          CaseSize: newValue,
          isValid: true,
      
        value1: newValue 
      });
    };
    const marks = [
      {
        value: 0,
        label: '0mm',
      },
      
    ];
    return (
      <div style={{ background: '#fff', marginBottom: '20px' }}> 
        <Container>
          <div className="space-3">
            <form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id="add_product_form">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div style={{marginLeft:'35px'}} >
                    <Typography variant="h6" component="p" className="primary-text uppercase">ADD NEW WATCH DETAILS</Typography>

                  </div>
                  <div className="space-left-30 space-top-20">

                    <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20" >
                      {/* <InputLabel htmlFor="component-simple"  className="color-BCBCBC">SELECT BRAND</InputLabel> */}
                      <NativeSelect
                        id="component-simple"
                        onChange={this.onchangeBrand}
                        // className="color-#424242"
                        style={{color:'#424242'}}
                      >

                        <option value='' >SELECT BRAND</option>
                        {
                          brandList.map((item) => {
                            return <option value={item.brandid}>{item.brandname}</option>
                          })
                        }
                      </NativeSelect>
                      <FormHelperText className="error-o">{this.state.onBrandErrorText}</FormHelperText>
                    </FormControl>

                    <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20"  >
                      <InputLabel style={{color:'#424242'}}>MODEL NUMBER</InputLabel>
                      <Input id="component-simple"
                        onChange={this.onchangeReferenceNumber}
                      />
                      <FormHelperText className="error-o" >{this.state.onReferenceNumberErrorText}</FormHelperText>
                    </FormControl>

                    <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20"  >
                      <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>MODEL NAME</InputLabel>
                      <Input id="component-simple"
                        onChange={this.onchangeModelName}
                      />
                      <FormHelperText className="error-o">{this.state.onModelNameErrorText}</FormHelperText>
                    </FormControl>

                    <InputLabel htmlFor="demo-customized-select-native" style={{marginTop:'25px', color:'#424242'}}>CASE SIZE</InputLabel>
                    <FormControl className="form-width-70 sliderclass" style={{marginBottom:'5px'}}  >
                    {/* <Typography style={{textAlign:'right'}}> {scale1(this.state.value1)}</Typography> */}
                     <Slider
                   
                           style={{ maxWidth: 404 }}
                           value={this.state.value1}
                           min={0} 
                           step={1}
                           max={60}
                          //  valueLabelFormat={numFormatter1}
                           marks={followersMarks1} 
                           onChange={handleChange1}
                           valueLabelDisplay="auto"
                           aria-labelledby="non-linear-slider"
                           aria-valuenow="0.00 mm"
                      />

              
                    <FormHelperText className="error-o">{this.state.onCaseSizeErrorText}</FormHelperText>
                    </FormControl>

                    <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20">
                      {/* <InputLabel htmlFor="demo-customized-select-native" className="color-BCBCBC">CASE MATERIAL</InputLabel> */}
                      <NativeSelect style={{marginBottom:'10px',marginTop:'10px'}}
                        id="demo-customized-select-native"
                        onChange={this.onchangeCaseMaterial}
                        style={{color:'#424242'}}
                      >

                        <option value=''>CASE MATERIAL</option>
                        {
                          caseMaterialList.map((item) => {
                            return <option value={item.casematerial}>{item.casematerial}</option>
                          })
                        }
                      </NativeSelect>
                      <FormHelperText className="error-o">{this.state.onCaseMaterialErrorText}</FormHelperText>
                    </FormControl>

                    <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20 space-top-20">
                      {/* <InputLabel htmlFor="demo-customized-select-native" className="color-BCBCBC">BAND MATERIAL</InputLabel> */}
                      <NativeSelect
                        id="demo-customized-select-native"
                        onChange={this.onchangeBandMaterial}
                        style={{color:'#424242'}}
                      >
                        <option value=''>BAND MATERIAL</option>
                        {

                          brandMaterialList && brandMaterialList.map((item) => {
                            return <option value={item.bandmaterial}>{item.bandmaterial}</option>
                          })
                        }
                      </NativeSelect>
                      <FormHelperText className="error-o">{this.state.onBandMaterialErrorText}</FormHelperText>
                    </FormControl>
                    <InputLabel htmlFor="demo-customized-select-native"  style={{marginTop:'25px', color:'#424242'}}  >BAND SIZE</InputLabel>
                    <FormControl className="form-width-70 sliderclass"  >
 {/* <                  Typography style={{textAlign:'right'}}> {scale(this.state.value)}</Typography> */}
                     <Slider
                           style={{ maxWidth: 404 }}
                           value={this.state.value}
                           min={0}
                           step={0.5}
                           max={14}
                          //  valueLabelFormat={numFormatter}
                           marks={followersMarks}
                           onChange={handleChange}
                           valueLabelDisplay="auto"
                           aria-labelledby="non-linear-slider"
                          //  aria-valuenow="0.00 mm"

                      />
                
                    <FormHelperText className="error-o">{this.state.onBandSizeErrorText}</FormHelperText>
                    </FormControl>

                  </div>
                </Grid>
               
                <Grid item xs={12} sm={6} md={6} >
                  
                  <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20"   >
                    <InputLabel htmlFor="component-simple" style={{color:'#424242',marginBottom:'10px'}}>BAND FACTORY LINKS</InputLabel>
                    <Input id="component-simple"
                      onChange={this.onchangeBandFactoryLinks}
                    />
                    <FormHelperText className="error-o">{this.state.onBandFactoryLinksErrorText}</FormHelperText>
                  </FormControl>
                  <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple"style={{color:'#424242'}}>MOVEMENT</InputLabel>
                    <Input id="component-simple"
                      onChange={this.onchangeMovement}
                    />
                    <FormHelperText className="error-o">{this.state.onMovementErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20"   >
                    <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>KNOWN LIST PRICE ($) </InputLabel>
                    <Input id="component-simple"
                      onChange={this.onchangeKnownListPrice}
                      // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                    <FormHelperText className="error-o">{this.state.onKnownListPriceErrorText}</FormHelperText>
                  </FormControl>

                  <InputLabel htmlFor="demo-customized-select-native" style={{ margin: "20px 0px", color:'#424242' }}>UPLOAD PHOTO</InputLabel>
                  <input accept="image/*" className="input-upload" id="icon-button-file1" type="file" onChange={this.UploadPhoto}  />
                  <label htmlFor="icon-button-file1">
                    <div className="" style={{ cursor: "pointer",width:"auto" ,marginRight:'170px'}}>
                      {(this.state.UploadPic) ?
                        <img src={this.state.UploadPic} alt="watchimage" className="img-responsive" style={{ width: "40%" }} />
                        :
                        <img src={watchimg} alt="watchimage" className="img-responsive" style={{ width: "40%" }} />
                      }
                    </div>
                  </label>
                  <FormHelperText className="error-o">{this.state.onUploadPicErrorText}</FormHelperText>

                  <Button type="submit" className="primary-bg color-white text-center1 form-width-70" style={{ margin: "20px 0px" }}> Submit</Button>


                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    );
  }
  render() {
    localStorage.setItem("menuTitle", "SEARCH");
    return (
      <ContentWrapper
        contentView={this.getContentView()}
      />
    );
  }
}


function mapStateToProps({ dealer, auth }) {
  return {
    brandInfo: dealer.brandInfo,
    caseMaterialInfo: dealer.caseMaterialInfo,
    brandMaterialList: dealer.brandMaterialList,
    currentUser: auth.currentUser,
    brandIdInfo: dealer.brandIdInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    brandList,
    caseMaterial,
    bandMaterial,
    getBrandId,

  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerProductDetailsComponent);