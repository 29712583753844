import { Divider } from '@material-ui/core';
import React, { Component } from 'react';
import ContentWrapper from './contentWrapper';

import { Container, Grid, Typography } from '@material-ui/core';

class GiveAwayComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    getContentView() {
        return (
            <div>
                <div>
                <div className="bg-who-we-are-page">
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <div className="space-7 text-center1">
                                    <Typography variant="h5" className="color-fff text-uppercase">About WatchOps</Typography>
                                    <Typography variant="h4" className="color-fff text-uppercase">Give Aways</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}></Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="space-top-30">
                    <Container>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <Typography variant="h5" className="text-center1 font-weight-600 color-383838"> 
                                    Membership has its advantages.                                  
                                     </Typography>
                                </div>
                                <div className="text-center1 space-top-20">
                                <Typography variant="h6" className="color-383838">
                                Active Impresari members can use rewards points to enter our occasional giveaways.
                                When we set out to create Impresari, one of our main objectives was to design our services in ways that enabled a sense of community and all that comes with it. Open communication and feedback, mutual trust and respect and most importantly a healthy sense of appreciation for the membership. The Impresari rewards program is a key piece of this enablement.
                                Members can acquire rewards points for all sorts of activities on the platform, from renting to posting reviews, referring friends to tagging Impresari in their social media. Essentially, Impresari rewards its membership for simply being active members of the community.
                                Rewards points can be used for many functions on the platform, from extending rentals to offsetting shipping and insurance fees. But, the majority of members choose to save their points up and cash them in for a very specific opportunity: The Impresari Offering.
                                The Impresari Offering is an exclusive watch giveaway available only to current Impresari members. The giveaway features pieces that are notoriously difficult to find and are often trading well above MSRP. Members can use their rewards balances to enter the giveaway for a chance to win and results are recorded, shared and celebrated with the membership. Some recent examples of the Impresari Offering are a Rolex Ceramic Daytona, a Rolex Hulk, an Audemars Piguet 15500 Royal Oak and a Rolex Submariner date.                                   
                               </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                </div>
                <div  className="space-3"></div>
                <Divider/>
                <div>
               


                 
                </div>
            </div>
         );
    }

    render() { 
        localStorage.setItem("menuTitle","HOME");
        return ( 
            <ContentWrapper
            contentView={this.getContentView()}
            />
         );
    }
}
 
export default GiveAwayComponent;