import React, { useEffect, useState } from "react";
import { Container, Typography, Divider, Switch, CardMedia, FormLabel, FormControlLabel, Checkbox, CardContent, Card, Box } from "@material-ui/core";
import {
  Breadcrumbs,
  Stack,
  Grid,
  InputBase,
  FormControl,
  Paper,
  InputAdornment,
  IconButton,
  TablePagination,
} from "@mui/material";
import alt from "../../../../assests/images/img-soon.png";
import { Link } from "react-router-dom";
import ContentWrapper from "../../../../common/components/contentWrapper";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { getDealerGroupInventoryAPI, getDealerSingleMiscAPI } from "../actions/dealerInventoryaction";
import { SpinnerRoundFilled } from "spinners-react";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import DealerDetailedCardView from "./detailed-view";
import CombinedDealer from "./combineddealerView";

const DealerDetailedView = () => {
  const ContentView = () => {
    const location = useLocation();
    const history = useHistory();
    const { payloadData } = location.state || {};
    const latestSortByValue = sessionStorage.getItem("sortByValue") ?? null;
    const [loader, setloader] = useState(true);
    const [payloadformat, setpayloadformat] = useState('');
    const [dealerList, setDealerList] = useState([]);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sortOption, setSortOption] = useState(latestSortByValue ?? "newestFirst");
    const [buisnessName, setBuisnessName] = useState("");
    const [sameItemStatus, setSameItemStatus] = useState(true);
    const [itemCheck, setItemCheck] = useState();

    useEffect(() => {
      if (!buisnessName) {
        if (payloadData?.type == 'standard_watch') {
          getDealerInventoryDetails();
        }
        else {
          getDealerMiscDetails()
        }
      }
    }, [buisnessName]);


    useEffect(() => {
      if (itemCheck) {
        if (payloadData?.type == 'standard_watch') {
          getDealerInventoryDetails();

        }
        else {
          getDealerMiscDetails()
        }
      }
    }, [itemCheck])

    const getDealerInventoryDetails = () => {
      setloader(true);
      let payload = {
        brand: payloadData?.brand,
        // key: payloadData?.key,
        dial_color: payloadData?.dial_color,
        condition: payloadData?.condition,
        inventory_status: payloadData?.inventory_status,
        type: payloadData?.type,
        business_name: buisnessName,
        model_no: payloadData?.model_no,
        productid: payloadData?.productid,
        is_group: sameItemStatus.toString()
      }
      setpayloadformat(payloadData);
      setPage(0);
      getDealerGroupInventoryAPI(payload, (err, response) => {
        if (response?.data) {
          // let quantityCheck = response.data?.[0]?.inventory_quantity;
          // if (quantityCheck) {
          //   let filteredValue = response.data.filter(item => item.createdon == payloadData?.inventory_date);
          //   setDealerList(filteredValue);
          //   setloader(false);
          let quantityCheck = response.data?.[0]?.inventory_quantity;
          if (quantityCheck) {
            if (payload?.inventory_date) {
              const filteredValue = response.data.filter(item => item.createdon == payloadData?.inventory_date);
              setDealerList(filteredValue);
            } else {
              let filteredValue = []
              filteredValue.push(response.data.reduce((mostRecent, current) => {
                return new Date(current.createdon) > new Date(mostRecent.createdon) ? current : mostRecent;
              }));
              setDealerList(filteredValue);
            }
            setloader(false);
          }
          else {
            // const filterValue = response.data.sort((a, b) => {
            //   if (a.lowest_price) {
            //     if (a.lowest_price === null || a.lowest_price === undefined) return 1;
            //     if (b.lowest_price === null || b.lowest_price === undefined) return -1;
            //     return a.lowest_price - b.lowest_price;
            //   }
            //   else {
            //     if (a.total_cost === null || a.total_cost === undefined) return 1;
            //     if (b.total_cost === null || b.total_cost === undefined) return -1;
            //     return a.total_cost - b.total_cost;
            //   }
            // });
            // setDealerList(filterValue);
            switch (sortOption) {
              case 'lowToHigh':
                handleSortChange('lowToHigh', response?.data);
                break;
              case 'highToLow':
                handleSortChange('highToLow', response?.data);
                break;
              case 'newestFirst':
              default:
                handleSortChange('newestFirst', response?.data);
                break;
            }
            setloader(false);
          }
        }
        if (err) {
          setloader(false);
          console.log("ERROR", err);
        }
      });
    };


    const getDealerMiscDetails = () => {
      setloader(true);
      let payload = {
        brand: payloadData?.brand,
        // key: payloadData?.key,
        dial_color: payloadData?.dial_color,
        condition: payloadData?.condition?.toUpperCase(),
        inventory_status: payloadData?.inventory_status,
        type: payloadData?.type,
        accountid: payloadData?.accountid,
        business_name: buisnessName,
      }
      setpayloadformat(payloadData);
      getDealerSingleMiscAPI(payload, (err, response) => {
        if (response?.data) {
          let quantityCheck = response.data?.[0]?.inventory_quantity;
          if (quantityCheck) {
            let filteredValue = response.data.filter(item => item.createdon == payloadData?.inventory_date);
            setDealerList(filteredValue);
            setloader(false);
          }
          else {
            // const filterValue = response.data.sort((a, b) => {
            //   if (a.lowest_price) {
            //     if (a.lowest_price === null || a.lowest_price === undefined) return 1;
            //     if (b.lowest_price === null || b.lowest_price === undefined) return -1;
            //     return a.lowest_price - b.lowest_price;
            //   }
            //   else {
            //     if (a.total_cost === null || a.total_cost === undefined) return 1;
            //     if (b.total_cost === null || b.total_cost === undefined) return -1;
            //     return a.total_cost - b.total_cost;
            //   }
            // });
            switch (sortOption) {
              case 'lowToHigh':
                handleSortChange('lowToHigh', response?.data);
                break;
              case 'highToLow':
                handleSortChange('highToLow', response?.data);
                break;
              case 'newestFirst':
              default:
                handleSortChange('newestFirst', response?.data);
                break;
            }
            setloader(false);
          }
        }
        if (err) {
          setloader(false);
          console.log("ERROR", err);
        }
      });
    };

    const handleEnterEvent = (event) => {
      if (event.key == 'Enter') {
        getDealerInventoryDetails();
      }
    }

    const handleSortChange = (option, data = dealerList) => {
      setSortOption(option);
      sessionStorage.setItem("sortByValue", option);
      if (option === "lowToHigh") {
        const filterValue = data.sort((a, b) => {
          if (a?.type == 'misc_watch' || a?.type == 'misc_product' || !sameItemStatus) {
            if (a.targetwholesaleprice === null || a.targetwholesaleprice === undefined) return 1;
            if (b.targetwholesaleprice === null || b.targetwholesaleprice === undefined) return -1;
            return a.targetwholesaleprice - b.targetwholesaleprice;
          }
          else {
            if (a.lowest_price === null || a.lowest_price === undefined) return 1;
            if (b.lowest_price === null || b.lowest_price === undefined) return -1;
            return a.lowest_price - b.lowest_price;
          }
        });
        setDealerList(filterValue);
      }
      if (option === "highToLow") {
        const filterValue = data.sort((a, b) => {
          if (a?.type == 'misc_watch' || a?.type == 'misc_product' || !sameItemStatus) {
            if (a.targetwholesaleprice === null || a.targetwholesaleprice === undefined) return 1;
            if (b.targetwholesaleprice === null || b.targetwholesaleprice === undefined) return -1;
            return b.targetwholesaleprice - a.targetwholesaleprice;
          }
          else {
            if (a.highest_price === null || a.highest_price === undefined) return 1;
            if (b.highest_price === null || b.highest_price === undefined) return -1;
            return b.highest_price - a.highest_price;
          }
        });
        setDealerList(filterValue);
      }
      if (option === "newestFirst") {
        const filterDate = data.sort((a, b) => {
          if (a?.type == 'misc_watch' || a?.type == 'misc_product' || !sameItemStatus) {
            if (a.createdon === null || a.createdon === undefined) return 1;
            if (b.createdon === null || b.createdon === undefined) return -1;
            return new Date(b.createdon) - new Date(a.createdon);
          }
          else {
            if (a.inventory_date === null || a.inventory_date === undefined) return 1;
            if (b.inventory_date === null || b.inventory_date === undefined) return -1;
            return new Date(b.inventory_date) - new Date(a.inventory_date);
          }
        });
        setDealerList(filterDate);
      }
    };

    const getOptionStyles = (option) => ({
      fontWeight: sortOption === option ? "700" : "normal",
      borderBottom: sortOption === option ? "2px solid #CEA05F" : "none",
      cursor: "pointer",
      fontSize: '13px',
    });

    const DealerAdornment = () => {
      if (buisnessName) {
        return (
          <InputAdornment position="end">
            {buisnessName && (
              <IconButton
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  setBuisnessName("");
                }}
                color="primary"
              >
                <ClearIcon />
              </IconButton>
            )}
            <IconButton
              className="primary-bg color-white"
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => getDealerInventoryDetails()}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        );
      } else {
        return (
          <InputAdornment position="end">
            <IconButton
              className="primary-bg color-white"
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={() => getDealerInventoryDetails()}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        );
      }
    };

    const handleChangePage = (event, newpage) => {
      setPage(newpage);
    };

    const handleChangeRowsPerPage = (event) => {
      setrowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleNextPage = (data, inventory_date) => {
      let payloadData = {
        brand: payloadformat?.brand,
        // key: payloadformat?.key,
        dial_color: payloadformat?.dial_color,
        condition: data?.conditions,
        inventory_status: payloadformat?.inventory_status,
        type: payloadformat?.type,
        productid: data?.productid,
        BusinessName: data?.businessname,
        business_name: buisnessName,
        model_no: payloadformat?.model_no,
        accountid: data?.accountid,
        inventory_date: inventory_date
      };
      history.push(`/dealerinventory/info`, { payloadData });
    };

    const handleInventoryView = (data) => {
      const { inventoryid = "" } = data
      let payload = {
        brand: payloadformat?.brand,
        // key: payloadformat?.key,
        dial_color: payloadformat?.dial_color,
        condition: payloadformat?.condition,
        inventory_status: payloadformat?.inventory_status,
        type: payloadformat?.type,
        productid: payloadformat?.productid,
        BusinessName: data?.businessname,
        business_name: data?.businessname,
        model_no: payloadformat?.model_no,
        accountid: payloadformat?.accountid,
      };
      history.push(`/dealerinventory/inventory-view/${inventoryid}`, { payloadData: payload, data });
    }

    const handlesameItemCheck = () => {
      setItemCheck(Math.random());
      setSameItemStatus(!sameItemStatus)
    }

    // const imageDestructure = (path) => {
    //   const parseValue = path.imagepaths ? JSON.parse(path.imagepaths) : alt
    //   return parseValue;
    // }

    // const onImagesShow = (row) => { };

    return (
      <div className="space-bottom-30" style={{ marginTop: "25px" }}>
        <Container>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Stack spacing={1}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<span className="sperator">›</span>}
                >
                  <Link
                    style={{
                      fontSize: "14px",
                      color: "#CEA05F",
                      textDecoration: "underline !important",
                    }}
                    to={{
                      pathname: "/dealerInventory",
                      state: { payloadData }
                    }}
                  >
                    Dealer Inventory
                  </Link>

                  <Grid
                    style={{
                      fontSize: "14px",
                      color: "#9e9e9e",
                      backgroundColor: "white",
                    }}
                    aria-current="page"
                  >
                    {payloadData?.type == 'standard_watch' ? payloadData?.model_no : payloadData?.business_name}
                  </Grid>
                </Breadcrumbs>
              </Stack>
            </Grid>
            {payloadData?.type == 'standard_watch' ?
              <Grid item xs={12} md={6}>
                <FormControl className="form-width-99">
                  <div
                    style={{
                      marginTop: "18px",
                      marginLeft: "0px",
                      marginBottom: "30px",
                    }}
                  >
                    <Paper
                      sx={{ p: "2px 4px" }}
                      style={{ height: "50px", display: "flex" }}
                    >
                      <InputBase
                        fullWidth
                        sx={{ ml: 3, flex: 1 }}
                        inputProps={{
                          "aria-label": "Enter Dealer Name",
                        }}
                        placeholder="Enter Dealer Name"
                        defaultValue={buisnessName}
                        value={buisnessName}
                        onChange={(e) => setBuisnessName(e.target.value)}
                        onKeyPress={handleEnterEvent}
                        endAdornment={DealerAdornment()}
                      />
                    </Paper>
                  </div>
                </FormControl>
                {/* <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20"
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "2rem" }}>
                  <Typography style={{ fontSize: '12px !important', fontWeight: 'bold !important' }}>Show Multiples of Same Items</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={sameItemStatus}
                        onChange={handlesameItemCheck}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                  // label="Show Multiples of Same Items"
                  />
                </FormControl> */}
              </Grid> : <Grid item xs={12} md={6}></Grid>}
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              alignItems="end"
              justifyContent="end"
              mb={1}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid
                  style={{ fontWeight: "bold" }}
                >
                  Sort by
                </Grid>
                <Grid
                  onClick={() => handleSortChange("newestFirst")}
                  style={getOptionStyles("newestFirst")}
                >
                  Newest First
                </Grid>
                <Grid
                  onClick={() => handleSortChange("lowToHigh")}
                  style={getOptionStyles("lowToHigh")}
                >
                  Price - Low to High
                </Grid>
                <Grid
                  onClick={() => handleSortChange("highToLow")}
                  style={getOptionStyles("highToLow")}
                >
                  Price - High to Low
                </Grid>
              </Stack>
            </Grid>
            <Divider style={{ width: "100%", border: "0.2px solid rgb(236, 219, 194)" }} />
            {loader === true ? (
              <div className="d-spinner">
                <SpinnerRoundFilled
                  size={51}
                  thickness={125}
                  speed={80}
                  color="rgba(57, 107, 172, 1)"
                  className="spp"
                  enabled={loader}
                />
              </div>
            )
              :
              (<Grid container spacing={2} style={{ marginTop: '1%' }}>
                {dealerList && dealerList.length > 0 ? (
                  dealerList &&
                  dealerList
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage +
                      rowsPerPage
                    ).map((row) => {
                      if (payloadformat?.type == 'standard_watch' && sameItemStatus) {
                        return <CombinedDealer item={row} page={2} handleNextPage={handleNextPage} type={'standard_watch'} />

                      }
                      else {
                        return <DealerDetailedCardView item={row} handleInventoryView={handleInventoryView} />

                      }
                    })
                ) : (
                  <Box
                    component="span"
                    sx={{
                      border: "2px dashed grey",
                      width: "100%",
                      padding: "50px 72px",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    <Container>
                      <Typography
                        variant="h6"
                        className="color-BCBCBC"
                        component="p"
                        align="center"
                      >
                        <b>No Records Found</b>
                      </Typography>
                    </Container>
                  </Box>
                )}
              </Grid>
              )}
          </Grid>
          {dealerList?.length > 0 &&
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={dealerList?.length || 0}
              rowsPerPage={rowsPerPage}
              SelectProps={{
                native: true
              }}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Container>
      </div>
    );
  };
  return <ContentWrapper contentView={ContentView()} />;
};

export default DealerDetailedView;
