import React, { Component } from 'react';
import { Container, Grid, Typography  } from '@material-ui/core';
import WatchImage1 from '../../../../assests/images/1.png';
import WatchImage2 from '../../../../assests/images/2.png';
import WatchImage3 from '../../../../assests/images/3.png';
import WatchImage4 from '../../../../assests/images/4.png';
import WatchImage5 from '../../../../assests/images/5.png';
import WatchImage6 from '../../../../assests/images/6.png';
import WatchImage7 from '../../../../assests/images/7.png';
import WatchImage8 from '../../../../assests/images/8.png';
import WatchImage9 from '../../../../assests/images/Group 1649.png';

class LoveWatchesComponent extends Component {
    constructor(props) {
        super(props)
        this.state = { 
         }
    }
    render() { 
        return ( 
            <div>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={6} style={{padding:'0px'}}>
                                    <div>
                                        <img src={WatchImage1} alt="" className="img-responsive1"/>
                                    </div>
                                    <div>
                                        <img src={WatchImage2} alt="" className="img-responsive1"/>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{padding:'0px'}}>
                                    <div>
                                        <img src={WatchImage3} alt="" className="img-responsive1"/>
                                    </div>
                                    <div>
                                        <img src={WatchImage4} alt="" className="img-responsive1"/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div>
                                <div className="space-5"></div>
                                    <Typography variant="h5" conponent="h6" className="text-center1 font-weight-700">Wait. We're Not Done Yet</Typography> 
                                    <Typography variant="body1" component="p" className="text-center1 color-575757">Love Watches?</Typography> 
                                    <div className="space-2"></div>
                                    <div>
                                    <Typography variant="h6" conponent="h6" className="text-center1 color-575757">FOLLOW US ON</Typography>
                                    <div className="border-love-watches-component"></div>
                                    <div className="center-margin space-2">
                                        <img src={WatchImage9} alt="social-icons"/>
                                        {/* <div className="social-icons-contact-us">
                                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                                        </div>
                                        <div className="social-icons-contact-us">
                                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                                        </div>
                                        <div className="social-icons-contact-us">
                                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                                        </div>
                                        <div className="social-icons-contact-us">
                                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                                        </div> */}
                                        {/* <ul className="social-icons">
                                            <li>
                                                <a href="/">
                                                <div className="social-icons-contact-us">
                                                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                                                </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/">
                                                <div className="social-icons-contact-us">
                                                    <i class="fa fa-facebook" aria-hidden="true"></i>
                                                </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/">
                                                <div className="social-icons-contact-us">
                                                    <i class="fa fa-twitter" aria-hidden="true"></i>
                                                </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/">
                                                <div className="social-icons-contact-us">
                                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                                </div>
                                                </a>
                                            </li>
                                        </ul> */}
                                    </div>
                                    </div>

                                <div className="space-5"></div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={6} style={{padding:'0px'}}>
                                    <div>
                                        <img src={WatchImage5} alt="" className="img-responsive1"/>
                                    </div>
                                    <div>
                                        <img src={WatchImage6} alt="" className="img-responsive1"/>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} style={{padding:'0px'}}>
                                    <div>
                                        <img src={WatchImage7} alt="" className="img-responsive1"/>
                                    </div>
                                    <div>
                                        <img src={WatchImage8} alt="" className="img-responsive1"/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div> 
        );
    }
}
 
export default LoveWatchesComponent;