const initialState = {
    contactManagement: "contactHomePage",
    contactViewData: {}
};

const ContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CONTACT_HOME':
            return {
                ...state,
                contactManagement: "contactHomePage"
            };
        case 'CONTACT_VIEW':
            return {
                ...state,
                contactManagement: "contactViewPage"
            };
        case 'CONTACT_ADD':
            return {
                ...state,
                contactManagement: "contactAddPage"
            };
        case 'CONTACT_EDIT':
            return {
                ...state,
                contactManagement: "contactEditPage"
            };
        case 'CONTACT_VIEW_DATA':
            return {
                ...state,
                contactViewData: action?.payload ?? {}
            };
        default:
            return state;
    }
};

export default ContactReducer;
