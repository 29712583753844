import React, { Component } from 'react';

import { Container, Grid, Typography, Button, Link } from '@material-ui/core';
import ologo from '../../../../assests/images/ologo.png';
import google from '../../../../assests/images/Group 1398.png';
import google1 from '../../../../assests/images/Group 1399.png';

class DealerSubscriptionSuccessfull extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }

    nextstep = () => {
        console.log("step2")
        this.props.setStep(5);
                if (this.props.backSubmit) {
                    this.props.backSubmit();
                }
        
            }
    render() {
        localStorage.setItem("menuTitle","SUBSCRIPTIONS");
        return (
            <div className="space-top-30 space-bottom-50">
                <Container maxWidth="md">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div>
                                <div className="text-center1">
                                    <img src={ologo} alt="logo"/>
                                </div>
                                <div className="text-center1 space-top-20">
                                    <Typography variant="h5"><b>Confirm your Subscription Package</b></Typography>
                                </div>
                                <div className="text-center1 space-top-10">
                                    <Typography variant="small">Thanks for choosing Hobbyist Tier Subscription Package to Impresari status updates.<br></br> You're almost done - we just need to confirm your subscription.</Typography>
                                </div>
                                <div className="text-center1 space-top-20">
                                    <Typography variant="small">In order to activate your subscription, please click the following link:</Typography>
                                </div>
                                <div className="space-top-20 text-center1">
                                    <div onClick={this.nextstep}>
                                        <Button className="button-bg-color">Confirm Subscription</Button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div className="space-3">
                                        <Typography variant="h4" className="button-bg-color2 text-center1">
                                            <Link href="/"><b>DOWNLOAD</b></Link>
                                        </Typography>
                                        <Typography variant="h5" className="button-bg-color2 text-center1">
                                            <Link href="/">OUR APP</Link>
                                        </Typography>
                                    </div>
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <div className="float-right">
                                                <a href="https://play.google.com/store">  <img src={google} alt="google" style={{width:'120px'}}/></a>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                            <div style={{float:'left'}}>
                                            <a href="https://www.apple.com/in/app-store/"> <img src={google1} alt="google" style={{width:'120px'}}/></a>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
         );
    }
    // render() { 
    //     return ( 
    //         <ContentWrapper
    //         contentView={this.getContentView()}
    //         />
    //      );
    // }
}
 
export default DealerSubscriptionSuccessfull;