import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Grid,
    Button,
    FormControl,
    IconButton,
    Box,
    Typography,
    Modal,
    Select,
    MenuItem,
    InputLabel,
    Input,
    FormHelperText,
} from '@mui/material';
import SuccessImage from "../../../../assests/images/success.png"
import { useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {updateInviteUserDetails} from '../actions/administration';
import { getuser } from '../../dashboard/actions/dashboardAction' 
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
    
const EditUserModal = ({ editopen, handleModalEditClose, saveModalEdit,saveModalEditOpen, userData,   getuser}) =>{
  console.log(userData)
    const { 
        register: register1, 
        handleSubmit: handleSubmit1, 
        formState: { errors: errors1 }, 
        reset: reset1,
        control: control1, 
        setValue: setValue1 
      } = useForm();
      const onSubmit1 = (data) => {
        const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null
        const inputObject = {
            first_name : data.FirstName1,
            last_name   : data.LastName1,
            accountid: userDetails ? userDetails.accountId : null,
            email: data.Email1,
            roleid: data.Role1,
            user_id: userData.userid
        }
        console.log(inputObject, "data");

        updateInviteUserDetails(inputObject,(error, result)=>{
            console.log("invite user response", result)
            if(result && result.success){
              saveModalEditOpen(true);
              if(userDetails.userId === userData.userid){
                getuser(userDetails.userId)
                setTimeout(()=>{
                    window.location.reload()
                },2000)
            }
            }else{
                toast("Error in sending invite to user", {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
            }
        })
      }

      useEffect(()=>{
        if(userData){
          setValue1('FirstName1', userData.firstname);
          setValue1('LastName1', userData.lastname);
          setValue1('Role1', userData.roleid);
          setValue1('Email1', userData.email);
        }
      },[userData])
    return (
        <div>

        <Modal
          open={editopen}
          onClose={() => {
            reset1({
                FirstName1: '',
                LastName1: '',
                Role1: '',
                Email1: '',
               })
          handleModalEditClose()}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '30%',
            height: 'auto',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '0px solid #000',

          }}
          >

            <Grid style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: '1px solid #e0e0e0', paddingBottom: '15px', alignItems: 'center' }}>
              <Grid>
                <Typography variant="h6" component="h6" style={{ marginLeft: '10px', marginTop: '10px' }}>Edit User</Typography>
              </Grid>
              <Grid >
                <IconButton onClick={()=>{
                    reset1({
                        FirstName1: '',
                        LastName1: '',
                        Role1: '',
                        Email1: '',
                       })
                  handleModalEditClose()
                }}>
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid style={{ display: 'flex', padding: '20px 35px 5px 35px' }}>
              <form key={1} onSubmit={handleSubmit1(onSubmit1)}>

                <FormControl variant="standard" className='formControl' style={{ marginBottom: '10px' }}  >
                  <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}> First Name  </InputLabel>
                  <Input id="component-simple"
                    style={{ marginBottom: '8px' }}
                    //   onChange={this.onchangePurchasedPrice}
                    // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    {...register1("FirstName1", { required: true })}
                  />
                  {errors1.FirstName1?.type === "required" && (



                    <FormHelperText className="error-o">Enter a First Name</FormHelperText>
                  )}
                </FormControl>

                <FormControl variant="standard" className='formControl'>
                  <InputLabel style={{ color: '#424242' }}>Last Name  </InputLabel>
                  <Input id="component-simple" style={{ marginBottom: '10px' }}
                    {...register1("LastName1", { required: true })}
                  //   onChange={this.onchangePurchasedPrice}
                  // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                  {errors1.LastName1?.type === "required" && (
                    <FormHelperText className="error-o">Please Enter The Last Name</FormHelperText>
                  )}
                </FormControl>

                <Controller
                                name="Role1"
                                control={control1}
                                rules={{ required: 'Please Select The Role' }}
                                defaultValue=""
                                render={({ field }) => (
                                    <FormControl variant="standard" className='formControl'>
                                        <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
                                        <Select
                                            className='select-input'
                                            style={{ color: "black", marginBottom: '10px' }}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Role"
                                            {...field}
                                        >
                                            <MenuItem value="2">Administrator</MenuItem>
                                            <MenuItem value="7">Back Office Viewer</MenuItem>
                                            <MenuItem value="8">Sales Personnel Viewer</MenuItem>
                                            <MenuItem value="9">Data Entry Assistant</MenuItem>
                                        </Select>
                                        {errors1.Role1 && (
                                            <FormHelperText className="error-o">{errors1.Role1.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />

                <FormControl variant="standard" className='formControl' style={{ marginBottom: '10px' }} >
                  <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>Email  </InputLabel>
                  <Input id="component-simple"
                    {...register1("Email1", {
                      required: true,
                      pattern: /\S+@\S+\.\S+/,
                    })}
                    disabled={true}
                  // onChange={this.onchangePurchasedPrice}
                  // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                  {errors1.Email1?.type === "required" && (
                    <FormHelperText className="error-o">Please Enter The Email</FormHelperText>
                  )}
                  {errors1.Email1?.type === "pattern" && (
                    <FormHelperText className="error-o">Invalid email address</FormHelperText>
                  )}
                </FormControl>

                <Box style={{
                  paddingTop: '1px', paddingLeft: '2px', paddingRight: '1px', paddingBottom: '4px'
                }}
                >

                  <Grid style={{ display: 'flex', flexDirection: 'row', marginTop: '35px', justifyContent: 'flex-end' }}>
                    <Grid>
                      <Button variant="text" style={{ width: '20px', color: 'blue', marginRight: '40px' }} onClick={() => handleModalEditClose()} >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid>
                      <Button variant="text" type='submit' style={{ width: '20px', color: 'blue' }}  >
                        Save
                      </Button>

                    </Grid>

                  </Grid>
                </Box>

              </form>
            </Grid>

          </Box>
        </Modal>
        {saveModalEdit ?
          <Modal
            open={saveModalEdit}
            onClose={() => handleModalEditClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '40%',
              height: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: '0px solid #000',

            }}
            >

              <Grid style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', float: 'right' }}>
                <Grid>
                  <IconButton onClick={() => handleModalEditClose()} >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '85px' }}>
                <Grid >
                  <img src={SuccessImage} alt='SuccessImage' style={{ width: '120px', height: '110px' }}></img>
                </Grid>
                <Grid>
                  <Typography variant="h6" component="h2">Updated Successfully</Typography>
                </Grid>
              </Grid>

            </Box>
          </Modal>

          : null}
      </div>
    );
}


function mapStateToProps({}) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getuser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);