import { Divider } from '@material-ui/core';
import React, { Component } from 'react';
import ContentWrapper from './contentWrapper';

import { Container, Grid, Typography } from '@material-ui/core';

class FAQComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    getContentView() {
        return (
            <div>
                <div>
                <div className="bg-who-we-are-page">
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <div className="space-7 text-center1">
                                    <Typography variant="h5" className="color-fff text-uppercase">About WatchOps</Typography>
                                    <Typography variant="h4" className="color-fff text-uppercase">FAQ</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}></Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="space-top-30">
                    <Container>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <Typography variant="h5" className="text-center1 font-weight-600 color-383838"> 
                                    Frequently Asked Questions                                    </Typography>
                                </div>
                                <div className="text-center1 space-top-20">
                                <Typography variant="h6" className="color-383838">
                                We’re constantly adding to this list of frequently asked questions. If you’re considering a membership and have questions that we’ve yet to address,
please contact us and we’d be happy to help.                                   
 </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                </div>
                <div  className="space-3"></div>
                <Divider/>
                <div>
               


                 
                </div>
            </div>
         );
    }

    render() { 
        localStorage.setItem("menuTitle","HOME");
        return ( 
            <ContentWrapper
            contentView={this.getContentView()}
            />
         );
    }
}
 
export default FAQComponent;