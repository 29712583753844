import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import '../style/Contactus.css';


class ContactUsBannerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                <div className="banner-contactus-component">
                    <Container>
                        <div>
                            <div className="space-5"></div>
                            <div className="space-3"></div>
                        <Typography variant="h6" conponent="h6" className="font-weight-700 color-white">Looking For Help?</Typography> 
                        <div className="space-1"></div>
                        <Typography variant="p" component="p" className="color-white">Get in touch by filling out the form below.</Typography> 
                        <Typography variant="p" component="p" className="color-white">We're also available by phone Monday-Saturday 10:00 AM - 7:00 PM</Typography> 
                        <Typography variant="p" component="p" className="color-white">We are happy to hear of your suggestions, queries and tips.</Typography> 
                        </div>
                    </Container>
                </div>
            </div>
         );
    }
}
 
export default ContactUsBannerComponent;