import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UpgradeImage from './utils/upgrade-plan.png';
import { Typography } from '@material-ui/core';
import logo from "../../../assests/images/logof.png";
import { useHistory } from "react-router-dom";


export default function LimitReachedComponent({isOpen, onClose, keyword='Product search and Inventories add'}) {
  const [open, setOpen] = React.useState(isOpen);
  const history = useHistory();
  const handleClose = (event,reason) => {
    onClose();
    history.push("/dealersubscribtions");
        // window.location.href='/dealersubscribtions';
  };

  
  return (
      <Dialog
        open={open}
        onClose={()=>{onClose()}}
        aria-labelledby="draggable-dialog-title"
        maxWidth={"sm"}
      >
        <DialogContent>
          <DialogContentText>
                <div><img src={logo} alt="watchops-logo" style={{display:'flex',margin: 'auto'}}/></div>
                <div style={{textAlign: 'center', margin: '10px'}}><Typography variant="h6">Your limit reached</Typography></div>
                <div style={{background: '#E6E7EA', padding: '10px', borderRadius: '10px'}}>
                    <img
                        src={UpgradeImage} 
                        alt="upgrade Subscription Pla dddn"
                        style={{display:'flex',margin: 'auto'}}
                    />
                </div>
                <br></br>
                <div>
                <Typography variant="subtitle1" className="color-383838">
                        You just reached your limit of 50 {keyword}. Upgrade to a paid plan to increase your limits.  
                    </Typography>  
                 </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-evenly', marginBottom:'20px'}}>
            <Button style={{width: '250px'}} className='CancelColor'variant="outlined" onClick={()=>{onClose()}}><b>Cancel</b></Button>
          <Button style={{width: '250px'}} className="SubmitColor" onClick={handleClose}><b>Subscribe</b></Button>
        </DialogActions>
      </Dialog>
  );
}