import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Container,
  Box,
  Grid,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import "../../../components/pages/dashboard/style/style.css";
import AdminComponent from "./adminComponent";
import ActivityLogsComponent from "./activityLogsComponent";
import UserTracking from "./userTracking";
import Dealerfeed from "./dealerComponent";
import MyInventory from "./myinventoryComponent";
import DealerCommunicationTracking from "./dealerCommunicationTracking";
import ContactManagement from "./ContactManagement/contactManagement";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContactManagementView from "./ContactManagement/contactManagementView";
import AddContactModal from "./ContactManagement/addContactModal";
import { useHistory } from "react-router";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    // height: 650,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {},
}));

const VerticalTabs = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [userDetails, setUserDetails] = React.useState(null);
  const accountId = window.sessionStorage.getItem("accountId");
  const useraccounts = window.sessionStorage.getItem("userDetails")
    ? JSON.parse(window.sessionStorage.getItem("userDetails"))
    : null;

  useEffect(() => {
    const auth = window.sessionStorage.getItem("userDetails")
      ? JSON.parse(window.sessionStorage.getItem("userDetails"))
      : null;
    setUserDetails(auth);
  }, []);

  useEffect(() => {
    // history.replace('/administration');
    props.updateContactPage("contactHomePage");
    props.updateContactData(null);
  }, [history]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const businessConfig = () => {
    const result = useraccounts.accountdetails.filter(
      (v) => v.accountid == accountId
    );
    return result[0].businessname;
  };

  // const { currentContactPage } = props;
  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            {userDetails && userDetails.accountName && (
              <>
                <Typography
                  variant="h4"
                  className="color-CEA05F text-uppercase"
                  style={{ margin: "15px 0px" }}
                >
                  {businessConfig()}
                </Typography>
              </>
            )}
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab
                style={{ textTransform: "none" }}
                label="&nbsp;&nbsp;User Management"
                {...a11yProps(0)}
                className="color-383838"
              />
              <Tab
                style={{ textTransform: "none" }}
                label="&nbsp;&nbsp;Activity Logs"
                {...a11yProps(1)}
                className="color-383838"
              />
              <Tab
                style={{ textTransform: "none" }}
                label="&nbsp;&nbsp;Dealers Feed"
                {...a11yProps(2)}
                className="color-383838"
              />
              <Tab
                style={{ textTransform: "none" }}
                label="&nbsp;&nbsp;My Inventory"
                {...a11yProps(3)}
                className="color-383838"
              />
              {/* <Tab
                style={{ textTransform: "none" }}
                label="&nbsp;&nbsp;Contact Management"
                {...a11yProps(4)}
                className="color-383838"
              /> */}
              {userDetails && userDetails.roleId === 3 && (
                <Tab
                  style={{ textTransform: "none" }}
                  label="&nbsp;&nbsp;Users Tracking"
                  {...a11yProps(4)}
                  className="color-383838"
                />
              )}
              {userDetails && userDetails.roleId === 3 && (
                <Tab
                  style={{ textTransform: "none" }}
                  label="&nbsp;&nbsp;Dealer Tracking"
                  {...a11yProps(5)}
                  className="color-383838"
                />
              )}
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <TabPanel value={value} index={0}>
              <AdminComponent {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ActivityLogsComponent {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Dealerfeed {...props} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <MyInventory {...props} />
            </TabPanel>
            {/* <TabPanel value={value} index={4}>
              {currentContactPage === "contactHomePage" && <ContactManagement {...props} />}
              {currentContactPage === "contactViewPage" && <ContactManagementView {...props} />}
              {currentContactPage === "contactAddPage" && <AddContactModal {...props} />}
              {currentContactPage === "contactEditPage" && <AddContactModal {...props} />}
            </TabPanel> */}
            <TabPanel value={value} index={4}>
              <UserTracking {...props} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <DealerCommunicationTracking {...props} />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ ContactManagement }) => {
  return {
    currentContactPage: ContactManagement.contactManagement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateContactPage: (type) => ({ type: type }),
      updateContactData: (payload) => ({ type: "CONTACT_VIEW_DATA", payload })
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalTabs);
