import React from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const DeleteConfirmation = ({
    openDeleteModal, setOpenDeleteModal, handleConfirmation, title, message, disabled = false
}) => {
    return (
        <Dialog
            open={openDeleteModal}
            // onClose={() => this.setState({ openDeleteModal: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogTitle>
                {title}
                <IconButton style={{ float: 'right' }}
                    onClick={() => setOpenDeleteModal(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div> {message} </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" autoFocus onClick={() => setOpenDeleteModal(false)}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleConfirmation} disabled={disabled}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteConfirmation