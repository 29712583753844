import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid, Typography, Card } from '@material-ui/core';
// import LineChart from './Chart';
import Chart from '../../../../assests/images/dashboard/chart.PNG';
import ebay from '../../../../assests/ebay.png';
import Facebook from '../../../../assests/images/facebook.png';
import Instagram from '../../../../assests/images/instagram.png';
import Chrono from '../../../../assests/chrono24.png';
import watchImg from '../../../../assests/images/dashboard/dashboardwatch.png';
import SplineChart from './chartComponent';
import { inventoryCount } from '../actions/dashboardAction';
import addinvetrysearchdataa from '../actions/dashboardaapi';

class DashboardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countData:''
        }
    }

    componentDidMount() {
    
        this.props.inventoryCount();
        this.getCount();
    }
    getCount(){
        addinvetrysearchdataa.dashboardInventoryCount().then((res) => {
            if (res.data.success === 1) {
            console.log(res,"dashboardInventoryCount");    
            this.setState({
                countData:res.data.data
            })
        } else if (res.data.success === 0){
            console.log("logout1")
            this.setState({loaded: true,}) 
          window.location.href='/login';
            
           }
        }
        );
    }
    render() {
        const invetoryCountInfo       = this.props.invetoryCountInfo;
        console.log(this.state.countData,"=========");
        // console.log(this.state.countData[0].inventory_count,"countData");
        return (
            <div className="">
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card className="space-marign-20">
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className="section-chart-heading-left">
                                                <SplineChart />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Card>
                            <Card>
                                            <div className="space-margin-20">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography variant="h5" className="font-weight-600 space-1">Promoted</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="space-margin-20">
                                                <Grid container spacing={2} justifyContent="center">
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={4} md={4}>
                                                                <div className="space-1">
                                                                    <img src={Instagram} alt="ebay" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} md={8}>
                                                                <div className="space-1">
                                                                    <Typography variant="h5" className="font-weight-400 space-1 float-right">{this.state.countData?this.state.countData.map((data) => (data.insta_count)):0}</Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <div style={{ width: '90%', background: '#CEA05F', height: '2px', margin: '10px 10px 25px 10px' }}>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item xs={6} sm={6} md={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={4} md={4}>
                                                                <div className="space-1">
                                                                    <img src={Facebook} alt="ebay" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} md={8}>
                                                                <div className="space-1">
                                                                    <Typography variant="h5" className="font-weight-400 space-1 float-right">{this.state.countData?this.state.countData.map((data) => (data.fb_count)):0}</Typography>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                    {/* <Grid item xs={6} sm={6} md={6}>
                                                        <div style={{ width: '90%', background: '#CEA05F', height: '2px', margin: '10px 10px 20px 10px' }}>
                                                        </div> 
                                                    </Grid>*/}
                                                    {/* <Grid item xs={6} sm={6} md={6}>
                                                        <div style={{ width: '90%', background: '#CEA05F', height: '2px', margin: '10px 10px 20px 10px' }}>
                                                        </div>
                                                    </Grid> */}
                                                </Grid>
                                            </div>
                                        </Card>
                            {/* <Card className="space-top-10">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={12}>
                                        <div className="section-chart-heading-left space-left-20">
                                            <Typography variant="h5" className="font-weight-600 space-1">Listed</Typography>

                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="space-1 space-top-10">
                                    <Grid container spacing={2}>
                                        
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={6} md={7}>
                                                    <div className="section-chart-heading-left space-top-15">
                                                        <img src={Chrono} alt="ebay" width="140px" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={7} sm={6} md={5}>
                                                    <div className="space-1">
                                                        <Typography variant="h5" className="font-weight-400 space-1 float-right">{this.state.countData?this.state.countData.map((data) => ((data.chrono24_count === null) ? 0 : data.chrono24_count)): 0 }</Typography>
                                                    </div>
                                                </Grid>
                                                <div style={{ width: '90%', background: '#CEA05F', height: '2px', margin: '26px 5px 19px 0px' }}>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Grid container spacing={2}>
                                                {/* <Grid item xs={6} sm={6} md={7}>
                                                    <div className="section-chart-heading-left space-top-15">
                                                        <img src={ebay} alt="ebay" width="140px" />
                                                    </div>
                                                </Grid> */}
                                                {/* <Grid item xs={7} sm={6} md={5}>
                                                    <div className="space-1">
                                                        <Typography variant="h5" className="font-weight-400 space-1 float-right">{this.state.countData?this.state.countData.map((data) => ((data.ebay_count === null) ? 0 : data.ebay_count)):0} </Typography>
                                                    </div>
                                                </Grid> */}
                                                {/* <div style={{ width: '90%', background: '#CEA05F', height: '2px', margin: '19px 5px 0px 0px' }}>
                                                </div> */}
                                            {/* </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Card> */} 
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card className="space-marign-top-10">
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4} md={5}>
                                            <div className="section-chart-heading-left chart-image space-1">
                                                <img src={watchImg} alt="Chart" className="height-135" />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={8} md={7}>
                                            <div className="space-1 text-center1">
                                                <Typography variant="h6" className="font-weight-700">
                                                    Total Inventory Available
                                                </Typography>
                                            </div>
                                            <div className="text-center1">
                                                <Typography variant="p">
                                                    <span className="font-size-22 font-weight-700">
                                                        {this.state.countData?this.state.countData.map((data) => (data.instock_count)):0}
                                                    </span> 
                                                    <br /> Pieces
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Card>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Card className="space-marign-10">
                                        <div className="space-marign-20">
                                            <Grid container spacing={2}>
                                                <Grid item xs={4} sm={6} md={6}>
                                                    <div className="section-chart">
                                                        <div className="chart-section">
                                                            {this.state.countData?this.state.countData.map((data) => (data.sold_count)):0}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={8} sm={6} md={6}>
                                                    <div>
                                                        <div className="section-chart-sold">
                                                            <Typography><b>SOLD</b></Typography>
                                                            <Typography>{this.state.countData?this.state.countData.map((data) => (data.sold_count)):0} sold out</Typography>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Card className="space-marign-10">
                                        <div className="space-marign-20">
                                            <Grid container spacing={2}>
                                                <Grid item xs={4} sm={6} md={6}>
                                                    <div className="section-chart">
                                                        <div className="chart-section">
                                                            {this.state.countData?this.state.countData.map((data) => (data.instock_count)):0}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={8} sm={6} md={6}>
                                                    <div>
                                                        <div className="section-chart-sold">
                                                            <Typography><b>In Stock</b></Typography>
                                                            <Typography>{this.state.countData?this.state.countData.map((data) => (data.instock_count)):0} in stock</Typography>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    {/* <div className="space-top-10"> */}
                                        {/* <Card>
                                            <div className="space-margin-20">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography variant="h5" className="font-weight-600 space-1">Promoted</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="space-margin-20">
                                                <Grid container spacing={2} justifyContent="center">
                                                    <Grid item xs={6} sm={6} md={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={4} md={4}>
                                                                <div className="space-1">
                                                                    <img src={Instagram} alt="ebay" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} md={8}>
                                                                <div className="space-1">
                                                                    <Typography variant="h5" className="font-weight-400 space-1 float-right">{this.state.countData?this.state.countData.map((data) => (data.insta_count)):0}</Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <div style={{ width: '90%', background: '#CEA05F', height: '2px', margin: '10px 10px 25px 10px' }}>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                    {/* <Grid item xs={6} sm={6} md={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={4} md={4}>
                                                                <div className="space-1">
                                                                    <img src={Facebook} alt="ebay" />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={8} md={8}>
                                                                <div className="space-1">
                                                                    <Typography variant="h5" className="font-weight-400 space-1 float-right">{this.state.countData?this.state.countData.map((data) => (data.fb_count)):0}</Typography>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                    {/* <Grid item xs={6} sm={6} md={6}>
                                                        <div style={{ width: '90%', background: '#CEA05F', height: '2px', margin: '10px 10px 20px 10px' }}>
                                                        </div> 
                                                    </Grid>*/}
                                                    {/* <Grid item xs={6} sm={6} md={6}>
                                                        <div style={{ width: '90%', background: '#CEA05F', height: '2px', margin: '10px 10px 20px 10px' }}>
                                                        </div>
                                                    </Grid> */}
                                                {/* </Grid>
                                            </div>
                                        </Card> */}
                                    {/* </div> */}
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>
                <div style={{ clear: 'both' }}></div>
            </div>
        );
    }
}

function mapStateToProps({ InventoryList }) {
    return {
        invetoryCountInfo: InventoryList.invetoryCountInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        inventoryCount,

    },
        dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
