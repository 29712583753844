const initialState = {
  userDeatilsInfo: {
    data: [],
  },
  UserCountryIdInfo: {},
  UserStateIdInfo: {},
  UserCityIdInfo: {},
};

const reducer = (state = initialState, action) => {
  let currentState = state;
  switch (action.type) {
    case "FETCH_USER_DETAILS_INFO_DATA_SUCCESS":
      currentState = { ...state, userDeatilsInfo: action.payload };
      break;
    case "FETCH_USER_COUNTRY_ID_DATA_SUCCESS":
      currentState = { ...state, UserCountryIdInfo: action.payload };
      break;
    case "FETCH_USER_STATE_ID_DATA_SUCCESS":
      currentState = { ...state, UserStateIdInfo: action.payload };
      break;
    case "FETCH_USER_CITY_ID_DATA_SUCCESS":
      currentState = { ...state, UserCityIdInfo: action.payload };
      break;
    default:
  }
  return currentState;
};

export default reducer;
