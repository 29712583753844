import axios from 'axios';
import { getToken } from '../../../utils/Authentication';

const request = axios.create({
    // baseURL: TigerURL,
    headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
});

request.interceptors.request.use((config) => {
    const configObj = config;
    const token = getToken();
    if (token) {
        const headers = { ...config.headers, Authorization : `Bearer ${token}` };
        configObj.headers = headers;
    }
    return configObj;
}, 
(error) => {
    // Do something with request error
    console.log("request error", error)
    return Promise.reject(error)
}
);

request.interceptors.response.use((response) => {
    // Do something with response data
    console.log("response",response);
    return response.data
}, (error) => {
    // Do something with response error
    console.log("response error",error.response);
   
    return Promise.reject(error)
}
);

export default request;