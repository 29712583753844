import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@mui/material/TextField';
import { toast } from "react-toastify";
import { Button, DialogActions, FormControl, FormHelperText } from "@material-ui/core";
import {
  voidInvoice
} from "./../../dashboard/actions/dashboardAction";
import InfoIcon from '@mui/icons-material/Info';
import { trim } from "lodash";
import { showErrorToast } from "../../../../common/components/Helper/Utils";

export default function CancelInvoiceModal(props) {

  const { open, handleClose, invoice, getInvoices } = props;
  const [reason, setReason] = useState('');
  const [errorText, setErrorText] = useState('');
  const [disabled, setDisabled] = useState(false);

  const updateInvoiceDetails = () => {
    if (reason.length >= 1000) {
      setErrorText('Reason cannot exceed 1000 characters.');
      return;
    }
    if (trim(reason) !== '') {
      setDisabled(true);
      const data = {
        'invoiceId': invoice.id,
        'userId': invoice.userid,
        'reason': trim(reason),
        'serialNo': invoice.serialno,
        'productId': invoice?.invoiceproductdetails?.product ? invoice?.invoiceproductdetails?.product[0]?.productid : null
      }
      voidInvoice(invoice.id, data, (err, results) => {
        if (results) {
          handleClose(true);
          getInvoices();
        }
        setDisabled(false);
        if (err) {
          handleClose(true);
          showErrorToast(err);
          setDisabled(false);
        }
      })
    }
    else {
      setErrorText('Void reason should not be blank.');
    }

  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'sm'}

    >
      <DialogTitle >
        Void Invoice
        <IconButton onClick={() => { handleClose() }} style={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div> Are you sure you want to <b>Void</b> the invoice? </div>
        <div><FormControl style={{ width: '400px', margin: '20px 0px' }}>
          <TextField
            id="outlined-multiline-flexible"
            label="Please enter the reason for void"
            multiline
            maxRows={4}
            required={true}
            style={{ width: '400px !important' }}
            onChange={(e) => { setReason(e.target.value); setErrorText('') }}
          />
          <FormHelperText className="error-o">{errorText}</FormHelperText>

        </FormControl></div>
        <p style={{ display: 'flex', alignItems: 'center' }}><InfoIcon style={{ marginRight: '5px', height: '20px', color: 'rgba(0, 0, 0, 0.6)' }} /> After void the inventory items are moved to unsold state</p>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={disabled} onClick={updateInvoiceDetails}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
