import React, { Component } from "react";
import { Container, Typography, Card, CardContent, CardMedia, Grid } from '@material-ui/core';
import img1 from '../../../../assests/images/Group 1868.png';
import img2 from '../../../../assests/images/Group 1871.png';
import img3 from '../../../../assests/images/Group 1870.png';
import '../style/homeComponent.css';

class SimpleBannerComponent extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="position-text-banner1" style={{clear:'both',padding:"0px 20px"}}>
            <Container  maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Card className="padding height-fix-290">
                <CardMedia
                  className="media"
                  image={img1}
                />
                <CardContent align="center padding-0">
                  <Typography variant="h6" align="center" className="text-wrap color-575757">
                    Freedom to change your mind
                  </Typography>
                  <div className="fix-text-width">
                  <Typography variant="p" align="center" className="color-555555">
                    Resales are difficult. Before you commit to something, take it for a test drive.
                  </Typography>
                  </div>
                </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card className="padding height-fix-290">
                  <CardMedia
                    className="media"
                    image={img2}
                  />
                  <CardContent align="center">
                    <Typography variant="h6" align="center" className="text-wrap color-575757">
                      Freedom to experience variety
                    </Typography>
                    <div className="fix-text-width">
                    <Typography variant="p" align="center" className="color-555555">
                      Your sense of style is constantly evolving. Your watches should be too.
                    </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Card className="padding height-fix-290">
                  <CardMedia
                    className="media"
                    image={img3}
                  />
                  <CardContent align="center">
                    <Typography variant="h6" align="center" className="text-wrap color-575757">
                      Freedom to try before you buy
                    </Typography>
                    <div className="fix-text-width">
                    <Typography variant="p" align="center" className="color-555555">
                      You’re in love but have no idea if it’s going to work in your life.
                    </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            </Container>
        </div>
         );
    }
}
 
export default SimpleBannerComponent;