import React from "react";
import Typography from "@mui/material/Typography";
import { IconButton, Grid, FormControl, MenuItem, Select, TextField, Paper, Button, FormHelperText } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { SpinnerRoundFilled } from "spinners-react";
import { CreateBusiness, getAccountUserId } from "../actions/dashboardAction";
import constants from "../../../../utils/constants";
import getUserdetailsforsubscrption from '../../../pages/dashboard/actions/dashboardaapi';
export default function BusinessCreatemodel(props) {
    const { open, handleloader, handleClose, statedata, countrydata } = props;
    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;
    const [businessinfo, setBusinessinfo] = useState({
        businessName: null,
        bankName: null,
        accountName: null,
        accountNo: null,
        achRouting: null,
        wireRouting: null,
        swiftCode: null,
        country: 0,
        addressLine1: null,
        addressLine2: null,
        city: null,
        statename: null,
        zipCode: null,
    })
    const [statelist,setStatelist] = useState([])
    const [countrylist,setCountrylist] = useState([])
    const [validationMsg, setValidationMsg] = useState({
        onUploadLogoErrorText: ''
    })
    const cropperRef = React.createRef();
    const [uploadlogo, setUploadlogo] = useState("")
    const [files, setFiles] = useState("")

useEffect(()=>{
    if(businessinfo.country){
        getUserdetailsforsubscrption.getAllStateList(businessinfo.country).then((response)=>{
            setStatelist(response.data.data)
        })
    }
},[businessinfo.country])

useEffect(()=>{
    if(statedata && countrydata){
        setCountrylist(countrydata);
        setStatelist(statedata);
    }
    else{
        getUserdetailsforsubscrption.getAllCountryList().then((response) => {
            setCountrylist(response.data.data);
        })
        // getUserdetailsforsubscrption.getAllStateList().then((response) => {
        //     setStatelist(response.data.data);
        // })  
    }
    
},[])

    const uploadLogo = ({ target }) => {
        const imageFile = target.files[0];
        setValidationMsg({ ...validationMsg, onUploadLogoErrorText: "" })
        if (!imageFile?.name?.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
            setValidationMsg({ ...validationMsg, onUploadLogoErrorText: "Please select only jpg,png or jpeg format images" })
            return false;
        }
        setUploadlogo(URL.createObjectURL(imageFile));
        setFiles(imageFile);
    }
    const removeEmptyKeys = (obj) => {
        const filteredEntries = Object.entries(obj).filter(([key, value]) => !(value === '' || value === undefined || (Array.isArray(value) && value.length === 0) || value ==null));
        return Object.fromEntries(filteredEntries);
    };

    const createForm = () => {
        handleClose()
        handleloader(false);
        console.log('userdddddd', userDetails.userId)
        const payload = {
            businessname: businessinfo.businessName,
            accountnumber: businessinfo.accountNo,
            accountname: businessinfo.accountName,
            userid: userDetails?.userId,
            isPrimaryAccountid: userDetails?.accountId,
            ach_routing: businessinfo.achRouting,
            wire_routing: businessinfo.wireRouting,
            swiftcode: businessinfo.swiftCode,
            bankname: businessinfo.bankName,
            tax_id: '590-90-0000',
            state: businessinfo.statename,
            country: businessinfo.country,
            addressline1: businessinfo.addressLine1,
            addressline2: businessinfo.addressLine2,
            city: businessinfo.city,
            zip: businessinfo.zipCode
        }
        const clearObj = removeEmptyKeys(payload)
        let fd = new FormData();
        if (files) {
            fd.set('files', files);
        } else if (uploadlogo) {
            fd.set('logo_url', uploadlogo);
        }
        for (const key in clearObj) {
            fd.set(key, clearObj[key])
        }
        CreateBusiness(fd, (error, res) => {
            //window.location.reload()
            getAccountUserId(userDetails?.userId, (req,res) => {
                        console.log('innnnn',res)
                const response = res.data
                let currentUser = {
                    userId: response.userid,
                    email: response.email,
                    firstName: response.firstname,
                    lastName: response.lastname,
                    roleId: response.roleid,
                    profilePic: response.profilepic,
                    isPlanExpired: response.isPlanExpired,
                    roleAccess: response.access,
                    accountdetails: response?.accountdetails,
                    accountName: response.businessname,
                    accountId: response.accountid,
                    membertierId: response.membertier_id,
                    userInActiveTime: response.userInActiveTime ? Number(response.userInActiveTime) : constants.USER_INACTIVE_TIME,
                    dealerInventoryExpiryDate: response.dealerinventory_expirydate,
                    dealerInventorySubscription: response.dealerinventory_plan,
                    isDealerInventoryExpired: response.IsDealarInventoryExipred,
                    planDetails: response.plandetails,
                    is_business_enable:response.is_business_enable
                };
                window.sessionStorage.setItem('userDetails', JSON.stringify(currentUser));
                handleloader(true)
            })
            
        });
    }


    const cropImage = (e) => {
        e.preventDefault();
        if (cropperRef.current) {
        const cropper = cropperRef.current.cropper;
        cropper.getCroppedCanvas().toBlob((blob) => {
          if(blob) {
            const url = URL?.createObjectURL(blob) || '';
        //   this.setState({
        //     cropResult: url,
        //     uploadLogo: url
        //   });
          setUploadlogo(url);

          const file = new File([blob], `${JSON.parse(sessionStorage?.userDetails)?.accountName+'.png'}`, {
            type: blob.type,
            lastModified: Date.now()
          });
          setFiles(file);
          } else {
            console.log('Failed to craete Blob..')
          }
          
        });
        }
      };
    const handleOnChange = (event) => {
        const value = event.target.value;
        setBusinessinfo({ ...businessinfo, [event.target.name]: value })
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullWidth={true}
                maxWidth={'md'}
                PaperProps={{
                    lg: { height: "96%" },
                    sx: { height: "96%" },
                    md: { height: "96%" },
                }}
            >
                <DialogTitle className="primary-text uppercase">
                    CREATE BUSINESS
                    <IconButton onClick={handleClose} style={{ float: "right" }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ height: "90%" }}>
                    <form onSubmit={(e) => { e.preventDefault(); createForm() }}>
                        {/* <div className=" space-left-20">
                                <Typography variant="h6" component="p" className="primary-text uppercase">CREATE BUSINESS</Typography>
                            </div> */}
                        <div className="space-2">
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <input accept="image/*" className="input-upload" id="image" name="files" type="file" onChange={uploadLogo} multiple />
                                    <label htmlFor="image">
                                        <div className="primary-border bg-color-F5F5F5" style={{ boxShadow: '0 10px 6px -6px #777', width: '200px', marginBottom: '30px' }}>
                                            <div className="space-5 text-center1" style={{ width: "100%" }}>
                                                <div className="text-center1">
                                                    {/* <img src={AddIcon} alt="Addicon" style={{cursor:'pointer'}}/> */}
                                                    <Typography variant="body2">Upload Company Logo</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                    {/* <Cropper
                                ref={cropperRef}
                                src={this.state.src}
                                style={{ height: 300, width: 300 }}
                                aspectRatio={1}
                                guides={false}
                                crop={cropImage}
                                background={false}
                                responsive={true}
                                />
                                <button onClick={cropImage} style={{ display: 'none' }}>Crop Image</button> */}
                                    {/* {!this.state.isLogoValid && (<FormHelperText className="error-o">{this.state.onUploadLogoErrorText}</FormHelperText>)} */}
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                    <div className="form-group multi-preview" style={{ display: 'block' }}>
                                        {/* {this.state.isDisabled1 && (<div className='space-marign-bottom-10' style={{ textAlign: 'center' }}><label className="myprofile-font">Company Logo</label></div>)} */}
                                        {uploadlogo && (
                                            <img src={uploadlogo.replaceAll('"', "")} alt="..." width="200px"
                                            />
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Business Name:</label>
                                        <TextField
                                            required
                                            id="standard-password-input"
                                            value={businessinfo.businessName}
                                            name={"businessName"}
                                            onChange={handleOnChange}
                                            //  value={userInfoData && userInfoData.businessname}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />
                                        {/* <FormHelperText className="error-o">{this.state.onBusinessNameErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Bank Name:</label>
                                        <TextField

                                            id="standard-password-input"
                                            value={businessinfo.bankName}
                                            name={"bankName"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onBankNameErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Account Name:</label>
                                        <TextField
                                            id="standard-password-input"
                                            value={businessinfo.accountName}
                                            name={"accountName"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onAccountNameErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Account Number:</label>
                                        <TextField

                                            id="standard-password-input"

                                            value={businessinfo.accountNo}
                                            name={"accountNo"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onAccountNumberErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">ACH Routing:</label>
                                        <TextField

                                            id="standard-password-input"

                                            value={businessinfo.achRouting}
                                            name={"achRouting"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onAchRoutingErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Wire Routing:</label>
                                        <TextField

                                            id="standard-password-input"

                                            value={businessinfo.wireRouting}
                                            name={"wireRouting"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onWireRoutingErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Swift Code:</label>
                                        <TextField

                                            id="standard-password-input"
                                            value={businessinfo.swiftCode}
                                            name={"swiftCode"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onSwiftCodeErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Country:</label>
                                        <Select style={{ marginTop: 0 }}
                                            value={businessinfo.country}
                                            name={"country"}
                                            onChange={handleOnChange}
                                        >
                                            {countrylist && countrylist.map((Country) => (
                                                <MenuItem value={Country.id}>{Country.name}</MenuItem>
                                            ))}
                                        </Select>
                                        {/* <FormHelperText className="error-o">{this.state.onCountryErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Address Line1:</label>
                                        <TextField
                                            id="standard-password-input"

                                            value={businessinfo.addressLine1}
                                            name={"addressLine1"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onAddressLine1ErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Address Line2:</label>
                                        <TextField

                                            id="standard-password-input"

                                            value={businessinfo.addressLine2}
                                            name={"addressLine2"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onAddressLine2ErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">City:</label>
                                        <TextField
                                            id="standard-password-input"
                                            value={businessinfo.city}
                                            name={"city"}
                                            onChange={handleOnChange}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />

                                        {/* <FormHelperText className="error-o">{this.state.onCityErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">State:</label>
                                        <Select style={{ marginTop: 0 }}
                                            value={businessinfo.statename ? Number(businessinfo.statename) : ""}
                                            name={"statename"}
                                            onChange={handleOnChange}
                                        >
                                            {
                                                statelist && statelist.map((item) => {
                                                    return <MenuItem value={item.id}>{item.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        {/* <FormHelperText className="error-o">{this.state.onStateErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                                        <label className="myprofile-font">Zip Code:</label>
                                        <TextField
                                            id="standard-password-input"

                                            value={businessinfo.zipCode}
                                            name={"zipCode"}
                                            onChange={handleOnChange}
                                            inputProps={{ maxLength: 6 }}
                                            type="text"
                                            className="registerSpace"
                                            aria-describedby="component-error-text"
                                        />
                                        {/* <FormHelperText className="error-o">{this.state.onZipCodeErrorText}</FormHelperText> */}
                                    </FormControl>
                                </Grid>



                                <Grid item xs={12} sm={12} md={6}>
                                    <div>
                                        <Button type="submit" className="SubmitColor space-marign-20" fullWidth={false} >Create</Button>
                                    </div>
                                </Grid>


                            </Grid>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

