import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container,Typography, Grid, Card, CardContent, Divider, Button }  from '@material-ui/core';
import '../style/homeComponent.css';
import { packagesList } from "./../../dealer/actions/dealerAction";

class SubscribeForWatchComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.onStartToday = this.onStartToday.bind(this);
        
    }
    componentDidMount() {
        this.props.packagesList();

    }   

    onStartToday = (pkg) => {
        const { history } = this.props;
        history.push("/sign-up");
    }
    render() {
        const packagesListData = this.props.packagesListInfo;
        return (
            <div>
                <Container>
                    <div>
                        <Typography variant="h5" align="center" className="heading-watch-lovers text-uppercase color-383838">
                            Subscribe For Luxury Watches
                        </Typography>
                    </div>
                    <div className="border-middle6"></div>
                    <div className="text-center1 padding-1">
                        <Typography variant="p" align="center" className="color-575757">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </Typography>
                    </div>
                    <div className="padding-1"></div>
                    <div>
                        <Container maxWidth="lg">
                            <Grid container spacing={7}>
                                 {packagesListData && packagesListData.map((row) => (
                                     console.log("row",row),
                                <Grid item={12} sm={6} md={4} className="alignment-4">
                                    <Card className="bg-card-signin-subscribe-color1">
                                        <CardContent align="center padding-0" className="text-center1" style={{height : "370px"}}>
                                            <Typography variant="h6" align="center" className="text-wrap color-383838" >
                                                {/* HOBBYIST TIER */}
                                                {(row) && (row.membertiername) ? (row.membertiername) : ''}
                                            </Typography>
                                            <Divider dark className="Divider-card-signin-subscribe1" />
                                            <div className="padding-1">
                                                <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                    &#36;<span className="font-size-26 color-383838"><b>{(row) && (row.price) ? row.price : ''}</b></span>/ <span className="small-0">month</span>
                                                </Typography>
                                            </div>
                                            <div className="clear-fix"></div>
                                            <Divider dark className="Divider-card-signin-subscribe1" />
                                            <div className="padding-1">
                                                <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                {(row) && (row.shortdesc) ? row.shortdesc : ''}
                                                </Typography>
                                            </div>
                                            <div className="clear-fix"></div>
                                            <Divider dark className="Divider-card-signin-subscribe1" />
                                            {(row) && (row.longdesc[1]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[1]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                              {(row) && (row.longdesc[2]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[2]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                              {(row) && (row.longdesc[3]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[3]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                              {(row) && (row.longdesc[4]) ?
                                            <div>
                                                <div className="clear-fix"></div>
                                                <Divider dark className="Divider-card-signin-subscribe1" />
                                                <div className="padding-1">
                                                    <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                        {row.longdesc[4]}<br/>
                                                    </Typography>
                                                </div>
                                            </div>
                                             :  ''}
                                            {/* <div className="padding-1">
                                                <Typography variant="p" align="center" className="text-wrap1 padding-1">
                                                    -
                                                    <br/>
                                                    <br/>
                                                </Typography>
                                            </div> */}
                                            <div className="clear-fix"></div>
                                            <Divider dark className="Divider-card-signin-subscribe1" />
                                            <div className="padding-1">
                                                <Typography variant="p" align="center" className="text-wrap1 padding-1 color-383838">
                                                    limited access
                                                </Typography>
                                            </div>
                                            <div className="clear-fix"></div>
                                            <div className="padding-3" onClick={this.props.handleNext}>
                                                 <Button className="Button-signingSubscribe2" onClick={() => { this.onStartToday(row) }}>START TODAY</Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                  ))}
                            </Grid>
                        </Container>
                    </div>
                </Container>
                
            </div>
        )
    }
}


function mapStateToProps({ dealer }) {
    return {
        packagesListInfo: dealer.packagesListInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        packagesList,
    },
        dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(SubscribeForWatchComponent);
