import { Link, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import validator from 'validator';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { Container, Grid,makeStyles, TextField, FormHelperText, Button } from '@material-ui/core';
import img1 from '../../assests/images/Group 1398.png';
import img2 from '../../assests/images/Group 1399.png';
import logof from '../../assests/images/logof.png';
import '../style/main.css';
import { newsLatterList } from "./../../components/pages/dealer/actions/dealerAction";

// class FooterComponent extends Component {
//     constructor(props) {
//         super(props);
//     this.state = {
//             newsLatter: '',
//             newsLatterError: ''
//         }
//         this.onChangeNewslatter = this.onChangeNewslatter.bind(this);
//     }
//     onChangeNewslatter(event) {
//         let newsLatter = validator.isEmail(event.target.value);
//         if (!newsLatter) {
//             this.setState({
//                 newsLatterError: "Enter correct Email Id",
//                 isValid: false
//             })
//         }
//         else {
//             this.setState({
//                 newsLatterError: "",
//                 newsLatter: event.target.value,
//                 isValid: true
//             })
//         }

//     }

//     validateInput() {
//         let newsLatterError = '';
//         let isValid = true;
//         if (this.state.newsLatter.length === 0) {
//             newsLatterError = 'Enter Correct Email Id';
//             isValid = false;
//         }
//         this.setState({
//             ...this.state,
//             newsLatterError,
//         });
//         return isValid;
//     }

//     submitForm(event) {
//         if (this.validateInput()) {
//             let data = JSON.stringify({
//                 "email": this.state.newsLatter,
//             });
//             newsLatterList(data, (error, res) => {
//             this.setState({newsLatter:''})
//             });
//         }
//     }

//     render() {
//         const itemContent ={
//             paddingRight:20,
//             cursor:'pointer'
//         }
//         return (
            
//               <div className="footermain">
//                     <Container>
                    
//                         <div>
//                             <Grid container>
                                
//                             <Grid item xs={12} sm={12} md={12}>
//                                 <div>
//                                     <p style={{ color: 'white', fontSize:'12px',textAlign:'center'}}>&#169; Copyright {new Date().getFullYear()} WatchOps. All Rights Reserved</p>
                                   
//                                 </div>
//                                 </Grid>
//                                 {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                                    
//                                 </Grid> */}
//                             </Grid>
//                         </div>
//                         <Grid container style={{display:'flex',marginBottom:11,justifyContent:'center',alignItems:'center',color:'white'}}>
//                                     <Link href='https://watchops.com/' className="text-white" item style={itemContent}>Home</Link>
//                                     <Link href='https://watchops.com/schedule-a-demo/' item style={itemContent}>Schedule a Demo</Link>
//                                     <Link href='https://watchops.com/contact-us/' item style={itemContent}>Contact us</Link>
//                                     <Link href='https://watchops.com/terms-of-use/' item style={itemContent}>Terms of Use</Link>
//                                     <Link href='https://watchops.com/terms-of-service/' item style={itemContent}>Terms of Service</Link>
//                                     <Link href='https://watchops.com/privacy-policy/' item style={itemContent}>Privacy Policy</Link>
//                                 </Grid>
//                     </Container>
//                 </div>
    
           
//         )
//     }
// }
const useStyles = makeStyles({
    itemContent: {
        marginRight:70,
        '&:hover': {
            color: '#fff !important',
            backgroundColor: '#414446 !important',
            cursor:'pointer'
        },
    },

})
function FooterComponent(){
    const classes=useStyles();
    return (
            
        <div className="footermain">
              <Container maxWidth={'md'}>
              
                  <div>
                      <Grid container>
                          
                      <Grid item xs={12} sm={12} md={12}>
                          <div>
                              <p style={{ color: 'white', fontSize:'12px',textAlign:'center'}}>&#169; Copyright {new Date().getFullYear()} WatchOps. All Rights Reserved</p>
                             
                          </div>
                          </Grid>
                          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                              
                          </Grid> */}
                      </Grid>
                  </div>
                  <Grid container style={{display:'flex',marginBottom:11,justifyContent:'space-between',alignItems:'center'}}>
                    <Grid item ><Link href='https://watchops.com/' className={classes.itemContent}>Home</Link></Grid>
                              
                    <Grid item ><Link href='https://watchops.com/schedule-a-demo/'  className={classes.itemContent}>Schedule a Demo</Link></Grid>
                    <Grid item ><Link href='https://watchops.com/contact-us/'  className={classes.itemContent}>Contact us</Link></Grid>
                    <Grid item >        <Link href='https://watchops.com/terms-of-use/'  className={classes.itemContent}>Terms of Use</Link></Grid>
                    <Grid item >        <Link href='https://watchops.com/terms-of-service/'  className={classes.itemContent}>Terms of Service</Link></Grid>
                    <Grid item >        <Link href='https://watchops.com/privacy-policy/'  className
                              ={classes.itemContent}>Privacy Policy</Link></Grid>
                          </Grid>
              </Container>
          </div>

     
  )
}
// export default FooterComponent;
export default connect(null, null)(FooterComponent);