const initialState = {
    browseWatchesInfo:{
        data:[],
    },
    invoicePriceInfo:{
        data:[],
    },
    dashboardaccordionInfo:{
        data:[],
    },
    brandInfo:{
        data:[]
    },
    BrandInfoByBrandName:{
        data:[]
    },
    caseSizeInfo:{
        data:[]
    },
    caseMaterialInfo:{
        data:[]
    },
    brandMaterialInfo:{
        data:[]
    },
    dialColorInfo:{
        data:[]
    },
    CaseSizeBySize:{
        data:[]
    },
    CaseMaterialByMaterial:{
        data:[]
    },
    ModelListInfo:{
        data:[]
    },
    ModelInfoByModelName:{
        data:[]
    },
    DialColorInfoByDialColor:{
        data:[]
    }
 };
     export default  (state = initialState, action) => {
         let currentState = state;
         switch(action.type) {
            case 'FETCH_BROWSE_WATCHES_DATA_SUCCESS':
                currentState = {...state,
                   browseWatchesInfo : action.payload
                };
            break;
            case 'FETCH_INVOICE_PRICE_DATA_SUCCESS':
                currentState = {...state,
                    invoicePriceInfo : action.payload
                };
            break;
            case 'FETCH_DASHBOARD_ACCORDION_DATA_SUCCESS':
                currentState = {...state,
                    dashboardaccordionInfo : action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_BRAND_DATA_SUCCESS':
                currentState = {...state,
                   brandInfo : action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_CASE_SIZE_DATA_SUCCESS':
                currentState = {...state,
                   caseSizeInfo : action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_CASE_MATERIAL_DATA_SUCCESS':
                currentState = {...state,
                   caseMaterialInfo : action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_BRAND_MATERIAL_DATA_SUCCESS':
                currentState = {...state,
                   brandMaterialInfo : action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_COLOR_DIAL_DATA_SUCCESS':
                currentState = {...state,
                   dialColorInfo : action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_MODEL_LIST_DATA_SUCCESS':
                currentState = {...state,
                   ModelListInfo : action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_CASE_SIZE_BY_SIZE_DATA_SUCCESS':
                currentState = {...state,
                   CaseSizeBySize : action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_CASE_MATERIAL_BY_MATERIAL_DATA_SUCCESS':
                currentState = {...state,
                   CaseMaterialByMaterial : action.payload,
                };
            break;
            case 'FETCH_BROWSE_WATCHES_BRAND_NAME_BY_BRAND_DATA_SUCCESS':
                currentState = {...state,
                    BrandInfoByBrandName: action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_MODEL_NAME_BY_MODEL_DATA_SUCCESS':
                currentState = {...state,
                    ModelInfoByModelName: action.payload
                };
            break;
            case 'FETCH_BROWSE_WATCHES_DIAL_COLOR_INFO_BY_DIAL_COLOR_DATA_SUCCESS':
                currentState = {...state,
                    DialColorInfoByDialColor: action.payload
                };
            break;
             default:
         }
         return currentState;
 }
 