import React, { Component } from 'react';
import validator from 'validator';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Grid, TextField, FormHelperText, Typography, Button } from '@material-ui/core';

import DealerBannerComponent from './dealerBannerComponent';
import '../style/dealer.css';


class DealerBusinessDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            firstName:'',
            lastName:'',
            businessName:'',
            emailId:'',
            phoneNumber:'',
            businessStreetAddress:'',
            country:'',
            city:'',
            state:'',
            zipCode:'',
            message:'',
            onFirstNameErrorText:'',
            onLastNameErrorText:'',
            onBusinessNameErrorText:'',
            onEmailIdErrorText:'',
            onPhoneNumberErrorText:'',
            onBusinessStreetAddressErrorText:'',
            onCountryErrorText:'',
            onCityErrorText:'',
            onStateErrorText:'',
            onZipCodeErrorText:'',
            onMessageErrorText:'',
         }
        this.onChangeOfFirstName                = this.onChangeOfFirstName.bind(this);
        this.onChangeOfLastName                 = this.onChangeOfLastName.bind(this);
        this.onChangeOfBusinessName             = this.onChangeOfBusinessName.bind(this);
        this.onChangeOfEmailId                  = this.onChangeOfEmailId.bind(this);
        this.onChangeOfPhoneNumber              = this.onChangeOfPhoneNumber.bind(this);
        this.onChangeOfBusinessStreetAddress    = this.onChangeOfBusinessStreetAddress.bind(this);
        this.onChangeOfCountry                  = this.onChangeOfCountry.bind(this);
        this.onChangeOfCity                     = this.onChangeOfCity.bind(this);
        this.onChangeOfState                    = this.onChangeOfState.bind(this);
        this.onChangeOfZipCode                  = this.onChangeOfZipCode.bind(this);
        this.onChangeOfMessage                  = this.onChangeOfMessage.bind(this);
        this.validateInput                      = this.validateInput.bind(this);
    }

    onChangeOfFirstName(event){
        let FirstNameVal = validator.isAlpha(event.target.value);
        if(!FirstNameVal){
          this.setState({
            onFirstNameErrorText:"Enter correct First Name",
            isValid:false
          })
        }
        else{
          this.setState({
            onFirstNameErrorText:"",
            firstName:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfLastName(event){
        let LastNameVal = validator.isAlpha(event.target.value);
        if(!LastNameVal){
          this.setState({
            onLastNameErrorText:"Enter correct Last Name",
            isValid:false
          })
        }
        else{
          this.setState({
            onLastNameErrorText:"",
            lastName:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfBusinessName(event){
        let BusinessNameVal = validator.isAlpha(event.target.value);
        if(!BusinessNameVal){
          this.setState({
            onBusinessNameErrorText:"Enter correct Business Name",
            isValid:false
          })
        }
        else{
          this.setState({
            onBusinessNameErrorText:"",
            businessName:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfEmailId(event){
        let emailIdVal = validator.isEmail(event.target.value);
        if(!emailIdVal){
          this.setState({
            onEmailIdErrorText:"Enter correct Email Id",
            isValid:false
          })
        }
        else{
          this.setState({
            onEmailIdErrorText:"",
            emailId:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfPhoneNumber(event){
        let PhoneNumberVal = validator.isMobilePhone(event.target.value);
        if(!PhoneNumberVal){
          this.setState({
            onPhoneNumberErrorText:"Enter correct Phone Number",
            isValid:false
          })
        }
        else{
          this.setState({
            onPhoneNumberErrorText:"",
            phoneNumber:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfBusinessStreetAddress(event){
        let BusinessstreetVal = validator.isAlphanumeric(event.target.value);
        if(!BusinessstreetVal){
          this.setState({
            onbusinessStreetAddressErrorText:"Enter correct Business Street Address",
            isValid:false
          })
        }
        else{
          this.setState({
            onbusinessStreetAddressErrorText:"",
            businessStreetAddress:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfCountry(event){
        let countryVal = validator.isAlpha(event.target.value);
        if(!countryVal){
          this.setState({
            onCountryErrorText:"Enter correct Country",
            isValid:false
          })
        }
        else{
          this.setState({
            onCountryErrorText:"",
            country:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfCity(event){
        let CityVal = validator.isAlpha(event.target.value);
        if(!CityVal){
          this.setState({
            onCityErrorText:"Enter correct City",
            isValid:false
          })
        }
        else{
          this.setState({
            onCityErrorText:"",
            city:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfState(event){
        let StateVal = validator.isAlpha(event.target.value);
        if(!StateVal){
          this.setState({
            onStateErrorText:"Enter correct State",
            isValid:false
          })
        }
        else{
          this.setState({
            onStateErrorText:"",
            state:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfZipCode=(event)=>{
        let ZipCodeVal = validator.isPostalCode(event.target.value);
        if(!ZipCodeVal){
          this.setState({
            onZipCodeErrorText:"Enter correct Zip Code",
            isValid:false
          })
        }
        else{
          this.setState({
            onZipCodeErrorText:"",
            zipCode:event.target.value,
            isValid:true
          })
        }
    }

    onChangeOfMessage(event){
        let MessageVal = validator.isEmpty(event.target.value);
        if(!MessageVal){
          this.setState({
            onMessageErrorText:"Enter correct Message",
            isValid:false
          })
        }
        else{
          this.setState({
            onMessageErrorText:"",
            message:event.target.value,
            isValid:true
          })
        }
    }

    validateInput(){
        console.log('this.state',this.state);
        let onFirstNameErrorText = '';
        let onLastNameErrorText  = '';
        let onBusinessNameErrorText = '';
        let onEmailIdErrorText = '';
        let onPhoneNumberErrorText = '';
        let onBusinessStreetAddressErrorText = '';
        let onCountryErrorText = '';
        let onCityErrorText = '';
        let onStateErrorText = '';
        let onZipCodeErrorText = '';
        let onMessageErrorText = '';

        let isValid = true;

        if (this.state.firstName.length === 0) {
            onFirstNameErrorText = 'Enter Correct First Name';
            isValid = false;
        } 
        if (this.state.lastName.length === 0) {
            onLastNameErrorText = 'Enter Correct Last Name';
            isValid = false;
        } 
        if (this.state.businessName.length === 0) {
            onBusinessNameErrorText = 'Enter Correct Business Name';
            isValid = false;
        } 
        if (this.state.emailId.length === 0) {
            onEmailIdErrorText = 'Enter Correct Email Id';
            isValid = false;
        } 
        if (this.state.phoneNumber.length === 0) {
            onPhoneNumberErrorText = 'Enter Correct Phone Number';
            isValid = false;
        } 
        if (this.state.businessStreetAddress.length === 0) {
            onBusinessStreetAddressErrorText = 'Enter Correct Business Street Address';
            isValid = false;
        } 
        if (this.state.country.length === 0) {
            onCountryErrorText = 'Enter Correct Country';
            isValid = false;
        } 
        if (this.state.city.length === 0) {
            onCityErrorText = 'Enter Correct City';
            isValid = false;
        } 
        if (this.state.state.length === 0) {
            onStateErrorText = 'Enter Correct State';
            isValid = false;
        } 
        if (this.state.zipCode.length === 0) {
            onZipCodeErrorText = 'Enter Correct Zip Code';
            isValid = false;
        } 
        if (this.state.message.length === 0) {
            onMessageErrorText = 'Enter Correct Message';
            isValid = false;
        } 


        this.setState({...this.state,
            onFirstNameErrorText,
            onLastNameErrorText,
            onBusinessNameErrorText,
            onEmailIdErrorText,
            onPhoneNumberErrorText,
            onBusinessStreetAddressErrorText,
            onCountryErrorText,
            onCityErrorText,
            onStateErrorText,
            onZipCodeErrorText,
            onMessageErrorText,
        })
        return isValid;
    }

    getContentView() {
        return (
            <div>
                <div>
                    <DealerBannerComponent/>
                </div>
                <div className="space-3 bg-white">
                    <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}></Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="space-2">
                                    <Typography variant="h6" component="h5" className="text-center1 primary-text">Tell us a little about yourself</Typography>
                                    <Typography variant="p" component="p" className="text-center1">We'll help you get started based on your responses</Typography>
                                </div>
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="outlined-textarea"
                                                placeholder="FIRST NAME *"
                                                multiline
                                                variant="outlined"
                                                fullWidth={true}
                                                className="MuiOutlinedInput"
                                                onChange={this.onChangeOfFirstName}
                                            />
                                            <FormHelperText className="error-o">{this.state.onFirstNameErrorText}</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="outlined-textarea"
                                                placeholder="LAST NAME *"
                                                multiline
                                                variant="outlined"
                                                fullWidth={true}
                                                className="MuiOutlinedInput"          
                                                onChange={this.onChangeOfLastName}

                                            />
                                            <FormHelperText className="error-o">{this.state.onLastNameErrorText}</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        id="outlined-textarea"
                                        placeholder="BUSINESS NAME *"
                                        multiline
                                        variant="outlined"
                                        fullWidth={true}
                                        className="MuiOutlinedInput"
                                        onChange={this.onChangeOfBusinessName}
                                    />
                                    <FormHelperText className="error-o">{this.state.onBusinessNameErrorText}</FormHelperText>
                                    <TextField
                                            id="outlined-textarea"
                                            placeholder="EMAIL *"
                                            multiline
                                            variant="outlined"
                                            fullWidth={true}
                                            className="MuiOutlinedInput"
                                            onChange={this.onChangeOfEmailId}
                                        />
                                    <FormHelperText className="error-o">{this.state.onEmailIdErrorText}</FormHelperText>
                                    <TextField
                                        id="outlined-textarea"
                                        placeholder="PHONE NUMBER *"
                                        multiline
                                        variant="outlined"
                                        fullWidth={true}
                                        className="MuiOutlinedInput"
                                        onChange={this.onChangeOfPhoneNumber}
                                    />
                                    <FormHelperText className="error-o">{this.state.onPhoneNumberErrorText}</FormHelperText>
                                    <TextField
                                        id="outlined-textarea"
                                        placeholder="BUSINESS STREET ADDRESS *"
                                        multiline
                                        variant="outlined"
                                        fullWidth={true}
                                        className="MuiOutlinedInput"
                                        onChange={this.onChangeOfBusinessStreetAddress}
                                    />
                                    <FormHelperText className="error-o">{this.state.onbusinessStreetAddressErrorText}</FormHelperText>
                                    <TextField
                                        id="outlined-textarea"
                                        placeholder="COUNTRY *"
                                        multiline
                                        variant="outlined"
                                        fullWidth={true}
                                        className="MuiOutlinedInput"
                                        onChange={this.onChangeOfCountry}
                                    />
                                    <FormHelperText className="error-o">{this.state.onCountryErrorText}</FormHelperText>
                                    <TextField
                                        id="outlined-textarea"
                                        placeholder="CITY *"
                                        multiline
                                        variant="outlined"
                                        fullWidth={true}
                                        className="MuiOutlinedInput"
                                        onChange={this.onChangeOfCity}
                                    />
                                    <FormHelperText className="error-o">{this.state.onCityErrorText}</FormHelperText>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="outlined-textarea"
                                                placeholder="STATE *"
                                                multiline
                                                variant="outlined"
                                                fullWidth={true}
                                                className="MuiOutlinedInput"
                                                onChange={this.onChangeOfState}
                                            />
                                            <FormHelperText className="error-o">{this.state.onStateErrorText}</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="outlined-textarea"
                                                placeholder="ZIP CODE *"
                                                multiline
                                                variant="outlined"
                                                fullWidth={true}
                                                className="MuiOutlinedInput"
                                                onChange={this.onChangeOfZipCode}
                                            />
                                            <FormHelperText className="error-o">{this.state.onZipCodeErrorText}</FormHelperText>
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        id="outlined-multiline-static"
                                        placeholder="MESSAGE *"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth={true}
                                        onChange={this.onChangeOfMessage}
                                        />
                                    {/* <TextField
                                        id="outlined-textarea"
                                        placeholder="MESSAGE *"
                                        rows={25}
                                        variant="outlined"
                                        fullWidth={true}
                                        className="MuiOutlinedInput"
                                    /> */}
                                    <FormHelperText className="error-o">{this.state.onMessageErrorText}</FormHelperText>
                                    <Button className="bg-card-signin-subscribe-color text-center1 form-width-100" onClick={this.validateInput}>Sumbit</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}></Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        );
    }

    render() { 
        return ( 
            <ContentWrapper
            contentView={this.getContentView()}
            />
         );
    }
}
 
export default DealerBusinessDetailsComponent;