import React, { Component } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import '../style/homeComponent.css';

class HowItWorksComponent extends Component {
    render() {
        return (
            <div>
                <div style={{background:"#fff"}}>
                <div className="space-2"></div>
                <Container maxWidth="lg">
                    <Typography variant="h5" align="center" className="font-weight-500 text-uppercase color-383838">How It Works?</Typography>
                    <div className="border-middle6"></div>
                    <div className="text-center1">
                        <Typography variant="p" className="pt-1 color-575757">Get things done through one touch amazing 3 step process</Typography>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={4}>
                            <div>
                                <div className="bg-color-3-how-it-work">
                                    <p>1</p>
                                </div>
                                <div className="pb-1"></div>
                                <div className="pb-1"></div>
                                <div className="text-center1">
                                    <Typography variant="h6" className="color-383838"><b>Download the WatchOps App</b></Typography>
                                    <div className="pb-1"></div>
                                    <div className="pb-1"></div>
                                    <Typography variant="p" className="color-383838">The WatchOps app is the center of it all. With state-of-the-art functionality and security, the WatchOps app is the key to unlocking the power of the WatchOps service.</Typography>
                                    <div className="pb-1"></div>
                                    <div className="pb-1"></div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div>
                                <div className="bg-color-3-how-it-work">
                                    <p>2</p>
                                </div>
                                <div className="pb-1"></div>
                                <div className="pb-1"></div>
                                <div className="text-center1">
                                    <Typography variant="h6" className="color-383838"><b>Apply for Membership</b></Typography>
                                    <div className="pb-1"></div>
                                    <div className="pb-1"></div>
                                    <Typography variant="p" className="color-383838">Applying for an WatchOps membership takes less than 5 minutes. Answer a few questions, verify your identity and you’ll be up and running in no time.</Typography>
                                    <div className="pb-1"></div>
                                    <div className="pb-1"></div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div>
                                <div className="bg-color-3-how-it-work">
                                    <p>3</p>
                                </div>
                                <div className="pb-1"></div>
                                <div className="pb-1"></div>
                                <div className="text-center1">
                                    <Typography variant="h6" className="color-383838"><b>Start Searching</b></Typography>
                                    <div className="pb-1"></div>
                                    <div className="pb-1"></div>
                                    <Typography variant="p" className="color-383838">Applying for an WatchOps membership takes less than 5 minutes. Answer a few questions, verify your identity and you’ll be up and running in no time.</Typography>
                                    <div className="pb-1"></div>
                                    <div className="pb-1"></div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                </div>
                <div className="pt-1"></div>
                <div className="pt-1"></div>
            </div>
        )
    }
}
export default HowItWorksComponent;
