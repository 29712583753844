import React, { Component } from 'react';
import Slider from "react-slick";
import { Container, Typography, Avatar } from '@material-ui/core';
import '../style/homeComponent.css';

class OurMemberSayComponent extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
    
        }
      }
    
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoPlay:true,
            arrows:true
          };
        return (
            <div>
                <div className="bg-color">
                <div className="space-3"></div>
                <Container>
                    <Typography variant="h5" align="center" className="font-weight-700 text-uppercase color-383838">What Our Members Say</Typography>
                    <div className="border-middle6"></div>
                    <Slider {...settings}>
                    <div className="text-center1">
                        <div className="space-1">
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className="img-large"/>
                        </div>
                        <Typography variant="p" className="text-center1 color-000">The variety in the collection is insane. I’ve gotten to try all the watches I’ve considered owning and I’m not even halfway through my list.</Typography>
                        <div className="space-1"></div>
                        <Typography variant="h6">Steve McFadded</Typography>
                    </div>
                    <div className="text-center1">
                        <div className="space-1">
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className="img-large"/>
                        </div>
                        <Typography variant="p" className="text-center1 color-000">The variety in the collection is insane. I’ve gotten to try all the watches I’ve considered owning and I’m not even halfway through my list.</Typography>
                        <div className="space-1"></div>
                        <Typography variant="h6">Steve McFadded</Typography>
                    </div>
                    <div className="text-center1">
                        <div className="space-1">
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className="img-large"/>
                        </div>
                        <Typography variant="p" className="text-center1 color-000">The variety in the collection is insane. I’ve gotten to try all the watches I’ve considered owning and I’m not even halfway through my list.</Typography>
                        <div className="space-1"></div>
                        <Typography variant="h6">Steve McFadded</Typography>
                    </div>
                    <div className="text-center1">
                        <div className="space-1">
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className="img-large"/>
                        </div>
                        <Typography variant="p" className="text-center1 color-000">The variety in the collection is insane. I’ve gotten to try all the watches I’ve considered owning and I’m not even halfway through my list.</Typography>
                        <div className="space-1"></div>
                        <Typography variant="h6">Steve McFadded</Typography>
                    </div>
                    </Slider>
                </Container>
               
                <div className="space-3"></div>
                </div>
            </div>
        )
    }
}
export default OurMemberSayComponent;