import React, { Component } from 'react';
import { Container, Grid, Typography, Link } from '@material-ui/core';

class ProductListingDetailsCompoent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                <div className="space-4">
                    <Container>
                        <div>
                            <div className="space-bottom-10">
                                <Typography variant="h5">Product Specifications</Typography>
                            </div>
                            <div className="space-bottom-15">
                                <Typography variant="small">Watch Information</Typography>
                            </div>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <div>
                                                    <Typography variant="p" component="p">Reference Number</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <div>
                                                    <Typography variant="p" component="p" className="float-right"><b>128239</b></Typography>
                                                </div>              
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Brand</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Rolex</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Model</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Day-Date 36</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Band Material</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Oyster Perpetual</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Case Material</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>White gold</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Year of Production</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>2020</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Scope of delivery</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Original box, Original papers</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Gender</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Men's watch/Unisex</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Location</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>USA, UK, New York</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="space-bottom-15">
                                    <Typography variant="small"><b>Caliber</b></Typography>
                                </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Movement</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Automatic</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Movement/Caliber</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>3255</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Power reserve</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>70 h</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                <div className="space-bottom-15 space-top-10">
                                    <Typography variant="small"><b>Case</b></Typography>
                                </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Case Material</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>White gold</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Case diameter</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>36 mm</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Water resistance</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>10 ATM</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                                    <div className="space-bottom-10 space-top-10 border-0">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p">Dial Color</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <div>
                                                <Typography variant="p" component="p" className="float-right"><b>Blue</b></Typography>
                                            </div>              
                                        </Grid>
                                    </Grid>
                                    </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className="test-us-free-background-color">
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="text-center1 space-1">
                            <Typography variant="h6" component="h6" className="color-white">Start your 30-day trial of our package now</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="text-center1 space-2">
                                <Link className="test-us-free-button-color color-white">TEST US FOR FREE!</Link>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                </div>
                <div className="space-m-2"></div>
            </div>
         );
    }
}
 
export default ProductListingDetailsCompoent;