import { Divider } from '@material-ui/core';
import React, { Component } from 'react';
import ContentWrapper from '../../../../common/components/contentWrapper';
import DealerProductUploadImagesComponent from './dealerProductUploadImagesComponent';
import MiscWatchesInventory from './miscWatchesInventory';

class MiscWatchesMainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryId: null

        }
    }

    inventoryIntakeSubmitted1 = (inventoryId) => {

        this.setState({
            ...this.state,
            inventoryId: inventoryId
        })

    }



    getContentView() {
        return (

            <div className="bg-white">
                <div className="space-top-30">
                   
                    {this.state.inventoryId ? (
                        <div>
                            <DealerProductUploadImagesComponent
                            {...this.props}
                                inventoryId={this.state.inventoryId} /></div>)
                        :
                        <MiscWatchesInventory
                            {...this.props}
                            submitted={this.inventoryIntakeSubmitted1}
                        />}
                        

                </div>
            </div>
        );
    }

    render() {
        // localStorage.setItem("menuTitle", "ADD INVENTORY");
        const userData = JSON.parse(sessionStorage.getItem("userDetails"));

        return (
            <ContentWrapper
                contentView={this.getContentView()}
               
            />
        );
    }
}

export default MiscWatchesMainPage;