import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
import ContentWrapper from '../../../../../common/components/contentWrapper';
import { Container, Paper, Typography, TextField, Grid, Button, InputLabel, Input, FormHelperText, Link, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
// import google from '../../../../assests/images/google.png';
// import facebook from '../../../../assests/images/facebook.png';

import '../../../../../common/style/main.css'
import { recoverPassword, login, addUserData, validateUserDetails } from "./../../../../Auth/reducer/actions"

import { toast } from "react-toastify";
import { InputAdornment, Select, MenuItem } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReactivateAccountModal from '../../../../../common/components/ReactivateAccountModal';

class RegisterSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {

      type: 'password',
      score: 'null',
      firstName: '',
      lastName: '',
      countryCode: '',
      phoneNumber: '',
      emailId: '',
      password: '',
      confirmPassword: '',
      terms: false,
      firstNameError: '',
      lastNameError: '',
      phoneNumberError: '',
      emailIdError: '',
      passwordError: '',
      confirmPasswordError: '',
      termsError: '',
      sign:"+",
      open:false,
      userId:'',
      email: '',
      component: 'SINGUP',
      deletedDate:'',

      businessName:'',
     
      address1: '',
      address2: '',
      country: 231,
      countryId:231,
      state: '',
      city: '',
      zipcode: '',
      address1ErrorText: '',
      address2ErrorText: '',
      countryErrorText: '',
      stateErrorText: '',
      cityErrorText: '',
      zipcodeErrorText: '',
      businessNameErrorText:'',
      userdetailsforsubcplan:[],
      statesListData: '',
      isValid: false,
      userdetailsbusiess:[],
      nextClick:false,

    }
    this.showHide = this.showHide.bind(this);
    this.passwordStrength = this.passwordStrength.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
    this.onChangeOfTerms = this.onChangeOfTerms.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.onChangeOfCountry = this.onChangeOfCountry.bind(this);
    this.handlePush = this.handlePush.bind(this);
    this.onChangeOfPhoneNumber = this.onChangeOfPhoneNumber.bind(this)
  }
 
  componentDidMount(){
          localStorage.removeItem("token");
          window.sessionStorage.removeItem("userDetails");
          //console.log(this.props.history.location.state)
          if(this.props.history?.location.state){
            const previousData = this.props.history.location.state;
            console.log(previousData,"previousData")
            console.log(previousData.first_name)
            this.setState({
              firstName: previousData.first_name,
              lastName: previousData.last_name,
              countryCode: previousData.country_code,
              phoneNumber: previousData.phone,
              emailId: previousData.email,
              password: previousData.password,
              confirmPassword: previousData.password,
              terms: true,
            })
          }
        
  }
      
  handleSocialLogin = (user) => {
    console.log(user,"userdetails")
    let data = {};
    let loginData = {};
    // eslint-disable-next-line default-case
    switch (user._provider) {
      case "google":
        data = {
          first_name: user._profile.firstName,
          last_name: user._profile.lastName,
          email: user._profile.email,
          phone: '',
          source: user._provider,
          source_id: user._profile.id,
          photo_url: user._profile.profilePicURL,
        };
        break;
      case "facebook":
        data = {
          first_name: user._profile.firstName,
          last_name: user._profile.lastName,
          email: user._profile.email,
          phone: '',
          source: user._provider,
          source_id: user._profile.id,
          photo_url: user._profile.profilePicURL,
        };
        break;
    }
    const { loginRequested, loginSuccess, loginFailed, history } = this.props;
    loginRequested(null);
    loginData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      source: data.source,
      source_id: data.source_id,
      phone:'0000000010',
      photo_url: data.photo_url,
      country_code:'+91'
    };
    // sociallogin(
    //   loginData,
    //   (res) => {
    //     console.log()
    //     if (res.success === 1) {
    //       toast("User Login successfully", {
    //         autoClose: 5000,
    //         type: toast.TYPE.SUCCESS,
    //         position: toast.POSITION.BOTTOM_CENTER,
    //       });
    //       loginSuccess(res);
    //       history.push("/add-to-inventory-search");
    //     }
    //     else {
    //       this.props.setUserDeatils(data);
    //       history.push("/verify-email ");
    //     }
    //   },
    //   (error) => {
    //     loginFailed(error);
    //     toast("Please check your credentials.", {
    //       autoClose: 5000,
    //       type: toast.TYPE.ERROR,
    //       position: toast.POSITION.BOTTOM_CENTER,
    //     });
    //   }
    // );
    console.log(loginData,"logindata")
    this.props.addUserData(loginData,(error, res) =>{
      console.log('res', res);
      if (res?.success === 1) {
        // this.props.recoverPassword(otpData, (error, res) => {
        //   if (error) {
        //     toast("Somthing went wrong", {
        //       autoClose: 5000,
        //       type: toast.TYPE.ERROR,
        //       position: toast.POSITION.BOTTOM_CENTER,
        //     });
        //   }
        // })
        this.props.setUserDeatils(data);
          toast("User Registered Successfully", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        // history.push("/login");
        // localStorage.setItem('email', this.state.emailId);
        // localStorage.setItem('mobile', this.state.phoneNumber);
      } else {
      let  loginDatadetails = {
          email: loginData.email,
          source: loginData.source,
          source_id: loginData.source_id
        };
        console.log(loginDatadetails,"data")
        login(
          
          loginDatadetails,
          (res) => {
            if (res.success === 1) {
              toast("User Login successfully", {
                autoClose: 5000,
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_CENTER,
              });
              loginSuccess(res);
              history.push("/add-to-inventory-search");
            }
            else {  
              this.props.setUserDeatils(data);
              history.push("/verify-email");
            }
          },
          (error) => {
            loginFailed(error);
            toast("Please check your credentials.", {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        );
      }
    });
  };


  handleSocialLoginFailure = (err) => {
    console.error(err)
  }
  showHide(e) {

    e.preventDefault();
    e.stopPropagation();
    this.setState({

      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  passwordStrength(e) {
    if (e.target.value === '') {
      this.setState({
        score: 'null'
      })
    }
    else {
      let pw = e.target.value;
      this.setState({
        score: pw.score
      });
    }
  }
  onChangeOfFirstName = (event) => {
    this.setState({
        firstName: event.target.value
    })
    let firstNameVal = event.target.value;
    if (!firstNameVal) {
      this.setState({
        firstNameError: "Enter First Name",
        isValid: false
      })
    }
    else {
      this.setState({
        firstNameError: "",
        isValid: true
      })
    }
  }
  onChangeOfTerms = (event) => {
    this.setState({
      terms: event.target.checked,
      isValid: true
    })

  }

  onChangeOfLastName = (event) => {
    this.setState({
        lastName: event.target.value
    })
    let lastNameVal = event.target.value;
    if (!lastNameVal) {
      this.setState({
        lastNameError: "Enter Last Name",
        isValid: false
      })
    }
    else {
      this.setState({
        lastNameError: "",
        isValid: true
      })
    }
  }

  onChangeOfBusinessName = (event) => {
    this.setState({
        businessName: event.target.value
    })
    let BusinessNameVal = event.target.value;
    if (!BusinessNameVal) {
      this.setState({
        businessNameErrorText: "Enter Business name",
        isValid: false
      })
    }
    else {
      this.setState({
        businessNameErrorText: "",
        isValid: true
      })
    }
  }

  onChangeOfZipcode = (event) => {
    if (event.target.value !== "") {
    let codeVal = validator.isNumeric(event.target.value);
    if (!codeVal) {
      this.setState({
        ...this.state,
        onCodeErrorText: "Enter correct Zip Code",
        isValid: false,
      })
    }
    else {
      this.setState({
        ...this.state,
        onCodeErrorText: "",
        code: event.target.value,
        isValid: true
      })
    }
  }else {
    this.setState({
            ...this.state,
            onCodeErrorText: "",
            code: event.target.value,
            isValid: true,
          })
        }
  }

  onChangeOfCountryCode = (event) => {
    if(event.target.value !== ""){
      let countryCodeVal =  validator.isNumeric(event.target.value);
     
      if(!countryCodeVal){
        this.setState({
          ...this.state,
          countryCodeError: "Enter minimum 3 characters for country code",
          isValid: false,
        })
      }
      else{
        this.setState({
          ...this.state,
          countryCodeError: "",
          countryCode: event.target.value,
          isValid: true
        })
      }
      }else{
        this.setState({
          ...this.state,
          countryCodeError: "",
          countryCode: event.target.value,
          isValid: true,
        })
      }
    }
    
 
  
  // onChangeOfCountryCode = (event) => {
    
  //   const regex = "^(?:[+\d].*\d|\d)$";
  //   var sign = "+";
  //   let countryCodeVal =  event.target.value;
  //   console.log("countryCodeVal",countryCodeVal);
  //   let countryCode = countryCodeVal.match(regex); 
  //  if (event.target.value >=1 && event.target.value <= 4) {
  // // let countryCodeVal = validator.isNumeric(event.target.value);
  //   if ( !countryCode) {
  //     this.setState({
  //       ...this.state,
  //       countryCodeError: "Enter minimum 3 characters for country code",
  //       isValid: false
  //     })
  //   }
  //   else {
  //     this.setState({
  //       ...this.state,
  //       countryCodeError: "",
  //       countryCode: countryCodeVal,
  //       isValid: true
  //     })
  //   }
  // }
  //   else {
  //     this.setState({
  //       ...this.state,
  //       countryCodeError: "",
  //       countryCode: this.state.sign + event.target.value,
  //       isValid: true
  //     })
  //   }
  // }

  onChangeOfPhoneNumber = (event) => {
    this.setState({
      phoneNumber: event.target.value,
    })
   // let phoneNumberVal = validator.isMobilePhone(event.target.value);
   const regex = /^\d+$/

    let PhoneNumberVal = event.target.value;
    console.log(PhoneNumberVal)
    // if(event.target.value <= 9){
      let phoneNumber = regex.test(PhoneNumberVal); 
      if (!phoneNumber) {
          this.setState({
            phoneNumberError: "Enter Valid Phone Number",
            isValid: false
          })
      } else {
          this.setState({
            phoneNumberError: "",
            isValid: true
          })
      }
  // }
  //   else {
  //     this.setState({
  //       phoneNumberError: "",
  //       phoneNumber: event.target.value,
  //       isValid: true
  //     })
  //   }
  }

  onChangeOfEmailId = (event) => {
    this.setState({
      emailId: event.target.value,
    })
    let emailIdVal = validator.isEmail(event.target.value);
    if (!emailIdVal) {
      this.setState({
        emailIdError: "Enter correct Email Id",
        isValid: false
      })
    }
    else {
      this.setState({
        emailIdError: "",
        isValid: true
      })
    }
  }

  onChangeOfPassword = (event) => {
    this.setState({
        password: event.target.value,
      })
    let passwordVal = event.target.value;
    if (passwordVal < 8) {
      this.setState({
        passwordError: "Enter correct Password",
        isValid: false
      })
    }
    else {
      this.setState({
        passwordError: "",
        isValid: true
      })
    }
  }

  onChangeOfConfirmPassword = (event) => {
    this.setState({
        confirmPassword: event.target.value,
      })
    let confirmPasswordVal = event.target.value;
    if (!confirmPasswordVal) {
      this.setState({
        confirmPasswordError: "Enter Confirm Password",
        isValid: false
      })
    }
    else {
      this.setState({
        confirmPasswordError: "",
        isValid: true
      })
    }
  }

  handleClick = () => {
    const { history } = this.props;
    history.push('/terms-conditions')
  }

  validateInput() {
    console.log(this.state);
    let firstNameError = '';
    let lastNameError = '';
    let countryCodeError = '';
    let phoneNumberError = '';
    let emailIdError = ''
    let passwordError = '';
    let confirmPasswordError = '';
    let termsError = '';
    let businessNameErrorText = '';
    let isValid = true;

    if (this.state.firstName.length === 0) {
      firstNameError = 'Enter First Name';
      isValid = false;
    }

    if (this.state.lastName.length === 0) {
      lastNameError = 'Enter Last Name';
      isValid = false;
    }
    if (this.state.businessName.length === 0) {
        businessNameErrorText = 'Enter Business Name';
        isValid = false;
      }
    if (this.state.countryCode.length <= 1) {
     
      countryCodeError = 'Enter minimum 2 characters for country code';
      isValid = false;
    }
    if (this.state.phoneNumber.length === 0 || this.state.phoneNumber.length === null || this.state.phoneNumber.length <= 9) {

      phoneNumberError = 'Enter Phone Number';
      isValid = false;
    }
    if (this.state.emailId.length === 0) {
      emailIdError = 'Enter Email Id';
      isValid = false;
    }
    if (this.state.password.length < 8) {
      passwordError = 'Enter password with minimum 8 characters';
      isValid = false;
    }
    if (this.state.confirmPassword.length < 8) {
      confirmPasswordError = 'Enter Confirm Password';
      isValid = false;
    }

    if (this.state.confirmPassword != this.state.password) {
      confirmPasswordError = 'Passwords do not match';
      isValid = false;
    }
    if (this.state.terms === false) {
      termsError = 'Please accept terms & conditions';
      isValid = false;
    }

    this.setState({
      ...this.state,
      firstNameError,
      lastNameError,
      emailIdError,
      passwordError,
      confirmPasswordError,
      countryCodeError,
      phoneNumberError,
      termsError,
      businessNameErrorText,
    })
    return isValid;
  }
  
  handlePush = (data) => {
    const { history } = this.props;
  
    if(this.props.history.location.state && this.props.history.location.state.businessData ){
      data['businessData'] = this.props.history.location.state.businessData
    }
    console.log('handlePushdata',data)
     
    // history.push({
    //   pathname: '/bussiness-detail',
    //   state: data
    // });
  }

  submitForm(event) {
    if (this.validateInput()) {
      console.log("I am here")
      let data = {
        "first_name": this.state.firstName,
        "last_name": this.state.lastName,
        "country_code": this.state.countryCode,
        "phone": this.state.phoneNumber,
        "email": this.state.emailId,
        "password": this.state.password,
        "business_name": this.state.businessName
      };
      console.log("validate user",data)
      validateUserDetails(data,(error,result)=>{
        console.log("validateUserDetails:validateUserDetails")
        console.log(result)
        if (error) {
                  toast("Somthing went wrong", {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
        }else{
          if(result){
            if (result.success === 1) {
              //this.props.handleNext();
              //this.handlePush(data)
              console.log("addUserDatadata",data)
              this.props.addUserData(data,(error, res) =>{
                console.log('res', res);
                localStorage.setItem('userId',res.data.userid)
                localStorage.setItem('token',res.data.token)
                
                if (res?.success === 1) {
                    this.props.handleNext();
                } 
              });
            }else if(result.success ===0){
              console.log(result)
              toast(result.message, {
                autoClose: 5000,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          }else{
            toast("Somthing went wrong", {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        }
      })
      
      
     
    //   let otpData = {
    //     'to': this.state.emailId,
    //     'url': 'email-otp/send'
    //   };
    // console.log(this.props);
    //   const { history } = this.props;
    //   this.props.addUserData(data,(error, res) =>{
    //   console.log('res', res);
    //   if (res.success === 1) {
    //     this.props.recoverPassword(otpData, (error, res) => {
    //       if (error) {
    //         toast("Somthing went wrong", {
    //           autoClose: 5000,
    //           type: toast.TYPE.ERROR,
    //           position: toast.POSITION.BOTTOM_CENTER,
    //         });
    //       }
    //       else{
    //         toast("OTP is sent to your registered Email Id"+" "+this.state.emailId, {
    //           autoClose: 5000,
    //           type: toast.TYPE.SUCCESS,
    //           position: toast.POSITION.BOTTOM_CENTER,
    //         });
    //       }
          
    //     })
    //     this.props.setUserDeatils(data);
    //       toast("User Registered Successfully", {
    //         autoClose: 5000,
    //         type: toast.TYPE.SUCCESS,
    //         position: toast.POSITION.BOTTOM_CENTER,
    //       });
    //     history.push("/verify-email");
    //     localStorage.setItem('email', this.state.emailId);
    //     localStorage.setItem('mobile', this.state.phoneNumber);
    //   } 
      
    //   else if(res.success === 0 && res.deletedAccount && res.deletedAccount.keepThisAccount )
    //     {
    //       let date=res.deletedAccount.deletedDate;
    //       console.log(date)
    //       let currentDate=moment(date, "YYYY-MM-DD");
    //       currentDate.add(1,'month')
         
    //     this.setState({
    //       open:true,
    //       userId: res.deletedAccount.userId,
    //       email: res.deletedAccount.email,
    //       deletedDate:currentDate.format("YYYY-MM-DD")
    //     })
       
    //     console.log(currentDate,"Delete Date")
    //   //   toast("Your Account can be activated.Please Login again", {
    //   //     autoClose: 5000,
    //   //     type: toast.TYPE.SUCCESS,
    //   //     position: toast.POSITION.BOTTOM_CENTER,
    //   // });
    // }
    //   else {
    //     toast(res.message, {
    //       autoClose: 5000,
    //       type: toast.TYPE.ERROR,
    //       position: toast.POSITION.BOTTOM_CENTER,
    //     });
    //   }
    // });
    }
  }

  handleClose(){
   this.setState({
    open:false
    })  
   }

  getContentView() {
    return (
      <div>
       
        <Container maxWidth="sm" className="space-1">
        <Paper style={{paddingLeft:'10px',paddingRight:'10px'}}>
          <div className="text-center">
            <Typography variant="h5" gutterBottom align="center" className="text-white">
              Sign Up
              </Typography>
          </div>
          <form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='subscribe_signup_form' >
            <Grid>
              <Grid item xs={12} sm={12} md={12}>

                <FormControl className="form-width-100  ">
                  <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>FIRST NAME</InputLabel>
                  <Input id="component-simple"
                    value={this.state.firstName}
                    onChange={this.onChangeOfFirstName}
                  />
                  <FormHelperText className="error-o">{this.state.firstNameError}</FormHelperText>
                </FormControl>

                <FormControl className="form-width-100 ">
                  <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>LAST NAME</InputLabel>
                  <Input id="component-simple"
                    value={this.state.lastName}
                    onChange={this.onChangeOfLastName}
                  />
                  <FormHelperText className="error-o">{this.state.lastNameError}</FormHelperText>
                </FormControl>

                <FormControl className="form-width-100  ">
                  <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>E-MAIL</InputLabel>
                  <Input id="component-simple"
                    value={this.state.emailId}
                    onChange={this.onChangeOfEmailId}
                  />
                  <FormHelperText className="error-o">{this.state.emailIdError}</FormHelperText>
                </FormControl>
                <FormControl className="form-width-100  ">
                  <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>BUSINESS NAME</InputLabel>
                  <Input id="component-simple"
                    value={this.state.businessName}
                    onChange={this.onChangeOfBusinessName}
                  />
                  <FormHelperText className="error-o">{this.state.businessNameErrorText}</FormHelperText>
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item={12} sm={6} md={6} xs={12}>
                    <FormControl className="form-width-100 ">
                      <InputLabel htmlFor="component-simple"style={{color:'#424242'}}>COUNTRY CODE</InputLabel>
                      <Input id="component-simple"
                        value={this.state.countryCode}
                        onChange={this.onChangeOfCountryCode}
                        inputProps={{ maxLength: 4 }}
                        // startAdornment={<InputAdornment position="start">+</InputAdornment>}
                      />
                      <FormHelperText className="error-o">{this.state.countryCodeError}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item={12} sm={6} md={6} xs={12}>
                    <FormControl className="form-width-100 ">
                      <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>MOBILE NUMBER</InputLabel>
                      <Input id="component-simple"
                        value={this.state.phoneNumber}
                        onChange={this.onChangeOfPhoneNumber}
                        inputProps={{ maxLength: 10 }}
                      />
                      <FormHelperText className="error-o">{this.state.phoneNumberError}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl className="form-width-100  ">
                  <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>PASSWORD</InputLabel>
                  <Input id="component-simple"
                    type={this.state.type}
                    endAdornment={<InputAdornment position="end">
                      <span onClick={this.showHide} style={{ color: '#CEA05F' }}>{this.state.type === 'input' ? <VisibilityIcon style={{ cursor: 'pointer' }} /> : <VisibilityOffIcon style={{ cursor: 'pointer' }} />}</span></InputAdornment>}
                    onChange={this.onChangeOfPassword}
                    value={this.state.password}
                  />

                  <FormHelperText className="error-o">{this.state.passwordError}</FormHelperText>
                </FormControl>


                <FormControl className="form-width-100  ">
                  <InputLabel htmlFor="component-simple" style={{color:'#424242'}}>CONFIRM PASSWORD</InputLabel>
                  <Input id="component-simple"
                    type={this.state.type}

                    onChange={this.onChangeOfConfirmPassword}
                    value={this.state.confirmPassword}
                  />

                  <FormHelperText className="error-o">{this.state.confirmPasswordError}</FormHelperText>
                </FormControl>


                <div>

                  <FormControlLabel style={{ marginRight: '5px', marginBottom:'2px' ,color:'#424242'}}
                
                    label="Agree with"

                    control={
                      <Checkbox
                      Style={{color:'black'}}
                        name="checkedB"
                        color="primary"
                        onChange={this.onChangeOfTerms}
                        value={this.state.terms}
                        checked={this.state.terms}
                      />
                    }
                  />
                  <label style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '17px' }}
                    className="color-CEA05F"
                    label="Terms & Condition"
                    
                  ><a href="/terms-conditions" target="_blank" className="color-CEA05F">Terms & Condition</a></label>
                  <FormHelperText className="error-o">{this.state.termsError}</FormHelperText>
                  {/* <FormHelperText className="error-o">{this.state.checkboxError}</FormHelperText> */}

                </div>

                <Button type="submit" className="SubmitColor space-marign-20" style={{ margin: '10px 10px 10px 20px' }} fullWidth={true}>Next</Button>

              </Grid>
              
            </Grid>
          </form>
          {/* <div className="text-center1 padding1">
            <Typography variant="small" gutterBottom align="center" className="color-p text-center1 color-767676">
              Already have an account?&nbsp;
                                <Link href="/login" className="notVendorLink">
                                      Login
                                </Link>
            </Typography>
          </div> */}
  
 
         
       
      
          {/* <div>
            <Container maxWidth="lg" >
              <Typography variant="h6" align="center">or</Typography>
              <Typography variant="h6" align="center" className="space-1">With your social network</Typography>
              <Grid container spacing={2} style={{textAlign:'center',display: 'block',margin: '4px 0px'}}>
                 <div  style={{display:'inline',width:'65px'}}>
                    
                      <SocialButton style={{border:'0'}}
                        provider='google'
                        appId='549487280719-31o7b86uepl6ittate90eaa7uqb6h7i6'
                       // appId='865862680774-o5ki3dhntl5el220lf749k4a2tc69hjl'
                        onLoginSuccess={this.handleSocialLogin}
                        onLoginFailure={this.handleSocialLoginFailure}
                      >
                        <img src={google} width="40px" />
                      </SocialButton>
                  
                </div>
                   <div style={{display:'inline',width:'65px'}}>
                   
                      <SocialButton style={{border:'0',marginLeft:'20px'}}
                         provider='facebook'
                         appId='1049257275603123'
                         onLoginSuccess={this.handleSocialLogin}
                         onLoginFailure={this.handleSocialLoginFailure}
                      >
                          <img src={facebook} width="40px" />
                      </SocialButton>
                    
                   </div> */}
                  {/* <Grid item xs={4} sm={4} md={4}>
                  </Grid>
                  <Grid item xs={4} sm={4} md={1}></Grid> */}
                {/* </Grid>
            </Container>
          </div> */}
          
          <div className="space-3"></div>
          </Paper>
        </Container>
        <ReactivateAccountModal open={this.state.open} handleClose={this.handleClose} deletedDate={this.state.deletedDate} data={{...this.state}} />
       
      </div>
    );
  }
  render() {
    return (
        this.getContentView()
    //   this.props.type == 'popup' ? this.getContentView() :
    //     <ContentWrapper
    //       contentView={this.getContentView()}
    //     />

    )
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signUpRequested: (payload) => dispatch({ type: "SIGNUP_REQUESTED", payload }),
    signUpSuccess: (payload) => dispatch({ type: "SIGNUP_SUCCESS", payload }),
    signUpFailed: (payload) => dispatch({ type: "SIGNUP_FAILED", payload }),
    loginRequested: (payload) => dispatch({ type: "LOGIN_REQUESTED", payload }),
    loginSuccess: (payload) => dispatch({ type: "LOGIN_SUCCESS", payload }),
    loginFailed: (payload) => dispatch({ type: "LOGIN_FAILED", payload }),
    setUserDeatils: (payload) => dispatch({ type: "GET_USER_DETAILS_DATA_SUCCESS", payload }),
    recoverPassword,
    addUserData,
    sociallogin:(payload) => dispatch({ type: "SIGNUP_SUCCESS", payload }),
  },
    dispatch)
}


export default connect(null, mapDispatchToProps)(RegisterSignUp);
