import React, { Component } from 'react';
import { Container, Divider, Grid, Typography, Button} from '@material-ui/core';
import ContentWrapper from '../../../../common/components/contentWrapper';
import RightIcon from '../../../../assests/images/Group 911.svg';
import PriceList from '../../../../assests/images/Group 1863.png';
import EbayIcon from '../../../../assests/images/ebay.png';
import ChronoIcon from '../../../../assests/images/chrono.png';
import FbIcon from '../../../../assests/images/fb.png';
import Insta from '../../../../assests/images/insta.png';
import '../style/success.css'


class AddWatchSuccessFullComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    

    getContentView() {
        return (
            <div className="bg-white">
                <div className="space-3">
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3}></Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="space-top-30">
                                <div className="text-center1">
                                    <img src={RightIcon} alt="right-Icon"/>
                                </div>
                                <div className="text-center1 space-top-20 space-bottom-20">
                                    <Typography variant="h5" className="font-weight-500 font-size-24">Your watch has been added into our database, We will confirm your submission soon</Typography>
                                </div>
                                
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}></Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}></Grid>
                        <Grid item xs={12} sm={4} md={4}>
                        <div className="space-3">
                            <Button className="bg-card-signin-subscribe-color text-center1 form-width-100" href="/search">SEARCH WATCHES</Button>
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}></Grid>
                    </Grid>
                    
                </Container>
            </div>
            </div>
         );
    }

    render() { 
        localStorage.setItem("menuTitle","CONTRIBUTE NEW WATCH");
        return ( 
            <ContentWrapper
            contentView={this.getContentView()}
            />
         );
    }

}
 
export default AddWatchSuccessFullComponent;