import React, { Component } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import img1 from '../../../../assests/images/Group 1602.png';
import img2 from '../../../../assests/images/Group 1603.png';
import img3 from '../../../../assests/images/Group 1398.png';
import img4 from '../../../../assests/images/Group 1399.png';
import img5 from '../../../../assests/images/Group 1604.png';
import downloadourappimage1 from '../../../../assests/images/home/downloadourappimage1.png';
import downloadourappimage2 from '../../../../assests/images/home/downloadourappimage2.png';
class DownloadNowComponent extends Component {
    render() {
        return (
            <div>
                <div style={{background:'#CEA05F'}}>
                <div className="padding-1"></div>
                <div className="padding-1"></div>
                    <Container maxWidth="md">
                        <div className="pb-1"></div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3} md={3}>
                                <div>
                                    <img src={downloadourappimage2} alt="impresariDownload" className="img-responsive"/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <div>
                                    <img src={downloadourappimage1} alt="impresariDownload2" className="img-responsive"/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Typography variant="h3" align="center" className="text-white"><b>DOWNLOAD</b></Typography>
                                <div className="pb-1"></div>
                                <Typography variant="h3" align="center" className="font-weight-100 text-white">OUR APP</Typography>
                                <div className="pb-1"></div>
                                <div className="text-center1">
                                    <Typography variant="p" align="center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Typography>
                                </div>
                                <div className="pb-1"></div>
                                <div className="pb-1"></div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <div className="text-center1">
                                          <a href="https://play.google.com/store"> <img src={img3} alt="impresariDownload3" width="120px"/></a> 
                                        </div>
                                            <div className="pb-1"></div>
                                        <div className="text-center1">
                                         <a href="https://www.apple.com/in/app-store/">   <img src={img4} alt="impresariDownload4" width="120px"/></a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                    <   div className="text-center1">
                                            <img src={img5} alt="impresariDownload5" width="80px" height="91px"/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                <div className="padding-1"></div>
                <div className="padding-1"></div>
                </div>
            </div>
        )
    }
}
export default DownloadNowComponent;