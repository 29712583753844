import { Divider } from '@material-ui/core';
import React, { Component } from 'react';
import ContentWrapper from '../../../../common/components/contentWrapper';
import WhoWeAreBannerComponent from './whoWeAreBannerComponent';
import WhoWeAreGreatReasonsComponent from './whoWeareGreatReasonsComponent';
import WhoWeAreLogoSliderComponent from './WhoWeAreSliderComponent';


class WhoAreMainPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    getContentView() {
        return (
            <div>
                <div>
                    <WhoWeAreBannerComponent/>
                </div>
                <div  className="space-3"></div>
                <Divider/>
                <div>
                    <WhoWeAreGreatReasonsComponent/>
                    <WhoWeAreLogoSliderComponent/>
                </div>
            </div>
         );
    }

    render() { 
      return ( 
            <ContentWrapper
            contentView={this.getContentView()}
            />
         );
    }
}
 
export default WhoAreMainPageComponent;