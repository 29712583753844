import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Paper, Typography, Grid, Button, InputLabel, Input, FormControl, 
         TextField, FormHelperText, Link 
       } from '@material-ui/core';
import validator from 'validator';
import '../../../../common/style/main.css';
import { addUserData, login } from "./../../reducer/actions";
import { toast } from "react-toastify";

class gfComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: '',
      emailId: '',
      phoneNumber: '',
      phoneErrorText: '',
      countryCodeError: '',
      phonecountryErrorText: '',
      isValid: false,
    }
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.onChangeOfCountryCode = this.onChangeOfCountryCode.bind(this);
  }
  onChangeOfCountryCode(event) {
    let countryCodeVal = event.target.value.length;

    console.log(countryCodeVal);
    if (countryCodeVal > 4) {
      this.setState({
        countryCodeError: "Enter minimum 3 characters for country code",
        isValid: false
      })
    }
    else {
      this.setState({
        countryCodeError: "",
        countryCode: event.target.value,
        isValid: true
      })
    }
  }
  onPhoneNumberChange(event) {
    let phoneVal = validator.isMobilePhone(event.target.value);
    if (!phoneVal) {
      this.setState({
        ...this.state,
        phoneErrorText: "Enter Valid Phone Number",
        isValid: false
      });
    } else {
      this.setState({
        ...this.state,
        phoneNumber: event.target.value,
        phoneErrorText: "",
        isValid: true
      });
    }
  }

  validateInput() {
    console.log(this.state);
    let phonecountryErrorText = '';
    let isValid = true;
    if (this.state.countryCode.length === 0 || this.state.phoneNumber.length === 0) {
      phonecountryErrorText = 'Enter Correct Country Code Or Phone Number and country code';
      isValid = false;
    }
    this.setState({
      ...this.state,
      phonecountryErrorText,
    });
    return isValid;
  }

  submitForm(event) {
    let data = {};
    let loginData = {};
    if (this.validateInput()) {
      data = {
        first_name: this.props.userDetailsInfo && this.props.userDetailsInfo.first_name,
        last_name: this.props.userDetailsInfo && this.props.userDetailsInfo.last_name,
        email: this.props.userDetailsInfo && this.props.userDetailsInfo.email,
        phone: this.state.phoneNumber,
        country_code: this.state.countryCode,
        source: this.props.userDetailsInfo && this.props.userDetailsInfo.source,
        source_id: this.props.userDetailsInfo && this.props.userDetailsInfo.source_id,
        photo_url: this.props.userDetailsInfo && this.props.userDetailsInfo.photo_url,
      };
      loginData = {
        email: this.props.userDetailsInfo && this.props.userDetailsInfo.email,
        source: this.props.userDetailsInfo && this.props.userDetailsInfo.source,
        source_id: this.props.userDetailsInfo && this.props.userDetailsInfo.source_id,
      };
      const { loginRequested, loginSuccess, loginFailed, history } = this.props;
      const { signUpRequested, signUpSuccess, signUpFailed } = this.props;
      signUpRequested(null);
      addUserData(
        data,
        (error, res) => {
          if (res.success === 1) {
            signUpSuccess(res);
            loginRequested(null);
            login(
              loginData,
              (res) => {
                if (res.success === 1) {
                  toast("User Logged in successfully", {
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  loginSuccess(res);
                  history.push("/dealersubscribtions");
                } else {
                  loginFailed(res);
                  history.push("/login");
                }
              },
              (error) => {
                loginFailed(error);
                toast("Somthing went wrong", {
                  autoClose: 5000,
                  type: toast.TYPE.ERROR,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
            );
          }
          else {
            signUpFailed(res);
            toast(res.message, {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
            history.push("/login");
          }
        },
        (error) => {
          loginFailed(error);
          toast("Something went wrong. Kindly try to relogin.", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      );
    }
  }




  getContentView() {
    return (
      <div>
        <Container maxWidth="sm" className="space-3">
          <Paper className="border-radius">
            <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='recovery_password' >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item={12} sm={6} md={12} xs={12}>
                      <FormControl className="registerSpace form-width-100">
                        <InputLabel id="demo-simple-select-outlined-label" className="color-BCBCBC">COUNTRY CODE</InputLabel>
                        <Input id="component-simple"
                          onChange={this.onChangeOfCountryCode}
                        />

                        <FormHelperText className="error-o">{this.state.countryCodeError}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item={12} sm={6} md={12} xs={12}>
                      <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10"  >
                        <InputLabel htmlFor="component-simple" className="color-BCBCBC">PHONE NUMBER</InputLabel>
                        <Input id="component-simple"
                          onChange={this.onPhoneNumberChange}
                          defaultValue={this.state.phoneNumber}
                        />
                        <FormHelperText className="error-o" >{this.state.phoneErrorText}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormHelperText className="error-o">{this.state.phonecountryErrorText}</FormHelperText>

                </Grid>
                <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} >Submit</Button>
              </Grid>
            </form>
            <div className="space-1"></div>
          </Paper>
        </Container>
      </div>
    );
  }
  render() {
    return (<ContentWrapper
      contentView={this.getContentView()}
    />
    )
  }
}

function mapStateToProps({ auth }) {
  return {
    userDetailsInfo: auth.userDetailsInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signUpRequested: (payload) => dispatch({ type: "SIGNUP_REQUESTED", payload }),
    signUpSuccess: (payload) => dispatch({ type: "SIGNUP_SUCCESS", payload }),
    signUpFailed: (payload) => dispatch({ type: "SIGNUP_FAILED", payload }),
    loginRequested: (payload) => dispatch({ type: "LOGIN_REQUESTED", payload }),
    loginSuccess: (payload) => dispatch({ type: "LOGIN_SUCCESS", payload }),
    loginFailed: (payload) => dispatch({ type: "LOGIN_FAILED", payload }),

  };
}


export default connect(mapStateToProps, mapDispatchToProps)(gfComponent);





