import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid, Typography, Card, FormControlLabel, Checkbox, Button,FormGroup, FormHelperText, FormControl, InputLabel, Popover, Input, TextField} from '@material-ui/core';
import AddIcon from '../../../../assests/images/add.png';
import XLS from '../../../../assests/images/xls.png';
import AccessRestricted from '../../../../assests/images/AccessRestricted.png';
import '../style/style.css';
import { toast } from "react-toastify";
import { updateProductDetails, brandList } from '../actions/dashboardAction';
import RightIcon from '../../../../assests/images/Group 911.svg';
import { SpinnerRoundFilled } from 'spinners-react';
import constants from '../../../../utils/constants';
import MaterialTable from 'material-table';
import {Link} from '@material-ui/core';
import PubNub from 'pubnub';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { InfoIconWithTooltip } from 'icon-with-tooltip';
import Header from '../../../../common/components/Header';
import FooterComponent from '../../../../common/components/Footer';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from '@material-ui/lab';
const { innerWidth: width, innerHeight: height } = window;

const steps = [
    {
      status: "Validating the File",
      step: 1,
      info: "Checking the header, file extension and data types of each fields"
    },
    {
      status: "Processing Data",
      step: 2,
      info: "Collect the Valid data and convert the data into processing format"
    },
    {
      status: "Loading Data",
      step: 3,
      info: "Creating New Watches into Inventory"
    },
    {
      status: "Checking Completeness",
      step: 4,
      info: "Inventory Created with Valid Data and Error / Failure File Prepared"
    }
  ];
  
function ProductDetailsComponent(props) {
    const [bulkUploadFile, setBulkUploadFile] = useState("")
    const [bulkUploadCheckBox, setBulkUploadCheckBox] = useState(false)
    const [errorBulkUploadCheckBox, setErrorBulkUploadCheckBox] = useState("")
    const [errorModel, setErrorModel] = useState(null);
    const [loading, setLoading] = useState(false)
    const [sampleData, setSampleData] = useState([])
    const [successFeedback, setSuccessFeedback] = useState(false)
    const [productxlid, setProductxlid] = useState(null)
    const [statusMessage, setStatusMessage] = useState("Processing the File")
    const [transfer, setTransfer] = useState({step:0})
    const [position, setPosition] = useState(0.1);
    const [brandModel, setBrandModel] = useState(null);
    const [brandModelList, setBrandModelList] = useState(props?.brandModelList);
    const [noData, setNoData] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorTarget, setAnchorTarget] = useState(null);
    const [userData, setUserData] = useState(null);
    const open = Boolean(anchorEl);

    const [selectedModel, setSelectedModel] = useState(null);

    const pubnub = useMemo(() =>{
        console.log("Pubnub")
        return new PubNub({ 
        publishKey: constants.PUBNUB_PUBLISHKEY,
        subscribeKey: constants.PUBNUB_SUBSCRIBEKEY,
        uuid: constants.PUBNUB_UUID,
        autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
        restore: true, 
        })
    },[]);

    useEffect(()=>{
        const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
        if(userDetails){
            setUserData(userDetails);
        }

    },[])

    const getStepPosition = (transferStep) => {
        let index = steps.findIndex(({ step }) => step === transferStep) + 1;
        return  index / steps.length
      };
    
    const validateInput = () =>{
        let errorBulkUploadCheckBox = '';
        let errorModelTxt = '';
        let isValid = true;

        if (bulkUploadCheckBox === false) {
            errorBulkUploadCheckBox = 'Please Check It';
            isValid = false;
        }
        if (!selectedModel?.brandname) {
            errorModelTxt = 'Please Select Model';
            isValid = false;
        }
        setErrorBulkUploadCheckBox(errorBulkUploadCheckBox);
        setErrorModel(errorModelTxt);
        return isValid;
    };

    const bulkUploadFileFun = ({  target }) => {
        const imageFile = target.files[0];
        setBulkUploadFile(imageFile);
    }

    const onChangeOfCheckBox = (event) =>{
        setBulkUploadCheckBox(event.target.checked)
        if(event.target.checked === true){
            setErrorBulkUploadCheckBox('')
        }else{
            setErrorBulkUploadCheckBox('Please Check It')
        }
    }
   
    const handleMessage = (event) => {
        console.log('handle messgae', event)
        if(event && event.channel && event.channel === `${productxlid}_status` ){
            setStatusMessage(event.message.data);
            if(event.message.step > transfer.step){
                setTransfer({step: event.message.step})
                const position = getStepPosition(event.message.step);
                setPosition(position)
            }
            
        }
        if(event && event.channel && event.channel === `${productxlid}_data` ){
            setTransfer({step: event.message.step})
            const position = getStepPosition(event.message.step);
                setPosition(position)
            if(event.message && event.message.productxlid){
                const data = event.message;
                let successFeedback = data && data.data.filter(function (el) {
                    return el.status === 'Success' && el.watchCount > 0;
                });
                setSuccessFeedback(successFeedback.length > 0)
                setLoading(false);
                setSampleData(data.data);
            }
        }
      };
     
    useEffect(() => {
        
      pubnub.addListener({ message: handleMessage });
      pubnub.subscribe({ channels: [`${productxlid}_status`, `${productxlid}_data`]});
      return () => {
        pubnub.removeListener(handleMessage);
        pubnub.unsubscribe({
          channels: [`${productxlid}_status`, `${productxlid}_data`],
        });
      };
     
    }, [productxlid,pubnub]);

    
    // const onChangeModel = (event)=>{
    //     setNoData(false);
    //     setBrandModel(event.target.value);
    //     setAnchorTarget(event.currentTarget);
    //     if(event.target.value){
    //         setErrorModel('');
    //     }else{
    //         setErrorModel('Please Select Model');
    //     }
    // }

    useEffect(()=>{ 
        props.brandList();
    },[]);

    useEffect(()=>{
        console.log(props.brandModelList);
        if (props?.brandModelList && props?.brandModelList?.length) {
            setAnchorEl(anchorTarget);
        } else {
            if (brandModel) {
                setNoData(true)
            }
        }
        const modelList = props?.brandModelList?.data.map(v => ({...v, label: v.brandname}));
        console.log(modelList)
        setBrandModelList(modelList);
    },[props?.brandModelList]);

    const clearSearchInput =()=>{
        setBrandModel(null);        
        setSelectedModel({brandname:"",brandid:""});
        setBrandModelList([]);
        setNoData(false);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSubmit = () => {
        if (validateInput()) {
            let fd = new FormData();
            fd.set('product_update_xl', bulkUploadFile);
            fd.set('brandid',selectedModel?.brandid);
            fd.set('brandname',selectedModel?.brandname);

            props.updateProductDetails(
                fd,
                (success) => {
                  toast(success.message, {
                    autoClose: 5000,
                    type: success.success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  setTransfer({step: 1})
                  const position = getStepPosition(1);
                setPosition(position)
                  setLoading(true);
                  if(success.data === null){
                    setLoading(false);
                  }
                  if(success.data){
                    setProductxlid( success.data?.productxlid)
                  }
                },
                (error) => {
                  toast("Product Update Failed", {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                }
            );
        }
    }
    const getContentView = () => {
        return(
            <div style={{backgroundColor:'white', minHeight: (height-114)+'px'}}>
            <div className="">
                <div>
                    {loading === false && sampleData && sampleData.length === 0 && (
                        <div>
                        <Container>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" className="color-575757 text-center1 space-bottom-10"><b>Download the excel sample file by clicking on the xls icon below before you bulk upload your products</b></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div style={{width:'45px',height:'71px',textAlign:'center',margin:"0 auto"}}>
                                        <Card>
                                            <a href="https://impresari-images.s3.amazonaws.com/product_sample_file.xlsx">
                                            <img src={XLS} alt="XLS" style={{width:'45px',height:'71px',textAlign:'center',margin:"0 auto"}} />
                                            </a>
                                        </Card>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container maxWidth="md">
                            <Grid item xs={12} sm={12}>
                                <Card className="space-marign-20">
                                    <div style={{padding:'10px 10px 10px 20px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <div className="space-top-20">
                                                    <input className="input-upload" id="icon-button-file9" type="file" onChange={bulkUploadFileFun}/>
                                                        <div class="margin-120">
                                                            <label htmlFor="icon-button-file9">
                                                                <Card className="Primary-Border">
                                                                    <div className="space-3 primary-border bg-color-F5F5F5">
                                                                        <div className="space-5 primary-border-dashed text-center1" style={{width:"100%"}}>
                                                                            {(bulkUploadFile)? 
                                                                            <div className="text-center1">
                                                                                Selected File :{" "}
                                                                                {bulkUploadFile && bulkUploadFile.name}
                                                                            </div>
                                                                            :
                                                                            <div className="text-center1">
                                                                                <img src={AddIcon} alt="Addicon" style={{cursor:'pointer'}}/>
                                                                                <Typography variant="body2">Upload Bulk XLS/CSV file</Typography>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </label>
                                                        </div>
                                                        
                                                            <div className="form-width-50" style={{margin:"20px 120px 10px"}}>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="brand"
                                                                    options={brandModelList}
                                                                    getOptionLabel={(option) => (option ? option.brandname : "")}
                                                                    sx={{ width: 300 }}
                                                                    onChange={(event,newValue)=>{
                                                                        setSelectedModel(newValue);
                                                                        setErrorModel(null);
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} label="Brand" InputLabelProps={{ style: {color: "rgb(66, 66, 66)"}}} />}
                                                                />
                                                                {/* { brandModel || selectedModel?.modelname ? <InputLabel htmlFor="model" shrink={true} style={{ color: "#424242" }}>Model</InputLabel> : 
                                                                <InputLabel htmlFor="model" shrink={false} style={{ color: "#424242" }}>Model</InputLabel> }
                                                                <Input id="model" inputProps={{ type: 'text'}} autoComplete={"off"} value={brandModel || selectedModel?.modelname} onChange={onChangeModel} />
                                                                <div className="searchIcon">
                                                                    { !brandModel && !selectedModel?.modelname ? (
                                                                        <SearchIcon />
                                                                    ) : (
                                                                        <CloseIcon id="clearBtn" onClick={clearSearchInput} />
                                                                    )}
                                                                </div>
                                                                { brandModelList && brandModelList.length > 0 && (
                                                                    <Popover 
                                                                        id="bran-model"
                                                                        open={open}
                                                                        anchorEl={anchorEl}
                                                                        onClose={handleClose}
                                                                        anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                        }}
                                                                        PaperProps={{
                                                                            style: { height: '200px',overflow:'auto' },
                                                                        }}
                                                                    >
                                                                        {brandModelList?.slice(0, 15).map((item) => {
                                                                            return (
                                                                            <Typography style={{ padding: 10, width: 300 }} onClick={()=> {
                                                                                setBrandModel(null);
                                                                                setSelectedModel(item);
                                                                                setBrandModelList([]);
                                                                                setAnchorEl(null);
                                                                            }}>{item['modelname']}</Typography>
                                                                            );
                                                                        })} 
                                                                    </Popover>
                                                                )}
                                                                { noData && (
                                                                    <div className="dataResult">  
                                                                        <a className="dataItem">
                                                                            No data found
                                                                        </a>
                                                                    </div>
                                                                )} */}
                                                        </div>                                                    
                                                        { errorModel && ( 
                                                            <FormHelperText style={{color:"red",textIndent:"120px"}}>
                                                                {errorModel}
                                                            </FormHelperText>
                                                        )}
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                style={{margin:'10px 110px'}}
                                                                className="margin-110"
                                                                control={<Checkbox
                                                                    name="checkedA"
                                                                    onChange={onChangeOfCheckBox}
                                                                    />
                                                                }
                                                                label="Check the box to confirm you have authority to upload these data."
                                                            />
                                                        </FormGroup>
                                                        <FormHelperText style={{color:"red",textIndent:"120px"}}>
                                                            {errorBulkUploadCheckBox}
                                                        </FormHelperText>
                                                        <Container maxWidth="xs">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Button className="font-size-12 bg-card-signin-subscribe-color form-width-50 float-right" onClick={onSubmit}>Upload</Button>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Button className="font-size-12 Button-outline-border form-width-50">Cancel</Button>
                                                                </Grid>
                                                            </Grid> 
                                                        </Container>
                                                </div>
                                            </Grid>
    
                                        </Grid>
                                    </div>
                                </Card>
                            </Grid>
                        </Container>
                        </div>
                    )}
            
                {loading === true &&
                (<div>
                    <div id="ProgressWrapper" style={{ margin: 'auto', marginTop: '50px', width: '1000px'}}>
                        <ProgressBar
                        width={830}
                        percent={100 * (position)}
                        filledBackground="linear-gradient(to right, rgba(57, 107, 172, 1), rgba(57, 107, 172, 1))"
                        >
                        {steps.map((step, index, arr) => {
                            return (
                            <Step
                                position={100 * (index / arr.length)}
                                transition="scale"
                                style={{left: '6% !important'}}
                                children={({ accomplished }) => (
                                <div>
                                    <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "50%",
                                        width: 20,
                                        height: 20,
                                        color: "white",
                                        marginTop: 45,
                                        marginBottom: 20,
                                        marginLeft: index  === steps.length-1 ? 45 : 57,
                                        backgroundColor: accomplished ? "rgba(57, 107, 172, 1)" : "gray"
                                    }}
                                    >
                                    {index + 1}
                                    </div>
                                    <div className="steps-text" style={{ display: 'flex',alignItems: 'center'}}>
                <b>{steps[index].status}</b>
                                    <InfoIconWithTooltip text={steps[index].info} placement="right" />
                </div>
                                </div>
                                )}
                            />
                            );
                        })}
                        </ProgressBar>
                    </div>
                    <div class="d-spinner">
                        <SpinnerRoundFilled size={51} thickness={125} speed={80}  color="rgba(57, 107, 172, 1)"/> 
                    </div>
                </div>)}
                {sampleData && sampleData.length > 0 &&
                <div>
                    <Container>
                    <MaterialTable
                        title={`Bulk Upload Summary for the Id: ${productxlid}`}
                        data={sampleData}
                        columns={[
                            { field: "status",title: 'STATUS' },
                            { field: "watchCount" , title: 'WATCH COUNT' },
                            { field: "filePath",title: 'DOWNLOAD FILE',  render: rowData => <Link href={`${rowData.filePath}`} target="_blank">{rowData.filePath}</Link> }
                        ]} />
                    </Container>
                    <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3}></Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            {successFeedback && (
                            <div className="space-top-30">
                                <div className="text-center1">
                                    <img src={RightIcon} alt="right-Icon"/>
                                </div>
                                <div className="text-center1 space-top-20 space-bottom-20">
                                    <Typography variant="h5" className="font-weight-500 font-size-24">
                                        Your Product details has been updated into our database, 
                                        </Typography>
                                </div>
                            </div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}></Grid>
                    </Grid>
                </Container>
                                </div>
    }
                </div>
            </div>
            </div>
        )
    }
    const accessRestricted = () => {
        return(
            <div>
                <div style={{textAlign: 'center'}}>
                        <Typography variant='h4'>Access Denied</Typography>
                        <br></br>
                        <Typography variant='h6'>you don't have permission to access request page</Typography>
                    </div>
                <div>
                    <img src={AccessRestricted} alt="access restricted" style={{  display: 'block',marginLeft: 'auto',marginRight: 'auto', width: '45%'}}/>
                </div>
            </div>
           
        )
    }

    return ( 
        
        <div style={{backgroundColor:'white'}}>
        <Header/>
        <div style={{backgroundColor:'white', minHeight: (height-150)+'px'}}>
        {(userData && userData.roleId && userData.roleId !== 3 ) ? accessRestricted() : getContentView()}
        </div>
        <FooterComponent/>
    </div>
        );
}

function mapStateToProps({ dealer, brandInfo }) {
    return {
        BulkUploadInfo:dealer.BulkUploadInfo,
        brandModelList : brandInfo.brandInfo
    }
  }

  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateProductDetails,
        brandList
    },
      dispatch)
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsComponent);