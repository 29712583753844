import React, { Component } from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid, Typography, TextField, Button, Input, FormHelperText, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import phone from '../../../../assests/images/phone.png';
import map from '../../../../assests/images/map.png';
import text from '../../../../assests/images/text.png';
import { toast } from "react-toastify";
import { countriesList } from '../../signup/actions/signUpActionsComponent';


class ContactUsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode:'',
      Name: '',
      BusinessName: '',
      phoneNumber: '',
      BusinessStreetArea: '',
      Email: '',
      Country: '',
      City: '',
      State: '',
      ZipCode: '',
      Message: '',
      terms: false,
      onNameErrorText: "",
      onBusinessNameErrorText: '',
      phoneNumberError: '',
      onBusinessStreetAreaErrorText: '',
      onCountryErrorText: '',
     countryCodeError:'',
      onCityErrorText: '',
      onStateErrorText: '',
      onZipCodeErrorText: '',
      onMessageErrorText: '',
      termsError: '',
    }
    this.onChangeOfName = this.onChangeOfName.bind(this);
    this.onChangeOfBusinessName = this.onChangeOfBusinessName.bind(this);
    this.onChangeOfPhoneNumber = this.onChangeOfPhoneNumber.bind(this);
    this.onChangeOfEmail = this.onChangeOfEmail.bind(this);
    this.onChangeOfBusinessStreetArea = this.onChangeOfBusinessStreetArea.bind(this);
    this.onChangeOfCountry = this.onChangeOfCountry.bind(this);
    this.onChangeOfCity = this.onChangeOfCity.bind(this);
    this.onChangeOfState = this.onChangeOfState.bind(this);
    this.onChangeOfZipCode = this.onChangeOfZipCode.bind(this);
    this.onChangeOfMessage = this.onChangeOfMessage.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.onChangeOfTerms = this.onChangeOfTerms.bind(this);
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    this.props.countriesList();
  }

  onChangeOfName(event) {
    let fullNameVal = validator.isEmpty(event.target.value);
    if (fullNameVal) {
      this.setState({
        onNameErrorText: "Enter correct Full Name",
        isValid: false
      })
    }
    else {
      this.setState({
        onNameErrorText: "",
        Name: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfEmail(event) {
    let EmailVal = validator.isEmail(event.target.value);
    if (!EmailVal) {
      this.setState({
        onEmailErrorText: "Enter proper Email",
        isValid: false
      })
    }
    else {
      this.setState({
        onEmailErrorText: "",
        Email: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfBusinessName(event) {
    let BusinessNameVal = validator.isEmpty(event.target.value);
    if (BusinessNameVal) {
      this.setState({
        onBusinessNameErrorText: "Enter correct Business Name",
        isValid: false
      })
    }
    else {
      this.setState({
        onBusinessNameErrorText: "",
        BusinessName: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfCountryCode = (event) => {
    const regex = "^(?:[+\d].*\d|\d)$";
    var sign = "+";
    let countryCodeVal =  event.target.value;
    console.log("countryCodeVal",countryCodeVal);
    let countryCode = countryCodeVal.match(regex); 
   if (event.target.value >=1 && event.target.value <= 4) {
  // let countryCodeVal = validator.isNumeric(event.target.value);
    if ( !countryCode) {
      this.setState({
        ...this.state,
        countryCodeError: "Enter minimum 3 characters for country code",
        isValid: false
      })
    }
    else {
      this.setState({
        ...this.state,
        countryCodeError: "",
        countryCode: countryCodeVal,
        isValid: true
      })
    }
  }
    else {
      this.setState({
        ...this.state,
        countryCodeError: "",
        countryCode: this.state.sign + event.target.value,
        isValid: true
      })
    }
    
  }

  onChangeOfPhoneNumber(event) {
    const regex = "^\\d[0-9]$";
    let PhoneNumberVal = event.target.value;
    if(event.target.value <= 9){
    let phoneNumber = PhoneNumberVal.match(regex); 
    if (!phoneNumber) {
      this.setState({
        phoneNumberError: "Enter Valid Phone Number",
        isValid: false
      })
    }
    else {
      this.setState({
        phoneNumberError: "",
        phoneNumber: event.target.value,
        isValid: true
      })
    }
  }
    else {
      this.setState({
        phoneNumberError: "",
        phoneNumber: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfBusinessStreetArea(event) {
    let BusinessStreetAreaVal = event.target.value;
    if (BusinessStreetAreaVal === "") {
      this.setState({
        onBusinessStreetAreaErrorText: "Enter correct Business Street Area",
        isValid: false
      })
    }
    else {
      this.setState({
        onBusinessStreetAreaErrorText: "",
        BusinessStreetArea: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfCountry(event) {
    let CountryVal = event.target.value;
    if (!CountryVal) {
      this.setState({
        onCountryErrorText: "Enter correct Country",
        isValid: false
      })
    }
    else {
      this.setState({
        onCountryErrorText: "",
        Country: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfCity(event) {
    let CityVal = event.target.value;
    if (!CityVal) {
      this.setState({
        onCityErrorText: "Enter correct City",
        isValid: false
      })
    }
    else {
      this.setState({
        onCityErrorText: "",
        City: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfState(event) {
    let StateVal = event.target.value;
    if (!StateVal) {
      this.setState({
        onStateErrorText: "Enter correct State",
        isValid: false
      })
    }
    else {
      this.setState({
        onStateErrorText: "",
        State: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfZipCode(event) {
    if (event.target.value !== "") {
      let zipcodeVal = validator.isNumeric(event.target.value);
      if (!zipcodeVal) {
        this.setState({
          ...this.state,
          onZipCodeErrorText: "Enter correct Zip Code",
          isValid: false,
        })
      }
      else {
        this.setState({
          ...this.state,
          onZipCodeErrorText: "",
          ZipCode: event.target.value,
          isValid: true
        })
      }
    }else {
      this.setState({
              ...this.state,
              onZipCodeErrorText: "",
              ZipCode: event.target.value,
              isValid: true,
            })
          }
  
  }
  onChangeOfMessage(event) {
    let MessageVal = validator.isEmpty(event.target.value);
    if (MessageVal) {
      this.setState({
        onMessageErrorText: "Enter correct Message",
        isValid: false
      })
    }
    else {
      this.setState({
        onMessageErrorText: "",
        Message: event.target.value,
        isValid: true
      })
    }
  }
  onChangeOfTerms = (event) => {
    this.setState({
      terms: event.target.checked,
      isValid: true
    })

  }
  handleClick = () => {
    const { history } = this.props;
    history.push('/terms-conditions')
  }

  validateInput() {
    console.log('this.state', this.state.PhoneNumber);
    let onNameErrorText = '';
    let onEmailErrorText = '';
    let onBusinessNameErrorText = '';

    let onBusinessStreetAreaErrorText = '';
    let onCountryErrorText = '';
    let onCityErrorText = '';
    let onStateErrorText = '';
    let onZipCodeErrorText = '';
    let onMessageErrorText = '';
    let termsError = '';
    let countryCodeError = '';
    let phoneNumberError = '';

    let isValid = true;

    if (this.state.Name.length === 0) {
      onNameErrorText = 'Enter Correct Full Name';
      isValid = false;
    }

    if (this.state.Name.length === 0) {
      onEmailErrorText = 'Enter Email';
      isValid = false;
    }
    if (this.state.BusinessName.length === 0) {
      onBusinessNameErrorText = 'Enter Correct Business Name';
      isValid = false;
    }
   
    if (this.state.countryCode.length === 0 && this.state.countryCode.length <= 1) {
      countryCodeError = 'Enter minimum 4 characters for country code';
      isValid = false;
    }
    if (this.state.phoneNumber.length === 0 || this.state.phoneNumber.length === null || this.state.phoneNumber.length <= 9) {
      phoneNumberError = 'Enter Phone Number';
      isValid = false;
    }
    if (this.state.BusinessStreetArea.length === 0) {
      onBusinessStreetAreaErrorText = 'Enter Correct Business Street Area';
      isValid = false;
    }
    if (this.state.Country.length === 0) {
      onCountryErrorText = 'Enter Correct Country';
      isValid = false;
    }
    if (this.state.City.length === 0) {
      onCityErrorText = 'Enter Correct City';
      isValid = false;
    }
    if (this.state.State.length === 0) {
      onStateErrorText = 'Enter Correct State';
      isValid = false;
    }
    if (this.state.ZipCode.length < 5) {
      onZipCodeErrorText = 'Enter max 5 digits Numeric';
      isValid = false;
    }
    if (this.state.Message.length === 0) {
      onMessageErrorText = 'Enter Message';
      isValid = false;
    }
    if (this.state.terms === false) {
      termsError = 'Please accept terms & conditions';
      isValid = false;
    }

    this.setState({
      ...this.state,
      countryCodeError,
      onNameErrorText,
      onEmailErrorText,
      onBusinessNameErrorText,
      phoneNumberError,
      onBusinessStreetAreaErrorText,
      onCountryErrorText,
      onCityErrorText,
      onStateErrorText,
      onZipCodeErrorText,
      onMessageErrorText,
      termsError
    });
    return isValid;
  }

  submitForm(event) {
    if (this.validateInput()) {
      document.getElementById("contact_form").reset();
      this.props.history.push("/contactus");
      toast("Thanks for contacting us! We will be in touch with you shortly", {
        autoClose: 5000,
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.BOTTOM_CENTER,
      });
      this.setState({
        Name: '',
        BusinessName: '',
        PhoneNumber: '',
        BusinessStreetArea: '',
        Email: '',
        Country: "",
        City: '',
        State: '',
        ZipCode: '',
        Message: '',
        terms: true,
        countryCodeError:'',
        phoneNumberError:''

      })
    }
  }


  render() {
    var CountryList = this.props.CountryListInfo;
    return (
      <div>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={8}>
              <div>
                <Typography variant="h6" component="p" className="primary-text uppercase" >GET ADVICE ANY TIME</Typography>


              </div>
              <div className="space-2"></div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <div style={{ marginLeft: '10px' }}>
                    <Typography variant="p" component="p" className="font-weight-700 color-232323" style={{ paddingBottom: "10px" }}>NEW YORK</Typography>
                  </div>
                  <div className="space-1"></div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={2} md={2}>
                        <div>
                          <img src={phone} alt="phone-img" className="img-responsive" />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <div>
                          <Typography variant="p" component="p"><b>PHONE NUMBER</b></Typography>
                          <Typography variant="p" component="p">+1 (555) 555-1212</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="space-1"></div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={2} md={2}>
                        <div>
                          <img src={map} alt="phone-img" className="img-responsive" />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <div >
                          <Typography variant="p" component="p"><b>LOCATIONS</b></Typography>
                          <Typography variant="p" component="p">12345 North Main Street, <br />New York, NY 555555</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="space-1"></div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={2} md={2}>
                        <div>
                          <img src={text} alt="phone-img" className="img-responsive" />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <div>
                          <Typography variant="p" component="p"><b>Email</b></Typography>
                          <Typography variant="p" component="p">info@yourwebsite.com</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div style={{ marginLeft: '10px' }}>
                    <Typography variant="p" component="p" className="font-weight-700 color-232323" style={{ paddingBottom: "10px" }}>SAN DIEGO</Typography>
                  </div>
                  <div className="space-1"></div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={2} md={2}>
                        <div>
                          <img src={phone} alt="phone-img" className="img-responsive" />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <div>
                          <Typography variant="p" component="p"><b>PHONE NUMBER</b></Typography>
                          <Typography variant="p" component="p">+1 (800) 563-1515</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="space-1"></div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={2} md={2}>
                        <div>
                          <img src={map} alt="phone-img" className="img-responsive" />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <div>
                          <Typography variant="p" component="p"><b>LOCATIONS</b></Typography>
                          <Typography variant="p" component="p">12345 North Main Street, <br />New York, NY 555555</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="space-1"></div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={2} md={2}>
                        <div>
                          <img src={text} alt="phone-img" className="img-responsive" />
                        </div>
                      </Grid>
                      <Grid item xs={10} sm={10} md={10}>
                        <div>
                          <Typography variant="p" component="p"><b>Email</b></Typography>
                          <Typography variant="p" component="p">info@yourwebsite.com</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>

              <div>
                <Typography variant="h6" component="p" className="primary-text uppercase" >LET'S TALK ABOUT YOUR BUSINESS</Typography>

              </div>
              <div className="space-1"></div>
              <form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='contact_form' >
                <div>
                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{color:'black'}}>FULL NAME</InputLabel>
                    <Input id="component-simple"
                      onChange={this.onChangeOfName}
                    />
                    <FormHelperText className="error-o" >{this.state.onNameErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{color:'black'}}>BUSINESS NAME </InputLabel>
                    <Input id="component-simple"
                      onChange={this.onChangeOfBusinessName}
                    />
                    <FormHelperText className="error-o" >{this.state.onBusinessNameErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{color:'black'}}>EMAIL</InputLabel>
                    <Input id="component-simple"
                      onChange={this.onChangeOfEmail}
                    />
                    <FormHelperText className="error-o" >{this.state.onEmailErrorText}</FormHelperText>
                  </FormControl>

                  {/* <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" className="color-BCBCBC">PHONE NUMBER</InputLabel>
                    <Input id="component-simple"
                      onChange={this.onChangeOfPhoneNumber}
                    />
                    <FormHelperText className="error-o" >{this.state.onPhoneNumberNameErrorText}</FormHelperText>
                  </FormControl> */}
                <Grid container spacing={2}>
                  <Grid item={12} sm={6} md={6} xs={12}>
                    <FormControl className="form-width-100 ">
                      <InputLabel htmlFor="component-simple" style={{color:'black'}}>COUNTRY CODE</InputLabel>
                      <Input id="component-simple"
                        defaultValue={this.state.phoneNumber}
                        onChange={this.onChangeOfCountryCode}
                        inputProps={{ maxLength: 4 }}
                       
                      />
                       <FormHelperText className="error-o">{this.state.countryCodeError}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item={12} sm={6} md={6} xs={12}>
                    <FormControl className="form-width-100 ">
                      <InputLabel htmlFor="component-simple" style={{color:'black'}}>MOBILE NUMBER</InputLabel>
                      <Input id="component-simple"
                        defaultValue={this.state.phoneNumber}
                        onChange={this.onChangeOfPhoneNumber}
                      />
                      <FormHelperText className="error-o">{this.state.phoneNumberError}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{color:'black'}}>BUSINESS STREET ADDRESS</InputLabel>
                    <Input id="component-simple"
                      onChange={this.onChangeOfBusinessStreetArea}
                    />
                    <FormHelperText className="error-o" >{this.state.onBusinessStreetAreaErrorText}</FormHelperText>
                  </FormControl>


                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                    <InputLabel htmlFor="component-simple" style={{color:'black'}}>COUNTRY</InputLabel>
                    <Select

                      onChange={this.onChangeOfCountry}
                      label="Country"
                      value={this.state.Country}
                    >
                      <MenuItem value="">{ " " }</MenuItem>
                      {CountryList.map((Country) => (
                        <MenuItem value={Country.id}>{Country.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="error-o">{this.state.onCountryErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                    <InputLabel id="demo-simple-select-outlined-label" style={{color:'black'}}>STATE</InputLabel>
                    <Input id="component-simple"
                      onChange={this.onChangeOfState}
                    />
                    <FormHelperText className="error-o">{this.state.onStateErrorText}</FormHelperText>
                  </FormControl>
                  <Grid container spacing={2}>
                    <Grid item sm={6} md={6}>
                      <FormControl className="registerSpace form-width-100">
                        <InputLabel id="demo-simple-select-outlined-label" style={{color:'black'}}>CITY</InputLabel>
                        <Input id="component-simple"
                          onChange={this.onChangeOfCity}
                        />
                        <FormHelperText className="error-o">{this.state.onCityErrorText}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                        <InputLabel htmlFor="component-simple" style={{color:'black'}}>ZIP CODE</InputLabel>
                        <Input id="component-simple"
                          onChange={this.onChangeOfZipCode}
                          inputProps={{ maxLength: 5 }}
                        />
                        <FormHelperText className="error-o" >{this.state.onZipCodeErrorText}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{color:'black'}}>MESSAGE</InputLabel>
                    <Input id="component-simple"
                      multiline
                      rows={2}
                      onChange={this.onChangeOfMessage}
                    />
                    <FormHelperText className="error-o" >{this.state.onMessageErrorText}</FormHelperText>
                  </FormControl>

                  <div>
                    <FormControlLabel style={{ marginRight: '5px', marginBottom: '2px' }}
                     style={{color:'black'}}
                      label="Agree with"

                      control={
                        <Checkbox
                        style={{color:'black'}}
                          name="checkedB"
                          color="primary"
                          onChange={this.onChangeOfTerms}
                          defaultValue={this.state.terms}
                        />
                      }
                    />
                    <label style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '17px' }}
                      className="color-CEA05F"
                      label="Terms & Condition"
                      onClick={this.handleClick}
                    >Terms & Condition</label>
                    <FormHelperText className="error-o">{this.state.termsError}</FormHelperText>

                  </div>
                  <Button type="submit" size="large" textColor="white" className="primary-bg color-white text-center1 form-width-100" >Submit</Button>
                </div>
              </form>
            </Grid>

          </Grid>
        </Container>
      </div>
    );
  }
}

function mapStateToProps({ CountryListInfo }) {
  return {
    CountryListInfo: CountryListInfo.CountryListInfo.data,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    countriesList,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsFormComponent);