import React, { Component } from 'react';
import Slider from "react-slick";
import { Container, Paper, Card, Typography } from '@material-ui/core';
import imglogo1 from '../../../../assests/images/logo1.png';
import imglogo2 from '../../../../assests/images/logo2.png';
import imglogo3 from '../../../../assests/images/logo3.png';
import imglogo4 from '../../../../assests/images/logo4.png';
import imglogo5 from '../../../../assests/images/logo5.png';
import imglogo6 from '../../../../assests/images/logo6.png';
import imglogo7 from '../../../../assests/images/logo7.png';
import imglogo8 from '../../../../assests/images/logo8.png';
import imglogo9 from '../../../../assests/images/logo9.png';
import imglogo10 from '../../../../assests/images/logo10.png';
import imglogo11 from '../../../../assests/images/logo11.png';
import imglogo12 from '../../../../assests/images/logo12.png';
import imglogo13 from '../../../../assests/images/logo13.png';
import imglogo14 from '../../../../assests/images/logo14.png';

class WhoWeAreLogoSliderComponent extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
    
        }
      }
    
    render() {
        var settings = {
            arrows:true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 8,
            slidesToScroll: 2,
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        return (
            <div className="space-1">
                <div style={{background:"#fff"}} className="space-top-30 space-bottom-30"> 
                <div>
                    <div className="space-1">
                        <Typography variant="h6" className="text-center1">WHO WE REPRESENT</Typography>
                    </div>
                    <div className="space-2">
                        <Typography variant="h4" className="text-center1">LUXURY BRANDS AVAILABLE AT WATCHOPS</Typography>
                    </div>
                </div>
                <Container maxWidth="xl">
                    <Slider {...settings}>
                        <div>
                            <Card className="space-m-1" className="space-m-1">
                                <img src={imglogo1} alt="client-logo-1" className="img-responsive1 img-width"/>
                            </Card>
                        </div>
                        <div>
                            <Card className="space-m-1">
                            <img src={imglogo2} alt="client-logo-2"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo3} alt="client-logo-3"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo4} alt="client-logo-4"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo5} alt="client-logo-5"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo6} alt="client-logo-6"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo7} alt="client-logo-7"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo8} alt="client-logo-8"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo9} alt="client-logo-9"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo10} alt="client-logo-10"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo11} alt="client-logo-11"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo12} alt="client-logo-12"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo13} alt="client-logo-13"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                        <div>
                        <Card className="space-m-1">
                            <img src={imglogo14} alt="client-logo-14"className="img-responsive1 img-width" />
                            </Card>
                        </div>
                    </Slider>
                    </Container>
                </div>
            </div>
        )
    }
}
export default WhoWeAreLogoSliderComponent;

