import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Grid, Typography, Card, Checkbox, FormControlLabel, Paper, FormControl, Input, Button, InputAdornment, TextField, Divider } from '@material-ui/core';
import ContentWrapper from '../../../../common/components/contentWrapper';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import { SpinnerRoundFilled } from 'spinners-react';
import IconButton from '@material-ui/core/IconButton';
import scrollToComponent from 'react-scroll-to-component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import tick from '../../../../assests/images/tick-circle.svg'
import InfoIcon from '../../../../assests/images/info.svg'
import CloseIcon from '../../../../assests/images/close.svg'
import { subscriptionsPlans, updatePaymentDetails } from '../actions/dealerAction';
const Loader = require('react-loader');
class DealerPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            loaded: false,
            
        }
        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.onChangeCardNumber = this.onChangeCardNumber.bind(this);
        this.billingDetailsdatadeatails = JSON.parse(localStorage.getItem('savedbildetails'))
        this.selectedpackgesdata = JSON.parse(localStorage.getItem('selectedpackges'))

    }
    componentDidMount() {
        scrollToComponent(this.Violet, { offset: 0, align: 'top', duration: 1500 })
        this.sroclltop()
        setTimeout(() => {
            this.setState({loaded: true,})
        }, 3000);

    }
    sroclltop() {
        scrollToComponent(this.Violet, { offset: 0, align: 'top', duration: 1500 })
    }

    handleExpandClick = () => {
        if (this.state.expanded === false) {
            this.setState({
                expanded: true
            })
        }
        else {
            this.setState({
                expanded: false
            })
        }
    }

    onChangePackage = () => {
        localStorage.removeItem("checkoutdetails")
        localStorage.removeItem("singlepkg")
console.log("step2")
this.props.setStep(0);
        if (this.props.backSubmit) {
            this.props.backSubmit();
        }

    }
    onChangeCardNumber = () => {
       localStorage.removeItem("checkoutdetails")
        console.log("step2")
        this.props.setStep(2);
                if (this.props.backSubmit) {
                    this.props.backSubmit();
                } 
        
            }

    submitForm(event) {
        
        this.setState({loaded:false})
console.log(this.selectedpackgesdata,"selected",this.props.selectedPackage,this.billingDetailsdatadeatails)
        console.log(this.billingDetailsdatadeatails,)
        let addons =0
        if(this.billingDetailsdatadeatails.addOn === true){
         addons = 1 
        } else {
         addons = 0
        }
        console.log(addons,"selected1")
        let data = {
            "user_id": this.props.currentUser.userId,
            "membertier_id": this.billingDetailsdatadeatails.membertier_id ?this.billingDetailsdatadeatails.membertier_id :this.selectedpackgesdata.membertierid,
            "gateway": "stripe",
            "listing_addon": addons,
            "card_number": this.billingDetailsdatadeatails.card_number,
            "exp_month": this.billingDetailsdatadeatails.exp_month,
            "exp_year": this.billingDetailsdatadeatails.exp_year,
            "cvc": this.billingDetailsdatadeatails.cvc,
            "billing_country": this.billingDetailsdatadeatails.billing_country,
            "billing_state": this.billingDetailsdatadeatails.billing_state,
            "billing_city": this.billingDetailsdatadeatails.billing_city,
            "billing_zip": this.billingDetailsdatadeatails.billing_zip,
            "billing_addressline1": this.billingDetailsdatadeatails.billing_addressline1,
            "billing_addressline2": this.billingDetailsdatadeatails.billing_addressline2,
        };
        if (this.props.submit) {
            this.props.submit(data);
            console.log(data)
setTimeout(() => {
    this.setState({loaded:true})
}, 4000);
    //    localStorage.removeItem("savedbildetails")

            
        }

    }

    render() {

        console.log(this.props.selectedPackage,"props")
        const selectedpackgesdatas = JSON.parse(localStorage.getItem('selectedpackges'))
        const billingDetailsdata = JSON.parse(localStorage.getItem('savedbildetails'))
        const checkoutdetisdata = JSON.parse(localStorage.getItem("checkoutdetails"))
        console.log(billingDetailsdata,selectedpackgesdatas,checkoutdetisdata,"check123")
        const packageDetailsdate = this.props.selectedPackage;
        // const billingDetailsdata = this.props.billingDetails;
        let _addon = '';
        let totalPrise ='';
        if (billingDetailsdata && billingDetailsdata.addOn === true) {
            _addon = 1;

        }
        const addedprice = +selectedpackgesdatas?.price + +selectedpackgesdatas?.price;
        console.log(addedprice,selectedpackgesdatas?.price)
        console.log(totalPrise,this.props,"props");
        return (
                  <div style={{ background: '#fff' }}>
                 <div className="space-3">
                 {this.state.loaded== false ? <div class="d-spinner">
        <SpinnerRoundFilled size={51} thickness={125} speed={80} color="rgba(57, 107, 172, 1)" className="spp"  enabled={this.state.loaded == false} /></div> :
                    <Container class="violet" ref={(section) => { this.Violet = section; }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <div>
                                    <Container>
                                        <div className="space-2" >
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <div >
                                                        <Typography variant="h6" component="p" className="primary-text uppercase">NEW MEMBERSHIP</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <div>
                                                        <Button onClick={this.onChangePackage} variant="contained" color="primary" className=" float-right primary-bg color-white ">CHANGE</Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <Card className="space-3">
                                            <div className="space-2">
                                                <div>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} sm={6} md={6}>
                                                            <div>
                                                                <Typography variant="body1"><b>{selectedpackgesdatas && selectedpackgesdatas.membertiername}</b></Typography>

                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} md={6}>
                                                            <div>
                                                                <Typography variant="small" className="float-right">${selectedpackgesdatas && selectedpackgesdatas.pricepermonth}/Month</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <p className="color-AAAAAA">{selectedpackgesdatas && selectedpackgesdatas.shortdesc}</p>
                                                        </Grid>
                                                    </Grid>
                                                        <Grid container spacing={2}>
                                                                {selectedpackgesdatas && selectedpackgesdatas.packagedetails && selectedpackgesdatas.packagedetails.packagedetails.map((item)=>{
                                                                        return(<Grid container spacing={2} style={{margin:"10px 5px"}}>
                                                                                     <Grid item xs={6} sm={6} md={6} className="padding-0">{item.feature}</Grid><> { typeof item.limit != 'number' ? <Grid item xs={6} sm={12} md={6} className="padding-0"><img src={item?.isIncluded ? tick : CloseIcon} style={{ width: '25px' }} /></Grid> : <Grid item xs={6} sm={12} md={6} className="padding-0">{item?.limit}</Grid>}</>
                                                                                </Grid>
                                                                )}
                                                                )}
                                                        </Grid>
                                                </div>
                                            </div>
                                        </Card>
                                        {/* <div className="space-3">
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12} md={12} className="padding-0">
                                                    <div>
                                                        <div style={{ display: "flex" }} className="primary-text">
                                                            <CardActions disableSpacing>
                                                                Add Gift Card or Promo Code
                                                    <IconButton
                                                                    className={(this.state.expanded) ? "expandOpen" : "expand"}
                                                                    onClick={this.handleExpandClick}
                                                                    aria-expanded={this.state.expanded}
                                                                    aria-label="show more"
                                                                >
                                                                    <ExpandMoreIcon />
                                                                </IconButton>
                                                            </CardActions>
                                                            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                                                <div className="space-top-10">
                                                                    <TextField
                                                                        id="outlined-password-input"
                                                                        label="COPOUN CODE"
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                                </div>
                                                            </Collapse>

                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div> */}
                                        <br />
                                        <div>
                                            <Card className="space-2">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                                                                    <Typography variant="body1"><b>Billing Address</b></Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div className="float-right" style={{height:"30px"}}>
                                                                    <div className="Address">
                                                                        <ul>
                                                                            <li>
                                                                                <Button onClick={this.onChangeCardNumber}  ><span className="primary-text" >EDIT</span></Button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                 </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                            <div className="address">
                                                                <ul>
                                                                <li>{billingDetailsdata && billingDetailsdata.billing_addressline1}</li>
                                                                <li>{billingDetailsdata && billingDetailsdata.billing_addressline2}</li>
                                                                <li>{billingDetailsdata && billingDetailsdata.billing_state}</li>
                                                                <li>{billingDetailsdata && billingDetailsdata.billing_city}</li>
                                                                <li>{billingDetailsdata && billingDetailsdata.billing_zip}</li>
                                                                </ul>
                                                            </div>
                                                            </Grid>
                                                         </Grid>
                                                        
                                                    </Grid>

                                                </Grid>
                                            </Card>
                                        </div>
                                    </Container>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='checkout' >
                                    <div className="space-top-50">
                                        <Paper >
                                            <Container>
                                                <div  >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} sm={6} md={6}>
                                                            <div className='space-m-1'>
                                                                <Typography variant="body1"><b>Payment Method</b></Typography>
                                                                <div className="space-top-10" style={{ display: 'inline-block' }}>
                                                                    <ul className="box-0">
                                                                        {/* <li>
                                                                            <div className="space-box">
                                                                            </div>
                                                                        </li> */}
                                                                        <li>
                                                                            <Typography variant="p" className="colorAAAAAA">Card Number<br></br>*{billingDetailsdata?.card_number}</Typography>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} md={6}>
                                                            <div className="Address">
                                                                <ul>
                                                                    <li>
                                                                        <Button onClick={this.onChangeCardNumber}  ><span className="color-CEA05F" >EDIT</span></Button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Grid>
                                                        {/* <Grid item xs={12} sm={12} md={12}>
                                                            <div className="space-3">
                                                                <FormControl className="form-width-100">
                                                                    <Input
                                                                        id="standard-adornment-weight"
                                                                        placeholder="VERIFY CVV"
                                                                        value={billingDetailsdata.cvc}
                                                                        endAdornment={<InputAdornment position="end"
                                                                        ></InputAdornment>}
                                                                        aria-describedby="standard-weight-helper-text"
                                                                        inputProps={{
                                                                            'aria-label': 'weight',
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </Grid> */}
                                                    </Grid>
                                                    <div>
                                                        <Divider></Divider>
                                                    </div>
                                                    {/* <Loader loaded={this.state.loaded}>     */}
                                                       <div className="space-top-50 space-bottom-30 space-left-30 space-right-30">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div>
                                                                    <Typography className="colorAAAAAA"><b> {packageDetailsdate && packageDetailsdate.membertiername}</b></Typography>
                                                                    {/* {_addon?
                                                                        <Typography className="colorAAAAAA">Add Listing and Promotions</Typography>
                                                                        : ''} */}
                                                                </div>
                                                                <div className="space-top-20">
                                                                    <Typography variant="h6" className="colorAAAAAA"><b>Total</b></Typography>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6}>
                                                                <div className="float-right">
                                                                    <div>
                                                                        <Typography className="colorAAAAAA"><b> ${selectedpackgesdatas && selectedpackgesdatas.pricepermonth}</b></Typography>
                                                                        {/* {_addon?
                                                                            <Typography className="colorAAAAAA"><b>${selectedpackgesdatas && selectedpackgesdatas.price}</b></Typography>
                                                                            : ''} */}
                                                                    </div>
                                                                    <div className="space-top-20">
                                                                    {/* {_addon?<Typography className="colorAAAAAA"><b>${addedprice}.00</b></Typography>: */}
                                                                            <Typography className="colorAAAAAA"><b>${selectedpackgesdatas && selectedpackgesdatas.pricepermonth}</b></Typography>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                       
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <div className="space-top-20">
                                                                    <div>
                                                                        <Button type="submit" className="SubmitColor2 text-center1 form-width-100" >Submit</Button>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    {/* </Loader> */}
                                                </div>
                                            </Container>

                                        </Paper>
                                    </div>
                                </form>
                            </Grid>
                        </Grid>
                                                                        </Container> }

                </div>
                
            </div>
        );
    }
    // render(){
    //     return(<ContentWrapper
    //         contentView={this.getContentView()}
    //         />
    //         )
    // }
}


function mapStateToProps({ dealer, auth }) {
    return {
        selectedPackage: dealer.selectedPackage,
        billingDetails: dealer.billingDetails,
        currentUser: auth.currentUser,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updatePaymentDetails,
    },
        dispatch)
}



// navigator.geolocation.getCurrentPosition(showMap);
export default connect(mapStateToProps, mapDispatchToProps)(DealerPayment);