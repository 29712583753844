import React, { useEffect, useState } from "react";
import { Container, Typography, Divider, Box } from "@material-ui/core";
import {
  Breadcrumbs,
  Stack,
  Grid,
  TablePagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import ContentWrapper from "../../../../common/components/contentWrapper";
import { getDealerInventoryDetailsAPI } from "../actions/dealerInventoryaction";
import { SpinnerRoundFilled } from "spinners-react";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import DealerDetailedCardView from "./detailed-view";

const DealerDetailedView = () => {
  const ContentView = () => {
    const history = useHistory();
    const location = useLocation();
    const { payloadData } = location.state || {}
    const latestSortByValue = sessionStorage.getItem("sortByValue") ?? null;
    const [loader, setloader] = useState(true);
    const [dealerList, setDealerList] = useState([]);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sortOption, setSortOption] = useState(latestSortByValue ?? "newestFirst");
    const [payloadformat, setpayloadformat] = useState('');
    const [model, setModel] = useState("")

    useEffect(() => {
      getDealerInventoryDetails();
    }, [])

    const getDealerInventoryDetails = () => {
      setloader(true);
      let payload = {
        brand: payloadData?.brand,
        // key: payloadData?.key,
        dial_color: payloadData?.dial_color,
        condition: payloadData?.condition?.toUpperCase(),
        inventory_status: payloadData?.inventory_status,
        type: payloadData?.type,
        accountid: payloadData?.accountid,
        productid: payloadData?.productid,
        model_no: payloadData?.model_no
      };
      setpayloadformat(payload);
      setModel(payloadData?.model_no);
      setPage(0);
      getDealerInventoryDetailsAPI(payload, (err, response) => {
        if (response?.data) {
          let quantityCheck = response.data?.[0]?.inventory_quantity;
          if (quantityCheck) {
            let filteredValue = response.data.filter(item => item.createdon == payloadData?.inventory_date);
            setDealerList(filteredValue);
            setloader(false);
          }
          else {
            // const filterValue = response.data.sort((a, b) => {
            //   if (a.purchaseprice === null || a.purchaseprice === undefined) return 1;
            //   if (b.purchaseprice === null || b.purchaseprice === undefined) return -1;
            //   return a.purchaseprice - b.purchaseprice;
            // });
            switch (sortOption) {
              case 'lowToHigh':
                handleSortChange('lowToHigh', response?.data);
                break;
              case 'highToLow':
                handleSortChange('highToLow', response?.data);
                break;
              case 'newestFirst':
              default:
                handleSortChange('newestFirst', response?.data);
                break;
            }
            setloader(false);
          }
        }
        if (err) {
          setloader(false);
          console.log("ERROR", err);
        }
      });
    };

    const handleSortChange = (option, data = dealerList) => {
      setSortOption(option);
      sessionStorage.setItem("sortByValue", option);
      if (option === "lowToHigh") {
        const filterValue = data.sort((a, b) => {
          if (a.targetwholesaleprice === null || a.targetwholesaleprice === undefined) return 1;
          if (b.targetwholesaleprice === null || b.targetwholesaleprice === undefined) return -1;
          return a.targetwholesaleprice - b.targetwholesaleprice;
        });
        setDealerList(filterValue);
      }
      if (option === "highToLow") {
        const filterValue = data.sort((a, b) => {
          if (a.targetwholesaleprice === null || a.targetwholesaleprice === undefined) return 1;
          if (b.targetwholesaleprice === null || b.targetwholesaleprice === undefined) return -1;
          return b.targetwholesaleprice - a.targetwholesaleprice;
        });
        setDealerList(filterValue);
      }
      if (option === "newestFirst") {
        const filterDate = data.sort((a, b) => {
          if (a.createdon === null || a.createdon === undefined) return 1;
          if (b.createdon === null || b.createdon === undefined) return -1;
          return new Date(b.createdon) - new Date(a.createdon);
        });
        setDealerList(filterDate);
      }
    };

    const getOptionStyles = (option) => ({
      fontWeight: sortOption === option ? "700" : "normal",
      borderBottom: sortOption === option ? "2px solid #CEA05F" : "none",
      cursor: "pointer",
      fontSize: '13px',
    });

    const handleChangePage = (event, newpage) => {
      setPage(newpage);
    };

    const handleChangeRowsPerPage = (event) => {
      setrowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleInventoryView = (data) => {
      const { inventoryid = "" } = data
      history.push(`/dealerinventory/inventory-view/${inventoryid}`, { payloadData, data });
    }

    return (
      <div className="space-bottom-30" style={{ marginTop: "25px" }}>
        <Container>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Stack spacing={1}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<span className="sperator">›</span>}
                >
                  <Link
                    style={{
                      fontSize: "14px",
                      color: "#CEA05F",
                      textDecoration: "underline !important",
                    }}
                    to={{
                      pathname: "/dealerInventory",
                      state: { payloadData }
                    }}
                  >
                    Dealer Inventory
                  </Link>

                  <Grid
                    style={{
                      fontSize: "14px",
                      color: "#9e9e9e",
                      backgroundColor: "white",
                      cursor: 'pointer'
                    }}
                    aria-current="page"
                    onClick={() => {
                      let payloadData = {
                        brand: payloadformat?.brand,
                        // key: payloadformat?.key,
                        dial_color: payloadformat?.dialColor,
                        inventory_status: payloadformat.inventory_status,
                        type: payloadformat.type,
                        productid: payloadformat?.productid ?? null,
                        model_no: model ?? "",
                      };
                      history.push(`/dealerinventory/view`, { payloadData });
                    }}
                  // to={{
                  //     pathname: "/dealerInventory/view",
                  //   }}
                  >
                    {payloadData?.model_no ?? "NA"}
                  </Grid>
                  <Grid
                    style={{
                      fontSize: "14px",
                      color: "#9e9e9e",
                      backgroundColor: "white",
                    }}
                    aria-current="page"
                  >
                    {payloadData?.BusinessName ?? ""}
                  </Grid>
                </Breadcrumbs>
              </Stack>
            </Grid>
            {/* <Grid item xs={12} md={6} sx={{
              p: '0 !important'
            }}></Grid> */}
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              alignItems="end"
              justifyContent="end"
              mb={1}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid
                  style={{ fontWeight: "bold" }}
                >
                  Sort by
                </Grid>
                <Grid
                  onClick={() => handleSortChange("newestFirst")}
                  style={getOptionStyles("newestFirst")}
                >
                  Newest First
                </Grid>
                <Grid
                  onClick={() => handleSortChange("lowToHigh")}
                  style={getOptionStyles("lowToHigh")}
                >
                  Price - Low to High
                </Grid>
                <Grid
                  onClick={() => handleSortChange("highToLow")}
                  style={getOptionStyles("highToLow")}
                >
                  Price - High to Low
                </Grid>
              </Stack>
            </Grid>
            <Divider style={{ width: "100%", border: "0.2px solid rgb(236, 219, 194)" }} />
            {loader === true ? (
              <div className="d-spinner">
                <SpinnerRoundFilled
                  size={51}
                  thickness={125}
                  speed={80}
                  color="rgba(57, 107, 172, 1)"
                  className="spp"
                  enabled={loader}
                />
              </div>
            )
              :
              (
                <Grid container spacing={2} style={{ marginTop: '1%' }}>
                  {dealerList && dealerList.length > 0 ? (
                    dealerList.map((item) => {
                      return <DealerDetailedCardView item={item} handleInventoryView={handleInventoryView} />

                    })
                  ) : (
                    <Box
                      component="span"
                      sx={{
                        border: "2px dashed grey",
                        width: "100%",
                        padding: "50px 72px",
                        marginTop: "20px",
                      }}
                    >
                      {" "}
                      <Container>
                        <Typography
                          variant="h6"
                          className="color-BCBCBC"
                          component="p"
                          align="center"
                        >
                          <b>No Records Found</b>
                        </Typography>
                      </Container>
                    </Box>
                  )}
                </Grid>
              )}
          </Grid>
          {dealerList?.length > 0 &&
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={dealerList?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Container>
      </div>
    );
  };
  return <ContentWrapper contentView={ContentView()} />;
};

export default DealerDetailedView;
