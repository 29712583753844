import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Container,
    Grid,
    Typography,
    Link,
    FormControl,
    InputLabel,
    Select,
    Paper,
    MenuItem,
    Box,
    Chip,
    Tooltip,
    Divider,
} from "@material-ui/core";
import { InputBase } from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MaterialTable from "material-table";
import Icon from "@material-ui/core/Icon";
import Email from '../../../../../assests/images/profile/Path 2038.png';
import phone from '../../../../../assests/PhoneCall.png';
import NoData from '../../../../../assests/images/no-data.jpg'
import imageNotAvailable from '../../../../../assests/images/img-soon.png'
import {
    fetchInventoryWatches,
    brandList,
    DealerList,
    filterBySearch,
    inventoryDetailById,
    SearchInventoryWatches,
    fetchInventoryExcelList,
} from "../../actions/dashboardAction";
import _ from "lodash";
import searchinventry from "../../actions/dashboardaapi"
import Card from "@material-ui/core/Card";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import { SpinnerRoundFilled } from "spinners-react";
import ContentWrapper from "../../../../../common/components/contentWrapper";
import FacebookIcon from '../../../../../../src/assests/Facebook.png';
import whatsappIcon from '../../../../../../src/assests/whatsappIcon.png'
import Imessage from '../../../../../../src/assests/Imessage.png'
import Telegram from '../../../../../../src/assests/Telegram.png'
import profileimg from "../../../../../../src/assests/images/profile/profile.png";
// import Slider from 'react-slick';
const InventoryStatusLookup = {
    "1": "Inhand",
    "2": "Incoming",
    "3": "Out on Memo",
    "4": "On Hold / Reserved",
    "5": "Available to Order",
    "6": "Needs Service / Polish",
    "7": "At Service",
    "8": "Need to Ship",
    "9": "Shipped waiting on payment",
    "10": "Sold & Cashed Out",
    "11": "Intake Complete",
};

const communicationLogo = {
    phone: phone,
    imessage: Imessage,
    whatsapp: whatsappIcon,
    facebook: FacebookIcon,
    telegram: Telegram
}

class PrivateDealerInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            render: false,
            searchEnabled: false,
            inventoryid: "",
            productid: "",
            purchaseprice: "",
            // purchasedate: "",
            modledata: "",
            purchasefrom: "",
            CaseSize: "",
            CaseMaterial: "",
            BandMaterial: "",
            disable: "",
            selectedProduct: { productId: "" },
            sold: true,
            avail: false,
            soldbtn: false,
            BrandName: "",
            brand_id: "",
            brand_description: "",
            InventoryType: "",
            InventoryStatus: '',
            key: "",
            data: [],
            searchString: "",
            nonsearchstring: true,
            updatedInvenentryList: [],
            errorMessage: "",
            inventory_id: "",
            listed_on: "all",
            sold_status: "",
            loaded: false,
            UserId: "",
            purchasedate: 0,
            autocomplete: {
                currentInput: "",
                loading: false,
                data: [],
            },
            cursor: 0,
            inventoryData: [],

            selected: { product: null },
            copied: false,
            setCopied: false,
            soldStatus: "0",
            dataSoldUnsold: false,
            removedSoldUnsold: "",
            open: false,
            rowsPerPage: 10,
            page: 0,
            downloadloading: false,
            inventoryxlid: this.props?.match?.params?.bulkUploadId
                ? this.props?.match?.params?.bulkUploadId
                : "",
            anchorE2: null,
            openDropDown: false,
            inventoryStatus: "ALL",
            openE2: false,
            InventoryCategory: null,
            type: null,
            dealerName: '',
            accessData: null,
            userData: sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null,
            reasonChange: '',
            openReasonChange: false,
            rowDetails: '',
            soldText: 'Sold Reason:',
            unsoldText: 'Unsold Reason:',
            communicationMethod: [],
            businessName: '',
            logo: '',
            settingInventoryStatus: false,
            dealerenable: true,
        };

        this.SearchInventoryWatchesdetails = this.SearchInventoryWatchesdetails.bind(this);
        this.handleChangeOfBrandByBrandName = this.handleChangeOfBrandByBrandName.bind(this);
        this.autocomplete = this.autocomplete.bind(this);
        this.removebrandname = this.removebrandname.bind(this);
        this.removeInventoryType = this.removeInventoryType.bind(this);
        this.removeInventoryStatus = this.removeInventoryStatus.bind(this);
        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
        this.handleChangeOfInventoryStatus = this.handleChangeOfInventoryStatus.bind(this);
        this.removedealername = this.removedealername.bind(this);
        this.removeInventoryCategory = this.removeInventoryCategory.bind(this);
    }


    componentDidMount() {
        console.log(this.props.match.params, "this.props.history:this.props.history")
        if (this.props.history && this.props.history.location && this.props.history.location.state && this.props.location.state.inventoryCacheState) {
            this.setState({ ...this.props.location.state.inventoryCacheState });
        } else {

            this.setState({
                UserId:
                    this.props && this.props.currentUser && this.props.currentUser.userId,
            });
            setTimeout(() => {
                this.setState({ loaded: true });
            }, 2000);
            this.props.brandList();
            this.props.DealerList();
        }
    }
    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ openDropDown: true }, () => {
            document.addEventListener("click", this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ openDropDown: false }, () => {
            document.removeEventListener("click", this.hideDropdownMenu);
        });
    }


    // componentWillReceiveProps(newProps) {
    //     this.setState({
    //         ...this.state,
    //     });
    //     const data = {
    //         brand_id: this.state.brand_id,
    //         brand_description: this.state.brand_description,
    //         inventory_type: this.state.InventoryType,
    //         inventory_status: this.state.InventoryStatus,
    //         type: this.state.type,
    //         category: this.state.InventoryCategory
    //     };
    //     if (data.type === "ALL") {
    //         delete data.type
    //     }
    //     if (data.category === "ALL") {
    //         delete data.category
    //     }
    //     this.SearchInventoryWatchesdetails();
    // }
    handleChangeOfBrandByBrandName = (event) => {
        let brand = event.target.value;
        console.log(event.target.value);
        this.setState({
            brand_id: brand.brandid,
            brand_description: brand.brandname,
        });
        let brandName = "";
        if (brand.brandid == "ALL") {
            brandName = "";
        } else {
            brandName = brand.brandid;
        }
        this.setState({
            BrandName: brandName,
            modledata: brand.brandname,
        });
        setTimeout(() => {
            this.SearchInventoryWatchesdetails();
        }, 1000);

    };

    removeInventoryStatus() {
        this.setState({
            inventoryStatus: "",
            InventoryStatus: "",
            searchEnabled: false,
        });
        setTimeout(() => {
            this.SearchInventoryWatchesdetails();
        }, 1000);
    }


    removeInventoryType() {
        this.setState({
            InventoryType: "",
            searchEnabled: false,
        });
        setTimeout(() => {
            this.SearchInventoryWatchesdetails();
        }, 1000);
    }


    removedealername() {
        this.setState({
            dealerName: "",
            searchEnabled: false,
        });
        this.setState({ inventoryData: [], page: 0, loaded: true });
        this.setState({
            businessName: '',
            logo: '',
            settingInventoryStatus: '',
            communicationMethod: [],
            dealerenable: true
        });
        // setTimeout(() => {
        //     this.SearchInventoryWatchesdetails();
        // }, 1000);
    }
    removeInventoryCategory() {
        this.setState({
            InventoryCategory: "",
            searchEnabled: false,
        });
        setTimeout(() => {
            this.SearchInventoryWatchesdetails();
        }, 1000);

    }
    removebrandname() {
        this.props.brandList();
        this.setState({
            modledata: "",
            removedbrndname: "",
            searchEnabled: false,
            brand_id: "",
            brand_description: "",
            BrandName: "",
            loaded: false,
            page_no: "1",
            limit: "12",
        });

        setTimeout(() => {
            this.SearchInventoryWatchesdetails();
        }, 1000);
    }

    onChangeOfFirstName = (event) => {
        this.setState({
            key: event.target.value,
        });
    };
    autocomplete(evt) {
        let text = evt.target.value;
        this.setState({ searchString: text });
        this.setState({ loading: true });
        this.getAutocomplete(text);
    }

    selectItem(id) {
        const { searchItems } = this.state;
        let selectedItem = searchItems.find((item) => item.model === id);
        this.setState({ searchString: selectedItem.searchattr });
        this.setState({ searchStringdetails: selectedItem.searchattr });
        this.setState({ autocomplete: { data: [] } });
    }

    handleListKeydown(evt) {
        console.log(evt);
    }

    handleChange(evt) {
        this.setState({ item: { [evt.target.name]: evt.target.value } });
    }

    SearchInventoryWatchesdetails(userObject = null) {
        this.setState({ loaded: false });
        let keySearch = this.state.key;
        let searchData = "";
        if (keySearch) {
            searchData = keySearch.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
            searchData = searchData.replaceAll("-", "");
        }

        let data = {
            brand_id: this.state.brand_id,
            brand_name: this.state.brand_description,
            key: searchData,
            inventory_type: this.state.InventoryType,
            inventory_status: this.state.InventoryStatus,
            type: this.state.type,
            category: this.state.InventoryCategory
        };
        if (data.type === "ALL") {
            delete data.type
        }
        if (data.category === "ALL") {
            delete data.category
        }
        if (
            data.brand_id !== "" ||
            data.key !== "" ||
            data.inventory_type !== "" ||
            data.inventory_status !== "" ||
            data.type !== "ALL" ||
            data.category !== "ALL"
        ) {
            this.setState({ searchEnabled: true });
        }
        if (data.sold === "") {
            data.sold = "ALL"
        }
        const dealerName = this.state?.dealerName || "";
        searchinventry.getPublicFeedDealersDAta(data, dealerName)
            .then((res) => {
                console.log(res, "get public dat")
                if (res.data && res.data.inventory) {
                    console.log("step 1")
                    this.setState({ inventoryData: res.data.inventory, updatedInvenentryList: res.data?.inventory, page: 0, loaded: true });
                } else {
                    console.log("step 2")
                    this.setState({ inventoryData: [], page: 0, loaded: true });
                }
                if (res?.data?.dealerfeedsetting && res.data?.dealerfeedsetting.length) {
                    const setting = res.data.dealerfeedsetting[0];
                    this.setState({
                        businessName: setting.businessname,
                        logo: setting.logo_url,
                        settingInventoryStatus: setting?.inventory_status,
                        settingTargetWholeSalePrice: false,
                        settingEndCustomerPricing: false,
                        communicationMethod: setting?.communication?.communication ? setting?.communication?.communication : []
                    });
                }
                if (res.success === 0) {
                    this.setState({
                        updatedInvenentryList: [],
                        errorMessage: res.message,
                        loaded: true,
                    });
                }
                else {
                    this.setState({
                        errorMessage: res.message,
                        loaded: true,
                    });
                }
            }).catch((e) => {
                this.setState({
                    errorMessage: "error",
                    loaded: true,
                });
            })
    }

    handleChangeOfInventoryStatus(event) {
        let status = event.target.value;
        this.setState({
            inventoryStatus: status,
        });

        setTimeout(() => {
            this.SearchInventoryWatchesdetails();
        }, 1000);
    }


    handleChangePage = (event, newpage) => {
        this.setState({ page: newpage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value });
        this.setState({ page: 0 });
    };

    handleClearButton = () => {
        this.setState({
            key: "",
        });
        setTimeout(() => {
            this.SearchInventoryWatchesdetails();
        }, 1000);
    };
    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            //13 is the enter keycode
            this.SearchInventoryWatchesdetails();
        }
    }
    endAdornment = () => {
        const values = this.state.key;
        if (values) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => {
                            this.handleClearButton();
                        }}
                        onMouseDown={(e) => {
                            e.preventDefault();
                        }}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        disabled={this.state.dealerenable}
                        onClick={this.SearchInventoryWatchesdetails}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        } else {
            return (
                <InputAdornment position="end">
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        disabled={this.state.dealerenable}
                        onClick={this.SearchInventoryWatchesdetails}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        }
    };
    RedirectionChange(item) {
        if (item.mode == 'whatsapp') {
            window.open(`https://web.whatsapp.com/#${item?.value}`)

        }
    }
    inventoryrender() {
        let inventoryList = [];
        if (
            this.state.searchEnabled &&
            (this.state.key !== "" ||
                this.state.brand_id !== "" ||
                this.state.InventoryType !== "" ||
                this.state.InventoryStatus !== ""
            )
        ) {
            console.log("checked in if")
            inventoryList = this.state.updatedInvenentryList;
        } else {
            console.log("checked in else")
            inventoryList = this.state.inventoryData;
        }
        const brandInfo = this.props.brandInfo.data;
        const dealerInfo = this.props?.dealerInfo?.data;
        console.log('dealerInfo', this.props)
        const dealernotavalContent = !this.state.dealerenable && this.state.loaded ? { paddingLeft: "20px" } : { paddingLeft: "20px", display: 'flex', height: '70%', justifyContent: 'center', alignItems: 'center' }
        const dealerCheck = !this.state.dealerenable && this.state.loaded
        return (
            <div className="space-bottom-30 someextrahight" style={{ marginTop: '20px' }}>
                {/* {this.state.errorMessage && (
                      <div style={{}}>
                          <h1 style={{textAlign:'center'}}>{this.state.errorMessage}</h1>
                          <br />
                          <br />
                         <img src={NoData} alt="watchimage" className="img-responsive" style={{ backgroundSize: 'cover',  margin: 'auto', }} />
                       </div>
                    ) } */}
                <Grid container spacing={3} style={{ width: "100%" }}>
                    {!this.state.errorMessage && (
                        <>
                            <Grid item xs={12} sm={6} md={3}>
                                <Paper style={dealernotavalContent} >
                                    {dealerCheck ?
                                        <div>
                                            <div className="space-bottom-20 space-top-20">
                                                <Typography type="title" color="inherit">
                                                    <h2><b>DEALER INFORMATION</b></h2>
                                                </Typography>
                                            </div>


                                            <div className="profile-pic" style={{ position: 'relative' }}>
                                                {/* <input accept="image/*" className="input-upload" id="icon-button-file2" type="file" /> */}

                                                <label htmlFor="icon-button-file2">
                                                    <div>
                                                        <img src={this.state.logo ? this.state.logo.replaceAll('"', "") : profileimg} alt="watchimage" className="img-responsive" style={{ width: "140px", height: '140px', backgroundSize: 'cover', borderRadius: '50%', margin: 'auto', cursor: 'pointer' }} />
                                                    </div>
                                                </label>
                                            </div>
                                            <div style={{ clear: 'both' }}></div>
                                            <div className="profile-Details space-bottom-20 space-top-20">
                                                <Typography variant="body1" color="inherit">
                                                    <b>Business Name:  {this.state.businessName}</b>
                                                </Typography>
                                            </div>
                                            <Divider />
                                            <div className="userdetails">
                                                <ul>
                                                    {
                                                        this.state.communicationMethod && this.state.communicationMethod.map((item) => {

                                                            return (
                                                                <li>
                                                                    <div className="color-555555" style={{ wordBreak: 'break-word' }}>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={1} md={1}>
                                                                                <img src={communicationLogo[item.mode]} alt="" width="14px" />
                                                                            </Grid>
                                                                            <Grid item xs={11} md={9} style={{ cursor: 'pointer' }} onClick={() => this.RedirectionChange(item)}>
                                                                                &nbsp;&nbsp;
                                                                                {item?.value}

                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </li>


                                                            )
                                                        })
                                                    }


                                                </ul>
                                            </div>
                                        </div>
                                        : <Typography
                                            variant="h6"
                                            className="color-BCBCBC"
                                            component="p"
                                            align="center"
                                        >
                                            <b>Dealer is not available</b>
                                        </Typography>}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9}  >
                                <Paper className="space-2">
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            <Typography type="title" color="inherit">
                                                <h1><b>MY DEALER INVENTORY</b></h1>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ margin: "-10px" }}>
                                        {/* <Grid item xs={12} sm={6} md={12}> */}

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6} md={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12} md={4}>
                                                        <div>
                                                            <span
                                                                onClick={this.removedealername}
                                                                style={{ float: "right" }}
                                                            >
                                                                <Icon
                                                                    className={
                                                                        this.state.dealerName ? "fa fa-times-circle" : ""
                                                                    }
                                                                />
                                                            </span>
                                                            <FormControl variant="outlined" className="formControl">
                                                                <InputLabel
                                                                    id="demo-simple-select-outlined-label"
                                                                    className="font-14"
                                                                >
                                                                    Dealer
                                                                </InputLabel>
                                                                <Select
                                                                    style={{ color: "black" }}
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== 'misc_product') {
                                                                            this.setState({ dealerName: e.target.value, InventoryCategory: "", dealerenable: false });
                                                                        } else {
                                                                            this.setState({ dealerName: e.target.value });
                                                                        }
                                                                        //this.SearchInventoryWatchesdetails();
                                                                        setTimeout(() => {
                                                                            this.SearchInventoryWatchesdetails();
                                                                        }, 0);
                                                                    }
                                                                    }
                                                                    label="Dealer"
                                                                    value={this.state.dealerName}
                                                                >
                                                                    {/* <MenuItem value="naveen">naveen</MenuItem> */}

                                                                    {dealerInfo &&
                                                                        dealerInfo?.map((row) => (
                                                                            <MenuItem value={row.businessname}>{row?.businessname}</MenuItem>
                                                                        ))}

                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                        <Typography
                                                            variant="h6"
                                                            className="color-383838"
                                                            style={{ fontSize: "15px" }}
                                                        >
                                                            {this.state.type}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4}>
                                                        <div>
                                                            <span
                                                                onClick={this.removebrandname}
                                                                style={{ float: "right" }}
                                                            >
                                                                <Icon
                                                                    className={
                                                                        this.state.modledata ? "fa fa-times-circle" : ""
                                                                    }
                                                                />
                                                            </span>

                                                            <FormControl variant="outlined" className="formControl">
                                                                <InputLabel
                                                                    id="demo-simple-select-outlined-label"
                                                                    className="font-14"
                                                                >
                                                                    Brand
                                                                </InputLabel>
                                                                <Select
                                                                    style={{ color: "black", backgroundColor: this.state.dealerenable ? 'rgba(0, 0, 0, 0.26)' : null }}
                                                                    disabled={this.state.dealerenable}
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    onChange={this.handleChangeOfBrandByBrandName}
                                                                    label="Brand"
                                                                >
                                                                    <MenuItem value="ALL">All</MenuItem>
                                                                    {brandInfo &&
                                                                        brandInfo.map((row) => (
                                                                            <MenuItem value={row}>{row.brandname}</MenuItem>
                                                                        ))}
                                                                </Select>
                                                                {/* <Typography
                                                            variant="h6"
                                                            className="color-383838"
                                                            style={{ fontSize: "15px" }}
                                                        >
                                                            {this.state.modledata
                                                                ? this.state.modledata
                                                                : this.state.removedbrndname}{" "}
                                                        </Typography> */}
                                                            </FormControl>
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={4}>
                                                        <FormControl
                                                            className="form-width-99"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                this.SearchInventoryWatchesdetails();
                                                            }}
                                                        >
                                                            <div style={{ marginTop: "30px", marginLeft: "15px" }}>
                                                                <Paper
                                                                    // component="form"
                                                                    sx={{ p: "2px 4px" }}
                                                                    style={{ height: "50px", display: "flex", backgroundColor: this.state.dealerenable ? 'rgba(0, 0, 0, 0.26)' : null }}
                                                                >
                                                                    <InputBase
                                                                        fullWidth

                                                                        sx={{ ml: 3, flex: 1 }}
                                                                        inputProps={{
                                                                            "aria-label": "Enter Model no / Inventory Id / Inventory Title",
                                                                        }}
                                                                        disabled={this.state.dealerenable}
                                                                        placeholder="Enter Model no / Inventory Id / Inventory Title"
                                                                        onChange={this.onChangeOfFirstName}
                                                                        onKeyPress={this.enterPressed.bind(this)}
                                                                        value={this.state.key}
                                                                        endAdornment={this.endAdornment()}
                                                                    />
                                                                </Paper>
                                                            </div>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <br />
                                    {this.state.loaded === false ? (
                                        <div class="d-spinner">
                                            <SpinnerRoundFilled
                                                size={51}
                                                thickness={125}
                                                speed={80}
                                                color="rgba(57, 107, 172, 1)"
                                                className="spp"
                                                enabled={this.state.loaded == false}
                                            />
                                        </div>
                                    ) : (
                                        <Grid container spacing={1}>
                                            {this.state.inventoryData &&
                                                this.state.inventoryData.length > 0 &&
                                                this.state.inventoryxlid !== "" && (
                                                    <div style={{ margin: "auto", width: "100%" }}>
                                                        <Container style={{ marginBottom: "30px" }}>
                                                            <MaterialTable
                                                                title={`Bulk Upload Summary`}
                                                                data={this.state.inventoryData}
                                                                columns={[
                                                                    { field: "status", title: "STATUS" },
                                                                    {
                                                                        field: "filePath",
                                                                        title: "DOWNLOAD FILE",
                                                                        render: (rowData) => (
                                                                            <Link
                                                                                href={`${rowData.filePath}`}
                                                                                target="_blank"
                                                                            >
                                                                                {rowData.filePath}
                                                                            </Link>
                                                                        ),
                                                                    },
                                                                ]}
                                                            />
                                                        </Container>
                                                    </div>
                                                )}
                                            {inventoryList &&
                                                inventoryList.length === 0 &&
                                                !this.state.loaded ? (
                                                <Container style={{ marginBottom: "30px" }}>
                                                    <Typography
                                                        variant="body2"
                                                        className="textSecondary color-292C2F"
                                                        component="p"
                                                        align="center"
                                                    >
                                                        <b>No Watches Added Here!</b>
                                                    </Typography>
                                                </Container>
                                            ) : (
                                                ""
                                            )}
                                            {inventoryList && inventoryList.length > 0 ? (
                                                inventoryList &&
                                                inventoryList
                                                    .slice(
                                                        this.state.page * this.state.rowsPerPage,
                                                        this.state.page * this.state.rowsPerPage +
                                                        this.state.rowsPerPage
                                                    )
                                                    .map((row) => {
                                                        return (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={12}
                                                                md={6}
                                                                className=""
                                                                key={row.inventoryid}
                                                            >
                                                                <Link className="">
                                                                    <Card
                                                                        className="inventrybox"
                                                                        style={{
                                                                            cursor: row?.product_imagepaths?.images ? 'pointer' : null,
                                                                            //   boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                                                                            "&:hover": {
                                                                                boxShadow:
                                                                                    "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                                                                            },
                                                                            background: (row.type && (row.type === "misc_watch" || row.type === "misc_product")) ? "antiquewhite" : "white",
                                                                            paddingTop: '20px',
                                                                            paddingBottom: '20px'
                                                                        }}
                                                                    >
                                                                        <Grid container spacing={2}>
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                sm={4}
                                                                                md={4}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="text-center1"
                                                                                    style={{
                                                                                        paddingLeft: "30px",
                                                                                    }}
                                                                                >
                                                                                    {(row.sold_unsold_reason || row.sold_unsold_reason) ? (<Tooltip
                                                                                        title={
                                                                                            (row.sold == 1 ?
                                                                                                `${this.state.soldText}${row.sold_unsold_reason}` :
                                                                                                `${this.state.unsoldText}${row.sold_unsold_reason}`
                                                                                            )
                                                                                        }
                                                                                        placement="right"
                                                                                    >

                                                                                        <img
                                                                                            src={
                                                                                                row?.imagepaths
                                                                                                    ? (row?.imagepaths?.front || row?.imagepaths?.back || row?.imagepaths?.left || row?.imagepaths?.right || row?.imagepaths?.free_form || row?.imagepaths?.buckle_opened || row?.imagepaths?.buckle_closed || row?.imagepaths?.wrist_shot)
                                                                                                    : (row?.product_imagepaths?.images
                                                                                                        ? row?.product_imagepaths?.images
                                                                                                        : imageNotAvailable)
                                                                                            }
                                                                                            alt="rolex"
                                                                                            className="img-responsive1 inventry-img"
                                                                                        />
                                                                                    </Tooltip>) : (<img
                                                                                        src={
                                                                                            row?.imagepaths
                                                                                                ? (row?.imagepaths?.front || row?.imagepaths?.back || row?.imagepaths?.left || row?.imagepaths?.right || row?.imagepaths?.free_form || row?.imagepaths?.buckle_opened || row?.imagepaths?.buckle_closed || row?.imagepaths?.wrist_shot)
                                                                                                : (row?.product_imagepaths?.images
                                                                                                    ? row?.product_imagepaths?.images
                                                                                                    : imageNotAvailable)
                                                                                        }
                                                                                        alt="rolex"
                                                                                        className="img-responsive1 inventry-img"
                                                                                    />)}

                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={8} md={8}>
                                                                                <div className="space-1 text-center1">
                                                                                    <Grid container spacing={2}>
                                                                                        {row.voidreason ? (
                                                                                            <Tooltip title={row.voidreason}>
                                                                                                <Grid
                                                                                                    item
                                                                                                    xs={12}
                                                                                                    sm={12}
                                                                                                    md={12}
                                                                                                    style={{
                                                                                                        display: "flex",
                                                                                                        justifyContent: "flex-end",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        row.status &&
                                                                                                            row.status === "Void" ? (
                                                                                                            <Chip
                                                                                                                className="void-status"
                                                                                                                size="small"
                                                                                                                label={"Void"}
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Tooltip>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        <Grid item xs={12} sm={12} md={12}>
                                                                                            <div
                                                                                            >
                                                                                                <Typography
                                                                                                    variant="subtitle2"
                                                                                                    className="textSecondary color-292C2 MuiTypography-textupper"
                                                                                                    component="p"
                                                                                                >
                                                                                                    <b>
                                                                                                        {

                                                                                                            (row.type === null) ?
                                                                                                                (
                                                                                                                    row?.brandname
                                                                                                                        ? row?.brandname + (
                                                                                                                            row?.prodattr?.reference
                                                                                                                                ? " - " + row?.prodattr?.reference + (
                                                                                                                                    row?.inventoryid ?
                                                                                                                                        " - " + row.inventoryid : ""
                                                                                                                                )
                                                                                                                                : ""
                                                                                                                        )
                                                                                                                        : "" + (
                                                                                                                            row?.prodattr?.reference
                                                                                                                                ? row?.prodattr?.reference + (
                                                                                                                                    row?.inventoryid
                                                                                                                                        ? " - " + row?.inventoryid
                                                                                                                                        : "" + (
                                                                                                                                            row?.inventoryid
                                                                                                                                                ? row?.inventoryid
                                                                                                                                                : ""
                                                                                                                                        )
                                                                                                                                )
                                                                                                                                : row?.inventoryid
                                                                                                                        )
                                                                                                                )
                                                                                                                : row?.prodattr?.brand_name
                                                                                                                    ? row?.prodattr?.brand_name + (
                                                                                                                        row?.prodattr?.model_number
                                                                                                                            ? " - " + row?.prodattr?.model_number + (
                                                                                                                                row?.inventoryid ?
                                                                                                                                    " - " + row.inventoryid : ""
                                                                                                                            )
                                                                                                                            : ""
                                                                                                                    )
                                                                                                                    : "" + (
                                                                                                                        row?.prodattr?.model_number
                                                                                                                            ? row?.prodattr?.model_number + (
                                                                                                                                row?.inventoryid
                                                                                                                                    ? " - " + row?.inventoryid
                                                                                                                                    : "" + (
                                                                                                                                        row?.inventoryid
                                                                                                                                            ? row?.inventoryid
                                                                                                                                            : ""
                                                                                                                                    )
                                                                                                                            )
                                                                                                                            : row?.inventoryid
                                                                                                                    )

                                                                                                        }
                                                                                                    </b>
                                                                                                </Typography>
                                                                                            </div>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12} md={12}>
                                                                                            <div
                                                                                            >
                                                                                                {row.type !== 'misc_product' && (
                                                                                                    <>
                                                                                                        {row?.brandname && (
                                                                                                            <>
                                                                                                                <Typography variant="p">
                                                                                                                    Brand :{" "}
                                                                                                                    {(row?.brandname)
                                                                                                                    }
                                                                                                                </Typography>
                                                                                                                <br />
                                                                                                            </>
                                                                                                        )}
                                                                                                        {row?.reference && (
                                                                                                            <>
                                                                                                                <Typography variant="p">
                                                                                                                    Model Number :{" "}
                                                                                                                    {(row?.reference)
                                                                                                                    }
                                                                                                                </Typography>
                                                                                                                <br />
                                                                                                            </>
                                                                                                        )}
                                                                                                        <Typography variant="p">
                                                                                                            Box :{" "}
                                                                                                            {(row?.warrantybox && row.warrantybox === '1'
                                                                                                                ? "Yes"
                                                                                                                : "No")
                                                                                                            }
                                                                                                        </Typography>
                                                                                                        <br />
                                                                                                        <Typography variant="p">
                                                                                                            Warranty  :{" "}
                                                                                                            {row.warrantypaper ? "Yes"
                                                                                                                : "No"
                                                                                                            }
                                                                                                        </Typography>
                                                                                                        <br />
                                                                                                        <Typography variant="p">
                                                                                                            Condition  :{" "}
                                                                                                            {row.condition && row.condition === 4 ? 'New' :
                                                                                                                row.condition && row.condition === 3 ? 'Retail Ready' :
                                                                                                                    row.condition && row.condition === 2 ? 'Mint' :
                                                                                                                        'Pre-owned'
                                                                                                            }
                                                                                                        </Typography>
                                                                                                        <br />
                                                                                                        {this.status?.settingTargetWholeSalePrice && row.targetwholesaleprice && (
                                                                                                            <>
                                                                                                                <Typography variant="p">
                                                                                                                    Target Wholesale Price:{" "}
                                                                                                                    $ {row.targetwholesaleprice}
                                                                                                                </Typography>
                                                                                                                <br />
                                                                                                            </>
                                                                                                        )}
                                                                                                        {this.status?.settingEndCustomerPricing && row.retailprice && (
                                                                                                            <>
                                                                                                                <Typography variant="p">
                                                                                                                    Target End Customer Price:{" "}
                                                                                                                    $ {row.retailprice}
                                                                                                                </Typography>
                                                                                                                <br />
                                                                                                            </>
                                                                                                        )}
                                                                                                        {row.note && (
                                                                                                            <>
                                                                                                                <Typography variant="p">
                                                                                                                    Public Dealer Notes:{" "}
                                                                                                                    {row.note}
                                                                                                                </Typography>
                                                                                                                <br />
                                                                                                            </>
                                                                                                        )}
                                                                                                        {this.status?.settingInventoryStatus && (
                                                                                                            <Typography variant="p">
                                                                                                                Inventory Status :{" "}
                                                                                                                {
                                                                                                                    (row?.inventory_status && this.status.settingInventoryStatus
                                                                                                                        ? InventoryStatusLookup[row?.inventory_status]
                                                                                                                        : "---")
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        )}

                                                                                                    </>
                                                                                                )}
                                                                                                {row.type === 'misc_product' && (
                                                                                                    <>
                                                                                                        <Typography variant="p">
                                                                                                            Product Name:{" "}
                                                                                                            {(row &&
                                                                                                                row
                                                                                                                    ?.technical_details
                                                                                                                    ?.product_title ?
                                                                                                                row
                                                                                                                    ?.technical_details
                                                                                                                    ?.product_title
                                                                                                                : "---")
                                                                                                            }
                                                                                                        </Typography>
                                                                                                        <br />
                                                                                                        <Typography variant="p" className="text-capitalize">
                                                                                                            Category:{" "}
                                                                                                            {(row &&
                                                                                                                row.category ?
                                                                                                                row.category
                                                                                                                : "---")
                                                                                                            }
                                                                                                        </Typography>
                                                                                                        <br />
                                                                                                        <Typography variant="p">
                                                                                                            Condition  :{" "}
                                                                                                            {row.condition && row.condition === 1 ? 'New' :
                                                                                                                'Used'
                                                                                                            }
                                                                                                        </Typography>
                                                                                                        <br />
                                                                                                        {this.status?.settingInventoryStatus && (
                                                                                                            <Typography variant="p">
                                                                                                                Inventory Status :{" "}
                                                                                                                {
                                                                                                                    (row?.inventory_status && this.status.settingInventoryStatus
                                                                                                                        ? InventoryStatusLookup[row?.inventory_status]
                                                                                                                        : "---")
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        )}

                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </Grid>
                                                                                        {row.sold == 1 ? (
                                                                                            <div
                                                                                                style={{
                                                                                                    opacity: "0.4",
                                                                                                }}
                                                                                            >
                                                                                                <Grid container spacing={1}>
                                                                                                    <Grid item xs={6} sm={6} md={5}>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                marginTop: "7px",
                                                                                                                marginLeft: "10px",
                                                                                                                width: "77px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {/* <img src={ebay} alt="ebay" className="img-responsive1" /> */}
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6} sm={6} md={5}>

                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        ) : null}

                                                                                        <Grid item xs={12} sm={12} md={1}></Grid>

                                                                                    </Grid>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                </Link>
                                                            </Grid>
                                                        );
                                                    })
                                            ) : this.state.dealerenable ? (
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        border: "2px dashed grey",
                                                        width: "100%",
                                                        padding: "50px 72px",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    {" "}
                                                    <Container>
                                                        <Typography
                                                            variant="h6"
                                                            className="color-BCBCBC"
                                                            component="p"
                                                            align="center"
                                                        >
                                                            <b>Please Choose Dealer</b>
                                                        </Typography>
                                                    </Container>
                                                </Box>
                                            ) :
                                                (
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            border: "2px dashed grey",
                                                            width: "100%",
                                                            padding: "50px 72px",
                                                            marginTop: "20px",
                                                        }}
                                                    >
                                                        {" "}
                                                        <Container>
                                                            <Typography
                                                                variant="h6"
                                                                className="color-BCBCBC"
                                                                component="p"
                                                                align="center"
                                                            >
                                                                <b>No Records Found</b>
                                                            </Typography>
                                                        </Container>
                                                    </Box>
                                                )}
                                        </Grid>
                                    )}
                                    {!this.state.dealerenable ? <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={inventoryList.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        SelectProps={{
                                            native: true
                                        }}
                                        onPageChange={this.handleChangePage}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    /> : null}

                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>

            </div>
        );
    }
    render() {
        return (
            <div>
                <ContentWrapper contentView={this.inventoryrender()} >
                </ContentWrapper>

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, browseWatchesInfo, dealer } =
        state;
    return {
        currentUser: auth.currentUser,
        brandInfo: browseWatchesInfo.brandInfo,
        dealerInfo: dealer.dealerInfo
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchInventoryWatches,
            filterBySearch,
            brandList,
            DealerList,
            inventoryDetailById,
            fetchInventoryExcelList,
            SearchInventoryWatches,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateDealerInventory);
