import React, { Component } from 'react';
import { Container, Paper } from '@material-ui/core';

class SubHeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                <div>
                    <Paper style={{height:'50px'}} className="alignment-height">
                        <div className="space-top-10 space-bottom-10">
                            <Container>
                                <ul className="subheader">
                                    <li><a href="/browsewatches">WATCH COLLECTION</a></li>
                                    {/* <li><a href="#">catalogue</a></li> */}
                                    <li><a href="/faq">FAQ</a></li>
                                    {/* <li><a href="#">BUYER PROTECTION</a></li> */}
                                </ul>
                            </Container>
                        </div>
                    </Paper>
                </div>
            </div>
         );
    }
}
 
export default SubHeaderComponent;