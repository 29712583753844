import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { monthlyList, weeklyList, dailyList } from '../actions/dashboardAction';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
let monthly = [
  {
    name: "JAN",
    visits: 5,
  },
  {
    name: "FEB",
    visits: 10,
  },
  {
    name: "MAR",
    visits: 3,
  },
  {
    name: "APR",
    visits: 16,
  },
  {
    name: "MAY",
    visits: 20,
  },
  {
    name: "JUN",
    visits: 30,
  },
  {
    name: "JUL",
    visits: 25,
  },
  {
    name: "AUG",
    visits: 18,
  },
  {
    name: "SEP",
    visits: 10,
  },
  {
    name: "OCT",
    visits: 25,
  },
  {
    name: "NOV",
    visits: 29,
  },
  {
    name: "DEC",
    visits: 30,
  },
];
let weekly = [
  {
    name: "week 1",
    visits: 15,
  },
  {
    name: "week 2",
    visits: 1,
  },
  {
    name: "week 3",
    visits: 30,
  },
  {
    name: "week 4",
    visits: 12,
  },
  {
    name: "week 5",
    visits: 20,
  },
  {
    name: "week 6",
    visits: 30,
  },
  {
    name: "week 7",
    visits: 25,
  },
  {
    name: "week 8",
    visits: 18,
  },


];
let daily = [
  {
    name: "8 Mar",
    visits: 5,
  },
  {
    name: "7 Mar",
    visits: 2,
  },
  {
    name: "6 Mar",
    visits: 20,
  },
  {
    name: "week 4",
    visits: 12,
  },
  {
    name: "5 Mar",
    visits: 10,
  },
  {
    name: "4 Mar",
    visits: 2,
  },

];
class ChartComponent extends Component {
  constructor(props) {
    super(props);
    this.onChangeGraphData = this.onChangeGraphData.bind(this);
    this.state = {
      current: 'Monthly',

      data: []
    };
  }

  componentDidMount() {
    this.props.monthlyList();
    this.props.weeklyList();
    this.props.dailyList();
  }



  onChangeGraphData(e) {
    this.setState({
      ...this.state,
      current: e.target.value

    });

  }
  static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  render() {
    let data = [];
    if (this.state.current == 'Weekly') {
      data = this.props.weeklyListInfo;
      console.log(data);
    }
    if (this.state.current == 'Monthly') {
      data = this.props.monthlyListInfo;
      console.log(data);
    }
    if (this.state.current == 'Daily') {
      data = this.props.dailyListInfo;
    }
    return (
      <div>
        <div style={{ height: '330px' }}>
          <div style={{ height: "240px", width: "100" }}>
            <FormControl style={{ margin: '10px', minWidth: 120 }}>
              <InputLabel id="component-simple"  ></InputLabel>
              <Select
                labelId="component-simple"
                selected
                value={this.state.current}
                onChange={this.onChangeGraphData}
              >

                <MenuItem value="Monthly" default>Monthly</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
                <MenuItem value="Daily">Daily</MenuItem>

              </Select>
            </FormControl>
            <ResponsiveContainer>
              <AreaChart
                data={data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey="name">
                  <Label value="Pages of my website" offset={0} position="insideBottom" />
                </XAxis> */}
                <XAxis dataKey="name" label={{ value: this.state.current, offset:2, position: "insideBottom" }} />
                <YAxis label={{ value: "Search Count", position: "center",offset:1, angle: -90, }}  />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="visits"
                  stroke="#CEA05F "
                  fill="#CEA05F "
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>


      </div>

    );
  }
}

//   }

// }

function mapStateToProps({ InventoryList }) {
  return {
    monthlyListInfo: InventoryList.monthlyListInfo,
    weeklyListInfo: InventoryList.weeklyListInfo,
    dailyListInfo: InventoryList.dailyListInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    monthlyList,
    weeklyList,
    dailyList

  },
    dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);


