import React, { Component } from 'react';
import { Container,Typography, Grid, Card, CardMedia, CardContent, Button }  from '@material-ui/core';
import img1 from '../../../../assests/images/Group 1864.png';
import img2 from '../../../../assests/images/Group 1865.png';
import img3 from '../../../../assests/images/Group 1866.png';
import img4 from '../../../../assests/images/Group 1867.png';
import '../style/homeComponent.css';

class WatchLoversComponent extends Component {
    render() {
        return (
            <div>
                <Container>
                    <div>
                        <Typography variant="h5" align="center" className="heading-watch-lovers text-uppercase color-383838">
                            WatchOps was built for watch lovers, by watch lovers.
                        </Typography>
                    </div>
                    <div className="border-middle6"></div>
                    <div className="text-center1 padding-1">
                        <Typography variant="p" align="center" className="color-383838">
                            We exist to elevate your style with the finest timepieces in the world without having to own and maintain them.
                        </Typography>
                    </div>
                    <div className="padding-1"></div>
                    <div className="alignment-0">
                        <Grid container spacing={4}>
                            <Grid item={12} sm={6} md={3}>
                                <Card className="bg-card-watch-lovers-color">
                                    <div className="media">
                                        <img src={img1} alt="mg1" height="60px" className="img-responsive1 img-dimen"/>
                                    </div>
                                    <CardContent align="center padding-0">
                                        <Typography variant="h6" align="center" className="text-wrap color-white">
                                            Flexible Memberships
                                        </Typography>
                                        <div className="padding-2">
                                            <Typography variant="p" align="center">
                                                With 4 membership tiers, 4 rental terms and hundreds of styles, WatchOps has a watch for every occasion.
                                            </Typography>
                                        </div>
                                        <div className="padding-3 color-white text-center1">
                                            <Button href="/" className="color-white text-center1">READ MORE »</Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item={12} sm={6} md={3}>
                                <Card className="bg-card-watch-lovers-color">
                                <div className="media">
                                        <img src={img2} alt="mg1" height="60px" className="img-responsive1 img-dimen"/>
                                    </div>
                                    <CardContent align="center padding-0">
                                        <Typography variant="h6" align="center" className="text-wrap color-white">
                                                Amazing Selection
                                        </Typography>
                                        <div className="padding-2">
                                            <Typography variant="p" align="center">
                                                WatchOps offers the latest references from Rolex, Cartier, Breitling, Omega, Audemars Piguet, Patek Philippe and so many more.
                                            </Typography>
                                        </div>
                                        <div className="padding-3 color-white text-center1">
                                            <Button href="/" className="color-white text-center1">READ MORE »</Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item={12} sm={6} md={3}> 
                                <Card className="bg-card-watch-lovers-color">
                                <div className="media">
                                        <img src={img3} alt="mg1" height="60px" className="img-responsive1  img-dimen"/>
                                    </div>
                                    <CardContent align="center padding-0">
                                        <Typography variant="h6" align="center" className="text-wrap color-white">
                                            Incredible Service
                                        </Typography>
                                        <div className="padding-2">
                                            <Typography variant="p" align="center">
                                                We’ll help you choose the perfect piece and with fully-insured overnight shipping you’ll have your watch in record time.
                                            </Typography>
                                        </div>
                                        <div className="padding-3 color-white text-center1">
                                            <Button href="/" className="color-white text-center1">READ MORE »</Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item={12} sm={6} md={3}>
                            <Card className="bg-card-watch-lovers-color">
                                     <div className="media">
                                        <img src={img4} alt="mg1" height="60px" className="img-responsive1  img-dimen"/>
                                    </div>
                                    <CardContent align="center padding-0">
                                        <Typography variant="h6" align="center" className="text-wrap color-white">
                                            Premier Rewards
                                        </Typography>
                                        <div className="padding-2">
                                            <Typography variant="p" align="center">
                                                Members can accumulate and cash in rewards in a variety of ways, including entering our famous monthly drawings.
                                            </Typography>
                                        </div>
                                        <div className="padding-3 color-white text-center1">
                                            <Button href="/" className="color-white text-center1">READ MORE »</Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        )
    }
}
export default WatchLoversComponent;